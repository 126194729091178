import * as ActionType from "../constants/ActivitySheet";

const initialState = {
  listActivitySheet: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  listActivitySheetSection: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  activitySheetById: {
    data: {},
    isLoading: false,
  },
  createActivitySheetSection: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  updateActivitySheetSection: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  updateSortActivitySheet: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  createActivitySheet: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  updateActivitySheet: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  deleteActivitySheet: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

const activitySheetReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_ACTIVITY_SHEET_REQUEST:
      return {
        ...state,
        listActivitySheet: {
          ...state.listActivitySheet,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_ACTIVITY_SHEET_SUCCESS:
      const tempListActivitySheet = payload.listActivitySheetsSection.data.map(
        (activitySheetSection) => {
          return {
            ...activitySheetSection,
            listActivitySheet: payload.listActivitySheet.data.filter(
              (activitySheet) =>
                activitySheet.activitySheetSectionId ===
                activitySheetSection.activitySheetSectionId
            ),
          };
        }
      );
      return {
        ...state,
        listActivitySheet: {
          ...state.listActivitySheet,
          data: tempListActivitySheet,
          isLoading: false,
        },
      };
    case ActionType.GET_LIST_ACTIVITY_SHEET_FAILED:
      return {
        ...state,
        listActivitySheet: {
          ...state.listActivitySheet,
          isLoading: false,
        },
      };

    case ActionType.GET_ACTIVITY_SHEET_BY_ID_REQUEST:
      return {
        ...state,
        activitySheetById: {
          ...state.activitySheetById,
          isLoading: true,
        },
      };
    case ActionType.GET_ACTIVITY_SHEET_BY_ID_SUCCESS:
      return {
        ...state,
        activitySheetById: {
          ...state.activitySheetById,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_ACTIVITY_SHEET_BY_ID_FAILED:
      return {
        ...state,
        activitySheetById: {
          ...state.activitySheetById,
          isLoading: false,
        },
      };

    case ActionType.GET_LIST_ACTIVITY_SHEET_SECTION_REQUEST:
      return {
        ...state,
        listActivitySheetSection: {
          ...state.listActivitySheetSection,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_ACTIVITY_SHEET_SECTION_SUCCESS:
      return {
        ...state,
        listActivitySheetSection: {
          ...state.listActivitySheetSection,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_LIST_ACTIVITY_SHEET_SECTION_FAILED:
      return {
        ...state,
        listActivitySheetSection: {
          ...state.listActivitySheetSection,
          isLoading: false,
        },
      };

    case ActionType.CREATE_ACTIVITY_SHEET_SECTION_REQUEST:
      return {
        ...state,
        createActivitySheetSection: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.CREATE_ACTIVITY_SHEET_SECTION_SUCCESS:
      return {
        ...state,
        createActivitySheetSection: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.CREATE_ACTIVITY_SHEET_SECTION_FAILED:
      return {
        ...state,
        createActivitySheetSection: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.UPDATE_ACTIVITY_SHEET_SECTION_REQUEST:
      return {
        ...state,
        updateActivitySheetSection: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_ACTIVITY_SHEET_SECTION_SUCCESS:
      return {
        ...state,
        updateActivitySheetSection: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_ACTIVITY_SHEET_SECTION_FAILED:
      return {
        ...state,
        updateActivitySheetSection: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.UPDATE_SORT_ACTIVITY_SHEET_REQUEST:
      return {
        ...state,
        updateSortActivitySheet: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_SORT_ACTIVITY_SHEET_SUCCESS:
      return {
        ...state,
        updateSortActivitySheet: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_SORT_ACTIVITY_SHEET_FAILED:
      return {
        ...state,
        updateSortActivitySheet: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.CREATE_ACTIVITY_SHEET_REQUEST:
      return {
        ...state,
        createActivitySheet: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.CREATE_ACTIVITY_SHEET_SUCCESS:
      return {
        ...state,
        createActivitySheet: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.CREATE_ACTIVITY_SHEET_FAILED:
      return {
        ...state,
        createActivitySheet: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.UPDATE_ACTIVITY_SHEET_REQUEST:
      return {
        ...state,
        updateActivitySheet: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_ACTIVITY_SHEET_SUCCESS:
      return {
        ...state,
        updateActivitySheet: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_ACTIVITY_SHEET_FAILED:
      return {
        ...state,
        updateActivitySheet: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.DELETE_ACTIVITY_SHEET_REQUEST:
      return {
        ...state,
        deleteActivitySheet: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.DELETE_ACTIVITY_SHEET_SUCCESS:
      return {
        ...state,
        deleteActivitySheet: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.DELETE_ACTIVITY_SHEET_FAILED:
      return {
        ...state,
        deleteActivitySheet: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    default: {
      return state;
    }
  }
};

export default activitySheetReducer;
