import httpRequest from "../util/axios";

export const fetchListBook = async (option = {}) => {
  try {
    const result = await httpRequest.get("/admin/books", option, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListCategory = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/books/categories",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateBook = async (body = {}) => {
  try {
    const result = await httpRequest.post("/admin/books", body, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchBookById = async (option = {}) => {
  try {
    const result = await httpRequest.get(`/admin/books/${option?.id}`);

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateBook = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/books/${body?.id}`,
      body,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusBook = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/books/${body?.id}/status`,
      body,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
