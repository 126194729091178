export const GET_LIST_ROLES_REQUEST = "GET_LIST_ROLES_REQUEST";
export const GET_LIST_ROLES_SUCCESS = "GET_LIST_ROLES_SUCCESS";
export const GET_LIST_ROLES_FAILED = "GET_LIST_ROLES_FAILED";

export const GET_LIST_MENUS_REQUEST = "GET_LIST_MENUS_REQUEST";
export const GET_LIST_MENUS_SUCCESS = "GET_LIST_MENUS_SUCCESS";
export const GET_LIST_MENUS_FAILED = "GET_LIST_MENUS_FAILED";

export const CREATE_ADMIN_ROLE_REQUEST = "CREATE_ADMIN_ROLE_REQUEST";
export const CREATE_ADMIN_ROLE_SUCCESS = "CREATE_ADMIN_ROLE_SUCCESS";
export const CREATE_ADMIN_ROLE_FAILED = "CREATE_ADMIN_ROLE_FAILED";

export const RESET_STATUS_RBAC_REQUEST = "RESET_STATUS_RBAC_REQUEST";
export const RESET_STATUS_RBAC_SUCCESS = "RESET_STATUS_RBAC_SUCCESS";
export const RESET_STATUS_RBAC_FAILED = "RESET_STATUS_RBAC_FAILED";

export const GET_ADMIN_ROLE_BY_ID_REQUEST = "GET_ADMIN_ROLE_BY_ID_REQUEST";
export const GET_ADMIN_ROLE_BY_ID_SUCCESS = "GET_ADMIN_ROLE_BY_ID_SUCCESS";
export const GET_ADMIN_ROLE_BY_ID_FAILED = "GET_ADMIN_ROLE_BY_ID_FAILED";

export const UPDATE_ADMIN_ROLE_REQUEST = "UPDATE_ADMIN_ROLE_REQUEST";
export const UPDATE_ADMIN_ROLE_SUCCESS = "UPDATE_ADMIN_ROLE_SUCCESS";
export const UPDATE_ADMIN_ROLE_FAILED = "UPDATE_ADMIN_ROLE_FAILED";

export const DELETE_ADMIN_ROLE_REQUEST = "DELETE_ADMIN_ROLE_REQUEST";
export const DELETE_ADMIN_ROLE_SUCCESS = "DELETE_ADMIN_ROLE_SUCCESS";
export const DELETE_ADMIN_ROLE_FAILED = "DELETE_ADMIN_ROLE_FAILED";

export const GET_LIST_ADMIN_USERS_REQUEST = "GET_LIST_ADMIN_USERS_REQUEST";
export const GET_LIST_ADMIN_USERS_SUCCESS = "GET_LIST_ADMIN_USERS_SUCCESS";
export const GET_LIST_ADMIN_USERS_FAILED = "GET_LIST_ADMIN_USERS_FAILED";

export const CREATE_ADMIN_USER_REQUEST = "CREATE_ADMIN_USER_REQUEST";
export const CREATE_ADMIN_USER_SUCCESS = "CREATE_ADMIN_USER_SUCCESS";
export const CREATE_ADMIN_USER_FAILED = "CREATE_ADMIN_USER_FAILED";

export const DELETE_ADMIN_USER_REQUEST = "DELETE_ADMIN_USER_REQUEST";
export const DELETE_ADMIN_USER_SUCCESS = "DELETE_ADMIN_USER_SUCCESS";
export const DELETE_ADMIN_USER_FAILED = "DELETE_ADMIN_USER_FAILED";

export const GET_ADMIN_USER_BY_ID_REQUEST = "GET_ADMIN_USER_BY_ID_REQUEST";
export const GET_ADMIN_USER_BY_ID_SUCCESS = "GET_ADMIN_USER_BY_ID_SUCCESS";
export const GET_ADMIN_USER_BY_ID_FAILED = "GET_ADMIN_USER_BY_ID_FAILED";

export const UPDATE_ROLE_USER_REQUEST = "UPDATE_ROLE_USER_REQUEST";
export const UPDATE_ROLE_USER_SUCCESS = "UPDATE_ROLE_USER_SUCCESS";
export const UPDATE_ROLE_USER_FAILED = "UPDATE_ROLE_USER_FAILED";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
