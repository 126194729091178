import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Book";
import * as Api from "../../api/book";
import {
  createBookFailed,
  createBookSuccess,
  getListBookFailed,
  getListBookSuccess,
  getListCategoryFailed,
  getListCategorySuccess,
  getBookByIdSuccess,
  getBookByIdFailed,
  updateBookSuccess,
  updateBookFailed,
  updateStatusBookSuccess,
} from "../actions/Book";
import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";
import moment from "moment";

function* getListBookWithOption({ payload }) {
  try {
    const resultListBook = yield call(Api.fetchListBook, {
      params: { ...payload.params },
    });
    yield put(
      getListBookSuccess(
        {
          ...resultListBook,
          limit: payload?.params?.limit,
          isForRemoveBook: payload?.params?.isForRemoveBook,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list book", "Failed");
    yield put(getListBookFailed());
  }
}

export function* watchListBookRequest() {
  yield takeEvery(ActionType.GET_LIST_BOOK_REQUEST, getListBookWithOption);
}

function* getListCategoryWithOption({ payload }) {
  try {
    const resultListCategory = yield call(Api.fetchListCategory, {
      params: { ...payload.params },
    });
    yield put(
      getListCategorySuccess(
        {
          ...resultListCategory,
          limit: payload?.params?.limit,
          type: payload?.params?.type,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list publisher", "Failed");
    yield put(getListCategoryFailed());
  }
}

export function* watchListCategoryRequest() {
  yield takeEvery(
    ActionType.GET_LIST_CATEGORY_REQUEST,
    getListCategoryWithOption
  );
}

function* createBook({ payload }) {
  try {
    const resultCreateBook = yield call(Api.fetchCreateBook, payload);
    if (resultCreateBook.success === true) {
      yield put(createBookSuccess(resultCreateBook.data));
      successNotification("Create book", "Success");
    } else {
      yield put(createBookFailed(resultCreateBook || {}));
      resultCreateBook?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create book", "Failed");
    yield put(createBookFailed());
  }
}

export function* watchCreateBookRequest() {
  yield takeEvery(ActionType.CREATE_BOOK_REQUEST, createBook);
}

function* getBookById({ payload }) {
  try {
    const resultBook = yield call(Api.fetchBookById, {
      id: payload?.id,
    });
    yield put(getBookByIdSuccess({ ...resultBook } || {}));
  } catch (error) {
    errorNotification("Get book by id", "Failed");
    yield put(getBookByIdFailed());
  }
}

export function* watchGetBookByIdRequest() {
  yield takeEvery(ActionType.GET_BOOK_BY_ID_REQUEST, getBookById);
}

function* updateBook({ payload }) {
  try {
    const resultUpdateBook = yield call(Api.fetchUpdateBook, payload);
    yield put(
      updateBookSuccess(
        {
          data: {
            ...payload,
            expiredAt: moment
              .unix(payload?.expiredAt)
              .local()
              .format("YYYY-MM-DD"),
          },
        } || {}
      )
    );
    if (resultUpdateBook.success === true) {
      successNotification("Update book", "Success");
    } else {
      resultUpdateBook?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update book", "Failed");
    yield put(updateBookFailed());
  }
}

export function* watchUpdateBookRequest() {
  yield takeEvery(ActionType.UPDATE_BOOK_REQUEST, updateBook);
}

function* updateStatusBook({ payload }) {
  try {
    const resultUpdateStatusBook = yield call(
      Api.fetchUpdateStatusBook,
      payload
    );
    yield put(
      updateStatusBookSuccess(
        {
          data: payload,
        } || {}
      )
    );
    if (resultUpdateStatusBook.success === true) {
      successNotification("Update status book", "Success");
    } else {
      resultUpdateStatusBook?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update status book by id", "Failed");
    yield put(updateBookFailed());
  }
}

export function* watchUpdateStatusBookRequest() {
  yield takeEvery(ActionType.UPDATE_STATUS_BOOK_REQUEST, updateStatusBook);
}

export default function* rootSaga() {
  yield all([
    fork(watchListBookRequest),
    fork(watchListCategoryRequest),
    fork(watchCreateBookRequest),
    fork(watchGetBookByIdRequest),
    fork(watchUpdateBookRequest),
    fork(watchUpdateStatusBookRequest),
  ]);
}
