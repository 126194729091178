import httpRequest from "../util/axios";

export const fetchListSchool = async (option = {}) => {
  try {
    const result = await httpRequest.get("/admin/schools", option, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListTeacherBySchoolId = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/${option?.schoolId}/teachers`,
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListStudentBySchoolId = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/${option?.id}/students`,
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateSchool = async (body = {}) => {
  try {
    const result = await httpRequest.post("/admin/schools", body, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateSchool = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/info`,
      body,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateSchooCred = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}`,
      body,
      null,
      {}
    );
    if (result.status === 200) return result.data;
    else return result.response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusSchool = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/status`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListClassBySchoolId = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/${option?.id}/classes`,
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateClass = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      `/admin/schools/${body?.schoolId}/classes`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateClass = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/classes/${body?.classId}`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDeleteClass = async (body = {}) => {
  try {
    const result = await httpRequest.delete(
      `/admin/schools/${body?.schoolId}/classes/${body?.classId}`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateTeacher = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      `/admin/schools/${body?.schoolId}/teachers`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusTeacher = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/teachers/${body?.teacherId}/status`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateTeacher = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/teachers/${body?.teacherId}`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDeleteTeacher = async (body = {}) => {
  try {
    const result = await httpRequest.delete(
      `/admin/schools/${body?.schoolId}/teachers/${body?.teacherId}`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSetClassTeacher = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/teachers/set-classes`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSetClassesStudent = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/students/set-classes`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDeleteStudent = async (body = {}) => {
  try {
    const result = await httpRequest.delete(
      `/admin/schools/${body?.schoolId}/students/${body?.studentId}`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusStudent = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/students/${body?.studentId}/status`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSetBulkActiveTeacher = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/teachers/actived-by-ids`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSetBulkActiveStudent = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/students/actived-by-ids`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchFindSchool = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/public/find-school",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDetailSchool = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/${body?.schoolId}`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSummarySchool = async () => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/summary-school`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSummaryBySchoolId = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/${option?.id}/summary`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchActivitySchool = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/schools/list-of-activities",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListOfReadSchool = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/schools/list-of-reads",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListOfReadStudent = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/list-of-reads/${body?.readingId}/students`,
      body,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchReadHistorySchool = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/schools/list-of-readhistory",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDetailReadHistorySchool = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/list-of-readhistory/${body?.userId}`,
      { params: body.params },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateStudent = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      `/admin/schools/${body?.schoolId}/students`,
      body.payload,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStudent = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/students/${body?.studentId}`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSetClassStudent = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/students/${body?.studentId}/set-class`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
export const fetchConnectAccountStudent = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/students/connect-account-from-school`,
      { ...body?.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListFamily = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/schools/family/search",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchChildFamily = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/family/search/${body?.familyId}`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSetAccounToFamily = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/schools/set-account-to-family",
      body,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSetAccounToFamilyAdmin = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      `/admin/schools/${body.schoolId}/set-account-to-family`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchVerifyConnectFamily = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/students/${body?.studentId}/verify`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchGetDetailConnectionByStudentId = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/students/${body?.studentId}`
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchGetDetailConnectionByStudentIdAdmin = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/${body?.schoolId}/students/${body?.studentId}`
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const updateTeacherAdminAccess = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/schools/${body?.schoolId}/teachers/${body?.subUserId}/set-admin-access`,
      { ...body.payload }
    );
    if (result.status === 200) return result.data;
    else return result.response.data;
  } catch (error) {
    throw error;
  }
};
