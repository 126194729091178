import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";
import { filteredMenus, isSubMenuAllowed } from "../../util/allowedAction";
import useShowAlert from "../../hook/useShowAlert";

const { SubMenu } = Menu;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const pathname = useSelector(({ common }) => common.pathname);
  const [showAlert] = useShowAlert();

  const myProfile = useSelector(({ profile }) => profile.myProfile);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[0];
  let defaultSelectedKeys;
  if (
    defaultOpenKeys === "content-management" ||
    defaultOpenKeys === "billing-management" ||
    defaultOpenKeys === "user-engagement" ||
    defaultOpenKeys === "user-account" ||
    defaultOpenKeys === "user-activation" ||
    defaultOpenKeys === "content-display"
  ) {
    defaultSelectedKeys = selectedKeys.split("/")[1];
  } else {
    defaultSelectedKeys = selectedKeys.split("/")[0];
  }

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)} ${
            showAlert && myProfile?.data?.type === 6 ? "gx-mt-3" : ""
          }`}
          style={{
            borderBottomColor: myProfile?.data?.type === 6 ? "white" : "",
          }}
        >
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            defaultSelectedKeys={[defaultSelectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            className={`${
              myProfile?.data?.type === 7 && "custom-menu-publisher"
            } ${myProfile?.data?.type === 6 && "custom-menu-school"}`}
          >
            {/* {data
              .find((item) => item.type === myProfile?.data?.type)
              ?.sideMenus?.map((menu) => {
                if (menu?.isSubMenu) {
                  return (
                    <SubMenu
                      key={menu.key}
                      icon={menu.icon}
                      title={menu.title}
                      className="gx-custom-sub-menu"
                    >
                      {menu.items.map((subMenu) => (
                        <Menu.Item key={subMenu.key}>
                          <Link to={subMenu.to}>
                            <span>{subMenu.title}</span>
                          </Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  );
                } else {
                  return (
                    <Menu.Item key={menu.key} icon={menu.icon}>
                      <Link to={menu.to}>
                        <span>{menu.title}</span>
                      </Link>
                    </Menu.Item>
                  );
                }
              })} */}
            {filteredMenus(myProfile.data.menus).map((updateMenu) => {
              if (updateMenu?.isSubMenu) {
                return (
                  <SubMenu
                    key={updateMenu.key}
                    icon={updateMenu.icon}
                    title={updateMenu.title}
                    className="gx-custom-sub-menu"
                  >
                    {updateMenu.items.map((subMenu) => {
                      if (isSubMenuAllowed(subMenu, myProfile.data.type)) {
                        return (
                          <Menu.Item key={subMenu.key}>
                            <Link to={subMenu.to}>
                              <span>{subMenu.title}</span>
                            </Link>
                          </Menu.Item>
                        );
                      }
                      return null;
                    })}
                  </SubMenu>
                );
              } else {
                return (
                  <Menu.Item key={updateMenu.key} icon={updateMenu.icon}>
                    <Link to={updateMenu.to}>
                      <span>{updateMenu.title}</span>
                    </Link>
                  </Menu.Item>
                );
              }
            })}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
