import * as ActionType from "../constants/Transaction";
import {
  listTransactionDTO,
  listTransactionSubsTypeDTO,
} from "../helpers/transaction";

const initialState = {
  listTransactionSubsType: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  listTransaction: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  listRoyaltyStatement: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  detailRoyaltyStatement: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  detailReports: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  isLoadingActionTransaction: false,
  isSuccessActionTransaction: false,
  isErrorActionTransaction: false,
  createTransactionSpecialPackage: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

const transactionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_TRANSACTION_SUBS_TYPE_REQUEST:
      return {
        ...state,
        listTransactionSubsType: {
          ...state.listTransactionSubsType,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_TRANSACTION_SUBS_TYPE_SUCCESS:
      return {
        ...state,
        listTransactionSubsType: {
          ...state.listTransactionSubsType,
          data: listTransactionSubsTypeDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
          type: payload?.type,
        },
      };
    case ActionType.GET_LIST_TRANSACTION_SUBS_TYPE_FAILED:
      return {
        ...state,
        listTransactionSubsType: {
          ...state.listTransactionSubsType,
          isLoading: false,
        },
      };

    case ActionType.GET_LIST_TRANSACTION_REQUEST:
      return {
        ...state,
        listTransaction: {
          ...state.listTransaction,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_TRANSACTION_SUCCESS:
      return {
        ...state,
        listTransaction: {
          ...state.listTransaction,
          data: listTransactionDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_TRANSACTION_FAILED:
      return {
        ...state,
        listTransaction: {
          ...state.listTransaction,
          isLoading: false,
        },
      };

    case ActionType.GET_LIST_ROYALTY_STATEMENT_REQUEST:
      return {
        ...state,
        listRoyaltyStatement: {
          ...state.listRoyaltyStatement,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_ROYALTY_STATEMENT_SUCCESS:
      return {
        ...state,
        listRoyaltyStatement: {
          ...state.listRoyaltyStatement,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_ROYALTY_STATEMENT_FAILED:
      return {
        ...state,
        listRoyaltyStatement: {
          ...state.listRoyaltyStatement,
          isLoading: false,
        },
      };

    case ActionType.GET_DETAIL_REPORTS_REQUEST:
      return {
        ...state,
        detailReports: {
          ...state.detailReports,
          isLoading: true,
        },
      };
    case ActionType.GET_DETAIL_REPORTS_SUCCESS:
      return {
        ...state,
        detailReports: {
          ...state.detailReports,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_DETAIL_REPORTS_FAILED:
      return {
        ...state,
        detailReports: {
          ...state.detailReports,
          isLoading: false,
        },
      };

    case ActionType.GET_DETAIL_ROYALTY_STATEMENT_REQUEST:
      return {
        ...state,
        detailRoyaltyStatement: {
          ...state.detailRoyaltyStatement,
          isLoading: true,
        },
      };
    case ActionType.GET_DETAIL_ROYALTY_STATEMENT_SUCCESS:
      return {
        ...state,
        detailRoyaltyStatement: {
          ...state.detailRoyaltyStatement,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_DETAIL_ROYALTY_STATEMENT_FAILED:
      return {
        ...state,
        detailRoyaltyStatement: {
          ...state.detailRoyaltyStatement,
          isLoading: false,
        },
      };

    case ActionType.CREATE_TRANSACTION_SUBS_TYPE_REQUEST:
    case ActionType.UPDATE_TRANSACTION_SUBS_TYPE_REQUEST:
    case ActionType.UPDATE_STATUS_TRANSACTION_SUBS_TYPE_REQUEST:
    case ActionType.CREATE_MANUAL_TRANSACTION_REQUEST:
    case ActionType.UPDATE_STATUS_TRANSACTION_REQUEST:
    case ActionType.UPDATE_STATUS_PAYOUT_REQUEST:
      return {
        ...state,
        isSuccessActionTransaction: false,
        isErrorActionTransaction: false,
        isLoadingActionTransaction: true,
        redirectUrl: null,
      };
    case ActionType.CREATE_TRANSACTION_SUBS_TYPE_SUCCESS:
    case ActionType.UPDATE_TRANSACTION_SUBS_TYPE_SUCCESS:
    case ActionType.UPDATE_STATUS_TRANSACTION_SUBS_TYPE_SUCCESS:
    case ActionType.CREATE_MANUAL_TRANSACTION_SUCCESS:
    case ActionType.UPDATE_STATUS_TRANSACTION_SUCCESS:
    case ActionType.UPDATE_STATUS_PAYOUT_SUCCESS:
      return {
        ...state,
        isLoadingActionTransaction: false,
        isSuccessActionTransaction: true,
        redirectUrl: payload.redirectUrl,
      };
    case ActionType.CREATE_TRANSACTION_SUBS_TYPE_FAILED:
    case ActionType.UPDATE_TRANSACTION_SUBS_TYPE_FAILED:
    case ActionType.UPDATE_STATUS_TRANSACTION_SUBS_TYPE_FAILED:
    case ActionType.CREATE_MANUAL_TRANSACTION_FAILED:
    case ActionType.UPDATE_STATUS_TRANSACTION_FAILED:
    case ActionType.UPDATE_STATUS_PAYOUT_FAILED:
      return {
        ...state,
        isLoadingActionTransaction: false,
        isErrorActionTransaction: true,
        redirectUrl: null,
      };

    case ActionType.CREATE_TRANSACTION_SPECIAL_PACKAGE_REQUEST:
      return {
        ...state,
        createTransactionSpecialPackage: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.CREATE_TRANSACTION_SPECIAL_PACKAGE_SUCCESS:
      return {
        ...state,
        createTransactionSpecialPackage: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.CREATE_TRANSACTION_SPECIAL_PACKAGE_FAILED:
      return {
        ...state,
        createTransactionSpecialPackage: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    default: {
      return state;
    }
  }
};

export default transactionReducer;
