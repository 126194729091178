import React, { useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  hideMessage,
  showAuthLoader,
  userSignInPublisher,
} from "../../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../components/CircularProgress";

const SignInPartner = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  });

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userSignInPublisher(values));
  };

  return (
    <div className="gx-app-login-main-content gx-shadow-none">
      <div className="gx-app-logo-content">
        <div className="gx-app-logo-content-bg">
          <img src="/assets/images/bg.jpeg" alt="Neature" />
        </div>

        <div className="gx-app-logo-wid">
          <h1>
            <IntlMessages id="app.userAuth.signInPartner" />
          </h1>
        </div>
        <div className="gx-app-logo">
          <img alt="example" src="/assets/images/favicon.png" width="100" />
        </div>
      </div>
      <div className="gx-app-login-content">
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <Form.Item
            rules={[
              { required: true, message: "The input is not valid E-mail!" },
            ]}
            name="userNameOrEmail"
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please input your Password!" }]}
            name="password"
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button block type="primary" className="gx-mb-0" htmlType="submit">
              <IntlMessages id="app.userAuth.signIn" />
            </Button>
          </Form.Item>
        </Form>
      </div>

      {loader ? (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      ) : null}
      {showMessage ? message.error(alertMessage.toString()) : null}
    </div>
  );
};

export default SignInPartner;
