export const GET_LIST_RECOMMENDATION_REQUEST =
  "GET_LIST_RECOMMENDATION_REQUEST";
export const GET_LIST_RECOMMENDATION_SUCCESS =
  "GET_LIST_RECOMMENDATION_SUCCESS";
export const GET_LIST_RECOMMENDATION_FAILED = "GET_LIST_RECOMMENDATION_FAILED";

export const CREATE_RECOMMENDATION_REQUEST = "CREATE_RECOMMENDATION_REQUEST";
export const CREATE_RECOMMENDATION_SUCCESS = "CREATE_RECOMMENDATION_SUCCESS";
export const CREATE_RECOMMENDATION_FAILED = "CREATE_RECOMMENDATION_FAILED";

export const RESET_STATUS_RECOMMENDATION_REQUEST =
  "RESET_STATUS_RECOMMENDATION_REQUEST";

export const GET_RECOMMENDATION_BY_ID_REQUEST =
  "GET_RECOMMENDATION_BY_ID_REQUEST";
export const GET_RECOMMENDATION_BY_ID_SUCCESS =
  "GET_RECOMMENDATION_BY_ID_SUCCESS";
export const GET_RECOMMENDATION_BY_ID_FAILED =
  "GET_RECOMMENDATION_BY_ID_FAILED";

export const UPDATE_SORT_RECOMMENDATION_REQUEST =
  "UPDATE_SORT_RECOMMENDATION_REQUEST";
export const UPDATE_SORT_RECOMMENDATION_SUCCESS =
  "UPDATE_SORT_RECOMMENDATION_SUCCESS";
export const UPDATE_SORT_RECOMMENDATION_FAILED =
  "UPDATE_SORT_RECOMMENDATION_FAILED";

export const UPDATE_RECOMMENDATION_REQUEST = "UPDATE_RECOMMENDATION_REQUEST";
export const UPDATE_RECOMMENDATION_SUCCESS = "UPDATE_RECOMMENDATION_SUCCESS";
export const UPDATE_RECOMMENDATION_FAILED = "UPDATE_RECOMMENDATION_FAILED";

export const UPDATE_STATUS_RECOMMENDATION_REQUEST =
  "UPDATE_STATUS_RECOMMENDATION_REQUEST";
export const UPDATE_STATUS_RECOMMENDATION_SUCCESS =
  "UPDATE_STATUS_RECOMMENDATION_SUCCESS";
export const UPDATE_STATUS_RECOMMENDATION_FAILED =
  "UPDATE_STATUS_RECOMMENDATION_FAILED";
