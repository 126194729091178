import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Profile";
import * as Api from "../../api/profile";

import { errorNotification } from "../../components/NotificationPopUp";
import { getMyProfileFailed, getMyProfileSuccess } from "../actions";

function* getMyProfile({ payload }) {
  try {
    const resultMyProfile = yield call(Api.fetchMyProfile, {});
    yield put(getMyProfileSuccess({ ...resultMyProfile } || {}));
  } catch (error) {
    errorNotification("Get my profile", "Failed");
    yield put(getMyProfileFailed());
  }
}

export function* watchGetMyProfileRequest() {
  yield takeEvery(ActionType.GET_MY_PROFILE_REQUEST, getMyProfile);
}

export default function* rootSaga() {
  yield all([fork(watchGetMyProfileRequest)]);
}
