import httpRequest from "../util/axios";

export const fetchListCampaign = async (option = {}) => {
  try {
    const res = await httpRequest.get("/admin/campaigns", option, null, {});
    if (res.status === 200) {
      return res.data;
    } else {
      return res.response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const updateStatusCampaign = async (id = null, body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/campaigns/${id}/status`,
      body,
      null,
      {}
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const createCampaign = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/campaigns",
      { ...body },
      null,
      {}
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const updateCampaign = async (id, body = {}) => {
  try {
    const res = await httpRequest.put(`admin/campaigns/${id}`, body, null, {});
    if (res.status === 200) {
      return res.data;
    } else {
      return res.response.data;
    }
  } catch (err) {
    throw err;
  }
};
