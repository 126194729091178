import * as ActionType from "../constants/Rbac";

const initialState = {
  listRoles: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  listMenus: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  createAdminRole: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  adminRoleById: {
    data: {},
    isLoading: false,
  },
  deleteAdminRole: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  updateAdminRole: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  listAdminUsers: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  createAdminUser: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  deleteAdminUser: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  adminUserById: {
    data: {},
    isLoading: false,
  },
  updateRoleUser: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  resetPassword: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};
const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_ROLES_REQUEST:
      return {
        ...state,
        listRoles: {
          ...state.listRoles,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_ROLES_SUCCESS:
      return {
        ...state,
        listRoles: {
          ...state.listRoles,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_ROLES_FAILED:
      return {
        ...state,
        listRoles: {
          ...state.listRoles,
          isLoading: false,
        },
      };

    case ActionType.GET_LIST_MENUS_REQUEST:
      return {
        ...state,
        listMenus: {
          ...state.listMenus,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_MENUS_SUCCESS:
      return {
        ...state,
        listMenus: {
          ...state.listMenus,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_MENUS_FAILED:
      return {
        ...state,
        listMenus: {
          ...state.listMenus,
          isLoading: false,
        },
      };

    case ActionType.CREATE_ADMIN_ROLE_REQUEST:
      return {
        ...state,
        createAdminRole: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.CREATE_ADMIN_ROLE_SUCCESS:
      return {
        ...state,
        createAdminRole: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.CREATE_ADMIN_ROLE_FAILED:
      return {
        ...state,
        createAdminRole: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.UPDATE_ADMIN_ROLE_REQUEST:
      return {
        ...state,
        updateAdminRole: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_ADMIN_ROLE_SUCCESS:
      return {
        ...state,
        updateAdminRole: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_ADMIN_ROLE_FAILED:
      return {
        ...state,
        updateAdminRole: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.RESET_STATUS_RBAC_REQUEST:
      return {
        ...state,
        createAdminRole: {
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
        createAdminUser: {
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
        updateAdminRole: {
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
        deleteAdminRole: {
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
        deleteAdminUser: {
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
        updateRoleUser: {
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
        resetPassword: {
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
      };

    case ActionType.GET_ADMIN_ROLE_BY_ID_REQUEST:
      return {
        ...state,
        adminRoleById: {
          ...state.adminRoleById,
          isLoading: true,
        },
      };
    case ActionType.GET_ADMIN_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        adminRoleById: {
          ...state.adminRoleById,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_ADMIN_ROLE_BY_ID_FAILED:
      return {
        ...state,
        adminRoleById: {
          ...state.adminRoleById,
          isLoading: false,
        },
      };

    case ActionType.DELETE_ADMIN_ROLE_REQUEST:
      return {
        ...state,
        deleteAdminRole: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.DELETE_ADMIN_ROLE_SUCCESS:
      return {
        ...state,
        deleteAdminRole: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.DELETE_ADMIN_ROLE_FAILED:
      return {
        ...state,
        deleteAdminRole: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.GET_LIST_ADMIN_USERS_REQUEST:
      return {
        ...state,
        listAdminUsers: {
          ...state.listAdminUsers,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        listAdminUsers: {
          ...state.listAdminUsers,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_ADMIN_USERS_FAILED:
      return {
        ...state,
        listAdminUsers: {
          ...state.listAdminUsers,
          isLoading: false,
        },
      };

    case ActionType.CREATE_ADMIN_USER_REQUEST:
      return {
        ...state,
        createAdminUser: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.CREATE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        createAdminUser: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.CREATE_ADMIN_USER_FAILED:
      return {
        ...state,
        createAdminUser: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.DELETE_ADMIN_USER_REQUEST:
      return {
        ...state,
        deleteAdminUser: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.DELETE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        deleteAdminUser: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.DELETE_ADMIN_USER_FAILED:
      return {
        ...state,
        deleteAdminUser: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.GET_ADMIN_USER_BY_ID_REQUEST:
      return {
        ...state,
        adminUserById: {
          ...state.adminUserById,
          isLoading: true,
        },
      };
    case ActionType.GET_ADMIN_USER_BY_ID_SUCCESS:
      return {
        ...state,
        adminUserById: {
          ...state.adminUserById,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_ADMIN_USER_BY_ID_FAILED:
      return {
        ...state,
        adminUserById: {
          ...state.adminUserById,
          isLoading: false,
        },
      };

    case ActionType.UPDATE_ROLE_USER_REQUEST:
      return {
        ...state,
        updateRoleUser: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_ROLE_USER_SUCCESS:
      return {
        ...state,
        updateRoleUser: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_ROLE_USER_FAILED:
      return {
        ...state,
        updateRoleUser: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPassword: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPassword: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    default: {
      return state;
    }
  }
};

export default userReducer;
