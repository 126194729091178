import * as ActionType from "../constants/Book";
import { bookDTO, listBookDTO, listCategoryDTO } from "../helpers/book";

const initialState = {
  listBook: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
    isForRemoveBook: null,
  },
  listCategory: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
    type: null,
  },
  bookById: {
    data: {},
    isLoading: false,
  },
  isLoadingCreateBook: false,
  isSuccessCreateBook: false,
  isErrorCreateBook: false,
};

const bookReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_BOOK_REQUEST:
      return {
        ...state,
        listBook: {
          ...state.listBook,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_BOOK_SUCCESS:
      return {
        ...state,
        listBook: {
          ...state.listBook,
          data: listBookDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
          isForRemoveBook: payload?.isForRemoveBook,
        },
      };
    case ActionType.GET_LIST_BOOK_FAILED:
      return {
        ...state,
        listBook: {
          ...state.listBook,
          isLoading: false,
        },
      };

    case ActionType.GET_LIST_CATEGORY_REQUEST:
      return {
        ...state,
        listCategory: {
          ...state.listCategory,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        listCategory: {
          ...state.listCategory,
          data: listCategoryDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
          type: payload?.type,
        },
      };
    case ActionType.GET_LIST_CATEGORY_FAILED:
      return {
        ...state,
        listCategory: {
          ...state.listCategory,
          isLoading: false,
        },
      };

    case ActionType.CREATE_BOOK_REQUEST:
      return {
        ...state,
        isLoadingCreateBook: true,
        isSuccessCreateBook: false,
        isErrorCreateBook: false,
      };
    case ActionType.CREATE_BOOK_SUCCESS:
      return {
        ...state,
        isLoadingCreateBook: false,
        isSuccessCreateBook: true,
        isErrorCreateBook: false,
      };
    case ActionType.CREATE_BOOK_FAILED:
      return {
        ...state,
        isLoadingCreateBook: false,
        isSuccessCreateBook: false,
        isErrorCreateBook: true,
      };

    case ActionType.GET_BOOK_BY_ID_REQUEST:
      return {
        ...state,
        bookById: {
          ...state.bookById,
          isLoading: true,
        },
      };
    case ActionType.GET_BOOK_BY_ID_SUCCESS:
      return {
        ...state,
        bookById: {
          ...state.bookById,
          data: bookDTO(payload.data),
          isLoading: false,
        },
      };
    case ActionType.GET_BOOK_BY_ID_FAILED:
      return {
        ...state,
        bookById: {
          ...state.bookById,
          isLoading: false,
        },
      };

    case ActionType.UPDATE_BOOK_REQUEST:
      return {
        ...state,
        bookById: {
          ...state.bookById,
          isLoading: true,
        },
      };
    case ActionType.UPDATE_BOOK_SUCCESS:
      return {
        ...state,
        bookById: {
          ...state.bookById,
          data: bookDTO(payload?.data),
          isLoading: false,
        },
      };
    case ActionType.UPDATE_BOOK_FAILED:
      return {
        ...state,
        bookById: {
          ...state.bookById,
          isLoading: false,
        },
      };

    case ActionType.UPDATE_STATUS_BOOK_REQUEST:
      return {
        ...state,
        listBook: {
          ...state.listBook,
          isLoading: true,
        },
      };
    case ActionType.UPDATE_STATUS_BOOK_SUCCESS:
      return {
        ...state,
        listBook: {
          ...state.listBook,
          data: listBookDTO(
            state.listBook.data.filter(
              (book) => book?.bookId !== payload?.data?.id
            )
          ),
          isLoading: false,
        },
      };
    case ActionType.UPDATE_STATUS_BOOK_FAILED:
      return {
        ...state,
        listBook: {
          ...state.listBook,
          isLoading: false,
        },
      };

    default: {
      return state;
    }
  }
};

export default bookReducer;
