import * as ActionType from "../constants/Voucher";

export const getListVoucherRequest = (payload) => ({
  type: ActionType.GET_LIST_VOUCHER_REQUEST,
  payload,
});

export const getListVoucherSuccess = (payload) => ({
  type: ActionType.GET_LIST_VOUCHER_SUCCESS,
  payload,
});

export const getListVoucherFailed = (payload) => ({
  type: ActionType.GET_LIST_VOUCHER_FAILED,
  payload,
});

export const createVoucherRequest = (payload) => ({
  type: ActionType.CREATE_VOUCHER_REQUEST,
  payload,
});

export const createVoucherSuccess = (payload) => ({
  type: ActionType.CREATE_VOUCHER_SUCCESS,
  payload,
});

export const createVoucherFailed = (payload) => ({
  type: ActionType.CREATE_VOUCHER_FAILED,
  payload,
});

export const updateVoucherRequest = (payload) => ({
  type: ActionType.UPDATE_VOUCHER_REQUEST,
  payload,
});

export const updateVoucherSuccess = (payload) => ({
  type: ActionType.UPDATE_VOUCHER_SUCCESS,
  payload,
});

export const updateVoucherFailed = (payload) => ({
  type: ActionType.UPDATE_VOUCHER_FAILED,
  payload,
});

export const getSummaryVoucherRequest = (payload) => ({
  type: ActionType.GET_SUMMARY_VOUCHER_REQUEST,
  payload,
});

export const getSummaryVoucherSuccess = (payload) => ({
  type: ActionType.GET_SUMMARY_VOUCHER_SUCCESS,
  payload,
});

export const getSummaryVoucherFailed = (payload) => ({
  type: ActionType.GET_SUMMARY_VOUCHER_FAILED,
  payload,
});

export const updateStatusVoucherRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_VOUCHER_REQUEST,
  payload,
});

export const updateStatusVoucherSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_VOUCHER_SUCCESS,
  payload,
});

export const updateStatusVoucherFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_VOUCHER_FAILED,
  payload,
});
