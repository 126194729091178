/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import {
  getBannerOnSchoolRequest,
  hideMessage,
  showAuthLoader,
  userSignInSchool,
} from "../../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../components/CircularProgress";
import { isEmpty } from "lodash";

const SignInSchool = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const bannerSchool = useSelector(({ banner }) => banner.bannerSchool);
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  });
  useEffect(() => {
    dispatch(getBannerOnSchoolRequest());
  }, []);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userSignInSchool(values));
  };

  return (
    <div className="gx-app-login-wrap gx-bg-supernova">
      <div className="gx-app-login-container">
        {!isEmpty(bannerSchool?.data) &&
          moment().isBetween(
            moment.unix(JSON.parse(bannerSchool?.data?.value)?.startDate),
            moment.unix(JSON.parse(bannerSchool?.data?.value)?.endDate)
          ) && (
            <a href={JSON.parse(bannerSchool?.data?.value)?.bannerActionUrl}>
              <img
                src={JSON.parse(bannerSchool?.data?.value)?.bannerUrl}
                alt="Neature"
                style={{ marginBottom: "30px" }}
              />
            </a>
          )}
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src="/assets/images/bg_school.jpeg" alt="Neature" />
            </div>

            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/favicon.png" width="100" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "The input is not valid E-mail!",
                  },
                ]}
                name="userNameOrEmail"
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
                name="password"
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                >
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </Form.Item>
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default SignInSchool;
