import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Publisher";
import * as Api from "../../api/publisher";
import {
  getListPublisherSuccess,
  getListPublisherFailed,
  updateStatusPublisherSuccess,
  updateStatusPublisherFailed,
  createPublisherSuccess,
  createPublisherFailed,
  getPublisherByIdSuccess,
  getPublisherByIdFailed,
  updatePublisherSuccess,
  updatePublisherFailed,
  getPublisherInfoSuccess,
  getPublisherInfoFailed,
  getMyTopMostPopularBooksSuccess,
  getMyTopMostPopularBooksFailed,
  getTopMostPopularBooksSuccess,
  getTopMostPopularBooksFailed,
  updatePublisherInfoSuccess,
  updatePublisherInfoFailed,
} from "../actions/Publisher";
import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";

function* getListPublisherWithOption({ payload }) {
  try {
    const resultListPublisher = yield call(Api.fetchListPublisher, {
      params: { ...payload.params },
    });
    yield put(
      getListPublisherSuccess(
        { ...resultListPublisher, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list publisher", "Failed");
    yield put(getListPublisherFailed());
  }
}

export function* watchListPublisherRequest() {
  yield takeEvery(
    ActionType.GET_LIST_PUBLISHER_REQUEST,
    getListPublisherWithOption
  );
}

function* updateStatusPublisher({ payload }) {
  try {
    const resultUpdateStatusPublisher = yield call(
      Api.fetchUpdateStatusPublisher,
      payload
    );
    if (resultUpdateStatusPublisher.success === true) {
      yield put(updateStatusPublisherSuccess(resultUpdateStatusPublisher.data));
      successNotification("Update Status publisher", "Success");
    } else {
      yield put(updateStatusPublisherFailed(resultUpdateStatusPublisher || {}));
      resultUpdateStatusPublisher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status publisher", "Failed");
    yield put(updateStatusPublisherFailed());
  }
}

export function* watchUpdateStatusPublisherRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_PUBLISHER_REQUEST,
    updateStatusPublisher
  );
}

function* createPublisher({ payload }) {
  try {
    const resultCreatePublisher = yield call(Api.fetchCreatePublisher, payload);
    if (resultCreatePublisher.success === true) {
      yield put(createPublisherSuccess(resultCreatePublisher.data));
      successNotification("Create publisher", "Success");
    } else {
      yield put(createPublisherFailed(resultCreatePublisher || {}));
      resultCreatePublisher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create publisher", "Failed");
    yield put(createPublisherFailed());
  }
}

export function* watchCreatePublisherRequest() {
  yield takeEvery(ActionType.CREATE_PUBLISHER_REQUEST, createPublisher);
}

function* getPublisherById({ payload }) {
  try {
    const resultPublisher = yield call(Api.fetchPublisherById, payload);
    yield put(getPublisherByIdSuccess({ ...resultPublisher } || {}));
  } catch (error) {
    errorNotification("Get publisher by id", "Failed");
    yield put(getPublisherByIdFailed());
  }
}

export function* watchGetPublisherByIdRequest() {
  yield takeEvery(ActionType.GET_PUBLISHER_BY_ID_REQUEST, getPublisherById);
}

function* updatePublisher({ payload }) {
  try {
    const resultUpdatePublisher = yield call(Api.fetchUpdatePublisher, payload);

    if (resultUpdatePublisher.success === true) {
      yield put(updatePublisherSuccess(resultUpdatePublisher.data));
      successNotification("Update publisher", "Success");
    } else {
      yield put(updatePublisherSuccess(resultUpdatePublisher || {}));
      resultUpdatePublisher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update publisher", "Failed");
    yield put(updatePublisherFailed());
  }
}

export function* watchUpdatePublisherRequest() {
  yield takeEvery(ActionType.UPDATE_PUBLISHER_REQUEST, updatePublisher);
}

function* getPublisherInfo({ payload }) {
  try {
    const resultPublisher = yield call(Api.fetchPublisherInfo, payload);
    yield put(getPublisherInfoSuccess({ ...resultPublisher } || {}));
  } catch (error) {
    errorNotification("Get publisher info", "Failed");
    yield put(getPublisherInfoFailed());
  }
}

export function* watchGetPublisherInfoRequest() {
  yield takeEvery(ActionType.GET_PUBLISHER_INFO_REQUEST, getPublisherInfo);
}

function* getMyTopMostPopularBooks({ payload }) {
  try {
    const resultMyTopMostPopularBooks = yield call(
      Api.fetchMyTopMostPopularBooks,
      payload
    );
    yield put(
      getMyTopMostPopularBooksSuccess({ ...resultMyTopMostPopularBooks } || {})
    );
  } catch (error) {
    errorNotification("Get my top most popular books", "Failed");
    yield put(getMyTopMostPopularBooksFailed());
  }
}

export function* watchGetMyTopMostPopularBooksRequest() {
  yield takeEvery(
    ActionType.GET_MY_TOP_MOST_POPULAR_BOOKS_REQUEST,
    getMyTopMostPopularBooks
  );
}

function* getTopMostPopularBooks({ payload }) {
  try {
    const resultTopMostPopularBooks = yield call(
      Api.fetchTopMostPopularBooks,
      payload
    );
    yield put(
      getTopMostPopularBooksSuccess({ ...resultTopMostPopularBooks } || {})
    );
  } catch (error) {
    errorNotification("Get top most popular books", "Failed");
    yield put(getTopMostPopularBooksFailed());
  }
}

export function* watchGetTopMostPopularBooksRequest() {
  yield takeEvery(
    ActionType.GET_TOP_MOST_POPULAR_BOOKS_REQUEST,
    getTopMostPopularBooks
  );
}

function* updatePublisherInfo({ payload }) {
  try {
    const resultUpdatePublisherInfo = yield call(
      Api.fetchUpdatePublisherInfo,
      payload
    );

    if (resultUpdatePublisherInfo.success === true) {
      yield put(updatePublisherInfoSuccess(resultUpdatePublisherInfo.data));
      successNotification("Update publisher info", "Success");
    } else {
      yield put(updatePublisherInfoSuccess(resultUpdatePublisherInfo || {}));
      resultUpdatePublisherInfo?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update publisher info", "Failed");
    yield put(updatePublisherInfoFailed());
  }
}

export function* watchUpdatePublisherInfoRequest() {
  yield takeEvery(
    ActionType.UPDATE_PUBLISHER_INFO_REQUEST,
    updatePublisherInfo
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchListPublisherRequest),
    fork(watchUpdateStatusPublisherRequest),
    fork(watchCreatePublisherRequest),
    fork(watchGetPublisherByIdRequest),
    fork(watchUpdatePublisherRequest),
    fork(watchGetPublisherInfoRequest),
    fork(watchGetMyTopMostPopularBooksRequest),
    fork(watchGetTopMostPopularBooksRequest),
    fork(watchUpdatePublisherInfoRequest),
  ]);
}
