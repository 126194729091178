import * as ActionType from "../constants/Transaction";

export const getListTransactionSubsTypeRequest = (payload) => ({
  type: ActionType.GET_LIST_TRANSACTION_SUBS_TYPE_REQUEST,
  payload,
});

export const getListTransactionSubsTypeSuccess = (payload) => ({
  type: ActionType.GET_LIST_TRANSACTION_SUBS_TYPE_SUCCESS,
  payload,
});

export const getListTransactionSubsTypeFailed = (payload) => ({
  type: ActionType.GET_LIST_TRANSACTION_SUBS_TYPE_FAILED,
  payload,
});

export const createTransactionSubsTypeRequest = (payload) => ({
  type: ActionType.CREATE_TRANSACTION_SUBS_TYPE_REQUEST,
  payload,
});

export const createTransactionSubsTypeSuccess = (payload) => ({
  type: ActionType.CREATE_TRANSACTION_SUBS_TYPE_SUCCESS,
  payload,
});

export const createTransactionSubsTypeFailed = (payload) => ({
  type: ActionType.CREATE_TRANSACTION_SUBS_TYPE_FAILED,
  payload,
});

export const updateTransactionSubsTypeRequest = (payload) => ({
  type: ActionType.UPDATE_TRANSACTION_SUBS_TYPE_REQUEST,
  payload,
});

export const updateTransactionSubsTypeSuccess = (payload) => ({
  type: ActionType.UPDATE_TRANSACTION_SUBS_TYPE_SUCCESS,
  payload,
});

export const updateTransactionSubsTypeFailed = (payload) => ({
  type: ActionType.UPDATE_TRANSACTION_SUBS_TYPE_FAILED,
  payload,
});

export const updateStatusTransactionSubsTypeRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_TRANSACTION_SUBS_TYPE_REQUEST,
  payload,
});

export const updateStatusTransactionSubsTypeSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_TRANSACTION_SUBS_TYPE_SUCCESS,
  payload,
});

export const updateStatusTransactionSubsTypeFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_TRANSACTION_SUBS_TYPE_FAILED,
  payload,
});

export const getListTransactionRequest = (payload) => ({
  type: ActionType.GET_LIST_TRANSACTION_REQUEST,
  payload,
});

export const getListTransactionSuccess = (payload) => ({
  type: ActionType.GET_LIST_TRANSACTION_SUCCESS,
  payload,
});

export const getListTransactionFailed = (payload) => ({
  type: ActionType.GET_LIST_TRANSACTION_FAILED,
  payload,
});

export const createManualTransactionRequest = (payload) => ({
  type: ActionType.CREATE_MANUAL_TRANSACTION_REQUEST,
  payload,
});

export const createManualTransactionSuccess = (payload) => ({
  type: ActionType.CREATE_MANUAL_TRANSACTION_SUCCESS,
  payload,
});

export const createManualTransactionFailed = (payload) => ({
  type: ActionType.CREATE_MANUAL_TRANSACTION_FAILED,
  payload,
});

export const updateStatusTransactionRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_TRANSACTION_REQUEST,
  payload,
});

export const updateStatusTransactionSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_TRANSACTION_SUCCESS,
  payload,
});

export const updateStatusTransactionFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_TRANSACTION_FAILED,
  payload,
});

export const getListRoyaltyStatementRequest = (payload) => ({
  type: ActionType.GET_LIST_ROYALTY_STATEMENT_REQUEST,
  payload,
});

export const getListRoyaltyStatementSuccess = (payload) => ({
  type: ActionType.GET_LIST_ROYALTY_STATEMENT_SUCCESS,
  payload,
});

export const getListRoyaltyStatementFailed = (payload) => ({
  type: ActionType.GET_LIST_ROYALTY_STATEMENT_FAILED,
  payload,
});

export const updateStatusPayoutRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_PAYOUT_REQUEST,
  payload,
});

export const updateStatusPayoutSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_PAYOUT_SUCCESS,
  payload,
});

export const updateStatusPayoutFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_PAYOUT_FAILED,
  payload,
});

export const getDetailRoyaltyStatementRequest = (payload) => ({
  type: ActionType.GET_DETAIL_ROYALTY_STATEMENT_REQUEST,
  payload,
});

export const getDetailRoyaltyStatementSuccess = (payload) => ({
  type: ActionType.GET_DETAIL_ROYALTY_STATEMENT_SUCCESS,
  payload,
});

export const getDetailRoyaltyStatementFailed = (payload) => ({
  type: ActionType.GET_DETAIL_ROYALTY_STATEMENT_FAILED,
  payload,
});

export const getDetailReportsRequest = (payload) => ({
  type: ActionType.GET_DETAIL_REPORTS_REQUEST,
  payload,
});

export const getDetailReportsSuccess = (payload) => ({
  type: ActionType.GET_DETAIL_REPORTS_SUCCESS,
  payload,
});

export const getDetailReportsFailed = (payload) => ({
  type: ActionType.GET_DETAIL_REPORTS_FAILED,
  payload,
});

export const createTransactionSpecialPackageRequest = (payload) => ({
  type: ActionType.CREATE_TRANSACTION_SPECIAL_PACKAGE_REQUEST,
  payload,
});

export const createTransactionSpecialPackageSuccess = (payload) => ({
  type: ActionType.CREATE_TRANSACTION_SPECIAL_PACKAGE_SUCCESS,
  payload,
});

export const createTransactionSpecialPackageFailed = (payload) => ({
  type: ActionType.CREATE_TRANSACTION_SPECIAL_PACKAGE_FAILED,
  payload,
});
