export const categoryTypeDTO = (categoryType) => ({
  ...categoryType,
});

export const listCategoryTypeDTO = (listCategoryType = []) => {
  if (!listCategoryType.length) return [];
  return listCategoryType.map(categoryTypeDTO) ?? [];
};

export const subCategoryDTO = (subCategory) => ({
  ...subCategory,
});

export const listSubCategoryDTO = (listSubCategory = []) => {
  if (!listSubCategory.length) return [];
  return listSubCategory.map(subCategoryDTO) ?? [];
};
