import * as ActionType from "../constants/Publisher";

export const getListPublisherRequest = (payload) => ({
  type: ActionType.GET_LIST_PUBLISHER_REQUEST,
  payload,
});

export const getListPublisherSuccess = (payload) => ({
  type: ActionType.GET_LIST_PUBLISHER_SUCCESS,
  payload,
});

export const getListPublisherFailed = (payload) => ({
  type: ActionType.GET_LIST_PUBLISHER_FAILED,
  payload,
});

export const updateStatusPublisherFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_PUBLISHER_FAILED,
  payload,
});

export const updateStatusPublisherRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_PUBLISHER_REQUEST,
  payload,
});

export const updateStatusPublisherSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_PUBLISHER_SUCCESS,
  payload,
});

export const createPublisherFailed = (payload) => ({
  type: ActionType.CREATE_PUBLISHER_FAILED,
  payload,
});

export const createPublisherRequest = (payload) => ({
  type: ActionType.CREATE_PUBLISHER_REQUEST,
  payload,
});

export const createPublisherSuccess = (payload) => ({
  type: ActionType.CREATE_PUBLISHER_SUCCESS,
  payload,
});

export const getPublisherByIdFailed = (payload) => ({
  type: ActionType.GET_PUBLISHER_BY_ID_FAILED,
  payload,
});

export const getPublisherByIdRequest = (payload) => ({
  type: ActionType.GET_PUBLISHER_BY_ID_REQUEST,
  payload,
});

export const getPublisherByIdSuccess = (payload) => ({
  type: ActionType.GET_PUBLISHER_BY_ID_SUCCESS,
  payload,
});

export const updatePublisherFailed = (payload) => ({
  type: ActionType.UPDATE_PUBLISHER_FAILED,
  payload,
});

export const updatePublisherRequest = (payload) => ({
  type: ActionType.UPDATE_PUBLISHER_REQUEST,
  payload,
});

export const updatePublisherSuccess = (payload) => ({
  type: ActionType.UPDATE_PUBLISHER_SUCCESS,
  payload,
});

export const getPublisherInfoFailed = (payload) => ({
  type: ActionType.GET_PUBLISHER_INFO_FAILED,
  payload,
});

export const getPublisherInfoRequest = (payload) => ({
  type: ActionType.GET_PUBLISHER_INFO_REQUEST,
  payload,
});

export const getPublisherInfoSuccess = (payload) => ({
  type: ActionType.GET_PUBLISHER_INFO_SUCCESS,
  payload,
});

export const getMyTopMostPopularBooksFailed = (payload) => ({
  type: ActionType.GET_MY_TOP_MOST_POPULAR_BOOKS_FAILED,
  payload,
});

export const getMyTopMostPopularBooksRequest = (payload) => ({
  type: ActionType.GET_MY_TOP_MOST_POPULAR_BOOKS_REQUEST,
  payload,
});

export const getMyTopMostPopularBooksSuccess = (payload) => ({
  type: ActionType.GET_MY_TOP_MOST_POPULAR_BOOKS_SUCCESS,
  payload,
});

export const getTopMostPopularBooksFailed = (payload) => ({
  type: ActionType.GET_TOP_MOST_POPULAR_BOOKS_FAILED,
  payload,
});

export const getTopMostPopularBooksRequest = (payload) => ({
  type: ActionType.GET_TOP_MOST_POPULAR_BOOKS_REQUEST,
  payload,
});

export const getTopMostPopularBooksSuccess = (payload) => ({
  type: ActionType.GET_TOP_MOST_POPULAR_BOOKS_SUCCESS,
  payload,
});

export const updatePublisherInfoFailed = (payload) => ({
  type: ActionType.UPDATE_PUBLISHER_INFO_FAILED,
  payload,
});

export const updatePublisherInfoRequest = (payload) => ({
  type: ActionType.UPDATE_PUBLISHER_INFO_REQUEST,
  payload,
});

export const updatePublisherInfoSuccess = (payload) => ({
  type: ActionType.UPDATE_PUBLISHER_INFO_SUCCESS,
  payload,
});
