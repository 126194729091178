import * as ActionType from "../constants/Catetgory";
import {
  listCategoryTypeDTO,
  listSubCategoryDTO,
  subCategoryDTO,
} from "../helpers/category";

const initialState = {
  listCategoryType: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  listSubCategory: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  subCategoryById: {
    data: {},
    isLoading: false,
  },
  isLoadingActionCategory: false,
  isSuccessActionCategory: false,
  isErrorActionCategory: false,
};

const categoryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_CATEGORY_TYPE_REQUEST:
      return {
        ...state,
        listCategoryType: {
          ...state.listCategoryType,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_CATEGORY_TYPE_SUCCESS:
      return {
        ...state,
        listCategoryType: {
          ...state.listCategoryType,
          data: listCategoryTypeDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_CATEGORY_TYPE_FAILED:
      return {
        ...state,
        listCategoryType: {
          ...state.listCategoryType,
          isLoading: false,
        },
      };

    case ActionType.GET_LIST_SUB_CATEGORY_REQUEST:
      return {
        ...state,
        listSubCategory: {
          ...state.listSubCategory,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        listSubCategory: {
          ...state.listSubCategory,
          data: listSubCategoryDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_SUB_CATEGORY_FAILED:
      return {
        ...state,
        listSubCategory: {
          ...state.listSubCategory,
          isLoading: false,
        },
      };

    case ActionType.GET_SUB_CATEGORY_BY_ID_REQUEST:
      return {
        ...state,
        subCategoryById: {
          ...state.subCategoryById,
          isLoading: true,
        },
      };
    case ActionType.GET_SUB_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        subCategoryById: {
          ...state.subCategoryById,
          data: subCategoryDTO(payload.data),
          isLoading: false,
        },
      };
    case ActionType.GET_SUB_CATEGORY_BY_ID_FAILED:
      return {
        ...state,
        subCategoryById: {
          ...state.subCategoryById,
          isLoading: false,
        },
      };

    case ActionType.CREATE_SUB_CATEGORY_REQUEST:
    case ActionType.UPDATE_STATUS_SUB_CATEGORY_REQUEST:
    case ActionType.UPDATE_SUB_CATEGORY_REQUEST:
    case ActionType.SET_BOOK_CATEGORY_REQUEST:
    case ActionType.UNSET_BOOK_CATEGORY_REQUEST:
      return {
        ...state,
        isSuccessActionCategory: false,
        isErrorActionCategory: false,
        isLoadingActionCategory: true,
      };
    case ActionType.CREATE_SUB_CATEGORY_SUCCESS:
    case ActionType.UPDATE_STATUS_SUB_CATEGORY_SUCCESS:
    case ActionType.UPDATE_SUB_CATEGORY_SUCCESS:
    case ActionType.SET_BOOK_CATEGORY_SUCCESS:
    case ActionType.UNSET_BOOK_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoadingActionCategory: false,
        isSuccessActionCategory: true,
      };
    case ActionType.CREATE_SUB_CATEGORY_FAILED:
    case ActionType.UPDATE_STATUS_SUB_CATEGORY_FAILED:
    case ActionType.UPDATE_SUB_CATEGORY_FAILED:
    case ActionType.SET_BOOK_CATEGORY_FAILED:
    case ActionType.UNSET_BOOK_CATEGORY_FAILED:
      return {
        ...state,
        isLoadingActionCategory: false,
        isErrorActionCategory: true,
      };

    default: {
      return state;
    }
  }
};

export default categoryReducer;
