import * as ActionType from "../constants/Voucher";

const initialState = {
  listVoucher: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  summaryVoucher: {
    data: {},
    isLoading: false,
  },
  isLoadingActionVoucher: false,
  isSuccessActionVoucher: false,
  isErrorActionVoucher: false,
};

const voucherReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_VOUCHER_REQUEST:
      return {
        ...state,
        listVoucher: {
          ...state.listVoucher,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_VOUCHER_SUCCESS:
      return {
        ...state,
        listVoucher: {
          ...state.listVoucher,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_VOUCHER_FAILED:
      return {
        ...state,
        listVoucher: {
          ...state.listVoucher,
          isLoading: false,
        },
      };

    case ActionType.GET_SUMMARY_VOUCHER_REQUEST:
      return {
        ...state,
        summaryVoucher: {
          ...state.summaryVoucher,
          isLoading: true,
        },
      };
    case ActionType.GET_SUMMARY_VOUCHER_SUCCESS:
      return {
        ...state,
        summaryVoucher: {
          ...state.summaryVoucher,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_SUMMARY_VOUCHER_FAILED:
      return {
        ...state,
        summaryVoucher: {
          ...state.summaryVoucher,
          isLoading: false,
        },
      };

    case ActionType.CREATE_VOUCHER_REQUEST:
    case ActionType.UPDATE_VOUCHER_REQUEST:
    case ActionType.UPDATE_STATUS_VOUCHER_REQUEST:
      return {
        ...state,
        isSuccessActionVoucher: false,
        isErrorActionVoucher: false,
        isLoadingActionVoucher: true,
      };
    case ActionType.CREATE_VOUCHER_SUCCESS:
    case ActionType.UPDATE_VOUCHER_SUCCESS:
    case ActionType.UPDATE_STATUS_VOUCHER_SUCCESS:
      return {
        ...state,
        isLoadingActionVoucher: false,
        isSuccessActionVoucher: true,
      };
    case ActionType.CREATE_VOUCHER_FAILED:
    case ActionType.UPDATE_VOUCHER_FAILED:
    case ActionType.UPDATE_STATUS_VOUCHER_FAILED:
      return {
        ...state,
        isLoadingActionVoucher: false,
        isErrorActionVoucher: true,
      };

    default: {
      return state;
    }
  }
};

export default voucherReducer;
