import * as ActionType from "../constants/Banner";
import { listBannerDTO } from "../helpers/banner";

const initialState = {
  listBanner: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  bannerSchool: {
    isLoading: false,
    data: {},
  },
  isLoadingActionBanner: false,
  isSuccessActionBanner: false,
  isErrorActionBanner: false,
};

const bannerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_BANNER_REQUEST:
      return {
        ...state,
        listBanner: {
          ...state.listBanner,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_BANNER_SUCCESS:
      return {
        ...state,
        listBanner: {
          ...state.listBanner,
          data: listBannerDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_BANNER_FAILED:
      return {
        ...state,
        listBanner: {
          ...state.listBanner,
          isLoading: false,
        },
      };

    case ActionType.CREATE_BANNER_REQUEST:
    case ActionType.UPDATE_BANNER_REQUEST:
    case ActionType.DELETE_BANNER_REQUEST:
      return {
        ...state,
        isSuccessActionBanner: false,
        isErrorActionBanner: false,
        isLoadingActionBanner: true,
      };
    case ActionType.CREATE_BANNER_SUCCESS:
    case ActionType.UPDATE_BANNER_SUCCESS:
    case ActionType.DELETE_BANNER_SUCCESS:
      return {
        ...state,
        isLoadingActionBanner: false,
        isSuccessActionBanner: true,
      };
    case ActionType.CREATE_BANNER_FAILED:
    case ActionType.UPDATE_BANNER_FAILED:
    case ActionType.DELETE_BANNER_FAILED:
      return {
        ...state,
        isLoadingActionBanner: false,
        isErrorActionBanner: true,
      };

    case ActionType.GET_BANNER_ON_SCHOOL_REQUEST:
      return {
        ...state,
        bannerSchool: {
          ...state.bannerSchool,
          isLoading: true,
        },
      };
    case ActionType.GET_BANNER_ON_SCHOOL_SUCCESS:
      return {
        ...state,
        bannerSchool: {
          ...state.bannerSchool,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_BANNER_ON_SCHOOL_FAILED:
      return {
        ...state,
        bannerSchool: {
          data: false,
          isLoading: false,
        },
      };

    default: {
      return state;
    }
  }
};

export default bannerReducer;
