import httpRequest from "../util/axios";

export const fetchListTransactionSubsType = async (option = {}) => {
  try {
    const stringApi = option?.type === 6 ? '/admin/schools/transactions/subs-type' : '/admin/transactions/subs-type'
    delete option?.type
    
    const result = await httpRequest.get(
      stringApi,
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateTransactionSubsType = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/transactions/subs-type",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateTransactionSubsType = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/transactions/subs-type/${body?.subsTypeId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusTransactionSubsType = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/transactions/subs-type/${body?.subsTypeId}/status`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListTransaction = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/transactions",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateManualTransaction = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/transactions/create-order-manual",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusTransaction = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/transactions/${body?.transactionId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListRoyaltyStatement = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/transactions/royalty-statement",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusPayout = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/transactions/royalty-statement/${body?.payoutPublisherId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDetailRoyaltyStatement = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/transactions/royalty-statement/${body?.payoutPublisherId}`,
      { params: body.params },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDetailReports = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/transactions/royalty-statement/detail-reports",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateTransactionSpecialPackage = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/schools/transactions/special-package",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
