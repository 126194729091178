import * as ActionType from "../constants/Faq";

export const getListCategoryRequest = (payload) => ({
  type: ActionType.GET_LIST_CATEGORY_REQUEST,
  payload,
});

export const getListCategorySuccess = (payload) => ({
  type: ActionType.GET_LIST_CATEGORY_SUCCESS,
  payload,
});

export const getListCategoryFailed = (payload) => ({
  type: ActionType.GET_LIST_CATEGORY_FAILED,
  payload,
});

export const updateCategoryRequest = (payload) => ({
  type: ActionType.UPDATE_CATEGORY_REQUEST,
  payload,
});

export const updateCategorySuccess = (payload) => ({
  type: ActionType.UPDATE_CATEGORY_SUCCESS,
  payload,
});

export const updateCategoryFailed = (payload) => ({
  type: ActionType.UPDATE_CATEGORY_FAILED,
  payload,
});

export const createTopicRequest = (payload) => ({
  type: ActionType.CREATE_TOPIC_REQUEST,
  payload,
});

export const createTopicSuccess = (payload) => ({
  type: ActionType.CREATE_TOPIC_SUCCESS,
  payload,
});

export const createTopicFailed = (payload) => ({
  type: ActionType.CREATE_TOPIC_FAILED,
  payload,
});

export const createQuestionRequest = (payload) => ({
  type: ActionType.CREATE_QUESTION_REQUEST,
  payload,
});

export const createQuestionSuccess = (payload) => ({
  type: ActionType.CREATE_QUESTION_SUCCESS,
  payload,
});

export const createQuestionFailed = (payload) => ({
  type: ActionType.CREATE_QUESTION_FAILED,
  payload,
});

export const updateQuestionRequest = (payload) => ({
  type: ActionType.UPDATE_QUESTION_REQUEST,
  payload,
});

export const updateQuestionSuccess = (payload) => ({
  type: ActionType.UPDATE_QUESTION_SUCCESS,
  payload,
});

export const updateQuestionFailed = (payload) => ({
  type: ActionType.UPDATE_QUESTION_FAILED,
  payload,
});

export const deleteQuestionRequest = (payload) => ({
  type: ActionType.DELETE_QUESTION_REQUEST,
  payload,
});

export const deleteQuestionSuccess = (payload) => ({
  type: ActionType.DELETE_QUESTION_SUCCESS,
  payload,
});

export const deleteQuestionFailed = (payload) => ({
  type: ActionType.DELETE_QUESTION_FAILED,
  payload,
});

export const updateStatusQuestionRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_QUESTION_REQUEST,
  payload,
});

export const updateStatusQuestionSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_QUESTION_SUCCESS,
  payload,
});

export const updateStatusQuestionFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_QUESTION_FAILED,
  payload,
});

export const deleteSubSectionRequest = (payload) => ({
  type: ActionType.DELETE_SUB_SECTION_REQUEST,
  payload,
});

export const deleteSubSectionSuccess = (payload) => ({
  type: ActionType.DELETE_SUB_SECTION_SUCCESS,
  payload,
});

export const deleteSubSectionFailed = (payload) => ({
  type: ActionType.DELETE_SUB_SECTION_FAILED,
  payload,
});
