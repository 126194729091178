import moment from "moment";

export const publisherDTO = (publisher) => ({
  ...publisher,
  updated_at: moment(publisher.updated_at).fromNow(),
});

export const listPublisherDTO = (listPublisher = []) => {
  if (!listPublisher.length) return [];
  return listPublisher.map(publisherDTO) ?? [];
};
