import * as ActionType from "../constants/Publisher";
import { listPublisherDTO, publisherDTO } from "../helpers/publisher";

const initialState = {
  listPublisher: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
      hasNext: true,
    },
    totalData: 0,
  },
  publisherById: {
    data: {},
    isLoading: false,
  },
  publisherInfo: {
    data: {},
    isLoading: false,
  },
  myTopMostPopularBooks: {
    data: [],
    isLoading: false,
  },
  topMostPopularBooks: {
    data: [],
    isLoading: false,
  },
  isLoadingActionPublisher: false,
  isSuccessActionPublisher: false,
  isErrorActionPublisher: false,
};

const publisherReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_PUBLISHER_REQUEST:
      return {
        ...state,
        listPublisher: {
          ...state.listPublisher,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_PUBLISHER_SUCCESS:
      return {
        ...state,
        listPublisher: {
          ...state.listPublisher,
          data: listPublisherDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
            hasNext: payload?.paging?.hasNext,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_PUBLISHER_FAILED:
      return {
        ...state,
        listPublisher: {
          ...state.listPublisher,
          isLoading: false,
        },
      };

    case ActionType.GET_PUBLISHER_BY_ID_REQUEST:
      return {
        ...state,
        publisherById: {
          ...state.publisherById,
          isLoading: true,
        },
      };
    case ActionType.GET_PUBLISHER_BY_ID_SUCCESS:
      return {
        ...state,
        publisherById: {
          ...state.publisherById,
          data: publisherDTO(payload.data),
          isLoading: false,
        },
      };
    case ActionType.GET_PUBLISHER_BY_ID_FAILED:
      return {
        ...state,
        publisherById: {
          ...state.publisherById,
          isLoading: false,
        },
      };

    case ActionType.GET_PUBLISHER_INFO_REQUEST:
      return {
        ...state,
        publisherInfo: {
          ...state.publisherInfo,
          isLoading: true,
        },
      };
    case ActionType.GET_PUBLISHER_INFO_SUCCESS:
      return {
        ...state,
        publisherInfo: {
          ...state.publisherInfo,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_PUBLISHER_INFO_FAILED:
      return {
        ...state,
        publisherInfo: {
          ...state.publisherInfo,
          isLoading: false,
        },
      };

    case ActionType.GET_MY_TOP_MOST_POPULAR_BOOKS_REQUEST:
      return {
        ...state,
        myTopMostPopularBooks: {
          ...state.myTopMostPopularBooks,
          isLoading: true,
        },
      };
    case ActionType.GET_MY_TOP_MOST_POPULAR_BOOKS_SUCCESS:
      return {
        ...state,
        myTopMostPopularBooks: {
          ...state.myTopMostPopularBooks,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_MY_TOP_MOST_POPULAR_BOOKS_FAILED:
      return {
        ...state,
        myTopMostPopularBooks: {
          ...state.myTopMostPopularBooks,
          isLoading: false,
        },
      };

    case ActionType.GET_TOP_MOST_POPULAR_BOOKS_REQUEST:
      return {
        ...state,
        topMostPopularBooks: {
          ...state.topMostPopularBooks,
          isLoading: true,
        },
      };
    case ActionType.GET_TOP_MOST_POPULAR_BOOKS_SUCCESS:
      return {
        ...state,
        topMostPopularBooks: {
          ...state.topMostPopularBooks,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_TOP_MOST_POPULAR_BOOKS_FAILED:
      return {
        ...state,
        topMostPopularBooks: {
          ...state.topMostPopularBooks,
          isLoading: false,
        },
      };

    case ActionType.UPDATE_STATUS_PUBLISHER_REQUEST:
    case ActionType.CREATE_PUBLISHER_REQUEST:
    case ActionType.UPDATE_PUBLISHER_REQUEST:
    case ActionType.UPDATE_PUBLISHER_INFO_REQUEST:
      return {
        ...state,
        isSuccessActionPublisher: false,
        isErrorActionPublisher: false,
        isLoadingActionPublisher: true,
      };
    case ActionType.UPDATE_STATUS_PUBLISHER_SUCCESS:
    case ActionType.CREATE_PUBLISHER_SUCCESS:
    case ActionType.UPDATE_PUBLISHER_SUCCESS:
    case ActionType.UPDATE_PUBLISHER_INFO_SUCCESS:
      return {
        ...state,
        isLoadingActionPublisher: false,
        isSuccessActionPublisher: true,
      };
    case ActionType.UPDATE_STATUS_PUBLISHER_FAILED:
    case ActionType.CREATE_PUBLISHER_FAILED:
    case ActionType.UPDATE_PUBLISHER_FAILED:
    case ActionType.UPDATE_PUBLISHER_INFO_FAILED:
      return {
        ...state,
        isLoadingActionPublisher: false,
        isErrorActionPublisher: true,
      };
    default: {
      return state;
    }
  }
};

export default publisherReducer;
