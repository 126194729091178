import * as ActionType from "../constants/User";
import { listUserDTO } from "../helpers/user";

const initialState = {
  listUser: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  isLoadingActionUser: false,
  isSuccessActionUser: false,
  isErrorActionUser: false,
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_USER_REQUEST:
      return {
        ...state,
        listUser: {
          ...state.listUser,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_USER_SUCCESS:
      return {
        ...state,
        listUser: {
          ...state.listUser,
          data: listUserDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_USER_FAILED:
      return {
        ...state,
        listUser: {
          ...state.listUser,
          isLoading: false,
        },
      };

    case ActionType.CREATE_USER_REQUEST:
    case ActionType.UPDATE_STATUS_USER_REQUEST:
      return {
        ...state,
        isSuccessActionUser: false,
        isErrorActionUser: false,
        isLoadingActionUser: true,
      };
    case ActionType.CREATE_USER_SUCCESS:
    case ActionType.UPDATE_STATUS_USER_SUCCESS:
      return {
        ...state,
        isLoadingActionUser: false,
        isSuccessActionUser: true,
      };
    case ActionType.CREATE_USER_FAILED:
    case ActionType.UPDATE_STATUS_USER_FAILED:
      return {
        ...state,
        isLoadingActionUser: false,
        isErrorActionUser: true,
      };

    default: {
      return state;
    }
  }
};

export default userReducer;
