export const data = [
  {
    label: "Super Admin",
    type: 1,
    sideMenus: [
      {
        key: "dashboard",
        to: "/dashboard",
        icon: <i className="icon icon-widgets" />,
        title: "Dashboard",
        isSubMenu: false,
      },
      {
        key: "admin-rbac",
        to: "/admin-rbac/admin-roles",
        icon: <i className="icon icon-widgets" />,
        title: "Admin RBAC",
        isSubMenu: false,
      },
      {
        key: "user-management",
        icon: <i className="icon icon-widgets" />,
        title: "User Management",
        isSubMenu: true,
        items: [
          {
            key: "manage-account",
            to: "/user-management/manage-account",
            title: "Manage Account",
          },
        ],
      },
      {
        key: "content-partner",
        to: "/content-partner",
        icon: <i className="icon icon-widgets" />,
        title: "Content Partner",
        isSubMenu: false,
      },
      {
        key: "content-management",
        icon: <i className="icon icon-widgets" />,
        title: "Content Management",
        isSubMenu: true,
        items: [
          {
            key: "content",
            to: "/content-management/content",
            title: "Content",
          },
          {
            key: "recommendations",
            to: "/content-management/recommendations",
            title: "Recommendation",
          },
          {
            key: "educational-resource",
            to: "/content-management/educational-resource",
            title: "Lembar Aktivitas",
          },
        ],
      },
      {
        key: "user-engagement",
        icon: <i className="icon icon-widgets" />,
        title: "User Engagement",
        isSubMenu: true,
        items: [
          {
            key: "account-segmentation",
            to: "/user-engagement/account-segmentation",
            title: "Account Segmentation",
          },
          {
            key: "app-inbox",
            to: "/user-engagement/app-inbox",
            title: "App Inbox",
          },
          {
            key: "data-analysis",
            to: "/user-engagement/data-analysis",
            title: "Data Analysis",
          },
        ],
      },
      {
        key: "promo-management",
        icon: <i className="icon icon-widgets" />,
        title: "Promo Management",
        isSubMenu: true,
        items: [
          {
            key: "voucher",
            to: "/promo-management/voucher",
            title: "Voucher",
          },
        ],
      },
      {
        key: "billing-management",
        icon: <i className="icon icon-widgets" />,
        title: "Billing Management",
        isSubMenu: true,
        items: [
          {
            key: "account",
            to: "/billing-management/account",
            title: "Account Billings",
          },
          {
            key: "royalty-statement",
            to: "/billing-management/royalty-statement",
            title: "Royalty Statement",
          },
        ],
      },
      {
        key: "user-support-faq",
        to: "/user-support-faq",
        icon: <i className="icon icon-widgets" />,
        title: "User Support - FAQ",
        isSubMenu: false,
      },
      {
        key: "user-activation",
        icon: <i className="icon icon-widgets" />,
        title: "Ajang Membaca",
        isSubMenu: true,
        items: [
          {
            key: "reading-challenge",
            to: "/user-activation/reading-challenge",
            title: "Tantangan Baca",
          },
          {
            key: "reading-parade",
            to: "/user-activation/reading-challenge/reading-parade",
            title: "Parade Baca",
          },
        ],
      },
      {
        key: "campaign",
        icon: <i className="icon icon-widgets" />,
        title: "Campaign",
        isSubMenu: false,
        to: "/campaign",
      },
    ],
    isReadOnly: ["/user-management"],
  },
  {
    label: "Product Associate",
    type: 2,
    sideMenus: [
      {
        key: "dashboard",
        to: "/dashboard",
        icon: <i className="icon icon-widgets" />,
        title: "Dashboard",
        isSubMenu: false,
      },
      {
        key: "user-management",
        icon: <i className="icon icon-widgets" />,
        title: "User Management",
        isSubMenu: true,
        items: [
          {
            key: "manage-account",
            to: "/user-management/manage-account",
            title: "Manage Account",
          },
        ],
      },
      {
        key: "content-partner",
        to: "/content-partner",
        icon: <i className="icon icon-widgets" />,
        title: "Content Partner",
        isSubMenu: false,
      },
      {
        key: "content-management",
        icon: <i className="icon icon-widgets" />,
        title: "Content Management",
        isSubMenu: true,
        items: [
          {
            key: "content",
            to: "/content-management/content",
            title: "Content",
          },
          {
            key: "recommendations",
            to: "/content-management/recommendations",
            title: "Recommendation",
          },
          {
            key: "educational-resource",
            to: "/content-management/educational-resource",
            title: "Lembar Aktivitas",
          },
        ],
      },
      {
        key: "user-engagement",
        icon: <i className="icon icon-widgets" />,
        title: "User Engagement",
        isSubMenu: true,
        items: [
          {
            key: "account-segmentation",
            to: "/user-engagement/account-segmentation",
            title: "Account Segmentation",
          },
          {
            key: "app-inbox",
            to: "/user-engagement/app-inbox",
            title: "App Inbox",
          },
          {
            key: "data-analysis",
            to: "/user-engagement/data-analysis",
            title: "Data Analysis",
          },
        ],
      },
      {
        key: "promo-management",
        icon: <i className="icon icon-widgets" />,
        title: "Promo Management",
        isSubMenu: true,
        items: [
          {
            key: "voucher",
            to: "/promo-management/voucher",
            title: "Voucher",
          },
        ],
      },
      {
        key: "billing-management",
        icon: <i className="icon icon-widgets" />,
        title: "Billing Management",
        isSubMenu: true,
        items: [
          {
            key: "account",
            to: "/billing-management/account",
            title: "Account Billings",
          },
          {
            key: "royalty-statement",
            to: "/billing-management/royalty-statement",
            title: "Royalty Statement",
          },
        ],
      },
      {
        key: "user-support-faq",
        to: "/user-support-faq",
        icon: <i className="icon icon-widgets" />,
        title: "User Support - FAQ",
        isSubMenu: false,
      },
    ],
    isReadOnly: [
      "/content-partner",
      "/content-management/content/category",
      "/content-management/content/book",
      "/content-management/content/featured-collection",
      "/promo-management/voucher",
      "/billing-management/account",
    ],
  },
  {
    label: "Content Manager",
    type: 3,
    sideMenus: [
      {
        key: "dashboard",
        to: "/dashboard",
        icon: <i className="icon icon-widgets" />,
        title: "Dashboard",
        isSubMenu: false,
      },
      {
        key: "user-management",
        icon: <i className="icon icon-widgets" />,
        title: "User Management",
        isSubMenu: true,
        items: [
          {
            key: "manage-account",
            to: "/user-management/manage-account",
            title: "Manage Account",
          },
        ],
      },
      {
        key: "content-partner",
        to: "/content-partner",
        icon: <i className="icon icon-widgets" />,
        title: "Content Partner",
        isSubMenu: false,
      },
      {
        key: "content-management",
        icon: <i className="icon icon-widgets" />,
        title: "Content Management",
        isSubMenu: true,
        items: [
          {
            key: "content",
            to: "/content-management/content",
            title: "Content",
          },
          {
            key: "recommendations",
            to: "/content-management/recommendations",
            title: "Recommendation",
          },
          {
            key: "educational-resource",
            to: "/content-management/educational-resource",
            title: "Lembar Aktivitas",
          },
        ],
      },
      {
        key: "user-engagement",
        icon: <i className="icon icon-widgets" />,
        title: "User Engagement",
        isSubMenu: true,
        items: [
          {
            key: "account-segmentation",
            to: "/user-engagement/account-segmentation",
            title: "Account Segmentation",
          },
          {
            key: "app-inbox",
            to: "/user-engagement/app-inbox",
            title: "App Inbox",
          },
          {
            key: "data-analysis",
            to: "/user-engagement/data-analysis",
            title: "Data Analysis",
          },
        ],
      },
      {
        key: "promo-management",
        icon: <i className="icon icon-widgets" />,
        title: "Promo Management",
        isSubMenu: true,
        items: [
          {
            key: "voucher",
            to: "/promo-management/voucher",
            title: "Voucher",
          },
        ],
      },
      {
        key: "billing-management",
        icon: <i className="icon icon-widgets" />,
        title: "Billing Management",
        isSubMenu: true,
        items: [
          {
            key: "royalty-statement",
            to: "/billing-management/royalty-statement",
            title: "Royalty Statement",
          },
        ],
      },
      {
        key: "user-support-faq",
        to: "/user-support-faq",
        icon: <i className="icon icon-widgets" />,
        title: "User Support - FAQ",
        isSubMenu: false,
      },
    ],
    accessDeniedPage: ["/billing-management/account"],
  },
  {
    label: "Sales & BD Director",
    type: 4,
    sideMenus: [
      {
        key: "dashboard",
        to: "/dashboard",
        icon: <i className="icon icon-widgets" />,
        title: "Dashboard",
        isSubMenu: false,
      },
      {
        key: "user-management",
        icon: <i className="icon icon-widgets" />,
        title: "User Management",
        isSubMenu: true,
        items: [
          {
            key: "manage-account",
            to: "/user-management/manage-account",
            title: "Manage Account",
          },
        ],
      },
      {
        key: "content-partner",
        to: "/content-partner",
        icon: <i className="icon icon-widgets" />,
        title: "Content Partner",
        isSubMenu: false,
      },
      {
        key: "user-engagement",
        icon: <i className="icon icon-widgets" />,
        title: "User Engagement",
        isSubMenu: true,
        items: [
          {
            key: "account-segmentation",
            to: "/user-engagement/account-segmentation",
            title: "Account Segmentation",
          },
          {
            key: "app-inbox",
            to: "/user-engagement/app-inbox",
            title: "App Inbox",
          },
          {
            key: "data-analysis",
            to: "/user-engagement/data-analysis",
            title: "Data Analysis",
          },
        ],
      },
      {
        key: "promo-management",
        icon: <i className="icon icon-widgets" />,
        title: "Promo Management",
        isSubMenu: true,
        items: [
          {
            key: "voucher",
            to: "/promo-management/voucher",
            title: "Voucher",
          },
        ],
      },
      {
        key: "billing-management",
        icon: <i className="icon icon-widgets" />,
        title: "Billing Management",
        isSubMenu: true,
        items: [
          {
            key: "account",
            to: "/billing-management/account",
            title: "Account Billings",
          },
          {
            key: "royalty-statement",
            to: "/billing-management/royalty-statement",
            title: "Royalty Statement",
          },
        ],
      },
    ],
    accessDeniedPage: [
      "/content-management/content",
      "/content-management/recommendations",
      "/content-management/educational-resource",
      "/user-support-faq",
    ],
    isReadOnly: [
      "/content-partner",
      "/user-engagement/account-segmentation",
      "/user-engagement/app-inbox",
    ],
  },
  {
    label: "Marketing Director",
    type: 5,
    sideMenus: [
      {
        key: "dashboard",
        to: "/dashboard",
        icon: <i className="icon icon-widgets" />,
        title: "Dashboard",
        isSubMenu: false,
      },
      {
        key: "user-management",
        icon: <i className="icon icon-widgets" />,
        title: "User Management",
        isSubMenu: true,
        items: [
          {
            key: "manage-account",
            to: "/user-management/manage-account",
            title: "Manage Account",
          },
        ],
      },
      {
        key: "content-partner",
        to: "/content-partner",
        icon: <i className="icon icon-widgets" />,
        title: "Content Partner",
        isSubMenu: false,
      },
      {
        key: "user-engagement",
        icon: <i className="icon icon-widgets" />,
        title: "User Engagement",
        isSubMenu: true,
        items: [
          {
            key: "account-segmentation",
            to: "/user-engagement/account-segmentation",
            title: "Account Segmentation",
          },
          {
            key: "app-inbox",
            to: "/user-engagement/app-inbox",
            title: "App Inbox",
          },
          {
            key: "data-analysis",
            to: "/user-engagement/data-analysis",
            title: "Data Analysis",
          },
        ],
      },
      {
        key: "promo-management",
        icon: <i className="icon icon-widgets" />,
        title: "Promo Management",
        isSubMenu: true,
        items: [
          {
            key: "voucher",
            to: "/promo-management/voucher",
            title: "Voucher",
          },
        ],
      },
      {
        key: "billing-management",
        icon: <i className="icon icon-widgets" />,
        title: "Billing Management",
        isSubMenu: true,
        items: [
          {
            key: "account",
            to: "/billing-management/account",
            title: "Account Billings",
          },
          {
            key: "royalty-statement",
            to: "/billing-management/royalty-statement",
            title: "Royalty Statement",
          },
        ],
      },
    ],
    accessDeniedPage: [
      "/content-management/content",
      "/content-management/recommendations",
      "/content-management/educational-resource",
      "/user-support-faq",
    ],
    isReadOnly: [
      "/content-partner",
      "/user-engagement/account-segmentation",
      "/user-engagement/app-inbox",
      "/billing-management/account",
    ],
  },
  {
    label: "Admin Sekolah",
    type: 6,
    sideMenus: [
      {
        key: "dashboard",
        to: "/dashboard",
        icon: <i className="icon icon-widgets" />,
        title: "Dashboard",
        isSubMenu: false,
      },
      {
        key: "user-management",
        icon: <i className="icon icon-widgets" />,
        title: "User Management",
        isSubMenu: true,
        items: [
          {
            key: "manage-account",
            to: "/user-management/manage-account",
            title: "Manage Account",
          },
        ],
      },
      {
        key: "billing-management",
        icon: <i className="icon icon-widgets" />,
        title: "Billing Management",
        isSubMenu: true,
        items: [
          {
            key: "account",
            to: "/billing-management/account",
            title: "Account Billings",
          },
        ],
      },
    ],
    isReadOnly: ["/billing-management/account"],
  },
  {
    label: "Admin Publisher",
    type: 7,
    sideMenus: [
      {
        key: "dashboard",
        to: "/dashboard",
        icon: <i className="icon icon-widgets" />,
        title: "Dashboard",
        isSubMenu: false,
      },
      {
        key: "billing-management",
        icon: <i className="icon icon-widgets" />,
        title: "Billing Management",
        isSubMenu: true,
        items: [
          {
            key: "royalty-statement",
            to: "/billing-management/royalty-statement",
            title: "Royalty Statement",
          },
        ],
      },
    ],
  },
];

export const isActionNotAllowed = (type, pathname) => {
  const accessDenied = data.find(
    (item) =>
      item.type === type &&
      item.isReadOnly?.some((item) => pathname?.includes(item))
  );
  return accessDenied;
};

export const isActionAllowed = (action, pathname, menus) => {
  // if (!menus.find((it) => it.name === "Campaign")) {
  //   const adCampaignMenus = {
  //     name: "Campaign",
  //     actions: "view,create,delete",
  //   };
  //   menus.push(adCampaignMenus);
  //   console.log(menus);
  // }
  return filteredMenus(menus)
    .find((menu) => pathname?.includes(menu.key))
    ?.actions?.includes(action);
};

export const filteredMenus = (menus) => {
  // if (!menus.find((it) => it.name === "Campaign")) {
  //   const adCampaignMenus = {
  //     name: "Campaign",
  //     actions: "view,create,delete",
  //   };
  //   menus.push(adCampaignMenus);
  // }
  return menus
    ?.map((menu) => {
      const menus = SideMenus.find((item) => item.title === menu.name);
      return {
        ...menu,
        ...menus,
      };
    })
    .filter((menu) => menu.actions !== "" && menu?.actions?.includes("view"));
};

export const isSubMenuAllowed = (menu, type) => {
  if (type === 7) {
    if (["account", "partner", "voucher", "pricing-plan"].includes(menu.key)) {
      return false;
    }
    return true;
  } else if (type === 6) {
    if (
      [
        "royalty-statement",
        "partner",
        "content-partner",
        "voucher",
        "pricing-plan",
        "account-segmentation",
        "school-free-trial",
      ].includes(menu.key)
    ) {
      return false;
    }
    return true;
  }
  return true;
};

export const SideMenus = [
  {
    key: "dashboard",
    to: "/dashboard",
    icon: <i className="icon icon-widgets" />,
    title: "Dashboard",
    isSubMenu: false,
  },
  {
    key: "staff-roles",
    to: "/staff-roles/admin-roles",
    icon: <i className="icon icon-widgets" />,
    title: "Staff Roles",
    isSubMenu: false,
  },
  {
    key: "user-account",
    icon: <i className="icon icon-widgets" />,
    title: "User Account",
    isSubMenu: true,
    items: [
      {
        key: "manage-account",
        to: "/user-account/manage-account",
        title: "Manage Account",
      },
      {
        key: "account-segmentation",
        to: "/user-account/account-segmentation",
        title: "User Account Seg",
      },
      {
        key: "content-partner",
        to: "/user-account/content-partner",
        title: "Content Partner",
      },
      {
        key: "school-free-trial",
        to: "/user-account/school-free-trial",
        title: "School Free Trial",
      },
    ],
  },
  {
    key: "content-partner",
    to: "/content-partner",
    icon: <i className="icon icon-widgets" />,
    title: "Content Partner",
    isSubMenu: false,
  },
  {
    key: "content-management",
    icon: <i className="icon icon-widgets" />,
    title: "Content Management",
    isSubMenu: true,
    items: [
      {
        key: "content",
        to: "/content-management/content",
        title: "Content",
      },
    ],
  },
  {
    key: "user-engagement",
    icon: <i className="icon icon-widgets" />,
    title: "User Engagement",
    isSubMenu: true,
    items: [
      {
        key: "data-analysis",
        to: "/user-engagement/data-analysis",
        title: "Data Analysis",
      },
    ],
  },
  {
    key: "promo-management",
    icon: <i className="icon icon-widgets" />,
    title: "Promo Management",
    isSubMenu: true,
    items: [],
  },
  {
    key: "content-display",
    icon: <i className="icon icon-widgets" />,
    title: "Content Display",
    isSubMenu: true,
    items: [
      {
        key: "slide-banner",
        to: "/content-display/slide-banner",
        title: "Slide Banner",
      },
      {
        key: "recommendation-row",
        to: "/content-display/recommendation-row",
        title: "Recommendation Row",
      },
    ],
  },
  {
    key: "billing-management",
    icon: <i className="icon icon-widgets" />,
    title: "Billing Management",
    isSubMenu: true,
    items: [
      {
        key: "account",
        to: "/billing-management/account/family",
        title: "Transaction History",
      },
      {
        key: "royalty-statement",
        to: "/billing-management/royalty-statement",
        title: "Royalty Statement",
      },
      {
        key: "voucher",
        to: "/billing-management/voucher",
        title: "Voucher",
      },
      {
        key: "pricing-plan",
        to: "/billing-management/pricing-plan",
        title: "Pricing Plan",
      },
    ],
  },
  {
    key: "user-support-faq",
    to: "/user-support-faq",
    icon: <i className="icon icon-widgets" />,
    title: "FAQ",
    isSubMenu: false,
  },
  {
    key: "user-activation",
    icon: <i className="icon icon-widgets" />,
    title: "User Activation",
    isSubMenu: true,
    items: [
      {
        key: "reading-challenge",
        to: "/user-activation/reading-challenge",
        title: "Tantangan Baca",
      },
      {
        key: "reading-parade",
        to: "/user-activation/reading-challenge/reading-parade",
        title: "Parade Baca",
      },
      {
        key: "app-inbox",
        to: "/user-activation/app-inbox",
        title: "App Inbox",
      },
      {
        key: "educational-resource",
        to: "/user-activation/educational-resource",
        title: "Lembar Aktivitas",
      },
    ],
  },
  {
    key: "campaign",
    icon: <i className="icon icon-widgets" />,
    title: "Campaign",
    isSubMenu: false,
    to: "/campaign",
  },
];
