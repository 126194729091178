import * as ActionType from "../constants/Campaign";

const initialState = {
  listCampaign: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  updateStatus: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  createCampaign: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  updateCampaign: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

const campaignReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.GET_LIST_CAMPAIGN_REQUEST:
      return {
        ...state,
        listCampaign: {
          ...state.listCampaign,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_CAMPAIGN_SUCCESS:
      return {
        ...state,
        listCampaign: {
          ...state.listCampaign,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_CAMPAIGN_FAILED:
      return {
        ...state,
        listCampagin: {
          ...state.listCampaign,
          isLoading: false,
        },
      };
    // update status
    case ActionType.UPDATE_STATUS_CAMPAIGN_REQUEST:
      return {
        ...state,
        updateStatus: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_STATUS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        updateStatus: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_STATUS_CAMPAIGN_FAILED:
      return {
        ...state,
        updateStatus: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };
    //create
    case ActionType.CREATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        createCampaign: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        createCampaign: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.CREATE_CAMPAIGN_FAILED:
      return {
        ...state,
        createCampaign: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };
    // update
    case ActionType.UPDATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        updateCampaign: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        updateCampaign: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_CAMPAIGN_FAILED:
      return {
        ...state,
        updateCampaign: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };
    default: {
      return state;
    }
  }
};

export default campaignReducer;
