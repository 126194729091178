import * as ActionType from "../constants/Banner";

export const getListBannerRequest = (payload) => ({
  type: ActionType.GET_LIST_BANNER_REQUEST,
  payload,
});

export const getListBannerSuccess = (payload) => ({
  type: ActionType.GET_LIST_BANNER_SUCCESS,
  payload,
});

export const getListBannerFailed = (payload) => ({
  type: ActionType.GET_LIST_BANNER_FAILED,
  payload,
});

export const createBannerRequest = (payload) => ({
  type: ActionType.CREATE_BANNER_REQUEST,
  payload,
});

export const createBannerSuccess = (payload) => ({
  type: ActionType.CREATE_BANNER_SUCCESS,
  payload,
});

export const createBannerFailed = (payload) => ({
  type: ActionType.CREATE_BANNER_FAILED,
  payload,
});

export const updateBannerRequest = (payload) => ({
  type: ActionType.UPDATE_BANNER_REQUEST,
  payload,
});

export const updateBannerSuccess = (payload) => ({
  type: ActionType.UPDATE_BANNER_SUCCESS,
  payload,
});

export const updateBannerFailed = (payload) => ({
  type: ActionType.UPDATE_BANNER_FAILED,
  payload,
});

export const deleteBannerRequest = (payload) => ({
  type: ActionType.DELETE_BANNER_REQUEST,
  payload,
});

export const deleteBannerSuccess = (payload) => ({
  type: ActionType.DELETE_BANNER_SUCCESS,
  payload,
});

export const deleteBannerFailed = (payload) => ({
  type: ActionType.DELETE_BANNER_FAILED,
  payload,
});

export const getBannerOnSchoolRequest = (payload) => ({
  type: ActionType.GET_BANNER_ON_SCHOOL_REQUEST,
  payload,
});

export const getBannerOnSchoolSuccess = (payload) => ({
  type: ActionType.GET_BANNER_ON_SCHOOL_SUCCESS,
  payload,
});

export const getBannerOnSchoolFailed = (payload) => ({
  type: ActionType.GET_BANNER_ON_SCHOOL_FAILED,
  payload,
});
