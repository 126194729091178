import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Dashboard";
import * as Api from "../../api/dashboard";

import { errorNotification } from "../../components/NotificationPopUp";

import {
  getDashboardTotalUsersChartSuccess,
  getDashboardTotalUsersChartFailed,
  getDashboardTotalUsersAverageSuccess,
  getDashboardTotalUsersAverageFailed,
  getDashboardTotalRevenueSuccess,
  getDashboardTotalRevenueFailed,
  getDashboardTotalUsersSigninCohortSuccess,
  getDashboardTotalUsersSigninCohortFailed,
  getDashboardTotalUsersSubscriberCohortSuccess,
  getDashboardTotalUsersSubscriberCohortFailed,
  getDashboardTotalUsersTransactionsAverageSuccess,
  getDashboardTotalUsersTransactionsAverageFailed,
  getDashboardTotalTransactionsPackageRatioSuccess,
  getDashboardTotalTransactionsPackageRatioFailed,
  getDashboardTotalDurationReadAverageSuccess,
  getDashboardTotalDurationReadAverageFailed,
  getDashboardTotalAccountSummarySuccess,
  getDashboardTotalAccountSummaryFailed,
  getDashboardTotalCollectionAndCompletionBookSuccess,
  getDashboardTotalCollectionAndCompletionBookFailed,
  getDashboardTotalSummaryReadChartSuccess,
  getDashboardTotalSummaryReadChartFailed,
  getDashboardTotalTopReaderSuccess,
  getDashboardTotalTopReaderFailed,
  getDashboardChartUserLoginSuccess,
  getDashboardChartUserLoginFailed,
  getDashboardTotalUserLoginSuccess,
  getDashboardTotalUserLoginFailed,
  getDashboardTotalBookDurationByIdSuccess,
  getDashboardTotalBookDurationByIdFailed,
  getDashboardTotalVoucherUsageSuccess,
  getDashboardTotalVoucherUsageFailed,
  getDashboardTotalVoucherChartSuccess,
  getDashboardTotalVoucherChartFailed,
  getDashboardActivitySheetsSuccess,
  getDashboardActivitySheetsFailed,
  getDashboardTotalContentPopularSuccess,
  getDashboardTotalContentPopularFailed,
  getDashboardSegmentationsUsersSuccess,
  getDashboardSegmentationsUsersFailed,
  getDashboardSegmentationsTopUsersSuccess,
  getDashboardSegmentationsTopUsersFailed,
  getDashboardSegmentationsChartUsersSuccess,
  getDashboardSegmentationsChartUsersFailed,
} from "../actions/Dashboard";

function* getDashboardTotalUsersChart({ payload }) {
  try {
    const resultDashboardTotalUsersChart = yield call(
      Api.fetchDashboardTotalUsersChart,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalUsersChartSuccess(
        { ...resultDashboardTotalUsersChart, limit: payload?.params?.limit } ||
          {}
      )
    );
  } catch (error) {
    errorNotification("Get dashboard total users", "Failed");
    yield put(getDashboardTotalUsersChartFailed());
  }
}

export function* watchDashboardTotalUsersChartRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_USERS_CHART_REQUEST,
    getDashboardTotalUsersChart
  );
}

function* getDashboardTotalUsersAverage({ payload }) {
  try {
    const resultDashboardTotalUsersAverage = yield call(
      Api.fetchDashboardTotalUsersAverage,
      {}
    );
    yield put(
      getDashboardTotalUsersAverageSuccess(
        {
          ...resultDashboardTotalUsersAverage,
          limit: payload?.params?.limit,
        } || {}
      )
    );
  } catch (error) {
    console.log(error, "error");
    errorNotification("Get dashboard total users average", "Failed");
    yield put(getDashboardTotalUsersAverageFailed());
  }
}

export function* watchDashboardTotalUsersAverageRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_USERS_AVERAGE_REQUEST,
    getDashboardTotalUsersAverage
  );
}

function* getDashboardTotalRevenue({ payload }) {
  try {
    const resultDashboardTotalRevenue = yield call(
      Api.fetchDashboardTotalRevenue,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalRevenueSuccess({ ...resultDashboardTotalRevenue } || {})
    );
  } catch (error) {
    console.log(error);
    errorNotification("Get dashboard total revenue", "Failed");
    yield put(getDashboardTotalRevenueFailed());
  }
}

export function* watchDashboardTotalRevenueRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_REVENUE_REQUEST,
    getDashboardTotalRevenue
  );
}

function* getDashboardTotalUsersSigninCohort({ payload }) {
  try {
    const resultDashboardTotalUsersSigninCohort = yield call(
      Api.fetchDashboardTotalUsersSigninCohort,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalUsersSigninCohortSuccess(
        { ...resultDashboardTotalUsersSigninCohort } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification("Get dashboard total users signin cohort", "Failed");
    yield put(getDashboardTotalUsersSigninCohortFailed());
  }
}

export function* watchDashboardTotalUsersSigninCohortRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_REQUEST,
    getDashboardTotalUsersSigninCohort
  );
}

function* getDashboardTotalUsersSubscriberCohort({ payload }) {
  try {
    const resultDashboardTotalUsersSubscriberCohort = yield call(
      Api.fetchDashboardTotalUsersSubscriberCohort,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalUsersSubscriberCohortSuccess(
        { ...resultDashboardTotalUsersSubscriberCohort } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification("Get dashboard total users subscriber cohort", "Failed");
    yield put(getDashboardTotalUsersSubscriberCohortFailed());
  }
}

export function* watchDashboardTotalUsersSubscriberCohortRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_REQUEST,
    getDashboardTotalUsersSubscriberCohort
  );
}

function* getDashboardTotalUsersTransactionsAverage({ payload }) {
  try {
    const resultDashboardTotalUsersTransactionsAverage = yield call(
      Api.fetchDashboardTotalUsersTransactionsAverage,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalUsersTransactionsAverageSuccess(
        { ...resultDashboardTotalUsersTransactionsAverage } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification(
      "Get dashboard total users transactions average",
      "Failed"
    );
    yield put(getDashboardTotalUsersTransactionsAverageFailed());
  }
}

export function* watchDashboardTotalUsersTransactionsAverageRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_REQUEST,
    getDashboardTotalUsersTransactionsAverage
  );
}

function* getDashboardTotalTransactionsPackageRatio({ payload }) {
  try {
    const resultDashboardTotalTransactionsPackageRatio = yield call(
      Api.fetchDashboardTotalTransactionsPackageRatio,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalTransactionsPackageRatioSuccess(
        { ...resultDashboardTotalTransactionsPackageRatio } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification(
      "Get dashboard total transactions package ratio",
      "Failed"
    );
    yield put(getDashboardTotalTransactionsPackageRatioFailed());
  }
}

export function* watchDashboardTotalTransactionsPackageRatioRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_REQUEST,
    getDashboardTotalTransactionsPackageRatio
  );
}

function* getDashboardTotalDurationReadAverage({ payload }) {
  try {
    const resultDashboardTotalDurationReadAverage = yield call(
      Api.fetchDashboardTotalDurationReadAverage,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalDurationReadAverageSuccess(
        { ...resultDashboardTotalDurationReadAverage } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification("Get dashboard total duration read average", "Failed");
    yield put(getDashboardTotalDurationReadAverageFailed());
  }
}

export function* watchDashboardTotalDurationReadAverageRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_REQUEST,
    getDashboardTotalDurationReadAverage
  );
}

function* getDashboardTotalAccountSummary({ payload }) {
  try {
    const resultDashboardTotalAccountSummary = yield call(
      Api.fetchDashboardTotalAccountSummary,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalAccountSummarySuccess(
        { ...resultDashboardTotalAccountSummary } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification("Get dashboard total account summary", "Failed");
    yield put(getDashboardTotalAccountSummaryFailed());
  }
}

export function* watchDashboardTotalAccountSummaryRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_REQUEST,
    getDashboardTotalAccountSummary
  );
}

function* getDashboardTotalCollectionAndCompletionBook({ payload }) {
  try {
    const resultDashboardTotalCollectionAndCompletionBook = yield call(
      Api.fetchDashboardTotalCollectionAndCompletionBook,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalCollectionAndCompletionBookSuccess(
        { ...resultDashboardTotalCollectionAndCompletionBook } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification(
      "Get dashboard total collection and completion book",
      "Failed"
    );
    yield put(getDashboardTotalCollectionAndCompletionBookFailed());
  }
}

export function* watchDashboardTotalCollectionAndCompletionBookRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_REQUEST,
    getDashboardTotalCollectionAndCompletionBook
  );
}

function* getDashboardTotalSummaryReadChart({ payload }) {
  try {
    const resultDashboardTotalSummaryReadChart = yield call(
      Api.fetchDashboardTotalSummaryReadChart,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalSummaryReadChartSuccess(
        { ...resultDashboardTotalSummaryReadChart } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification("Get dashboard total summary read chart", "Failed");
    yield put(getDashboardTotalSummaryReadChartFailed());
  }
}

export function* watchDashboardTotalSummaryReadChartRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_REQUEST,
    getDashboardTotalSummaryReadChart
  );
}

function* getDashboardTotalTopReader({ payload }) {
  try {
    const resultDashboardTotalTopReader = yield call(
      Api.fetchDashboardTotalTopReader,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalTopReaderSuccess(
        { ...resultDashboardTotalTopReader } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification("Get dashboard total top reader", "Failed");
    yield put(getDashboardTotalTopReaderFailed());
  }
}

export function* watchDashboardTotalTopReaderRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_TOP_READER_REQUEST,
    getDashboardTotalTopReader
  );
}

function* getDashboardChartUserLogin({ payload }) {
  try {
    const resultDashboardChartUserLogin = yield call(
      Api.fetchDashboardChartUserLogin,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardChartUserLoginSuccess(
        { ...resultDashboardChartUserLogin } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification("Get dashboard chart user login", "Failed");
    yield put(getDashboardChartUserLoginFailed());
  }
}

export function* watchDashboardChartUserLoginRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_CHART_USER_LOGIN_REQUEST,
    getDashboardChartUserLogin
  );
}

function* getDashboardTotalUserLogin({ payload }) {
  try {
    const resultDashboardTotalUserLogin = yield call(
      Api.fetchDashboardTotalUserLogin,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalUserLoginSuccess(
        {
          ...resultDashboardTotalUserLogin,
        } || {}
      )
    );
  } catch (error) {
    console.log(error, "error");
    errorNotification("Get dashboard total user login", "Failed");
    yield put(getDashboardTotalUserLoginFailed());
  }
}

export function* watchDashboardTotalUserLoginRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_USER_LOGIN_REQUEST,
    getDashboardTotalUserLogin
  );
}

function* getDashboardTotalBookDurationById({ payload }) {
  try {
    const resultDashboardTotalBookDurationById = yield call(
      Api.fetchDashboardTotalBookDurationById,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalBookDurationByIdSuccess(
        {
          ...resultDashboardTotalBookDurationById,
        } || {}
      )
    );
  } catch (error) {
    console.log(error, "error");
    errorNotification("Get dashboard total book duration by id", "Failed");
    yield put(getDashboardTotalBookDurationByIdFailed());
  }
}

export function* watchDashboardTotalBookDurationByIdRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_REQUEST,
    getDashboardTotalBookDurationById
  );
}

function* getDashboardTotalVoucherUsage({ payload }) {
  try {
    const resultDashboardTotalVoucherUsage = yield call(
      Api.fetchDashboardTotalVoucherUsage,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalVoucherUsageSuccess(
        {
          ...resultDashboardTotalVoucherUsage,
        } || {}
      )
    );
  } catch (error) {
    console.log(error, "error");
    errorNotification("Get dashboard total voucher usage", "Failed");
    yield put(getDashboardTotalVoucherUsageFailed());
  }
}

export function* watchDashboardTotalVoucherUsageRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_VOUCHER_USAGE_REQUEST,
    getDashboardTotalVoucherUsage
  );
}

function* getDashboardTotalVoucherChart({ payload }) {
  try {
    const resultDashboardTotalVoucherChart = yield call(
      Api.fetchDashboardTotalVoucherChart,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalVoucherChartSuccess(
        {
          ...resultDashboardTotalVoucherChart,
          limit: payload?.params?.limit,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get dashboard total voucher chart", "Failed");
    yield put(getDashboardTotalVoucherChartFailed());
  }
}

export function* watchDashboardTotalVoucherChartRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_VOUCHER_CHART_REQUEST,
    getDashboardTotalVoucherChart
  );
}

function* getDashboardActivitySheets({ payload }) {
  try {
    const resultDashboardActivitySheets = yield call(
      Api.fetchDashboardActivitySheets,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardActivitySheetsSuccess(
        {
          ...resultDashboardActivitySheets,
          limit: payload?.params?.limit,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get dashboard activity sheets", "Failed");
    yield put(getDashboardActivitySheetsFailed());
  }
}

export function* watchDashboardActivitySheetsRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_ACTIVITY_SHEETS_REQUEST,
    getDashboardActivitySheets
  );
}

function* getDashboardTotalContentPopular({ payload }) {
  try {
    const resultDashboardTotalContentPopular = yield call(
      Api.fetchDashboardTotalContentPopular,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardTotalContentPopularSuccess(
        { ...resultDashboardTotalContentPopular } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification("Get dashboard total content popular", "Failed");
    yield put(getDashboardTotalContentPopularFailed());
  }
}

export function* watchDashboardTotalContentPopularRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_TOTAL_CONTENT_POPULAR_REQUEST,
    getDashboardTotalContentPopular
  );
}

function* getDashboardSegmentationsUsers({ payload }) {
  try {
    const resultDashboardSegmentationsUsers = yield call(
      Api.fetchDashboardSegmentationsUsers,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardSegmentationsUsersSuccess(
        { ...resultDashboardSegmentationsUsers } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification("Get dashboard chart segmentations users", "Failed");
    yield put(getDashboardSegmentationsUsersFailed());
  }
}

export function* watchDashboardSegmentationsUsersRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_SEGMENTATIONS_USERS_REQUEST,
    getDashboardSegmentationsUsers
  );
}

function* getDashboardSegmentationsTopUsers({ payload }) {
  try {
    const resultDashboardSegmentationsTopUsers = yield call(
      Api.fetchDashboardSegmentationsTopUsers,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardSegmentationsTopUsersSuccess(
        { ...resultDashboardSegmentationsTopUsers } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification("Get dashboard chart segmentations top users", "Failed");
    yield put(getDashboardSegmentationsTopUsersFailed());
  }
}

export function* watchDashboardSegmentationsTopUsersRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_REQUEST,
    getDashboardSegmentationsTopUsers
  );
}

function* getDashboardSegmentationsChartUsers({ payload }) {
  try {
    const resultDashboardSegmentationsChartUsers = yield call(
      Api.fetchDashboardSegmentationsChartUsers,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getDashboardSegmentationsChartUsersSuccess(
        { ...resultDashboardSegmentationsChartUsers } || {}
      )
    );
  } catch (error) {
    console.log(error);
    errorNotification(
      "Get dashboard chart segmentations chart users",
      "Failed"
    );
    yield put(getDashboardSegmentationsChartUsersFailed());
  }
}

export function* watchDashboardSegmentationsChartUsersRequest() {
  yield takeEvery(
    ActionType.GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_REQUEST,
    getDashboardSegmentationsChartUsers
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchDashboardTotalUsersChartRequest),
    fork(watchDashboardTotalUsersAverageRequest),
    fork(watchDashboardTotalRevenueRequest),
    fork(watchDashboardTotalUsersSigninCohortRequest),
    fork(watchDashboardTotalUsersSubscriberCohortRequest),
    fork(watchDashboardTotalUsersTransactionsAverageRequest),
    fork(watchDashboardTotalTransactionsPackageRatioRequest),
    fork(watchDashboardTotalDurationReadAverageRequest),
    fork(watchDashboardTotalAccountSummaryRequest),
    fork(watchDashboardTotalCollectionAndCompletionBookRequest),
    fork(watchDashboardTotalSummaryReadChartRequest),
    fork(watchDashboardTotalTopReaderRequest),
    fork(watchDashboardChartUserLoginRequest),
    fork(watchDashboardTotalUserLoginRequest),
    fork(watchDashboardTotalBookDurationByIdRequest),
    fork(watchDashboardTotalVoucherUsageRequest),
    fork(watchDashboardTotalVoucherChartRequest),
    fork(watchDashboardActivitySheetsRequest),
    fork(watchDashboardTotalContentPopularRequest),
    fork(watchDashboardSegmentationsUsersRequest),
    fork(watchDashboardSegmentationsTopUsersRequest),
    fork(watchDashboardSegmentationsChartUsersRequest),
  ]);
}
