import * as ActionType from "../constants/Dashboard";

export const getDashboardTotalUsersChartRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_CHART_REQUEST,
  payload,
});

export const getDashboardTotalUsersChartSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_CHART_SUCCESS,
  payload,
});

export const getDashboardTotalUsersChartFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_CHART_FAILED,
  payload,
});

export const getDashboardTotalUsersAverageRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_AVERAGE_REQUEST,
  payload,
});

export const getDashboardTotalUsersAverageSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_AVERAGE_SUCCESS,
  payload,
});

export const getDashboardTotalUsersAverageFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_AVERAGE_FAILED,
  payload,
});

export const getDashboardTotalRevenueRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_REVENUE_REQUEST,
  payload,
});

export const getDashboardTotalRevenueSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_REVENUE_SUCCESS,
  payload,
});

export const getDashboardTotalRevenueFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_REVENUE_FAILED,
  payload,
});

export const getDashboardTotalUsersSigninCohortRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_REQUEST,
  payload,
});

export const getDashboardTotalUsersSigninCohortSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_SUCCESS,
  payload,
});

export const getDashboardTotalUsersSigninCohortFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_FAILED,
  payload,
});

export const getDashboardTotalUsersSubscriberCohortRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_REQUEST,
  payload,
});

export const getDashboardTotalUsersSubscriberCohortSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_SUCCESS,
  payload,
});

export const getDashboardTotalUsersSubscriberCohortFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_FAILED,
  payload,
});

export const getDashboardTotalUsersTransactionsAverageRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_REQUEST,
  payload,
});

export const getDashboardTotalUsersTransactionsAverageSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_SUCCESS,
  payload,
});

export const getDashboardTotalUsersTransactionsAverageFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_FAILED,
  payload,
});

export const getDashboardTotalTransactionsPackageRatioRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_REQUEST,
  payload,
});

export const getDashboardTotalTransactionsPackageRatioSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_SUCCESS,
  payload,
});

export const getDashboardTotalTransactionsPackageRatioFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_FAILED,
  payload,
});

export const getDashboardTotalDurationReadAverageRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_REQUEST,
  payload,
});

export const getDashboardTotalDurationReadAverageSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_SUCCESS,
  payload,
});

export const getDashboardTotalDurationReadAverageFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_FAILED,
  payload,
});

export const getDashboardTotalAccountSummaryRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_REQUEST,
  payload,
});

export const getDashboardTotalAccountSummarySuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_SUCCESS,
  payload,
});

export const getDashboardTotalAccountSummaryFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_FAILED,
  payload,
});

export const getDashboardTotalCollectionAndCompletionBookRequest = (
  payload
) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_REQUEST,
  payload,
});

export const getDashboardTotalCollectionAndCompletionBookSuccess = (
  payload
) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_SUCCESS,
  payload,
});

export const getDashboardTotalCollectionAndCompletionBookFailed = (
  payload
) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_FAILED,
  payload,
});

export const getDashboardTotalSummaryReadChartRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_REQUEST,
  payload,
});

export const getDashboardTotalSummaryReadChartSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_SUCCESS,
  payload,
});

export const getDashboardTotalSummaryReadChartFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_FAILED,
  payload,
});

export const getDashboardTotalTopReaderRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_TOP_READER_REQUEST,
  payload,
});

export const getDashboardTotalTopReaderSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_TOP_READER_SUCCESS,
  payload,
});

export const getDashboardTotalTopReaderFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_TOP_READER_FAILED,
  payload,
});

export const getDashboardChartUserLoginRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_CHART_USER_LOGIN_REQUEST,
  payload,
});

export const getDashboardChartUserLoginSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_CHART_USER_LOGIN_SUCCESS,
  payload,
});

export const getDashboardChartUserLoginFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_CHART_USER_LOGIN_FAILED,
  payload,
});

export const getDashboardTotalUserLoginRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USER_LOGIN_REQUEST,
  payload,
});

export const getDashboardTotalUserLoginSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USER_LOGIN_SUCCESS,
  payload,
});

export const getDashboardTotalUserLoginFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_USER_LOGIN_FAILED,
  payload,
});

export const getDashboardTotalBookDurationByIdRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_REQUEST,
  payload,
});

export const getDashboardTotalBookDurationByIdSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_SUCCESS,
  payload,
});

export const getDashboardTotalBookDurationByIdFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_FAILED,
  payload,
});

export const getDashboardTotalVoucherUsageRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_VOUCHER_USAGE_REQUEST,
  payload,
});

export const getDashboardTotalVoucherUsageSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_VOUCHER_USAGE_SUCCESS,
  payload,
});

export const getDashboardTotalVoucherUsageFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_VOUCHER_USAGE_FAILED,
  payload,
});

export const getDashboardTotalVoucherChartRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_VOUCHER_CHART_REQUEST,
  payload,
});

export const getDashboardTotalVoucherChartSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_VOUCHER_CHART_SUCCESS,
  payload,
});

export const getDashboardTotalVoucherChartFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_VOUCHER_CHART_FAILED,
  payload,
});

export const getDashboardActivitySheetsRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_ACTIVITY_SHEETS_REQUEST,
  payload,
});

export const getDashboardActivitySheetsSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_ACTIVITY_SHEETS_SUCCESS,
  payload,
});

export const getDashboardActivitySheetsFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_ACTIVITY_SHEETS_FAILED,
  payload,
});

export const getDashboardTotalContentPopularRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_CONTENT_POPULAR_REQUEST,
  payload,
});

export const getDashboardTotalContentPopularSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_CONTENT_POPULAR_SUCCESS,
  payload,
});

export const getDashboardTotalContentPopularFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_TOTAL_CONTENT_POPULAR_FAILED,
  payload,
});

export const getDashboardSegmentationsUsersRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_SEGMENTATIONS_USERS_REQUEST,
  payload,
});

export const getDashboardSegmentationsUsersSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_SEGMENTATIONS_USERS_SUCCESS,
  payload,
});

export const getDashboardSegmentationsUsersFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_SEGMENTATIONS_USERS_FAILED,
  payload,
});

export const getDashboardSegmentationsTopUsersRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_REQUEST,
  payload,
});

export const getDashboardSegmentationsTopUsersSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_SUCCESS,
  payload,
});

export const getDashboardSegmentationsTopUsersFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_FAILED,
  payload,
});

export const getDashboardSegmentationsChartUsersRequest = (payload) => ({
  type: ActionType.GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_REQUEST,
  payload,
});

export const getDashboardSegmentationsChartUsersSuccess = (payload) => ({
  type: ActionType.GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_SUCCESS,
  payload,
});

export const getDashboardSegmentationsChartUsersFailed = (payload) => ({
  type: ActionType.GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_FAILED,
  payload,
});
