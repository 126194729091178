import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions";

const UserProfile = () => {
  const dispatch = useDispatch();

  const myProfile = useSelector(({ profile }) => profile.myProfile);

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar className="gx-size-40 gx-pointer gx-mr-3" alt="">
          {myProfile?.data?.name?.charAt(0)}
        </Avatar>
        <span 
          className="gx-avatar-name" 
          style={{ 
            color: myProfile?.data?.type === 6 ? 'white' : '' 
          }}
        >
          {myProfile?.data?.name}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
