import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SIGNIN_USER, SIGNOUT_USER } from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
} from "../../appRedux/actions/Auth";
import {
  fetchLogin,
  fetchLoginSchool,
  fetchLoginPublisher,
} from "../../api/auth";
import {
  SIGNIN_USER_PUBLISHER,
  SIGNIN_USER_SCHOOL,
} from "../../constants/ActionTypes";
import { clearMyProfileRequest } from "../actions";

function* signOut() {
  localStorage.clear();
  yield put(userSignOutSuccess());
  yield put(clearMyProfileRequest());
}

function* signInUserWithEmailPasswordRequest({ payload }) {
  const { email, password } = payload;
  try {
    const result = yield call(fetchLogin, { email, password });
    if (!result.success) {
      yield put(showAuthMessage(result?.errors[0]?.message));
    } else {
      localStorage.setItem("@token", result.data.token);
      localStorage.setItem("@user_id", result.data.id);
      // localStorage.setItem("@refresh_token", result.data.refreshToken);

      yield put(userSignInSuccess(result.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPasswordRequest);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

function* signInUserSchoolRequest({ payload }) {
  const { userNameOrEmail, password } = payload;
  try {
    const result = yield call(fetchLoginSchool, { userNameOrEmail, password });
    if (!result.success) {
      yield put(showAuthMessage(result?.errors[0]?.message));
    } else {
      localStorage.setItem("@token", result.data.token);
      localStorage.setItem("@user_id", result.data.id);
      // localStorage.setItem("@refresh_token", result.data.refreshToken);

      yield put(userSignInSuccess(result.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUserSchool() {
  yield takeEvery(SIGNIN_USER_SCHOOL, signInUserSchoolRequest);
}

function* signInUserPublisherRequest({ payload }) {
  const { userNameOrEmail, password } = payload;
  try {
    const result = yield call(fetchLoginPublisher, {
      userNameOrEmail,
      password,
    });
    if (!result.success) {
      yield put(showAuthMessage(result?.errors[0]?.message));
    } else {
      localStorage.setItem("@token", result.data.token);
      localStorage.setItem("@user_id", result.data.id);
      // localStorage.setItem("@refresh_token", result.data.refreshToken);

      yield put(userSignInSuccess(result.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUserPublisher() {
  yield takeEvery(SIGNIN_USER_PUBLISHER, signInUserPublisherRequest);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    fork(signInUserSchool),
    fork(signInUserPublisher),
  ]);
}
