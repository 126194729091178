import * as ActionType from "../constants/Inbox";

const initialState = {
  listAppInbox: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  appInboxById: {
    data: {},
    isLoading: false,
  },
  createAppInbox: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  sendAppInbox: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  updateAppInbox: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

const appInboxReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_APP_INBOX_REQUEST:
      return {
        ...state,
        listAppInbox: {
          ...state.listAppInbox,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_APP_INBOX_SUCCESS:
      return {
        ...state,
        listAppInbox: {
          ...state.listAppInbox,
          data: payload.data,
          isLoading: false,
          totalData: payload?.paging?.totalData || 0,
          status: payload?.status,
        },
      };
    case ActionType.GET_LIST_APP_INBOX_FAILED:
      return {
        ...state,
        listAppInbox: {
          ...state.listAppInbox,
          isLoading: false,
        },
      };

    case ActionType.GET_APP_INBOX_BY_ID_REQUEST:
      return {
        ...state,
        appInboxById: {
          ...state.appInboxById,
          isLoading: true,
        },
      };
    case ActionType.GET_APP_INBOX_BY_ID_SUCCESS:
      return {
        ...state,
        appInboxById: {
          ...state.appInboxById,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_APP_INBOX_BY_ID_FAILED:
      return {
        ...state,
        appInboxById: {
          ...state.appInboxById,
          isLoading: false,
        },
      };

    case ActionType.CREATE_APP_INBOX_REQUEST:
      return {
        ...state,
        createAppInbox: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.CREATE_APP_INBOX_SUCCESS:
      return {
        ...state,
        createAppInbox: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.CREATE_APP_INBOX_FAILED:
      return {
        ...state,
        createAppInbox: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.SEND_APP_INBOX_REQUEST:
      return {
        ...state,
        sendAppInbox: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.SEND_APP_INBOX_SUCCESS:
      return {
        ...state,
        sendAppInbox: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.SEND_APP_INBOX_FAILED:
      return {
        ...state,
        sendAppInbox: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.UPDATE_APP_INBOX_REQUEST:
      return {
        ...state,
        updateAppInbox: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_APP_INBOX_SUCCESS:
      return {
        ...state,
        updateAppInbox: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_APP_INBOX_FAILED:
      return {
        ...state,
        updateAppInbox: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    default: {
      return state;
    }
  }
};

export default appInboxReducer;
