export const GET_LIST_READING_PARADE_REQUEST =
  "GET_LIST_READING_PARADE_REQUEST";
export const GET_LIST_READING_PARADE_SUCCESS =
  "GET_LIST_READING_PARADE_SUCCESS";
export const GET_LIST_READING_PARADE_FAILED = "GET_LIST_READING_PARADE_FAILED";

export const CREATE_READING_PARADE_REQUEST = "CREATE_READING_PARADE_REQUEST";
export const CREATE_READING_PARADE_SUCCESS = "CREATE_READING_PARADE_SUCCESS";
export const CREATE_READING_PARADE_FAILED = "CREATE_READING_PARADE_FAILED";

export const RESET_STATUS_READING_PARADE_REQUEST =
  "RESET_STATUS_READING_PARADE_REQUEST";

export const UPDATE_READING_PARADE_REQUEST = "UPDATE_READING_PARADE_REQUEST";
export const UPDATE_READING_PARADE_SUCCESS = "UPDATE_READING_PARADE_SUCCESS";
export const UPDATE_READING_PARADE_FAILED = "UPDATE_READING_PARADE_FAILED";

export const GET_READING_PARADE_BY_ID_REQUEST =
  "GET_READING_PARADE_BY_ID_REQUEST";
export const GET_READING_PARADE_BY_ID_SUCCESS =
  "GET_READING_PARADE_BY_ID_SUCCESS";
export const GET_READING_PARADE_BY_ID_FAILED =
  "GET_READING_PARADE_BY_ID_FAILED";

export const UPDATE_STATUS_READING_PARADE_REQUEST =
  "UPDATE_STATUS_READING_PARADE_REQUEST";
export const UPDATE_STATUS_READING_PARADE_SUCCESS =
  "UPDATE_STATUS_READING_PARADE_SUCCESS";
export const UPDATE_STATUS_READING_PARADE_FAILED =
  "UPDATE_STATUS_READING_PARADE_FAILED";

export const RE_PUBLISHED_READING_PARADE_REQUEST =
  "RE_PUBLISHED_READING_PARADE_REQUEST";
export const RE_PUBLISHED_READING_PARADE_SUCCESS =
  "RE_PUBLISHED_READING_PARADE_SUCCESS";
export const RE_PUBLISHED_READING_PARADE_FAILED =
  "RE_PUBLISHED_READING_PARADE_FAILED";

export const GET_LIST_READING_CHALLENGE_REQUEST =
  "GET_LIST_READING_CHALLENGE_REQUEST";
export const GET_LIST_READING_CHALLENGE_SUCCESS =
  "GET_LIST_READING_CHALLENGE_SUCCESS";
export const GET_LIST_READING_CHALLENGE_FAILED =
  "GET_LIST_READING_CHALLENGE_FAILED";

export const CREATE_READING_CHALLENGE_REQUEST =
  "CREATE_READING_CHALLENGE_REQUEST";
export const CREATE_READING_CHALLENGE_SUCCESS =
  "CREATE_READING_CHALLENGE_SUCCESS";
export const CREATE_READING_CHALLENGE_FAILED =
  "CREATE_READING_CHALLENGE_FAILED";

export const GET_READING_CHALLENGE_BY_ID_REQUEST =
  "GET_READING_CHALLENGE_BY_ID_REQUEST";
export const GET_READING_CHALLENGE_BY_ID_SUCCESS =
  "GET_READING_CHALLENGE_BY_ID_SUCCESS";
export const GET_READING_CHALLENGE_BY_ID_FAILED =
  "GET_READING_CHALLENGE_BY_ID_FAILED";

export const UPDATE_READING_CHALLENGE_REQUEST =
  "UPDATE_READING_CHALLENGE_REQUEST";
export const UPDATE_READING_CHALLENGE_SUCCESS =
  "UPDATE_READING_CHALLENGE_SUCCESS";
export const UPDATE_READING_CHALLENGE_FAILED =
  "UPDATE_READING_CHALLENGE_FAILED";

export const RESET_STATUS_READING_CHALLENGE_REQUEST =
  "RESET_STATUS_READING_CHALLENGE_REQUEST";

export const UPDATE_STATUS_READING_CHALLENGE_REQUEST =
  "UPDATE_STATUS_READING_CHALLENGE_REQUEST";
export const UPDATE_STATUS_READING_CHALLENGE_SUCCESS =
  "UPDATE_STATUS_READING_CHALLENGE_SUCCESS";
export const UPDATE_STATUS_READING_CHALLENGE_FAILED =
  "UPDATE_STATUS_READING_CHALLENGE_FAILED";

export const RE_PUBLISHED_READING_CHALLENGE_REQUEST =
  "RE_PUBLISHED_READING_CHALLENGE_REQUEST";
export const RE_PUBLISHED_READING_CHALLENGE_SUCCESS =
  "RE_PUBLISHED_READING_CHALLENGE_SUCCESS";
export const RE_PUBLISHED_READING_CHALLENGE_FAILED =
  "RE_PUBLISHED_READING_CHALLENGE_FAILED";

export const UPLOAD_POINT_PARADE_REQUEST = "UPLOAD_POINT_PARADE_REQUEST";
export const UPLOAD_POINT_PARADE_SUCCESS = "UPLOAD_POINT_PARADE_SUCCESS";
export const UPLOAD_POINT_PARADE_FAILED = "UPLOAD_POINT_PARADE_FAILED";
