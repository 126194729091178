import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Transaction";
import * as Api from "../../api/transaction";
import * as ApiPublisher from "../../api/publisher";
import * as ApiSchoolAdmin from "../../api/schoolAdmin";
import {
  getListTransactionSubsTypeSuccess,
  getListTransactionSubsTypeFailed,
  createTransactionSubsTypeSuccess,
  createTransactionSubsTypeFailed,
  updateTransactionSubsTypeSuccess,
  updateTransactionSubsTypeFailed,
  updateStatusTransactionSubsTypeSuccess,
  updateStatusTransactionSubsTypeFailed,
  getListTransactionSuccess,
  getListTransactionFailed,
  createManualTransactionSuccess,
  createManualTransactionFailed,
  updateStatusTransactionSuccess,
  updateStatusTransactionFailed,
  getListRoyaltyStatementSuccess,
  getListRoyaltyStatementFailed,
  updateStatusPayoutSuccess,
  updateStatusPayoutFailed,
  getDetailRoyaltyStatementSuccess,
  getDetailRoyaltyStatementFailed,
  getDetailReportsSuccess,
  getDetailReportsFailed,
  createTransactionSpecialPackageSuccess,
  createTransactionSpecialPackageFailed,
} from "../actions/Transaction";
import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";

function* getListTransactionSubsTypeWithOption({ payload }) {
  try {
    const resultListTransactionSubsType = yield call(
      Api.fetchListTransactionSubsType,
      {
        params: { ...payload.params },
        type: payload.type,
      }
    );
    yield put(
      getListTransactionSubsTypeSuccess(
        {
          ...resultListTransactionSubsType,
          limit: payload?.params?.limit,
          type: payload?.params?.type,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list transaction subs type", "Failed");
    yield put(getListTransactionSubsTypeFailed());
  }
}

export function* watchListTransactionSubsTypeRequest() {
  yield takeEvery(
    ActionType.GET_LIST_TRANSACTION_SUBS_TYPE_REQUEST,
    getListTransactionSubsTypeWithOption
  );
}

function* createTransactionSubsType({ payload }) {
  try {
    const resultCreateTransactionSubsType = yield call(
      Api.fetchCreateTransactionSubsType,
      payload
    );
    if (resultCreateTransactionSubsType.success === true) {
      yield put(createTransactionSubsTypeSuccess(payload));
      successNotification("Create transaction subs type", "Success");
    } else {
      yield put(
        createTransactionSubsTypeFailed(resultCreateTransactionSubsType || {})
      );
      resultCreateTransactionSubsType?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create transaction subs type", "Failed");
    yield put(createTransactionSubsTypeFailed());
  }
}

export function* watchCreateTransactionSubsTypeRequest() {
  yield takeEvery(
    ActionType.CREATE_TRANSACTION_SUBS_TYPE_REQUEST,
    createTransactionSubsType
  );
}

function* updateTransactionSubsType({ payload }) {
  try {
    const resultUpdateTransactionSubsType = yield call(
      Api.fetchUpdateTransactionSubsType,
      payload
    );
    if (resultUpdateTransactionSubsType.success === true) {
      yield put(updateTransactionSubsTypeSuccess(payload));
      successNotification("Update transaction subs type", "Success");
    } else {
      yield put(
        updateTransactionSubsTypeFailed(resultUpdateTransactionSubsType || {})
      );
      resultUpdateTransactionSubsType?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update transaction subs type", "Failed");
    yield put(updateTransactionSubsTypeFailed());
  }
}

export function* watchUpdateTransactionSubsTypeRequest() {
  yield takeEvery(
    ActionType.UPDATE_TRANSACTION_SUBS_TYPE_REQUEST,
    updateTransactionSubsType
  );
}

function* updateStatusTransactionSubsType({ payload }) {
  try {
    const resultUpdateStatusTransactionSubsType = yield call(
      Api.fetchUpdateStatusTransactionSubsType,
      payload
    );
    if (resultUpdateStatusTransactionSubsType.success === true) {
      yield put(updateStatusTransactionSubsTypeSuccess(payload));
      successNotification("Update Status transaction subs type", "Success");
    } else {
      yield put(
        updateStatusTransactionSubsTypeFailed(
          resultUpdateStatusTransactionSubsType || {}
        )
      );
      resultUpdateStatusTransactionSubsType?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status transaction subs type", "Failed");
    yield put(updateStatusTransactionSubsTypeFailed());
  }
}

export function* watchUpdateStatusTransactionSubsTypeRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_TRANSACTION_SUBS_TYPE_REQUEST,
    updateStatusTransactionSubsType
  );
}

function* getListTransactionWithOption({ payload }) {
  try {
    let resultListTransaction = {};
    if (payload?.isSchool) {
      resultListTransaction = yield call(ApiSchoolAdmin.fetchListTransaction, {
        params: { ...payload.params },
      });
    } else {
      resultListTransaction = yield call(Api.fetchListTransaction, {
        params: { ...payload.params },
      });
    }
    yield put(
      getListTransactionSuccess(
        {
          ...resultListTransaction,
          limit: payload?.params?.limit,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list transaction", "Failed");
    yield put(getListTransactionFailed());
  }
}

export function* watchListTransactionRequest() {
  yield takeEvery(
    ActionType.GET_LIST_TRANSACTION_REQUEST,
    getListTransactionWithOption
  );
}

function* createManualTransaction({ payload }) {
  try {
    let resultCreateManualTransaction = {};
    if (payload?.isSchool) {
      resultCreateManualTransaction = yield call(
        ApiSchoolAdmin.fetchCreateManualTransaction,
        payload
      );
    } else {
      resultCreateManualTransaction = yield call(
        Api.fetchCreateManualTransaction,
        payload
      );
    }
    if (resultCreateManualTransaction.success === true) {
      yield put(createManualTransactionSuccess(payload));
      successNotification("Create Manual transaction", "Success");
      if (payload?.isSchool) {
        window.location.href = resultCreateManualTransaction?.data?.redirectUrl;
      }
    } else {
      yield put(
        createManualTransactionFailed(resultCreateManualTransaction || {})
      );
      resultCreateManualTransaction?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create Manual transaction", "Failed");
    yield put(createManualTransactionFailed());
  }
}

export function* watchCreateManualTransactionRequest() {
  yield takeEvery(
    ActionType.CREATE_MANUAL_TRANSACTION_REQUEST,
    createManualTransaction
  );
}

function* updateStatusTransaction({ payload }) {
  try {
    const resultUpdateStatusTransaction = yield call(
      Api.fetchUpdateStatusTransaction,
      payload
    );
    if (resultUpdateStatusTransaction.success === true) {
      yield put(
        updateStatusTransactionSuccess(resultUpdateStatusTransaction.data)
      );
      successNotification("Update Status transaction", "Success");
    } else {
      yield put(
        updateStatusTransactionFailed(resultUpdateStatusTransaction || {})
      );
      resultUpdateStatusTransaction?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status transaction", "Failed");
    yield put(updateStatusTransactionFailed());
  }
}

export function* watchUpdateStatusTransactionRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_TRANSACTION_REQUEST,
    updateStatusTransaction
  );
}

function* getListRoyaltyStatement({ payload }) {
  try {
    let resultListRoyaltyStatement = {};
    if (payload?.isPublisher) {
      resultListRoyaltyStatement = yield call(
        ApiPublisher.fetchListRoyaltyStatement,
        {
          params: { ...payload.params },
        }
      );
    } else {
      resultListRoyaltyStatement = yield call(Api.fetchListRoyaltyStatement, {
        params: { ...payload.params },
      });
    }
    yield put(
      getListRoyaltyStatementSuccess(
        { ...resultListRoyaltyStatement, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list royalty statement", "Failed");
    yield put(getListRoyaltyStatementFailed());
  }
}

export function* watchListRoyaltyStatementRequest() {
  yield takeEvery(
    ActionType.GET_LIST_ROYALTY_STATEMENT_REQUEST,
    getListRoyaltyStatement
  );
}

function* updateStatusPayout({ payload }) {
  try {
    const resultUpdateStatusPayout = yield call(
      Api.fetchUpdateStatusPayout,
      payload
    );

    if (resultUpdateStatusPayout.success === true) {
      yield put(
        updateStatusPayoutSuccess(resultUpdateStatusPayout?.data || {})
      );
      successNotification("Update Status payout", "Success");
    } else {
      yield put(updateStatusPayoutFailed(resultUpdateStatusPayout || {}));
      resultUpdateStatusPayout?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status payout", "Failed");
    yield put(updateStatusPayoutFailed());
  }
}

export function* watchUpdateStatusPayoutRequest() {
  yield takeEvery(ActionType.UPDATE_STATUS_PAYOUT_REQUEST, updateStatusPayout);
}

function* getDetailRoyaltyStatement({ payload }) {
  try {
    let resultDetailRoyaltyStatement = {};
    if (payload?.isPublisher) {
      resultDetailRoyaltyStatement = yield call(
        ApiPublisher.fetchDetailRoyaltyStatement,
        payload
      );
    } else {
      resultDetailRoyaltyStatement = yield call(
        Api.fetchDetailRoyaltyStatement,
        payload
      );
    }
    yield put(
      getDetailRoyaltyStatementSuccess(
        { ...resultDetailRoyaltyStatement, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get detail royalty statement", "Failed");
    yield put(getDetailRoyaltyStatementFailed());
  }
}

export function* watchDetailRoyaltyStatementRequest() {
  yield takeEvery(
    ActionType.GET_DETAIL_ROYALTY_STATEMENT_REQUEST,
    getDetailRoyaltyStatement
  );
}

function* getDetailReports({ payload }) {
  try {
    let resultDetailReports = {};
    if (payload?.isPublisher) {
      resultDetailReports = yield call(ApiPublisher.fetchDetailReports, {
        params: { ...payload.params },
      });
    } else {
      resultDetailReports = yield call(Api.fetchDetailReports, {
        params: { ...payload.params },
      });
    }
    yield put(
      getDetailReportsSuccess(
        {
          ...resultDetailReports,
          limit: payload?.params?.limit,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get detail reports", "Failed");
    yield put(getDetailReportsFailed());
  }
}

export function* watchDetailReportsRequest() {
  yield takeEvery(ActionType.GET_DETAIL_REPORTS_REQUEST, getDetailReports);
}

function* createTransactionSpecialPackage({ payload }) {
  console.log({ payload });
  try {
    const resultCreateTransactionSpecialPackage = yield call(
      Api.fetchCreateTransactionSpecialPackage,
      payload
    );
    if (resultCreateTransactionSpecialPackage.success === true) {
      yield put(createTransactionSpecialPackageSuccess(payload));
      successNotification("Create transaction special package", "Success");
    } else {
      yield put(
        createTransactionSpecialPackageFailed(
          resultCreateTransactionSpecialPackage || {}
        )
      );
      resultCreateTransactionSpecialPackage?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create transaction special package", "Failed");
    yield put(createTransactionSpecialPackageFailed());
  }
}

export function* watchCreateTransactionSpecialPackageRequest() {
  yield takeEvery(
    ActionType.CREATE_TRANSACTION_SPECIAL_PACKAGE_REQUEST,
    createTransactionSpecialPackage
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchListTransactionSubsTypeRequest),
    fork(watchCreateTransactionSubsTypeRequest),
    fork(watchUpdateTransactionSubsTypeRequest),
    fork(watchUpdateStatusTransactionSubsTypeRequest),
    fork(watchListTransactionRequest),
    fork(watchCreateManualTransactionRequest),
    fork(watchUpdateStatusTransactionRequest),
    fork(watchListRoyaltyStatementRequest),
    fork(watchUpdateStatusPayoutRequest),
    fork(watchDetailRoyaltyStatementRequest),
    fork(watchDetailReportsRequest),
    fork(watchCreateTransactionSpecialPackageRequest),
  ]);
}
