import * as ActionType from "../constants/Book";

export const getListBookRequest = (payload) => ({
  type: ActionType.GET_LIST_BOOK_REQUEST,
  payload,
});

export const getListBookSuccess = (payload) => ({
  type: ActionType.GET_LIST_BOOK_SUCCESS,
  payload,
});

export const getListBookFailed = (payload) => ({
  type: ActionType.GET_LIST_BOOK_FAILED,
  payload,
});

export const getListCategoryRequest = (payload) => ({
  type: ActionType.GET_LIST_CATEGORY_REQUEST,
  payload,
});

export const getListCategorySuccess = (payload) => ({
  type: ActionType.GET_LIST_CATEGORY_SUCCESS,
  payload,
});

export const getListCategoryFailed = (payload) => ({
  type: ActionType.GET_LIST_CATEGORY_FAILED,
  payload,
});

export const createBookRequest = (payload) => ({
  type: ActionType.CREATE_BOOK_REQUEST,
  payload,
});

export const createBookSuccess = (payload) => ({
  type: ActionType.CREATE_BOOK_SUCCESS,
  payload,
});

export const createBookFailed = (payload) => ({
  type: ActionType.CREATE_BOOK_FAILED,
  payload,
});

export const getBookByIdRequest = (payload) => ({
  type: ActionType.GET_BOOK_BY_ID_REQUEST,
  payload,
});

export const getBookByIdSuccess = (payload) => ({
  type: ActionType.GET_BOOK_BY_ID_SUCCESS,
  payload,
});

export const getBookByIdFailed = (payload) => ({
  type: ActionType.GET_BOOK_BY_ID_FAILED,
  payload,
});

export const updateBookRequest = (payload) => ({
  type: ActionType.UPDATE_BOOK_REQUEST,
  payload,
});

export const updateBookSuccess = (payload) => ({
  type: ActionType.UPDATE_BOOK_SUCCESS,
  payload,
});

export const updateBookFailed = (payload) => ({
  type: ActionType.UPDATE_BOOK_FAILED,
  payload,
});

export const updateStatusBookRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_BOOK_REQUEST,
  payload,
});

export const updateStatusBookSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_BOOK_SUCCESS,
  payload,
});

export const updateStatusBookFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_BOOK_FAILED,
  payload,
});
