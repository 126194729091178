import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Rbac";
import * as Api from "../../api/rbac";

import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";
import {
  getListRolesSuccess,
  getListRolesFailed,
  getListMenusSuccess,
  getListMenusFailed,
  createAdminRoleSuccess,
  createAdminRoleFailed,
  getAdminRoleByIdSuccess,
  getAdminRoleByIdFailed,
  deleteAdminRoleSuccess,
  deleteAdminRoleFailed,
  getListAdminUsersSuccess,
  getListAdminUsersFailed,
  createAdminUserSuccess,
  createAdminUserFailed,
  deleteAdminUserSuccess,
  deleteAdminUserFailed,
  getAdminUserByIdSuccess,
  getAdminUserByIdFailed,
  updateAdminRoleSuccess,
  updateAdminRoleFailed,
  updateRoleUserSuccess,
  updateRoleUserFailed,
  resetPasswordSuccess,
  resetPasswordFailed,
} from "../actions";

function* getListRoles({ payload }) {
  try {
    const resultListRoles = yield call(Api.fetchListRoles, {
      params: { ...payload.params },
    });
    yield put(
      getListRolesSuccess(
        { ...resultListRoles, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list roles", "Failed");
    yield put(getListRolesFailed());
  }
}

export function* watchListRolesRequest() {
  yield takeEvery(ActionType.GET_LIST_ROLES_REQUEST, getListRoles);
}

function* getListMenus({ payload }) {
  try {
    const resultListMenus = yield call(Api.fetchListMenus, {
      params: { ...payload.params },
    });
    yield put(
      getListMenusSuccess(
        { ...resultListMenus, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list menus", "Failed");
    yield put(getListMenusFailed());
  }
}

export function* watchListMenusRequest() {
  yield takeEvery(ActionType.GET_LIST_MENUS_REQUEST, getListMenus);
}

function* createAdminRole({ payload }) {
  try {
    const resultCreateAdminRole = yield call(Api.fetchCreateAdminRole, payload);
    if (resultCreateAdminRole.success === true) {
      yield put(createAdminRoleSuccess(resultCreateAdminRole.data));
      successNotification("Berhasil menambah Admin Role", "Success");
    } else {
      yield put(createAdminRoleFailed(resultCreateAdminRole || {}));
      resultCreateAdminRole?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Gagal menambah Admin Role", "Failed");
    yield put(createAdminRoleFailed());
  }
}

export function* watchCreateAdminRoleRequest() {
  yield takeEvery(ActionType.CREATE_ADMIN_ROLE_REQUEST, createAdminRole);
}

function* getAdminRoleById({ payload }) {
  try {
    const resultAdminRole = yield call(Api.fetchAdminRoleById, {
      adminRoleId: payload?.adminRoleId,
    });
    yield put(getAdminRoleByIdSuccess({ ...resultAdminRole } || {}));
  } catch (error) {
    errorNotification("Get admin role by id", "Failed");
    yield put(getAdminRoleByIdFailed());
  }
}

export function* watchGetAdminRoleByIdRequest() {
  yield takeEvery(ActionType.GET_ADMIN_ROLE_BY_ID_REQUEST, getAdminRoleById);
}

function* deleteAdminRole({ payload }) {
  try {
    const resultDeleteAdminRole = yield call(Api.fetchDeleteAdminRole, payload);
    if (resultDeleteAdminRole.success === true) {
      yield put(deleteAdminRoleSuccess(resultDeleteAdminRole.data));
      successNotification("Berhasil Hapus Admin Role", "Success");
    } else {
      yield put(deleteAdminRoleFailed(resultDeleteAdminRole || {}));
      resultDeleteAdminRole?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Gagal Hapus Admin Role", "Failed");
    yield put(deleteAdminRoleFailed());
  }
}

export function* watchDeleteAdminRoleRequest() {
  yield takeEvery(ActionType.DELETE_ADMIN_ROLE_REQUEST, deleteAdminRole);
}

function* getListAdminUsers({ payload }) {
  try {
    const resultListAdminUsers = yield call(Api.fetchListAdminUsers, {
      params: { ...payload.params },
    });
    yield put(
      getListAdminUsersSuccess(
        { ...resultListAdminUsers, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list admin users", "Failed");
    yield put(getListAdminUsersFailed());
  }
}

export function* watchListAdminUsersRequest() {
  yield takeEvery(ActionType.GET_LIST_ADMIN_USERS_REQUEST, getListAdminUsers);
}

function* createAdminUser({ payload }) {
  try {
    const resultCreateAdminUser = yield call(Api.fetchCreateAdminUser, payload);
    if (resultCreateAdminUser.success === true) {
      yield put(createAdminUserSuccess(resultCreateAdminUser.data));
      successNotification("Berhasil menambah Admin User", "Success");
    } else {
      yield put(createAdminUserFailed(resultCreateAdminUser || {}));
      resultCreateAdminUser?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Gagal menambah Admin User", "Failed");
    yield put(createAdminUserFailed());
  }
}

export function* watchCreateAdminUserRequest() {
  yield takeEvery(ActionType.CREATE_ADMIN_USER_REQUEST, createAdminUser);
}

function* deleteAdminUser({ payload }) {
  try {
    const resultDeleteAdminUser = yield call(Api.fetchDeleteAdminUser, payload);
    if (resultDeleteAdminUser.success === true) {
      yield put(deleteAdminUserSuccess(resultDeleteAdminUser.data));
      successNotification("Berhasil Hapus Admin User", "Success");
    } else {
      yield put(deleteAdminUserFailed(resultDeleteAdminUser || {}));
      resultDeleteAdminUser?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Gagal Hapus Admin User", "Failed");
    yield put(deleteAdminUserFailed());
  }
}

export function* watchDeleteAdminUserRequest() {
  yield takeEvery(ActionType.DELETE_ADMIN_USER_REQUEST, deleteAdminUser);
}

function* getAdminUserById({ payload }) {
  try {
    const resultAdminUser = yield call(Api.fetchAdminUserById, {
      adminUserId: payload?.adminUserId,
    });
    yield put(getAdminUserByIdSuccess({ ...resultAdminUser } || {}));
  } catch (error) {
    errorNotification("Get admin user by id", "Failed");
    yield put(getAdminUserByIdFailed());
  }
}

export function* watchGetAdminUserByIdRequest() {
  yield takeEvery(ActionType.GET_ADMIN_USER_BY_ID_REQUEST, getAdminUserById);
}

function* updateAdminRole({ payload }) {
  try {
    const resultUpdateAdminRole = yield call(Api.fetchUpdateAdminRole, payload);
    if (resultUpdateAdminRole.success === true) {
      yield put(updateAdminRoleSuccess(resultUpdateAdminRole.data));
      successNotification("Berhasil Ubah Staff", "Success");
    } else {
      yield put(updateAdminRoleFailed(resultUpdateAdminRole || {}));
      resultUpdateAdminRole?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Gagal Ubah Staff", "Failed");
    yield put(updateAdminRoleFailed());
  }
}

export function* watchUpdateAdminRoleRequest() {
  yield takeEvery(ActionType.UPDATE_ADMIN_ROLE_REQUEST, updateAdminRole);
}

function* updateRoleUser({ payload }) {
  try {
    const resultUpdateRoleUser = yield call(Api.fetchUpdateRoleUser, payload);
    if (resultUpdateRoleUser.success === true) {
      yield put(updateRoleUserSuccess(resultUpdateRoleUser.data));
      successNotification("Berhasil Pindahkan Staff", "Success");
    } else {
      yield put(updateRoleUserFailed(resultUpdateRoleUser || {}));
      resultUpdateRoleUser?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Gagal Pindahkan Staff", "Failed");
    yield put(updateRoleUserFailed());
  }
}

export function* watchUpdateRoleUserRequest() {
  yield takeEvery(ActionType.UPDATE_ROLE_USER_REQUEST, updateRoleUser);
}

function* resetPassword({ payload }) {
  try {
    const resultResetPassword = yield call(Api.fetchResetPassword, payload);
    if (resultResetPassword.success === true) {
      yield put(resetPasswordSuccess(resultResetPassword.data));
      successNotification("Berhasil Reset Password", "Success");
    } else {
      yield put(resetPasswordFailed(resultResetPassword || {}));
      resultResetPassword?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Gagal Reset Password", "Failed");
    yield put(resetPasswordFailed());
  }
}

export function* watchResetPasswordRequest() {
  yield takeEvery(ActionType.RESET_PASSWORD_REQUEST, resetPassword);
}

export default function* rootSaga() {
  yield all([
    fork(watchListRolesRequest),
    fork(watchListMenusRequest),
    fork(watchCreateAdminRoleRequest),
    fork(watchGetAdminRoleByIdRequest),
    fork(watchDeleteAdminRoleRequest),
    fork(watchListAdminUsersRequest),
    fork(watchCreateAdminUserRequest),
    fork(watchDeleteAdminUserRequest),
    fork(watchGetAdminUserByIdRequest),
    fork(watchUpdateAdminRoleRequest),
    fork(watchUpdateRoleUserRequest),
    fork(watchResetPasswordRequest),
  ]);
}
