export const GET_LIST_ACTIVITY_SHEET_REQUEST =
  "GET_LIST_ACTIVITY_SHEET_REQUEST";
export const GET_LIST_ACTIVITY_SHEET_SUCCESS =
  "GET_LIST_ACTIVITY_SHEET_SUCCESS";
export const GET_LIST_ACTIVITY_SHEET_FAILED = "GET_LIST_ACTIVITY_SHEET_FAILED";

export const CREATE_ACTIVITY_SHEET_SECTION_REQUEST =
  "CREATE_ACTIVITY_SHEET_SECTION_REQUEST";
export const CREATE_ACTIVITY_SHEET_SECTION_SUCCESS =
  "CREATE_ACTIVITY_SHEET_SECTION_SUCCESS";
export const CREATE_ACTIVITY_SHEET_SECTION_FAILED =
  "CREATE_ACTIVITY_SHEET_SECTION_FAILED";

export const UPDATE_ACTIVITY_SHEET_SECTION_REQUEST =
  "UPDATE_ACTIVITY_SHEET_SECTION_REQUEST";
export const UPDATE_ACTIVITY_SHEET_SECTION_SUCCESS =
  "UPDATE_ACTIVITY_SHEET_SECTION_SUCCESS";
export const UPDATE_ACTIVITY_SHEET_SECTION_FAILED =
  "UPDATE_ACTIVITY_SHEET_SECTION_FAILED";

export const UPDATE_SORT_ACTIVITY_SHEET_REQUEST =
  "UPDATE_SORT_ACTIVITY_SHEET_REQUEST";
export const UPDATE_SORT_ACTIVITY_SHEET_SUCCESS =
  "UPDATE_SORT_ACTIVITY_SHEET_SUCCESS";
export const UPDATE_SORT_ACTIVITY_SHEET_FAILED =
  "UPDATE_SORT_ACTIVITY_SHEET_FAILED";

export const GET_LIST_ACTIVITY_SHEET_SECTION_REQUEST =
  "GET_LIST_ACTIVITY_SHEET_SECTION_REQUEST";
export const GET_LIST_ACTIVITY_SHEET_SECTION_SUCCESS =
  "GET_LIST_ACTIVITY_SHEET_SECTION_SUCCESS";
export const GET_LIST_ACTIVITY_SHEET_SECTION_FAILED =
  "GET_LIST_ACTIVITY_SHEET_SECTION_FAILED";

export const CREATE_ACTIVITY_SHEET_REQUEST = "CREATE_ACTIVITY_SHEET_REQUEST";
export const CREATE_ACTIVITY_SHEET_SUCCESS = "CREATE_ACTIVITY_SHEET_SUCCESS";
export const CREATE_ACTIVITY_SHEET_FAILED = "CREATE_ACTIVITY_SHEET_FAILED";

export const GET_ACTIVITY_SHEET_BY_ID_REQUEST =
  "GET_ACTIVITY_SHEET_BY_ID_REQUEST";
export const GET_ACTIVITY_SHEET_BY_ID_SUCCESS =
  "GET_ACTIVITY_SHEET_BY_ID_SUCCESS";
export const GET_ACTIVITY_SHEET_BY_ID_FAILED =
  "GET_ACTIVITY_SHEET_BY_ID_FAILED";

export const UPDATE_ACTIVITY_SHEET_REQUEST = "UPDATE_ACTIVITY_SHEET_REQUEST";
export const UPDATE_ACTIVITY_SHEET_SUCCESS = "UPDATE_ACTIVITY_SHEET_SUCCESS";
export const UPDATE_ACTIVITY_SHEET_FAILED = "UPDATE_ACTIVITY_SHEET_FAILED";

export const DELETE_ACTIVITY_SHEET_REQUEST = "DELETE_ACTIVITY_SHEET_REQUEST";
export const DELETE_ACTIVITY_SHEET_SUCCESS = "DELETE_ACTIVITY_SHEET_SUCCESS";
export const DELETE_ACTIVITY_SHEET_FAILED = "DELETE_ACTIVITY_SHEET_FAILED";
