import * as ActionType from "../constants/Profile";
import { myProfileDTO } from "../helpers/profile";

const initialState = {
  myProfile: {
    data: {},
    isLoading: false,
    error: null,
    success: null,
  },
};

const profileReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_MY_PROFILE_REQUEST:
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          isLoading: true,
        },
      };
    case ActionType.GET_MY_PROFILE_SUCCESS:
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          data: myProfileDTO(payload.data),
          isLoading: false,
        },
      };
    case ActionType.GET_MY_PROFILE_FAILED:
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          isLoading: false,
        },
      };

    case ActionType.CLEAR_MY_PROFILE_REQUEST:
      return {
        ...state,
        myProfile: {
          data: {},
          isLoading: false,
          error: null,
          success: null,
        },
      };

    default: {
      return state;
    }
  }
};

export default profileReducer;
