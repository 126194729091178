import * as ActionType from "../constants/User";

export const getListUserRequest = (payload) => ({
  type: ActionType.GET_LIST_USER_REQUEST,
  payload,
});

export const getListUserSuccess = (payload) => ({
  type: ActionType.GET_LIST_USER_SUCCESS,
  payload,
});

export const getListUserFailed = (payload) => ({
  type: ActionType.GET_LIST_USER_FAILED,
  payload,
});

export const createUserRequest = (payload) => ({
  type: ActionType.CREATE_USER_REQUEST,
  payload,
});

export const createUserSuccess = (payload) => ({
  type: ActionType.CREATE_USER_SUCCESS,
  payload,
});

export const createUserFailed = (payload) => ({
  type: ActionType.CREATE_USER_FAILED,
  payload,
});

export const updateStatusUserRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_USER_REQUEST,
  payload,
});

export const updateStatusUserSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_USER_SUCCESS,
  payload,
});

export const updateStatusUserFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_USER_FAILED,
  payload,
});
