import * as ActionType from "../constants/Reading";

const initialState = {
  listReadingParade: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  readingParadeById: {
    data: {},
    isLoading: false,
  },
  isLoadingActionReadingParade: false,
  isSuccessActionReadingParade: false,
  isErrorActionReadingParade: false,
  listReadingChallenge: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  readingChallengeById: {
    data: {},
    isLoading: false,
  },
  isLoadingActionReadingChallenge: false,
  isSuccessActionReadingChallenge: false,
  isErrorActionReadingChallenge: false,
};

const readingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_READING_PARADE_REQUEST:
      return {
        ...state,
        listReadingParade: {
          ...state.listReadingParade,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_READING_PARADE_SUCCESS:
      return {
        ...state,
        listReadingParade: {
          ...state.listReadingParade,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_READING_PARADE_FAILED:
      return {
        ...state,
        listReadingParade: {
          ...state.listReadingParade,
          isLoading: false,
        },
      };

    case ActionType.CREATE_READING_PARADE_REQUEST:
    case ActionType.UPDATE_READING_PARADE_REQUEST:
    case ActionType.UPDATE_STATUS_READING_PARADE_REQUEST:
    case ActionType.RE_PUBLISHED_READING_PARADE_REQUEST:
    case ActionType.UPLOAD_POINT_PARADE_REQUEST:
      return {
        ...state,
        isLoadingActionReadingParade: true,
        isSuccessActionReadingParade: false,
        isErrorActionReadingParade: false,
      };
    case ActionType.CREATE_READING_PARADE_SUCCESS:
    case ActionType.UPDATE_READING_PARADE_SUCCESS:
    case ActionType.UPDATE_STATUS_READING_PARADE_SUCCESS:
    case ActionType.RE_PUBLISHED_READING_PARADE_SUCCESS:
    case ActionType.UPLOAD_POINT_PARADE_SUCCESS:
      return {
        ...state,
        isLoadingActionReadingParade: false,
        isSuccessActionReadingParade: true,
        isErrorActionReadingParade: false,
      };
    case ActionType.CREATE_READING_PARADE_FAILED:
    case ActionType.UPDATE_READING_PARADE_FAILED:
    case ActionType.UPDATE_STATUS_READING_PARADE_FAILED:
    case ActionType.RE_PUBLISHED_READING_PARADE_FAILED:
    case ActionType.UPLOAD_POINT_PARADE_FAILED:
      return {
        ...state,
        isLoadingActionReadingParade: false,
        isSuccessActionReadingParade: false,
        isErrorActionReadingParade: true,
      };

    case ActionType.RESET_STATUS_READING_PARADE_REQUEST:
      return {
        ...state,
        isLoadingActionReadingParade: false,
        isErrorActionReadingParade: false,
        isSuccessActionReadingParade: false,
        readingParadeById: {
          data: {},
          isLoading: false,
        },
      };

    case ActionType.GET_READING_PARADE_BY_ID_REQUEST:
      return {
        ...state,
        readingParadeById: {
          ...state.readingParadeById,
          isLoading: true,
        },
      };
    case ActionType.GET_READING_PARADE_BY_ID_SUCCESS:
      return {
        ...state,
        readingParadeById: {
          ...state.readingParadeById,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_READING_PARADE_BY_ID_FAILED:
      return {
        ...state,
        readingParadeById: {
          ...state.readingParadeById,
          isLoading: false,
        },
      };

    case ActionType.GET_LIST_READING_CHALLENGE_REQUEST:
      return {
        ...state,
        listReadingChallenge: {
          ...state.listReadingChallenge,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_READING_CHALLENGE_SUCCESS:
      return {
        ...state,
        listReadingChallenge: {
          ...state.listReadingChallenge,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_READING_CHALLENGE_FAILED:
      return {
        ...state,
        listReadingChallenge: {
          ...state.listReadingParade,
          isLoading: false,
        },
      };

    case ActionType.CREATE_READING_CHALLENGE_REQUEST:
    case ActionType.UPDATE_READING_CHALLENGE_REQUEST:
    case ActionType.UPDATE_STATUS_READING_CHALLENGE_REQUEST:
    case ActionType.RE_PUBLISHED_READING_CHALLENGE_REQUEST:
      return {
        ...state,
        isLoadingActionReadingChallenge: true,
        isSuccessActionReadingChallenge: false,
        isErrorActionReadingChallenge: false,
      };
    case ActionType.CREATE_READING_CHALLENGE_SUCCESS:
    case ActionType.UPDATE_READING_CHALLENGE_SUCCESS:
    case ActionType.UPDATE_STATUS_READING_CHALLENGE_SUCCESS:
    case ActionType.RE_PUBLISHED_READING_CHALLENGE_SUCCESS:
      return {
        ...state,
        isLoadingActionReadingChallenge: false,
        isSuccessActionReadingChallenge: true,
        isErrorActionReadingChallenge: false,
      };
    case ActionType.CREATE_READING_CHALLENGE_FAILED:
    case ActionType.UPDATE_READING_CHALLENGE_FAILED:
    case ActionType.UPDATE_STATUS_READING_CHALLENGE_FAILED:
    case ActionType.RE_PUBLISHED_READING_CHALLENGE_FAILED:
      return {
        ...state,
        isLoadingActionReadingChallenge: false,
        isSuccessActionReadingChallenge: false,
        isErrorActionReadingChallenge: true,
      };

    case ActionType.RESET_STATUS_READING_CHALLENGE_REQUEST:
      return {
        ...state,
        isLoadingActionReadingChallenge: false,
        isErrorActionReadingChallenge: false,
        isSuccessActionReadingChallenge: false,
        readingChallengeById: {
          data: {},
          isLoading: false,
        },
      };

    case ActionType.GET_READING_CHALLENGE_BY_ID_REQUEST:
      return {
        ...state,
        readingChallengeById: {
          ...state.readingChallengeById,
          isLoading: true,
        },
      };
    case ActionType.GET_READING_CHALLENGE_BY_ID_SUCCESS:
      return {
        ...state,
        readingChallengeById: {
          ...state.readingChallengeById,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_READING_CHALLENGE_BY_ID_FAILED:
      return {
        ...state,
        readingChallengeById: {
          ...state.readingChallengeById,
          isLoading: false,
        },
      };

    default: {
      return state;
    }
  }
};

export default readingReducer;
