import httpRequest from "../util/axios";

export const fetchListCategoryType = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/books/categories/type",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListSubCategory = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/books/categories",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateSubCategory = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/books/categories",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusSubCategory = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/books/categories/${body.subCategoryId}/status`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateSubCategory = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/books/categories/${body.subCategoryId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSubCategoryById = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/books/categories/${body?.subCategoryId}`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSetBookCategory = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/books/categories/type/${body?.categoryId}/set-categories`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUnsetBookCategory = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/books/categories/type/${body?.categoryId}/unset-categories`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
