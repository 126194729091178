import moment from "moment";

export const bookDTO = (book) => ({
  ...book,
  updated_at: moment(book?.updated_at).fromNow(),
  publisher: book?.publisher?.name,
  publisherId: book?.publisher?.publisherId,
});

export const listBookDTO = (listBook = []) => {
  if (!listBook.length) return [];
  return listBook.map(bookDTO) ?? [];
};

export const categoryDTO = (category) => ({
  ...category,
  updated_at: moment(category.updated_at).fromNow(),
});

export const listCategoryDTO = (listCategory = []) => {
  if (!listCategory.length) return [];
  return listCategory.map(categoryDTO) ?? [];
};
