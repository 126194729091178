export const GET_LIST_USER_REQUEST = "GET_LIST_USER_REQUEST";
export const GET_LIST_USER_SUCCESS = "GET_LIST_USER_SUCCESS";
export const GET_LIST_USER_FAILED = "GET_LIST_USER_FAILED";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";

export const UPDATE_STATUS_USER_REQUEST = "UPDATE_STATUS_USER_REQUEST";
export const UPDATE_STATUS_USER_SUCCESS = "UPDATE_STATUS_USER_SUCCESS";
export const UPDATE_STATUS_USER_FAILED = "UPDATE_STATUS_USER_FAILED";
