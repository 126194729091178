import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Book from "./Book";
import Profile from "./Profile";
import School from "./School";
import Collection from "./Collection";
import User from "./User";
import Publisher from "./Publisher";
import Category from "./Category";
import Transaction from "./Transaction";
import Banner from "./Banner";
import Voucher from "./Voucher";
import Faq from "./Faq";
import Recommendation from "./Recommendation";
import Segmentation from "./Segmentation";
import AppInbox from "./Inbox";
import ActivitySheet from "./ActivitySheet";
import Dashboard from "./Dashboard";
import Reading from "./Reading";
import Rbac from "./Rbac";
import Campaign from "./Campaign";
import SchoolFreeTrial from "./SchoolFreeTrial";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    notes: Notes,
    contact: Contact,
    common: Common,
    book: Book,
    profile: Profile,
    school: School,
    collection: Collection,
    user: User,
    publisher: Publisher,
    category: Category,
    transaction: Transaction,
    banner: Banner,
    voucher: Voucher,
    faq: Faq,
    recommendation: Recommendation,
    segmentation: Segmentation,
    appInbox: AppInbox,
    activitySheet: ActivitySheet,
    dashboard: Dashboard,
    reading: Reading,
    rbac: Rbac,
    campaign: Campaign,
    schoolFreeTrial: SchoolFreeTrial,
  });

export default createRootReducer;
