import * as ActionType from "../constants/Catetgory";

export const getListCategoryTypeRequest = (payload) => ({
  type: ActionType.GET_LIST_CATEGORY_TYPE_REQUEST,
  payload,
});

export const getListCategoryTypeSuccess = (payload) => ({
  type: ActionType.GET_LIST_CATEGORY_TYPE_SUCCESS,
  payload,
});

export const getListCategoryTypeFailed = (payload) => ({
  type: ActionType.GET_LIST_CATEGORY_TYPE_FAILED,
  payload,
});

export const getListSubCategoryRequest = (payload) => ({
  type: ActionType.GET_LIST_SUB_CATEGORY_REQUEST,
  payload,
});

export const getListSubCategorySuccess = (payload) => ({
  type: ActionType.GET_LIST_SUB_CATEGORY_SUCCESS,
  payload,
});

export const getListSubCategoryFailed = (payload) => ({
  type: ActionType.GET_LIST_SUB_CATEGORY_FAILED,
  payload,
});

export const createSubCategoryRequest = (payload) => ({
  type: ActionType.CREATE_SUB_CATEGORY_REQUEST,
  payload,
});

export const createSubCategorySuccess = (payload) => ({
  type: ActionType.CREATE_SUB_CATEGORY_SUCCESS,
  payload,
});

export const createSubCategoryFailed = (payload) => ({
  type: ActionType.CREATE_SUB_CATEGORY_FAILED,
  payload,
});

export const updateStatusSubCategoryRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_SUB_CATEGORY_REQUEST,
  payload,
});

export const updateStatusSubCategorySuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_SUB_CATEGORY_SUCCESS,
  payload,
});

export const updateStatusSubCategoryFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_SUB_CATEGORY_FAILED,
  payload,
});

export const updateSubCategoryRequest = (payload) => ({
  type: ActionType.UPDATE_SUB_CATEGORY_REQUEST,
  payload,
});

export const updateSubCategorySuccess = (payload) => ({
  type: ActionType.UPDATE_SUB_CATEGORY_SUCCESS,
  payload,
});

export const updateSubCategoryFailed = (payload) => ({
  type: ActionType.UPDATE_SUB_CATEGORY_FAILED,
  payload,
});

export const getSubCategoryByIdRequest = (payload) => ({
  type: ActionType.GET_SUB_CATEGORY_BY_ID_REQUEST,
  payload,
});

export const getSubCategoryByIdSuccess = (payload) => ({
  type: ActionType.GET_SUB_CATEGORY_BY_ID_SUCCESS,
  payload,
});

export const getSubCategoryByIdFailed = (payload) => ({
  type: ActionType.GET_SUB_CATEGORY_BY_ID_FAILED,
  payload,
});

export const setBookCategoryRequest = (payload) => ({
  type: ActionType.SET_BOOK_CATEGORY_REQUEST,
  payload,
});

export const setBookCategorySuccess = (payload) => ({
  type: ActionType.SET_BOOK_CATEGORY_SUCCESS,
  payload,
});

export const setBookCategoryFailed = (payload) => ({
  type: ActionType.SET_BOOK_CATEGORY_FAILED,
  payload,
});

export const unsetBookCategoryRequest = (payload) => ({
  type: ActionType.UNSET_BOOK_CATEGORY_REQUEST,
  payload,
});

export const unsetBookCategorySuccess = (payload) => ({
  type: ActionType.UNSET_BOOK_CATEGORY_SUCCESS,
  payload,
});

export const unsetBookCategoryFailed = (payload) => ({
  type: ActionType.UNSET_BOOK_CATEGORY_FAILED,
  payload,
});
