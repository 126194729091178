import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Banner";
import * as Api from "../../api/banner";
import {
  getListBannerSuccess,
  getListBannerFailed,
  createBannerSuccess,
  createBannerFailed,
  updateBannerSuccess,
  updateBannerFailed,
  deleteBannerSuccess,
  deleteBannerFailed,
  getBannerOnSchoolSuccess,
  getBannerOnSchoolFailed,
} from "../actions/Banner";
import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";

function* getListBannerWithOption({ payload }) {
  try {
    const resultListBanner = yield call(Api.fetchListBanner, {
      params: { ...payload.params },
    });
    yield put(
      getListBannerSuccess(
        {
          ...resultListBanner,
          limit: payload?.params?.limit,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list banner", "Failed");
    yield put(getListBannerFailed());
  }
}

export function* watchListBannerRequest() {
  yield takeEvery(ActionType.GET_LIST_BANNER_REQUEST, getListBannerWithOption);
}

function* createBanner({ payload }) {
  try {
    const resultCreateBanner = yield call(Api.fetchCreateBanner, payload);
    if (resultCreateBanner.success === true) {
      yield put(createBannerSuccess(resultCreateBanner.data));
      successNotification("Create banner", "Success");
    } else {
      yield put(createBannerFailed(resultCreateBanner || {}));
      resultCreateBanner?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create banner", "Failed");
    yield put(createBannerFailed());
  }
}

export function* watchCreateBannerRequest() {
  yield takeEvery(ActionType.CREATE_BANNER_REQUEST, createBanner);
}

function* updateBanner({ payload }) {
  try {
    const resultUpdateBanner = yield call(Api.fetchUpdateBanner, payload);
    if (resultUpdateBanner.success === true) {
      yield put(updateBannerSuccess(resultUpdateBanner.data));
      successNotification("Update banner", "Success");
    } else {
      yield put(updateBannerFailed(resultUpdateBanner || {}));
      resultUpdateBanner?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update banner", "Failed");
    yield put(updateBannerFailed());
  }
}

export function* watchUpdateBannerRequest() {
  yield takeEvery(ActionType.UPDATE_BANNER_REQUEST, updateBanner);
}

function* deleteBanner({ payload }) {
  try {
    const resultDeleteBanner = yield call(Api.fetchDeleteBanner, payload);
    if (resultDeleteBanner.success === true) {
      yield put(deleteBannerSuccess(resultDeleteBanner.data));
      successNotification("Delete banner", "Success");
    } else {
      yield put(deleteBannerFailed(resultDeleteBanner || {}));
      resultDeleteBanner?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Delete banner", "Failed");
    yield put(deleteBannerFailed());
  }
}

export function* watchDeleteBannerRequest() {
  yield takeEvery(ActionType.DELETE_BANNER_REQUEST, deleteBanner);
}

function* getBannerOnSchool({ payload }) {
  try {
    const resultBannerOnSchool = yield call(Api.fetchGetBannerOnSchool, {});
    yield put(
      getBannerOnSchoolSuccess(
        {
          ...resultBannerOnSchool,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get banner", "Failed");
    yield put(getBannerOnSchoolFailed());
  }
}

export function* watchBannerOnSchoolRequest() {
  yield takeEvery(ActionType.GET_BANNER_ON_SCHOOL_REQUEST, getBannerOnSchool);
}

export default function* rootSaga() {
  yield all([
    fork(watchListBannerRequest),
    fork(watchCreateBannerRequest),
    fork(watchUpdateBannerRequest),
    fork(watchDeleteBannerRequest),
    fork(watchBannerOnSchoolRequest),
  ]);
}
