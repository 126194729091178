import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Faq";
import * as Api from "../../api/faq";

import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";
import {
  createQuestionFailed,
  createQuestionSuccess,
  createTopicFailed,
  createTopicSuccess,
  getListCategoryFailed,
  getListCategorySuccess,
  updateCategorySuccess,
  updateCategoryFailed,
  updateQuestionFailed,
  updateQuestionSuccess,
  deleteQuestionSuccess,
  deleteQuestionFailed,
  updateStatusQuestionSuccess,
  updateStatusQuestionFailed,
  deleteSubSectionSuccess,
  deleteSubSectionFailed,
} from "../actions/Faq";

function* getListCategory({ payload }) {
  try {
    const resultListCategory = yield call(Api.fetchListCategory, {
      params: { ...payload.params },
    });
    yield put(
      getListCategorySuccess(
        { ...resultListCategory, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list category", "Failed");
    yield put(getListCategoryFailed());
  }
}

export function* watchListCategoryRequest() {
  yield takeEvery(ActionType.GET_LIST_CATEGORY_REQUEST, getListCategory);
}

function* updateCategory({ payload }) {
  try {
    const resultUpdateCategory = yield call(Api.fetchUpdateCategory, payload);
    if (resultUpdateCategory.success === true) {
      yield put(updateCategorySuccess(resultUpdateCategory.data));
      successNotification("Update category", "Success");
    } else {
      yield put(updateCategoryFailed(resultUpdateCategory || {}));
      resultUpdateCategory?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update category", "Failed");
    yield put(updateCategoryFailed());
  }
}

export function* watchUpdateCategoryRequest() {
  yield takeEvery(ActionType.UPDATE_CATEGORY_REQUEST, updateCategory);
}

function* createTopic({ payload }) {
  try {
    const resultCreateTopic = yield call(Api.fetchCreateTopic, payload);
    if (resultCreateTopic.success === true) {
      yield put(createTopicSuccess(resultCreateTopic.data));
      successNotification("Create topic", "Success");
    } else {
      yield put(createTopicFailed(resultCreateTopic || {}));
      resultCreateTopic?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create topic", "Failed");
    yield put(createTopicFailed());
  }
}

export function* watchCreateTopicRequest() {
  yield takeEvery(ActionType.CREATE_TOPIC_REQUEST, createTopic);
}

function* createQuestion({ payload }) {
  try {
    const resultCreateQuestion = yield call(Api.fetchCreateQuestion, payload);
    if (resultCreateQuestion.success === true) {
      yield put(createQuestionSuccess(resultCreateQuestion.data));
      successNotification("Create question", "Success");
    } else {
      yield put(createQuestionFailed(resultCreateQuestion || {}));
      resultCreateQuestion?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create question", "Failed");
    yield put(createQuestionFailed());
  }
}

export function* watchCreateQuestionRequest() {
  yield takeEvery(ActionType.CREATE_QUESTION_REQUEST, createQuestion);
}

function* updateQuestion({ payload }) {
  try {
    const resultUpdateQuestion = yield call(Api.fetchUpdateQuestion, payload);
    if (resultUpdateQuestion.success === true) {
      yield put(updateQuestionSuccess(resultUpdateQuestion.data));
      successNotification("Update question", "Success");
    } else {
      yield put(updateQuestionFailed(resultUpdateQuestion || {}));
      resultUpdateQuestion?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update question", "Failed");
    yield put(updateQuestionFailed());
  }
}

export function* watchUpdateQuestionRequest() {
  yield takeEvery(ActionType.UPDATE_QUESTION_REQUEST, updateQuestion);
}

function* deleteQuestion({ payload }) {
  try {
    const resultDeleteQuestion = yield call(Api.fetchDeleteQuestion, payload);
    if (resultDeleteQuestion.success === true) {
      yield put(deleteQuestionSuccess(resultDeleteQuestion.data));
      successNotification("Delete question", "Success");
    } else {
      yield put(deleteQuestionFailed(resultDeleteQuestion || {}));
      resultDeleteQuestion?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Delete question", "Failed");
    yield put(deleteQuestionFailed());
  }
}

export function* watchDeleteQuestionRequest() {
  yield takeEvery(ActionType.DELETE_QUESTION_REQUEST, deleteQuestion);
}

function* updateStatusQuestion({ payload }) {
  try {
    const resultUpdateStatusQuestion = yield call(
      Api.fetchUpdateStatusQuestion,
      payload
    );
    if (resultUpdateStatusQuestion.success === true) {
      yield put(updateStatusQuestionSuccess(resultUpdateStatusQuestion.data));
      successNotification("Update Status question", "Success");
    } else {
      yield put(updateStatusQuestionFailed(resultUpdateStatusQuestion || {}));
      resultUpdateStatusQuestion?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status question", "Failed");
    yield put(updateStatusQuestionFailed());
  }
}

export function* watchUpdateStatusQuestionRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_QUESTION_REQUEST,
    updateStatusQuestion
  );
}

function* deleteSubSection({ payload }) {
  try {
    const resultDeleteSubSection = yield call(
      Api.fetchDeleteSubSection,
      payload
    );
    if (resultDeleteSubSection.success === true) {
      yield put(deleteSubSectionSuccess(resultDeleteSubSection.data));
      successNotification("Delete sub secstion", "Success");
    } else {
      yield put(deleteSubSectionFailed(resultDeleteSubSection || {}));
      resultDeleteSubSection?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Delete sub section", "Failed");
    yield put(deleteSubSectionFailed());
  }
}

export function* watchDeleteSubSectionRequest() {
  yield takeEvery(ActionType.DELETE_SUB_SECTION_REQUEST, deleteSubSection);
}

export default function* rootSaga() {
  yield all([
    fork(watchListCategoryRequest),
    fork(watchUpdateCategoryRequest),
    fork(watchCreateTopicRequest),
    fork(watchCreateQuestionRequest),
    fork(watchUpdateQuestionRequest),
    fork(watchDeleteQuestionRequest),
    fork(watchUpdateStatusQuestionRequest),
    fork(watchDeleteSubSectionRequest),
  ]);
}
