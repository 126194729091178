import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Campaign";
import * as Api from "../../api/campaign";

import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";

import {
  getListCampaignSuccess,
  getListCampaignFailed,
  updateStatusCampaignSuccess,
  updateStatusCampaignFailed,
  createCampaignSuccess,
  createCampaignFailed,
  updateCampaignSuccess,
  updateCampaignFailed,
} from "../actions/Campaign";

function* getListCampaign({ payload }) {
  try {
    const res = yield call(Api.fetchListCampaign, {
      params: { ...payload.params },
    });
    yield put(
      getListCampaignSuccess({ ...res, limit: payload?.params?.limit } || {})
    );
  } catch (err) {
    errorNotification("Get List Campaign", "Failed");
    yield put(getListCampaignFailed());
  }
}

export function* watchListCampaignRequest() {
  yield takeEvery(ActionType.GET_LIST_CAMPAIGN_REQUEST, getListCampaign);
}

//update status
function* updateStatusCampaign({ payload }) {
  try {
    const id = payload?.campaignId;
    const status = payload?.isActive ? 1 : 0;
    const body = { status };
    const res = yield call(Api.updateStatusCampaign, id, body);

    if (res.success) {
      yield put(updateStatusCampaignSuccess(res.data));
      successNotification("Update Campaign Status", "Success");
    } else {
      yield put(updateStatusCampaignFailed(res || {}));
      res?.errors?.map((error) => errorNotification(error?.message));
    }
  } catch (err) {
    errorNotification("Update Campaign Status", "Failed");
  }
}

export function* watchUpdateStatusCampaignRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_CAMPAIGN_REQUEST,
    updateStatusCampaign
  );
}

//create
function* createCampaign({ payload }) {
  try {
    const res = yield call(Api.createCampaign, payload);
    if (res.success === true) {
      yield put(createCampaignSuccess(res.data));
      successNotification("Create Campaign", "Success");
    } else {
      yield put(createCampaignFailed(res || {}));
      res?.errors?.map((error) => errorNotification(error?.message));
    }
  } catch (err) {
    errorNotification("Create Campaign", "Failed");
    yield put(createCampaignFailed());
  }
}

export function* watchCreateCampaignRequest() {
  yield takeEvery(ActionType.CREATE_CAMPAIGN_REQUEST, createCampaign);
}

//update
function* updateCampaign({ payload }) {
  try {
    const id = payload.campaignId;
    const res = yield call(Api.updateCampaign, id, payload);
    if (res.success) {
      yield put(updateCampaignSuccess(res.data));
      successNotification("Update Campaign", "Success");
    } else {
      yield put(updateCampaignFailed(res || {}));
      res?.errors?.map((error) => errorNotification(error?.message));
    }
  } catch (err) {
    errorNotification("Update Campaign", "Failed");
    yield put(updateCampaignFailed());
  }
}

export function* watchUpdateCampaignRequest() {
  yield takeEvery(ActionType.UPDATE_CAMPAIGN_REQUEST, updateCampaign);
}

export default function* rootSaga() {
  yield all([
    fork(watchListCampaignRequest),
    fork(watchUpdateStatusCampaignRequest),
    fork(watchCreateCampaignRequest),
    fork(watchUpdateCampaignRequest),
  ]);
}
