export const GET_LIST_BANNER_REQUEST = "GET_LIST_BANNER_REQUEST";
export const GET_LIST_BANNER_SUCCESS = "GET_LIST_BANNER_SUCCESS";
export const GET_LIST_BANNER_FAILED = "GET_LIST_BANNER_FAILED";

export const CREATE_BANNER_REQUEST = "CREATE_BANNER_REQUEST";
export const CREATE_BANNER_SUCCESS = "CREATE_BANNER_SUCCESS";
export const CREATE_BANNER_FAILED = "CREATE_BANNER_FAILED";

export const UPDATE_BANNER_REQUEST = "UPDATE_BANNER_REQUEST";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";
export const UPDATE_BANNER_FAILED = "UPDATE_BANNER_FAILED";

export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_FAILED = "DELETE_BANNER_FAILED";

export const GET_BANNER_ON_SCHOOL_REQUEST = "GET_BANNER_ON_SCHOOL_REQUEST";
export const GET_BANNER_ON_SCHOOL_SUCCESS = "GET_BANNER_ON_SCHOOL_SUCCESS";
export const GET_BANNER_ON_SCHOOL_FAILED = "GET_BANNER_ON_SCHOOL_FAILED";
