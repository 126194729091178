import moment from "moment";

export const schoolDTO = (school) => ({
  ...school,
  createdAt: moment(school.createdAt).format("DD-MM-YYYY"),
});

export const listSchoolDTO = (listSchool = []) => {
  if (!listSchool.length) return [];
  return listSchool.map(schoolDTO) ?? [];
};

export const teacherDTO = (school) => ({
  ...school,
});

export const listTeacherDTO = (listTeacher = []) => {
  if (!listTeacher.length) return [];
  return listTeacher.map(teacherDTO) ?? [];
};

export const studentDTO = (school) => ({
  ...school,
});

export const listStudentDTO = (listStudent = []) => {
  if (!listStudent.length) return [];
  return listStudent.map(studentDTO) ?? [];
};

export const classDTO = (kelas) => ({
  ...kelas,
});

export const listClassDTO = (listClass = []) => {
  if (!listClass.length) return [];
  return listClass.map(classDTO) ?? [];
};
