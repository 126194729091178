import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/SchoolFreeTrial";
import * as Api from "../../api/schoolFreeTrial";

import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";

import {
  getListSchoolFreeTrialSuccess,
  getListSchoolFreeTrialFailed,
  updateStatusSchoolFreeTrialSuccess,
  updateStatusSchoolFreeTrialFailed,
  getSchoolFreeTrialByRequestIdSuccess,
  getSchoolFreeTrialByRequestIdFailed,
  updateSchoolFreeTrialSuccess,
  updateSchoolFreeTrialFailed,
  deleteSchoolFreeTrialSuccess,
  deleteSchoolFreeTrialFailed,
} from "../actions/SchoolFreeTrial";

function* getListSchoolFreeTrial({ payload }) {
  try {
    const res = yield call(Api.getListSchoolFreeTrial, {
      params: { ...payload.params },
    });
    yield put(
      getListSchoolFreeTrialSuccess(
        { ...res, limit: payload?.params?.limit } || {}
      )
    );
  } catch (err) {
    errorNotification("Get List School Free Trial", "Failed");
    yield put(getListSchoolFreeTrialFailed());
  }
}
export function* watchGetListSchoolFreeTrialRequest() {
  yield takeEvery(
    ActionType.GET_LIST_SCHOOL_FREE_TRIAL_REQUEST,
    getListSchoolFreeTrial
  );
}

function* getSchoolFreeTrialByRequestId({ payload }) {
  try {
    const res = yield call(Api.getSchoolFreeTrialByRequestId, payload);
    yield put(getSchoolFreeTrialByRequestIdSuccess(res || {}));
  } catch (err) {
    errorNotification("Get School Free Trial By Req Id", "Failed");
    yield put(getSchoolFreeTrialByRequestIdFailed());
  }
}
export function* watchGetSchoolFreeTrialByRequestIdRequest() {
  yield takeEvery(
    ActionType.GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_REQUEST,
    getSchoolFreeTrialByRequestId
  );
}

//update status
function* updateStatusSchoolFreeTrial({ payload }) {
  try {
    const id = payload?.schoolRequestId;
    const status = payload?.status;
    const body = { status };
    const res = yield call(Api.updateStatusSchoolFreeTrial, id, body);

    if (res.success) {
      yield put(updateStatusSchoolFreeTrialSuccess(res.data));
      successNotification("Update Status School Free Trial", "Success");
    } else {
      yield put(updateStatusSchoolFreeTrialFailed(res || {}));
      res?.errors?.map((error) => errorNotification(error?.message));
    }
  } catch (err) {
    errorNotification("Update Status School Free Trial", "Failed");
  }
}
export function* watchUpdateStatusSchoolFreeTrialRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_SCHOOL_FREE_TRIAL_REQUEST,
    updateStatusSchoolFreeTrial
  );
}

function* updateSchoolFreeTrial({ payload }) {
  try {
    const { schoolRequestId, ...rest } = payload;
    const res = yield call(Api.updateSchoolFreeTrial, schoolRequestId, rest);

    if (res.success) {
      yield put(updateSchoolFreeTrialSuccess(res.data));
      successNotification("Update School Free Trial", "Success");
    } else {
      yield put(updateSchoolFreeTrialFailed(res || {}));
      res?.errors?.map((error) => errorNotification(error?.message));
    }
  } catch (err) {
    errorNotification("Update School Free Trial", "Failed");
  }
}
export function* watchUpdateSchoolFreeTrialRequest() {
  yield takeEvery(
    ActionType.UPDATE_SCHOOL_FREE_TRIAL_REQUEST,
    updateSchoolFreeTrial
  );
}

function* deleteSchoolFreeTrial({ payload }) {
  try {
    const res = yield call(Api.deleteSchoolFreeTrial, payload);

    if (res.success) {
      yield put(deleteSchoolFreeTrialSuccess(res.data));
      successNotification("Delete School Free Trial", "Success");
    } else {
      yield put(deleteSchoolFreeTrialFailed(res || {}));
      res?.errors?.map((error) => errorNotification(error?.message));
    }
  } catch (err) {
    errorNotification("Delete School Free Trial", "Failed");
    yield put(deleteSchoolFreeTrialFailed());
  }
}
export function* watchDeleteSchoolFreeTrialRequest() {
  yield takeEvery(
    ActionType.DELETE_SCHOOL_FREE_TRIAL_REQUEST,
    deleteSchoolFreeTrial
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetListSchoolFreeTrialRequest),
    fork(watchUpdateStatusSchoolFreeTrialRequest),
    fork(watchGetSchoolFreeTrialByRequestIdRequest),
    fork(watchUpdateSchoolFreeTrialRequest),
    fork(watchDeleteSchoolFreeTrialRequest),
  ]);
}
