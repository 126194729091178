import httpRequest from "../util/axios";

export const fetchListUser = async (option = {}) => {
  try {
    const result = await httpRequest.get("/admin/users", option, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateUser = async (body = {}) => {
  try {
    const result = await httpRequest.post("/admin/users", body, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusUser = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/users/${body?.userId}/status`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
