import httpRequest from "../util/axios";

export const fetchListRecommendation = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/recommendations",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateRecommendation = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      `/admin/recommendations`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchRecommendationById = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/recommendations/${body?.recommendationId}`
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateSortRecommendation = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/recommendations/${body?.recommendationId}/sort`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateRecommendation = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/recommendations/${body?.recommendationId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusRecommendation = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/recommendations/${body?.recommendationId}/status`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
