import * as ActionType from "../constants/ActivitySheet";

export const getListActivitySheetRequest = (payload) => ({
  type: ActionType.GET_LIST_ACTIVITY_SHEET_REQUEST,
  payload,
});

export const getListActivitySheetSuccess = (payload) => ({
  type: ActionType.GET_LIST_ACTIVITY_SHEET_SUCCESS,
  payload,
});

export const getListActivitySheetFailed = (payload) => ({
  type: ActionType.GET_LIST_ACTIVITY_SHEET_FAILED,
  payload,
});

export const createActivitySheetSectionRequest = (payload) => ({
  type: ActionType.CREATE_ACTIVITY_SHEET_SECTION_REQUEST,
  payload,
});

export const createActivitySheetSectionSuccess = (payload) => ({
  type: ActionType.CREATE_ACTIVITY_SHEET_SECTION_SUCCESS,
  payload,
});

export const createActivitySheetSectionFailed = (payload) => ({
  type: ActionType.CREATE_ACTIVITY_SHEET_SECTION_FAILED,
  payload,
});

export const updateActivitySheetSectionRequest = (payload) => ({
  type: ActionType.UPDATE_ACTIVITY_SHEET_SECTION_REQUEST,
  payload,
});

export const updateActivitySheetSectionSuccess = (payload) => ({
  type: ActionType.UPDATE_ACTIVITY_SHEET_SECTION_SUCCESS,
  payload,
});

export const updateActivitySheetSectionFailed = (payload) => ({
  type: ActionType.UPDATE_ACTIVITY_SHEET_SECTION_FAILED,
  payload,
});

export const updateSortActivitySheetRequest = (payload) => ({
  type: ActionType.UPDATE_SORT_ACTIVITY_SHEET_REQUEST,
  payload,
});

export const updateSortActivitySheetSuccess = (payload) => ({
  type: ActionType.UPDATE_SORT_ACTIVITY_SHEET_SUCCESS,
  payload,
});

export const updateSortActivitySheetFailed = (payload) => ({
  type: ActionType.UPDATE_SORT_ACTIVITY_SHEET_FAILED,
  payload,
});

export const getListActivitySheetSectionRequest = (payload) => ({
  type: ActionType.GET_LIST_ACTIVITY_SHEET_SECTION_REQUEST,
  payload,
});

export const getListActivitySheetSectionSuccess = (payload) => ({
  type: ActionType.GET_LIST_ACTIVITY_SHEET_SECTION_SUCCESS,
  payload,
});

export const getListActivitySheetSectionFailed = (payload) => ({
  type: ActionType.GET_LIST_ACTIVITY_SHEET_SECTION_FAILED,
  payload,
});

export const createActivitySheetRequest = (payload) => ({
  type: ActionType.CREATE_ACTIVITY_SHEET_REQUEST,
  payload,
});

export const createActivitySheetSuccess = (payload) => ({
  type: ActionType.CREATE_ACTIVITY_SHEET_SUCCESS,
  payload,
});

export const createActivitySheetFailed = (payload) => ({
  type: ActionType.CREATE_ACTIVITY_SHEET_FAILED,
  payload,
});

export const getActivitySheetByIdRequest = (payload) => ({
  type: ActionType.GET_ACTIVITY_SHEET_BY_ID_REQUEST,
  payload,
});

export const getActivitySheetByIdSuccess = (payload) => ({
  type: ActionType.GET_ACTIVITY_SHEET_BY_ID_SUCCESS,
  payload,
});

export const getActivitySheetByIdFailed = (payload) => ({
  type: ActionType.GET_ACTIVITY_SHEET_BY_ID_FAILED,
  payload,
});

export const updateActivitySheetRequest = (payload) => ({
  type: ActionType.UPDATE_ACTIVITY_SHEET_REQUEST,
  payload,
});

export const updateActivitySheetSuccess = (payload) => ({
  type: ActionType.UPDATE_ACTIVITY_SHEET_SUCCESS,
  payload,
});

export const updateActivitySheetFailed = (payload) => ({
  type: ActionType.UPDATE_ACTIVITY_SHEET_FAILED,
  payload,
});

export const deleteActivitySheetRequest = (payload) => ({
  type: ActionType.DELETE_ACTIVITY_SHEET_REQUEST,
  payload,
});

export const deleteActivitySheetSuccess = (payload) => ({
  type: ActionType.DELETE_ACTIVITY_SHEET_SUCCESS,
  payload,
});

export const deleteActivitySheetFailed = (payload) => ({
  type: ActionType.DELETE_ACTIVITY_SHEET_FAILED,
  payload,
});
