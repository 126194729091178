import httpRequest from "../util/axios";

export const fetchListClass = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/classes`,
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListStudent = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/students`,
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListTeacher = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/schools/teachers`,
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateManualTransaction = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/schools/transactions/create-order",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListTransaction = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/schools/transactions",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
