export const GET_LIST_CAMPAIGN_REQUEST = "campaign/GET_LIST_CAMPAIGN_REQUEST";
export const GET_LIST_CAMPAIGN_SUCCESS = "campaign/GET_LIST_CAMPAIGN_SUCCESS";
export const GET_LIST_CAMPAIGN_FAILED = "campaign/GET_LIST_CAMPAIGN_FAILED";

export const UPDATE_STATUS_CAMPAIGN_REQUEST =
  "campaign/UPDATE_STATUS_CAMPAIGN_REQUEST";
export const UPDATE_STATUS_CAMPAIGN_SUCCESS =
  "campaign/UPDATE_STATUS_CAMPAIGN_SUCCESS";
export const UPDATE_STATUS_CAMPAIGN_FAILED =
  "campaign/UPDATE_STATUS_CAMPAIGN_FAILED";

export const CREATE_CAMPAIGN_REQUEST = "campaign/CREATE_CAMPAIGN_REQUEST";
export const CREATE_CAMPAIGN_SUCCESS = "campaign/CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILED = "campaign/CREATE_CAMPAIGN_FAILED";

export const UPDATE_CAMPAIGN_REQUEST = "campaign/UPDATE_CAMPAIGN_REQUEST";
export const UPDATE_CAMPAIGN_SUCCESS = "campaign/UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAILED = "campaign/UPDATE_CAMPAIGN_FAILED";
