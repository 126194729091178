import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Catetgory";
import * as Api from "../../api/category";
import {
  getListCategoryTypeSuccess,
  getListCategoryTypeFailed,
  getListSubCategorySuccess,
  getListSubCategoryFailed,
  createSubCategorySuccess,
  createSubCategoryFailed,
  updateStatusSubCategorySuccess,
  updateStatusSubCategoryFailed,
  updateSubCategorySuccess,
  updateSubCategoryFailed,
  getSubCategoryByIdSuccess,
  getSubCategoryByIdFailed,
  setBookCategorySuccess,
  setBookCategoryFailed,
  unsetBookCategorySuccess,
  unsetBookCategoryFailed,
} from "../actions/Category";
import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";

function* getListCategoryTypeWithOption({ payload }) {
  try {
    const resultListCategoryType = yield call(Api.fetchListCategoryType, {
      params: { ...payload.params },
    });
    yield put(
      getListCategoryTypeSuccess(
        { ...resultListCategoryType, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list category type", "Failed");
    yield put(getListCategoryTypeFailed());
  }
}

export function* watchListCategoryTypeRequest() {
  yield takeEvery(
    ActionType.GET_LIST_CATEGORY_TYPE_REQUEST,
    getListCategoryTypeWithOption
  );
}

function* getListSubCategoryWithOption({ payload }) {
  try {
    const resultListSubCategory = yield call(Api.fetchListSubCategory, payload);
    yield put(
      getListSubCategorySuccess(
        { ...resultListSubCategory, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list category type", "Failed");
    yield put(getListSubCategoryFailed());
  }
}

export function* watchListSubCategoryRequest() {
  yield takeEvery(
    ActionType.GET_LIST_SUB_CATEGORY_REQUEST,
    getListSubCategoryWithOption
  );
}

function* createSubCategory({ payload }) {
  try {
    const resultCreateSubCategory = yield call(
      Api.fetchCreateSubCategory,
      payload
    );
    if (resultCreateSubCategory.success === true) {
      yield put(createSubCategorySuccess(resultCreateSubCategory.data));
      successNotification("Create sub category", "Success");
    } else {
      yield put(createSubCategoryFailed(resultCreateSubCategory || {}));
      resultCreateSubCategory?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create sub category", "Failed");
    yield put(createSubCategoryFailed());
  }
}

export function* watchCreateSubCategoryRequest() {
  yield takeEvery(ActionType.CREATE_SUB_CATEGORY_REQUEST, createSubCategory);
}

function* updateStatusSubCategory({ payload }) {
  try {
    const resultUpdateStatusSubCategory = yield call(
      Api.fetchUpdateStatusSubCategory,
      payload
    );
    if (resultUpdateStatusSubCategory.success === true) {
      yield put(
        updateStatusSubCategorySuccess(resultUpdateStatusSubCategory.data)
      );
      successNotification("Update Status sub category", "Success");
    } else {
      yield put(
        updateStatusSubCategoryFailed(resultUpdateStatusSubCategory || {})
      );
      resultUpdateStatusSubCategory?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("UpdateStatus sub category", "Failed");
    yield put(updateStatusSubCategoryFailed());
  }
}

export function* watchUpdateStatusSubCategoryRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_SUB_CATEGORY_REQUEST,
    updateStatusSubCategory
  );
}

function* updateSubCategory({ payload }) {
  try {
    const resultUpdateSubCategory = yield call(
      Api.fetchUpdateSubCategory,
      payload
    );
    if (resultUpdateSubCategory.success === true) {
      yield put(updateSubCategorySuccess(resultUpdateSubCategory.data));
      successNotification("Update sub category", "Success");
    } else {
      yield put(updateSubCategoryFailed(resultUpdateSubCategory || {}));
      resultUpdateSubCategory?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update sub category", "Failed");
    yield put(updateSubCategoryFailed());
  }
}

export function* watchUpdateSubCategoryRequest() {
  yield takeEvery(ActionType.UPDATE_SUB_CATEGORY_REQUEST, updateSubCategory);
}

function* getSubCategoryById({ payload }) {
  try {
    const resultGetSubCategoryById = yield call(
      Api.fetchSubCategoryById,
      payload
    );
    if (resultGetSubCategoryById.success === true) {
      yield put(
        getSubCategoryByIdSuccess({ ...resultGetSubCategoryById } || {})
      );
    } else {
      yield put(getSubCategoryByIdFailed(resultGetSubCategoryById || {}));
      resultGetSubCategoryById?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Get subById category", "Failed");
    yield put(getSubCategoryByIdFailed());
  }
}

export function* watchGetSubCategoryByIdRequest() {
  yield takeEvery(
    ActionType.GET_SUB_CATEGORY_BY_ID_REQUEST,
    getSubCategoryById
  );
}

function* setBookCategory({ payload }) {
  try {
    const resultSetBookCategory = yield call(Api.fetchSetBookCategory, payload);
    if (resultSetBookCategory.success === true) {
      yield put(setBookCategorySuccess({ ...resultSetBookCategory } || {}));
      successNotification("Set book category", "Success");
    } else {
      yield put(setBookCategoryFailed(resultSetBookCategory || {}));
      resultSetBookCategory?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Set book category", "Failed");
    yield put(setBookCategoryFailed());
  }
}

export function* watchSetBookCategoryRequest() {
  yield takeEvery(ActionType.SET_BOOK_CATEGORY_REQUEST, setBookCategory);
}

function* unsetBookCategory({ payload }) {
  try {
    const resultUnsetBookCategory = yield call(
      Api.fetchUnsetBookCategory,
      payload
    );
    if (resultUnsetBookCategory.success === true) {
      yield put(unsetBookCategorySuccess({ ...resultUnsetBookCategory } || {}));
      successNotification("Unset book category", "Success");
    } else {
      yield put(unsetBookCategoryFailed(resultUnsetBookCategory || {}));
      resultUnsetBookCategory?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Unset book category", "Failed");
    yield put(unsetBookCategoryFailed());
  }
}

export function* watchUnsetBookCategoryRequest() {
  yield takeEvery(ActionType.UNSET_BOOK_CATEGORY_REQUEST, unsetBookCategory);
}

export default function* rootSaga() {
  yield all([
    fork(watchListCategoryTypeRequest),
    fork(watchListSubCategoryRequest),
    fork(watchCreateSubCategoryRequest),
    fork(watchUpdateStatusSubCategoryRequest),
    fork(watchUpdateSubCategoryRequest),
    fork(watchGetSubCategoryByIdRequest),
    fork(watchSetBookCategoryRequest),
    fork(watchUnsetBookCategoryRequest),
  ]);
}
