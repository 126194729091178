import * as ActionType from "../constants/Campaign";

export const getListCampaignRequest = (payload) => ({
  type: ActionType.GET_LIST_CAMPAIGN_REQUEST,
  payload,
});
export const getListCampaignSuccess = (payload) => ({
  type: ActionType.GET_LIST_CAMPAIGN_SUCCESS,
  payload,
});
export const getListCampaignFailed = (payload) => ({
  type: ActionType.GET_LIST_CAMPAIGN_FAILED,
  payload,
});

//update status
export const updateStatusCampaignRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_CAMPAIGN_REQUEST,
  payload,
});
export const updateStatusCampaignSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_CAMPAIGN_SUCCESS,
  payload,
});
export const updateStatusCampaignFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_CAMPAIGN_FAILED,
  payload,
});

//create
export const createCampaignRequest = (payload) => ({
  type: ActionType.CREATE_CAMPAIGN_REQUEST,
  payload,
});
export const createCampaignSuccess = (payload) => ({
  type: ActionType.CREATE_CAMPAIGN_SUCCESS,
  payload,
});
export const createCampaignFailed = (payload) => ({
  type: ActionType.CREATE_CAMPAIGN_FAILED,
  payload,
});

//update
export const updateCampaignRequest = (payload) => ({
  type: ActionType.UPDATE_CAMPAIGN_REQUEST,
  payload,
});
export const updateCampaignSuccess = (payload) => ({
  type: ActionType.UPDATE_CAMPAIGN_SUCCESS,
  payload,
});
export const updateCampaignFailed = (payload) => ({
  type: ActionType.UPDATE_CAMPAIGN_FAILED,
  payload,
});
