import * as ActionType from "../constants/Segmentation";

const initialState = {
  listSegmentation: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
      hasNext: true,
    },
    totalData: 0,
  },
};

const segmentationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_SEGMENTATION_REQUEST:
      return {
        ...state,
        listSegmentation: {
          ...state.listSegmentation,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_SEGMENTATION_SUCCESS:
      return {
        ...state,
        listSegmentation: {
          ...state.listSegmentation,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
            hasNext: payload?.paging?.hasNext,
            currentPage: payload?.paging?.currentPage,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_SEGMENTATION_FAILED:
      return {
        ...state,
        listSegmentation: {
          ...state.listSegmentation,
          isLoading: false,
        },
      };

    default: {
      return state;
    }
  }
};

export default segmentationReducer;
