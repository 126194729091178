import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Segmentation";
import * as Api from "../../api/segmentation";
import {
  getListSegmentationSuccess,
  getListSegmentationFailed,
} from "../actions/Segmentation";
import { errorNotification } from "../../components/NotificationPopUp";

function* getListSegmentation({ payload }) {
  try {
    const resultListSegmentation = yield call(Api.fetchListSegmentation, {
      params: { ...payload.params },
    });
    yield put(
      getListSegmentationSuccess(
        { ...resultListSegmentation, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list segmentation", "Failed");
    yield put(getListSegmentationFailed());
  }
}

export function* watchListSegmentationRequest() {
  yield takeEvery(
    ActionType.GET_LIST_SEGMENTATION_REQUEST,
    getListSegmentation
  );
}

export default function* rootSaga() {
  yield all([fork(watchListSegmentationRequest)]);
}
