import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/User";
import * as Api from "../../api/user";

import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";
import {
  createUserFailed,
  createUserSuccess,
  getListUserFailed,
  getListUserSuccess,
  updateStatusUserSuccess,
  updateStatusUserFailed,
} from "../actions";

function* getListUser({ payload }) {
  try {
    const resultListUser = yield call(Api.fetchListUser, {
      params: { ...payload.params },
    });
    yield put(
      getListUserSuccess(
        { ...resultListUser, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list school", "Failed");
    yield put(getListUserFailed());
  }
}

export function* watchListUserRequest() {
  yield takeEvery(ActionType.GET_LIST_USER_REQUEST, getListUser);
}

function* createUser({ payload }) {
  try {
    const resultCreateUser = yield call(Api.fetchCreateUser, payload);
    if (resultCreateUser.success === true) {
      yield put(createUserSuccess(resultCreateUser.data));
      successNotification("Create user", "Success");
    } else {
      yield put(createUserFailed(resultCreateUser || {}));
      resultCreateUser?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create user", "Failed");
    yield put(createUserFailed());
  }
}

export function* watchCreateUserRequest() {
  yield takeEvery(ActionType.CREATE_USER_REQUEST, createUser);
}

function* updateStatusUser({ payload }) {
  try {
    const resultUpdateStatusUser = yield call(
      Api.fetchUpdateStatusUser,
      payload
    );
    if (resultUpdateStatusUser.success === true) {
      yield put(updateStatusUserSuccess(resultUpdateStatusUser.data));
      successNotification("Update Status user", "Success");
    } else {
      yield put(updateStatusUserFailed(resultUpdateStatusUser || {}));
      resultUpdateStatusUser?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status user", "Failed");
    yield put(updateStatusUserFailed());
  }
}

export function* watchUpdateStatusUserRequest() {
  yield takeEvery(ActionType.UPDATE_STATUS_USER_REQUEST, updateStatusUser);
}

export default function* rootSaga() {
  yield all([
    fork(watchListUserRequest),
    fork(watchCreateUserRequest),
    fork(watchUpdateStatusUserRequest),
  ]);
}
