export const GET_LIST_CATEGORY_REQUEST = "faq/GET_LIST_CATEGORY_REQUEST";
export const GET_LIST_CATEGORY_SUCCESS = "faq/GET_LIST_CATEGORY_SUCCESS";
export const GET_LIST_CATEGORY_FAILED = "faq/GET_LIST_CATEGORY_FAILED";

export const UPDATE_CATEGORY_REQUEST = "faq/UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "faq/UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILED = "faq/UPDATE_CATEGORY_FAILED";

export const CREATE_TOPIC_REQUEST = "faq/CREATE_TOPIC_REQUEST";
export const CREATE_TOPIC_SUCCESS = "faq/CREATE_TOPIC_SUCCESS";
export const CREATE_TOPIC_FAILED = "faq/CREATE_TOPIC_FAILED";

export const CREATE_QUESTION_REQUEST = "faq/CREATE_QUESTION_REQUEST";
export const CREATE_QUESTION_SUCCESS = "faq/CREATE_QUESTION_SUCCESS";
export const CREATE_QUESTION_FAILED = "faq/CREATE_QUESTION_FAILED";

export const UPDATE_QUESTION_REQUEST = "faq/UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_SUCCESS = "faq/UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_FAILED = "faq/UPDATE_QUESTION_FAILED";

export const UPDATE_STATUS_QUESTION_REQUEST =
  "faq/UPDATE_STATUS_QUESTION_REQUEST";
export const UPDATE_STATUS_QUESTION_SUCCESS =
  "faq/UPDATE_STATUS_QUESTION_SUCCESS";
export const UPDATE_STATUS_QUESTION_FAILED =
  "faq/UPDATE_STATUS_QUESTION_FAILED";

export const DELETE_QUESTION_REQUEST = "faq/DELETE_QUESTION_REQUEST";
export const DELETE_QUESTION_SUCCESS = "faq/DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAILED = "faq/DELETE_QUESTION_FAILED";

export const DELETE_SUB_SECTION_REQUEST = "faq/DELETE_SUB_SECTION_REQUEST";
export const DELETE_SUB_SECTION_SUCCESS = "faq/DELETE_SUB_SECTION_SUCCESS";
export const DELETE_SUB_SECTION_FAILED = "faq/DELETE_SUB_SECTION_FAILED";
