import * as ActionType from "../constants/Recommendation";

export const getListRecommendationRequest = (payload) => ({
  type: ActionType.GET_LIST_RECOMMENDATION_REQUEST,
  payload,
});

export const getListRecommendationSuccess = (payload) => ({
  type: ActionType.GET_LIST_RECOMMENDATION_SUCCESS,
  payload,
});

export const getListRecommendationFailed = (payload) => ({
  type: ActionType.GET_LIST_RECOMMENDATION_FAILED,
  payload,
});

export const createRecommendationRequest = (payload) => ({
  type: ActionType.CREATE_RECOMMENDATION_REQUEST,
  payload,
});

export const createRecommendationSuccess = (payload) => ({
  type: ActionType.CREATE_RECOMMENDATION_SUCCESS,
  payload,
});

export const createRecommendationFailed = (payload) => ({
  type: ActionType.CREATE_RECOMMENDATION_FAILED,
  payload,
});

export const resetStatusRecommendationRequest = (payload) => ({
  type: ActionType.RESET_STATUS_RECOMMENDATION_REQUEST,
  payload,
});

export const getRecommendationByIdRequest = (payload) => ({
  type: ActionType.GET_RECOMMENDATION_BY_ID_REQUEST,
  payload,
});

export const getRecommendationByIdSuccess = (payload) => ({
  type: ActionType.GET_RECOMMENDATION_BY_ID_SUCCESS,
  payload,
});

export const getRecommendationByIdFailed = (payload) => ({
  type: ActionType.GET_RECOMMENDATION_BY_ID_FAILED,
  payload,
});

export const updateSortRecommendationRequest = (payload) => ({
  type: ActionType.UPDATE_SORT_RECOMMENDATION_REQUEST,
  payload,
});

export const updateSortRecommendationSuccess = (payload) => ({
  type: ActionType.UPDATE_SORT_RECOMMENDATION_SUCCESS,
  payload,
});

export const updateSortRecommendationFailed = (payload) => ({
  type: ActionType.UPDATE_SORT_RECOMMENDATION_FAILED,
  payload,
});

export const updateRecommendationRequest = (payload) => ({
  type: ActionType.UPDATE_RECOMMENDATION_REQUEST,
  payload,
});

export const updateRecommendationSuccess = (payload) => ({
  type: ActionType.UPDATE_RECOMMENDATION_SUCCESS,
  payload,
});

export const updateRecommendationFailed = (payload) => ({
  type: ActionType.UPDATE_RECOMMENDATION_FAILED,
  payload,
});

export const updateStatusRecommendationRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_RECOMMENDATION_REQUEST,
  payload,
});

export const updateStatusRecommendationSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_RECOMMENDATION_SUCCESS,
  payload,
});

export const updateStatusRecommendationFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_RECOMMENDATION_FAILED,
  payload,
});
