import * as ActionType from "../constants/Collection";
import { collectionDTO, listCollectionDTO } from "../helpers/collection";

const initialState = {
  listCollection: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  collectionById: {
    data: {},
    isLoading: false,
  },
  isLoadingActionCollection: false,
  isSuccessActionCollection: false,
  isErrorActionCollection: false,
};

const collectionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_COLLECTION_REQUEST:
      return {
        ...state,
        listCollection: {
          ...state.listCollection,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_COLLECTION_SUCCESS:
      return {
        ...state,
        listCollection: {
          ...state.listCollection,
          data: listCollectionDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_COLLECTION_FAILED:
      return {
        ...state,
        listCollection: {
          ...state.listCollection,
          isLoading: false,
        },
      };

    case ActionType.GET_COLLECTION_BY_ID_REQUEST:
      return {
        ...state,
        collectionById: {
          ...state.collectionById,
          isLoading: true,
        },
      };
    case ActionType.GET_COLLECTION_BY_ID_SUCCESS:
      return {
        ...state,
        collectionById: {
          ...state.collectionById,
          data: collectionDTO(payload.data),
          isLoading: false,
        },
      };
    case ActionType.GET_COLLECTION_BY_ID_FAILED:
      return {
        ...state,
        collectionById: {
          ...state.collectionById,
          isLoading: false,
        },
      };

    case ActionType.PUBLISH_COLLECTION_REQUEST:
    case ActionType.UPDATE_STATUS_COLLECTION_REQUEST:
    case ActionType.CREATE_COLLECTION_REQUEST:
    case ActionType.UPDATE_COLLECTION_REQUEST:
      return {
        ...state,
        isSuccessActionCollection: false,
        isErrorActionCollection: false,
        isLoadingActionCollection: true,
      };
    case ActionType.PUBLISH_COLLECTION_SUCCESS:
    case ActionType.UPDATE_STATUS_COLLECTION_SUCCESS:
    case ActionType.CREATE_COLLECTION_SUCCESS:
    case ActionType.UPDATE_COLLECTION_SUCCESS:
      return {
        ...state,
        isLoadingActionCollection: false,
        isSuccessActionCollection: true,
      };
    case ActionType.PUBLISH_COLLECTION_FAILED:
    case ActionType.UPDATE_STATUS_COLLECTION_FAILED:
    case ActionType.CREATE_COLLECTION_FAILED:
    case ActionType.UPDATE_COLLECTION_FAILED:
      return {
        ...state,
        isLoadingActionCollection: false,
        isErrorActionCollection: true,
      };

    default: {
      return state;
    }
  }
};

export default collectionReducer;
