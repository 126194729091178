import * as ActionType from "../constants/Recommendation";

const initialState = {
  listRecommendation: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  recommendationById: {
    data: {},
    isLoading: false,
  },
  isLoadingActionRecommendation: false,
  isSuccessActionRecommendation: false,
  isErrorActionRecommendation: false,
};

const recommendationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_RECOMMENDATION_REQUEST:
      return {
        ...state,
        listRecommendation: {
          ...state.listRecommendation,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        listRecommendation: {
          ...state.listRecommendation,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
          type: payload?.type,
        },
      };
    case ActionType.GET_LIST_RECOMMENDATION_FAILED:
      return {
        ...state,
        listRecommendation: {
          ...state.listRecommendation,
          isLoading: false,
        },
      };

    case ActionType.CREATE_RECOMMENDATION_REQUEST:
    case ActionType.UPDATE_SORT_RECOMMENDATION_REQUEST:
    case ActionType.UPDATE_RECOMMENDATION_REQUEST:
    case ActionType.UPDATE_STATUS_RECOMMENDATION_REQUEST:
      return {
        ...state,
        isSuccessActionRecommendation: false,
        isErrorActionRecommendation: false,
        isLoadingActionRecommendation: true,
      };
    case ActionType.CREATE_RECOMMENDATION_SUCCESS:
    case ActionType.UPDATE_SORT_RECOMMENDATION_SUCCESS:
    case ActionType.UPDATE_RECOMMENDATION_SUCCESS:
    case ActionType.UPDATE_STATUS_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        isLoadingActionRecommendation: false,
        isSuccessActionRecommendation: true,
      };
    case ActionType.CREATE_RECOMMENDATION_FAILED:
    case ActionType.UPDATE_SORT_RECOMMENDATION_FAILED:
    case ActionType.UPDATE_RECOMMENDATION_FAILED:
    case ActionType.UPDATE_STATUS_RECOMMENDATION_FAILED:
      return {
        ...state,
        isLoadingActionRecommendation: false,
        isErrorActionRecommendation: true,
      };

    case ActionType.RESET_STATUS_RECOMMENDATION_REQUEST:
      return {
        ...state,
        isLoadingActionRecommendation: false,
        isErrorActionRecommendation: false,
        isSuccessActionRecommendation: false,
      };

    case ActionType.GET_RECOMMENDATION_BY_ID_REQUEST:
      return {
        ...state,
        recommendationById: {
          ...state.recommendationById,
          isLoading: true,
        },
      };
    case ActionType.GET_RECOMMENDATION_BY_ID_SUCCESS:
      return {
        ...state,
        recommendationById: {
          ...state.recommendationById,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_RECOMMENDATION_BY_ID_FAILED:
      return {
        ...state,
        recommendationById: {
          ...state.recommendationById,
          isLoading: false,
        },
      };

    default: {
      return state;
    }
  }
};

export default recommendationReducer;
