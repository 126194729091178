import * as ActionType from "../constants/Reading";

export const getListReadingParadeRequest = (payload) => ({
  type: ActionType.GET_LIST_READING_PARADE_REQUEST,
  payload,
});

export const getListReadingParadeSuccess = (payload) => ({
  type: ActionType.GET_LIST_READING_PARADE_SUCCESS,
  payload,
});

export const getListReadingParadeFailed = (payload) => ({
  type: ActionType.GET_LIST_READING_PARADE_FAILED,
  payload,
});

export const createReadingParadeRequest = (payload) => ({
  type: ActionType.CREATE_READING_PARADE_REQUEST,
  payload,
});

export const createReadingParadeSuccess = (payload) => ({
  type: ActionType.CREATE_READING_PARADE_SUCCESS,
  payload,
});

export const createReadingParadeFailed = (payload) => ({
  type: ActionType.CREATE_READING_PARADE_FAILED,
  payload,
});

export const resetStatusReadingParadeRequest = (payload) => ({
  type: ActionType.RESET_STATUS_READING_PARADE_REQUEST,
  payload,
});

export const updateReadingParadeRequest = (payload) => ({
  type: ActionType.UPDATE_READING_PARADE_REQUEST,
  payload,
});

export const updateReadingParadeSuccess = (payload) => ({
  type: ActionType.UPDATE_READING_PARADE_SUCCESS,
  payload,
});

export const updateReadingParadeFailed = (payload) => ({
  type: ActionType.UPDATE_READING_PARADE_FAILED,
  payload,
});

export const getReadingParadeByIdRequest = (payload) => ({
  type: ActionType.GET_READING_PARADE_BY_ID_REQUEST,
  payload,
});

export const getReadingParadeByIdSuccess = (payload) => ({
  type: ActionType.GET_READING_PARADE_BY_ID_SUCCESS,
  payload,
});

export const getReadingParadeByIdFailed = (payload) => ({
  type: ActionType.GET_READING_PARADE_BY_ID_FAILED,
  payload,
});

export const updateStatusReadingParadeRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_READING_PARADE_REQUEST,
  payload,
});

export const updateStatusReadingParadeSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_READING_PARADE_SUCCESS,
  payload,
});

export const updateStatusReadingParadeFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_READING_PARADE_FAILED,
  payload,
});

export const rePublishedReadingParadeRequest = (payload) => ({
  type: ActionType.RE_PUBLISHED_READING_PARADE_REQUEST,
  payload,
});

export const rePublishedReadingParadeSuccess = (payload) => ({
  type: ActionType.RE_PUBLISHED_READING_PARADE_SUCCESS,
  payload,
});

export const rePublishedReadingParadeFailed = (payload) => ({
  type: ActionType.RE_PUBLISHED_READING_PARADE_FAILED,
  payload,
});

export const getListReadingChallengeRequest = (payload) => ({
  type: ActionType.GET_LIST_READING_CHALLENGE_REQUEST,
  payload,
});

export const getListReadingChallengeSuccess = (payload) => ({
  type: ActionType.GET_LIST_READING_CHALLENGE_SUCCESS,
  payload,
});

export const getListReadingChallengeFailed = (payload) => ({
  type: ActionType.GET_LIST_READING_CHALLENGE_FAILED,
  payload,
});

export const createReadingChallengeRequest = (payload) => ({
  type: ActionType.CREATE_READING_CHALLENGE_REQUEST,
  payload,
});

export const createReadingChallengeSuccess = (payload) => ({
  type: ActionType.CREATE_READING_CHALLENGE_SUCCESS,
  payload,
});

export const createReadingChallengeFailed = (payload) => ({
  type: ActionType.CREATE_READING_CHALLENGE_FAILED,
  payload,
});

export const getReadingChallengeByIdRequest = (payload) => ({
  type: ActionType.GET_READING_CHALLENGE_BY_ID_REQUEST,
  payload,
});

export const getReadingChallengeByIdSuccess = (payload) => ({
  type: ActionType.GET_READING_CHALLENGE_BY_ID_SUCCESS,
  payload,
});

export const getReadingChallengeByIdFailed = (payload) => ({
  type: ActionType.GET_READING_CHALLENGE_BY_ID_FAILED,
  payload,
});

export const updateReadingChallengeRequest = (payload) => ({
  type: ActionType.UPDATE_READING_CHALLENGE_REQUEST,
  payload,
});

export const updateReadingChallengeSuccess = (payload) => ({
  type: ActionType.UPDATE_READING_CHALLENGE_SUCCESS,
  payload,
});

export const updateReadingChallengeFailed = (payload) => ({
  type: ActionType.UPDATE_READING_CHALLENGE_FAILED,
  payload,
});

export const resetStatusReadingChallengeRequest = (payload) => ({
  type: ActionType.RESET_STATUS_READING_CHALLENGE_REQUEST,
  payload,
});

export const updateStatusReadingChallengeRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_READING_CHALLENGE_REQUEST,
  payload,
});

export const updateStatusReadingChallengeSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_READING_CHALLENGE_SUCCESS,
  payload,
});

export const updateStatusReadingChallengeFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_READING_CHALLENGE_FAILED,
  payload,
});

export const rePublishedReadingChallengeRequest = (payload) => ({
  type: ActionType.RE_PUBLISHED_READING_CHALLENGE_REQUEST,
  payload,
});

export const rePublishedReadingChallengeSuccess = (payload) => ({
  type: ActionType.RE_PUBLISHED_READING_CHALLENGE_SUCCESS,
  payload,
});

export const rePublishedReadingChallengeFailed = (payload) => ({
  type: ActionType.RE_PUBLISHED_READING_CHALLENGE_FAILED,
  payload,
});

export const uploadPointParadeRequest = (payload) => ({
  type: ActionType.UPLOAD_POINT_PARADE_REQUEST,
  payload,
});

export const uploadPointParadeSuccess = (payload) => ({
  type: ActionType.UPLOAD_POINT_PARADE_SUCCESS,
  payload,
});

export const uploadPointParadeFailed = (payload) => ({
  type: ActionType.UPLOAD_POINT_PARADE_FAILED,
  payload,
});
