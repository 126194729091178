import React from "react";
import { Card, Tabs } from "antd";
import SignInPartner from "./SignInPartner";
import SignInStaff from "./SignInStaff";

const { TabPane } = Tabs;

const SignIn = () => {
  return (
    <div className="gx-app-login-wrap gx-bg-biscay">
      <div className="gx-app-login-container">
        <Card className="gx-card">
          <Tabs type="card">
            <TabPane tab="Staff" key="1">
              <SignInStaff />
            </TabPane>
            <TabPane tab="Partner" key="2">
              <SignInPartner />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </div>
  );
};

export default SignIn;
