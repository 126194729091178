import httpRequest from "../util/axios";

export const fetchListPublisher = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/books/publishers",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusPublisher = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/books/publishers/${body?.publisherId}/status`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreatePublisher = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/books/publishers",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchPublisherById = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/books/publishers/${body?.publisherId}`
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdatePublisher = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/books/publishers/${body?.publisherId}`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchPublisherInfo = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/publishers/info",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchMyTopMostPopularBooks = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/publishers/my-top-most-popular-books",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchTopMostPopularBooks = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/publishers/top-most-popular-books",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdatePublisherInfo = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/publishers/info`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListRoyaltyStatement = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/publishers/royalty-statement",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDetailRoyaltyStatement = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/publishers/royalty-statement/${body?.payoutPublisherId}`,
      { params: body.params },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDetailReports = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/publishers/detail-reports",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
