import * as ActionType from "../constants/School";
import {
  listClassDTO,
  listSchoolDTO,
  listStudentDTO,
  listTeacherDTO,
  schoolDTO,
} from "../helpers/school";

const initialState = {
  listSchool: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
      hasNext: true,
    },
    totalData: 0,
  },
  listTeacher: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  listStudent: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  isLoadingActionSchool: false,
  isSuccessActionSchool: false,
  isErrorActionSchool: false,
  listClass: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  listFindSchool: {
    data: [],
    isLoading: false,
  },
  detailSchool: {
    data: {},
    isLoading: false,
  },
  summarySchool: {
    data: {},
    isLoading: false,
  },
  activitySchool: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  listOfReadSchool: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  listOfReadStudent: {
    data: [],
    isLoading: false,
    totalData: 0,
  },
  readHistorySchool: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  detailReadHistorySchool: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  listFamily: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  childFamily: {
    data: [],
    isLoading: false,
  },
  setAccountToFamily: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  setAccountToFamilyAdmin: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  verifyConnectFamily: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  detailConnectionByStudentId: {
    data: {},
    isLoading: false,
  },
  detailConnectionByStudentIdAdmin: {
    data: {},
    isLoading: false,
  },
};

const schoolReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_SCHOOL_REQUEST:
      return {
        ...state,
        listSchool: {
          ...state.listSchool,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_SCHOOL_SUCCESS:
      return {
        ...state,
        listSchool: {
          ...state.listSchool,
          data: listSchoolDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
            hasNext: payload?.paging?.hasNext,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_SCHOOL_FAILED:
      return {
        ...state,
        listSchool: {
          ...state.listSchool,
          isLoading: false,
        },
      };
    case ActionType.GET_LIST_TEACHER_BY_SCHOOL_ID_REQUEST:
      return {
        ...state,
        listTeacher: {
          ...state.listTeacher,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_TEACHER_BY_SCHOOL_ID_SUCCESS:
      return {
        ...state,
        listTeacher: {
          ...state.listTeacher,
          data: listTeacherDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_TEACHER_BY_SCHOOL_ID_FAILED:
      return {
        ...state,
        listTeacher: {
          ...state.listTeacher,
          isLoading: false,
        },
      };

    case ActionType.GET_LIST_STUDENT_BY_SCHOOL_ID_REQUEST:
      return {
        ...state,
        listStudent: {
          ...state.listStudent,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_STUDENT_BY_SCHOOL_ID_SUCCESS:
      return {
        ...state,
        listStudent: {
          ...state.listStudent,
          data: listStudentDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_STUDENT_BY_SCHOOL_ID_FAILED:
      return {
        ...state,
        listStudent: {
          ...state.listStudent,
          isLoading: false,
        },
      };

    case ActionType.CREATE_SCHOOL_REQUEST:
    case ActionType.UPDATE_TEACHER_ADMIN_ACCESS_REQUEST:
      return {
        ...state,
        isLoadingActionSchool: true,
      };
    case ActionType.CREATE_SCHOOL_SUCCESS:
    case ActionType.UPDATE_TEACHER_ADMIN_ACCESS_SUCCESS:
      return {
        ...state,
        isLoadingActionSchool: false,
        isSuccessActionSchool: true,
      };

    case ActionType.CREATE_SCHOOL_FAILED:
    case ActionType.UPDATE_TEACHER_ADMIN_ACCESS_FAILED:
      return {
        ...state,
        isLoadingActionSchool: false,
        isErrorActionSchool: true,
      };

    case ActionType.UPDATE_SCHOOL_REQUEST:
      return {
        ...state,
        isLoadingActionSchool: true,
      };
    case ActionType.UPDATE_SCHOOL_SUCCESS:
      return {
        ...state,
        isLoadingActionSchool: false,
        isSuccessActionSchool: true,
      };

    case ActionType.UPDATE_SCHOOL_FAILED:
      return {
        ...state,
        isLoadingActionSchool: false,
        isErrorActionSchool: true,
      };

    case ActionType.UPDATE_SCHOOL_CRED_REQUEST:
      return {
        ...state,
        isLoadingActionSchool: true,
      };

    case ActionType.UPDATE_SCHOOL_CRED_SUCCESS:
      return {
        ...state,
        isLoadingActionSchool: false,
        isSuccessActionSchool: true,
      };
    case ActionType.UPDATE_SCHOOL_CRED_FAILED:
      return {
        ...state,
        isLoadingActionSchool: false,
        isErrorActionSchool: true,
      };

    case ActionType.RESET_STATUS_SCHOOL_REQUEST:
      return {
        ...state,
        isLoadingActionSchool: false,
        isErrorActionSchool: false,
        isSuccessActionSchool: false,
        setAccountToFamily: {
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
        setAccountToFamilyAdmin: {
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
        verifyConnectFamily: {
          isLoading: false,
          isSuccess: false,
          isError: false,
        },
      };

    case ActionType.UPDATE_STATUS_SCHOOL_REQUEST:
      return {
        ...state,
        isLoadingActionSchool: true,
      };
    case ActionType.UPDATE_STATUS_SCHOOL_SUCCESS:
      return {
        ...state,
        isLoadingActionSchool: false,
        isSuccessActionSchool: true,
      };

    case ActionType.UPDATE_STATUS_SCHOOL_FAILED:
      return {
        ...state,
        isLoadingActionSchool: false,
        isErrorActionSchool: true,
      };

    case ActionType.GET_LIST_CLASS_BY_SCHOOL_ID_REQUEST:
      return {
        ...state,
        listClass: {
          ...state.listClass,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_CLASS_BY_SCHOOL_ID_SUCCESS:
      return {
        ...state,
        listClass: {
          ...state.listClass,
          data: listClassDTO(payload.data),
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_CLASS_BY_SCHOOL_ID_FAILED:
      return {
        ...state,
        listClass: {
          ...state.listClass,
          isLoading: false,
        },
      };

    case ActionType.FIND_SCHOOL_REQUEST:
      return {
        ...state,
        listFindSchool: {
          ...state.listFindSchool,
          isLoading: true,
        },
      };
    case ActionType.FIND_SCHOOL_SUCCESS:
      return {
        ...state,
        listFindSchool: {
          ...state.listFindSchool,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.FIND_SCHOOL_FAILED:
      return {
        ...state,
        listFindSchool: {
          ...state.listFindSchool,
          isLoading: false,
        },
      };

    case ActionType.GET_DETAIL_SCHOOL_REQUEST:
      return {
        ...state,
        detailSchool: {
          ...state.detailSchool,
          isLoading: true,
        },
      };
    case ActionType.GET_DETAIL_SCHOOL_SUCCESS:
      return {
        ...state,
        detailSchool: {
          ...state.detailSchool,
          data: schoolDTO(payload.data),
          isLoading: false,
        },
      };
    case ActionType.GET_DETAIL_SCHOOL_FAILED:
      return {
        ...state,
        detailSchool: {
          ...state.detailSchool,
          isLoading: false,
        },
      };

    case ActionType.GET_SUMMARY_SCHOOL_REQUEST:
      return {
        ...state,
        summarySchool: {
          ...state.summarySchool,
          isLoading: true,
        },
      };
    case ActionType.GET_SUMMARY_SCHOOL_SUCCESS:
      return {
        ...state,
        summarySchool: {
          ...state.summarySchool,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_SUMMARY_SCHOOL_FAILED:
      return {
        ...state,
        summarySchool: {
          ...state.summarySchool,
          isLoading: false,
        },
      };

    case ActionType.GET_ACTIVITY_SCHOOL_REQUEST:
      return {
        ...state,
        activitySchool: {
          ...state.activitySchool,
          isLoading: true,
        },
      };
    case ActionType.GET_ACTIVITY_SCHOOL_SUCCESS:
      return {
        ...state,
        activitySchool: {
          ...state.activitySchool,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_ACTIVITY_SCHOOL_FAILED:
      return {
        ...state,
        activitySchool: {
          ...state.activitySchool,
          isLoading: false,
        },
      };

    case ActionType.GET_LIST_OF_READ_SCHOOL_REQUEST:
      return {
        ...state,
        listOfReadSchool: {
          ...state.listOfReadSchool,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_OF_READ_SCHOOL_SUCCESS:
      return {
        ...state,
        listOfReadSchool: {
          ...state.listOfReadSchool,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_OF_READ_SCHOOL_FAILED:
      return {
        ...state,
        listOfReadSchool: {
          ...state.listOfReadSchool,
          isLoading: false,
        },
      };

    case ActionType.GET_LIST_OF_READ_STUDENT_REQUEST:
      return {
        ...state,
        listOfReadStudent: {
          ...state.listOfReadStudent,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_OF_READ_STUDENT_SUCCESS:
      return {
        ...state,
        listOfReadStudent: {
          ...state.listOfReadStudent,
          data: payload.data,
          isLoading: false,
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_OF_READ_STUDENT_FAILED:
      return {
        ...state,
        listOfReadStudent: {
          ...state.listOfReadStudent,
          isLoading: false,
        },
      };

    case ActionType.GET_READ_HISTORY_SCHOOL_REQUEST:
      return {
        ...state,
        readHistorySchool: {
          ...state.readHistorySchool,
          isLoading: true,
        },
      };
    case ActionType.GET_READ_HISTORY_SCHOOL_SUCCESS:
      return {
        ...state,
        readHistorySchool: {
          ...state.readHistorySchool,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_READ_HISTORY_SCHOOL_FAILED:
      return {
        ...state,
        readHistorySchool: {
          ...state.readHistorySchool,
          isLoading: false,
        },
      };

    case ActionType.GET_DETAIL_READ_HISTORY_SCHOOL_REQUEST:
      return {
        ...state,
        detailReadHistorySchool: {
          ...state.detailReadHistorySchool,
          isLoading: true,
        },
      };
    case ActionType.GET_DETAIL_READ_HISTORY_SCHOOL_SUCCESS:
      return {
        ...state,
        detailReadHistorySchool: {
          ...state.detailReadHistorySchool,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_DETAIL_READ_HISTORY_SCHOOL_FAILED:
      return {
        ...state,
        detailReadHistorySchool: {
          ...state.detailReadHistorySchool,
          isLoading: false,
        },
      };

    case ActionType.CREATE_CLASS_REQUEST:
    case ActionType.UPDATE_CLASS_REQUEST:
    case ActionType.DELETE_CLASS_REQUEST:
    case ActionType.CREATE_TEACHER_REQUEST:
    case ActionType.UPDATE_STATUS_TEACHER_REQUEST:
    case ActionType.UPDATE_TEACHER_REQUEST:
    case ActionType.DELETE_TEACHER_REQUEST:
    case ActionType.SET_CLASS_TEACHER_REQUEST:
    case ActionType.SET_CLASSES_STUDENT_REQUEST:
    case ActionType.DELETE_STUDENT_REQUEST:
    case ActionType.UPDATE_STATUS_STUDENT_REQUEST:
    case ActionType.SET_BULK_ACTIVE_TEACHER_REQUEST:
    case ActionType.SET_BULK_ACTIVE_STUDENT_REQUEST:
    case ActionType.CREATE_STUDENT_REQUEST:
    case ActionType.UPDATE_STUDENT_REQUEST:
    case ActionType.SET_CLASS_STUDENT_REQUEST:
    case ActionType.CONNECT_ACCOUNT_STUDENT_REQUEST:
      return {
        ...state,
        isSuccessActionSchool: false,
        isErrorActionSchool: false,
        isLoadingActionSchool: true,
      };
    case ActionType.UPDATE_CLASS_SUCCESS:
    case ActionType.DELETE_CLASS_SUCCESS:
    case ActionType.CREATE_CLASS_SUCCESS:
    case ActionType.CREATE_TEACHER_SUCCESS:
    case ActionType.UPDATE_STATUS_TEACHER_SUCCESS:
    case ActionType.UPDATE_TEACHER_SUCCESS:
    case ActionType.DELETE_TEACHER_SUCCESS:
    case ActionType.SET_CLASS_TEACHER_SUCCESS:
    case ActionType.SET_CLASSES_STUDENT_SUCCESS:
    case ActionType.DELETE_STUDENT_SUCCESS:
    case ActionType.UPDATE_STATUS_STUDENT_SUCCESS:
    case ActionType.SET_BULK_ACTIVE_TEACHER_SUCCESS:
    case ActionType.SET_BULK_ACTIVE_STUDENT_SUCCESS:
    case ActionType.CREATE_STUDENT_SUCCESS:
    case ActionType.UPDATE_STUDENT_SUCCESS:
    case ActionType.SET_CLASS_STUDENT_SUCCESS:
    case ActionType.CONNECT_ACCOUNT_STUDENT_SUCCESS:
      return {
        ...state,
        isLoadingActionSchool: false,
        isSuccessActionSchool: true,
      };

    case ActionType.CREATE_CLASS_FAILED:
    case ActionType.UPDATE_CLASS_FAILED:
    case ActionType.DELETE_CLASS_FAILED:
    case ActionType.CREATE_TEACHER_FAILED:
    case ActionType.UPDATE_STATUS_TEACHER_FAILED:
    case ActionType.UPDATE_TEACHER_FAILED:
    case ActionType.DELETE_TEACHER_FAILED:
    case ActionType.SET_CLASS_TEACHER_FAILED:
    case ActionType.SET_CLASSES_STUDENT_FAILED:
    case ActionType.DELETE_STUDENT_FAILED:
    case ActionType.UPDATE_STATUS_STUDENT_FAILED:
    case ActionType.SET_BULK_ACTIVE_TEACHER_FAILED:
    case ActionType.SET_BULK_ACTIVE_STUDENT_FAILED:
    case ActionType.CREATE_STUDENT_FAILED:
    case ActionType.UPDATE_STUDENT_FAILED:
    case ActionType.SET_CLASS_STUDENT_FAILED:
    case ActionType.CONNECT_ACCOUNT_STUDENT_FAILED:
      return {
        ...state,
        isLoadingActionSchool: false,
        isErrorActionSchool: true,
      };

    case ActionType.GET_LIST_FAMILY_REQUEST:
      return {
        ...state,
        listFamily: {
          ...state.listFamily,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_FAMILY_SUCCESS:
      return {
        ...state,
        listFamily: {
          ...state.listFamily,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_FAMILY_FAILED:
      return {
        ...state,
        listFamily: {
          ...state.listFamily,
          isLoading: false,
        },
      };

    case ActionType.GET_CHILD_FAMILY_REQUEST:
      return {
        ...state,
        childFamily: {
          ...state.childFamily,
          isLoading: true,
        },
      };
    case ActionType.GET_CHILD_FAMILY_SUCCESS:
      return {
        ...state,
        childFamily: {
          ...state.childFamily,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_CHILD_FAMILY_FAILED:
      return {
        ...state,
        childFamily: {
          ...state.childFamily,
          isLoading: false,
        },
      };

    case ActionType.SET_ACCOUNT_TO_FAMILY_REQUEST:
      return {
        ...state,
        setAccountToFamily: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.SET_ACCOUNT_TO_FAMILY_SUCCESS:
      return {
        ...state,
        setAccountToFamily: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.SET_ACCOUNT_TO_FAMILY_FAILED:
      return {
        ...state,
        setAccountToFamily: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.SET_ACCOUNT_TO_FAMILY_ADMIN_REQUEST:
      return {
        ...state,
        setAccountToFamilyAdmin: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.SET_ACCOUNT_TO_FAMILY_ADMIN_SUCCESS:
      return {
        ...state,
        setAccountToFamilyAdmin: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.SET_ACCOUNT_TO_FAMILY_ADMIN_FAILED:
      return {
        ...state,
        setAccountToFamilyAdmin: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.VERIFY_CONNECT_FAMILY_REQUEST:
      return {
        ...state,
        verifyConnectFamily: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.VERIFY_CONNECT_FAMILY_SUCCESS:
      return {
        ...state,
        verifyConnectFamily: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.VERIFY_CONNECT_FAMILY_FAILED:
      return {
        ...state,
        verifyConnectFamily: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_REQUEST:
      return {
        ...state,
        detailConnectionByStudentId: {
          ...state.detailConnectionByStudentId,
          isLoading: true,
        },
      };
    case ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_SUCCESS:
      return {
        ...state,
        detailConnectionByStudentId: {
          ...state.detailConnectionByStudentId,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_FAILED:
      return {
        ...state,
        detailConnectionByStudentId: {
          ...state.detailConnectionByStudentId,
          isLoading: false,
        },
      };

    case ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_REQUEST:
      return {
        ...state,
        detailConnectionByStudentIdAdmin: {
          ...state.detailConnectionByStudentIdAdmin,
          isLoading: true,
        },
      };
    case ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_SUCCESS:
      return {
        ...state,
        detailConnectionByStudentIdAdmin: {
          ...state.detailConnectionByStudentIdAdmin,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_FAILED:
      return {
        ...state,
        detailConnectionByStudentIdAdmin: {
          ...state.detailConnectionByStudentIdAdmin,
          isLoading: false,
        },
      };

    default: {
      return state;
    }
  }
};

export default schoolReducer;
