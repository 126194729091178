import axios from "axios";

const refreshToken = localStorage.getItem("@refresh_token");

axios.interceptors.request.use(
  (config) => {
    const newConfig = {
      ...config,
      ...{
        baseURL: `${process.env.REACT_APP_BASE_URL}`,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("@token")}` || "",
        },
      },
    };
    return newConfig;
  },
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === "/public/token/refresh"
    ) {
      localStorage.clear();
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .post("/public/token/refresh", { refreshToken })
        .then((res) => {
          const { status, data } = res.data;
          if (status === 200) {
            // 1) put token to LocalStorage
            localStorage.setItem("@token", data.accessToken);
            // 2) Change Authorization header
            axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
              "@token"
            )}`;
            // 3) return originalRequest object with Axios.
            return axios(originalRequest);
          }
        })
        .catch((err) => {
          localStorage.clear();
          window.location.reload();
          return Promise.reject(err);
        });
    }

    return error;
  }
);

// const httpRequest = new HttpRequest(process.env.REACT_APP_BASE_URL);

export default axios;
