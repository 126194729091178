import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Inbox";
import * as Api from "../../api/inbox";

import {
  getListAppInboxSuccess,
  getListAppInboxFailed,
  createAppInboxSuccess,
  createAppInboxFailed,
  sendAppInboxSuccess,
  sendAppInboxFailed,
  getAppInboxByIdSuccess,
  getAppInboxByIdFailed,
  updateAppInboxSuccess,
  updateAppInboxFailed,
} from "../actions/Inbox";

import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";

function* getListAppInbox({ payload }) {
  try {
    const resultListAppInbox = yield call(Api.fetchListAppInbox, {
      params: { ...payload.params },
    });
    yield put(
      getListAppInboxSuccess(
        { ...resultListAppInbox, status: payload?.params?.status } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list app inbox", "Failed");
    yield put(getListAppInboxFailed());
  }
}

export function* watchListAppInboxRequest() {
  yield takeEvery(ActionType.GET_LIST_APP_INBOX_REQUEST, getListAppInbox);
}

function* createAppInbox({ payload }) {
  try {
    const resultCreateAppInbox = yield call(Api.fetchCreateAppInbox, payload);
    if (resultCreateAppInbox.success === true) {
      yield put(createAppInboxSuccess(resultCreateAppInbox.data));
      successNotification("Create app inbox", "Success");
    } else {
      yield put(createAppInboxFailed(resultCreateAppInbox || {}));
      resultCreateAppInbox?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create app inbox", "Failed");
    yield put(createAppInboxFailed());
  }
}

export function* watchCreateAppInboxRequest() {
  yield takeEvery(ActionType.CREATE_APP_INBOX_REQUEST, createAppInbox);
}

function* sendAppInbox({ payload }) {
  try {
    const resultSendAppInbox = yield call(Api.fetchSendAppInbox, payload);
    if (resultSendAppInbox.success === true) {
      yield put(sendAppInboxSuccess(resultSendAppInbox.data));
      successNotification("Send app inbox", "Success");
    } else {
      yield put(sendAppInboxFailed(resultSendAppInbox || {}));
      resultSendAppInbox?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Send app inbox", "Failed");
    yield put(sendAppInboxFailed());
  }
}

export function* watchSendAppInboxRequest() {
  yield takeEvery(ActionType.SEND_APP_INBOX_REQUEST, sendAppInbox);
}

function* getAppInboxById({ payload }) {
  try {
    const resultAppInbox = yield call(Api.fetchAppInboxById, {
      appInboxId: payload?.appInboxId,
    });
    yield put(getAppInboxByIdSuccess({ ...resultAppInbox } || {}));
  } catch (error) {
    errorNotification("Get app inbox by id", "Failed");
    yield put(getAppInboxByIdFailed());
  }
}

export function* watchGetAppInboxByIdRequest() {
  yield takeEvery(ActionType.GET_APP_INBOX_BY_ID_REQUEST, getAppInboxById);
}

function* updateAppInbox({ payload }) {
  try {
    const resultUpdateAppInbox = yield call(Api.fetchUpdateAppInbox, payload);
    yield put(updateAppInboxSuccess());
    if (resultUpdateAppInbox.success === true) {
      successNotification("Update app inbox", "Success");
    } else {
      resultUpdateAppInbox?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update app inbox", "Failed");
    yield put(updateAppInboxFailed());
  }
}

export function* watchUpdateAppInboxRequest() {
  yield takeEvery(ActionType.UPDATE_APP_INBOX_REQUEST, updateAppInbox);
}

export default function* rootSaga() {
  yield all([
    fork(watchListAppInboxRequest),
    fork(watchCreateAppInboxRequest),
    fork(watchSendAppInboxRequest),
    fork(watchGetAppInboxByIdRequest),
    fork(watchUpdateAppInboxRequest),
  ]);
}
