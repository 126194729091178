import moment from "moment";
import { useEffect, useState } from "react";

const useShowAlert = () => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const startDate = moment("2022-06-15", "YYYY-MM-DD");
    const endDate = moment("2022-08-07", "YYYY-MM-DD");
    if (moment().isAfter(startDate) && moment().isBefore(endDate)) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, []);

  return [showAlert];
};

export default useShowAlert;
