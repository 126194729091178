import httpRequest from "../util/axios";

export const fetchLogin = async (data = {}) => {
  try {
    const result = await httpRequest.post("/admin/public/login", data);

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchLoginSchool = async (data = {}) => {
  try {
    const result = await httpRequest.post("/admin/public/login-school", data);

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchLoginPublisher = async (data = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/public/login-publisher",
      data
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
