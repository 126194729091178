import httpRequest from "../util/axios";

export const fetchListVoucher = async (option = {}) => {
  try {
    const result = await httpRequest.get("/admin/vouchers", option, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateVoucher = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/vouchers",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateVoucher = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/vouchers/${body?.voucherId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSummaryVoucher = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/vouchers/summary-by-year",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusVoucher = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/vouchers/${body?.voucherId}/status`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
