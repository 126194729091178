import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Voucher";
import * as Api from "../../api/voucher";

import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";
import {
  getListVoucherSuccess,
  getListVoucherFailed,
  createVoucherSuccess,
  createVoucherFailed,
  updateVoucherSuccess,
  updateVoucherFailed,
  getSummaryVoucherSuccess,
  getSummaryVoucherFailed,
  updateStatusVoucherSuccess,
  updateStatusVoucherFailed,
} from "../actions";

function* getListVoucher({ payload }) {
  try {
    const resultListVoucher = yield call(Api.fetchListVoucher, {
      params: { ...payload.params },
    });
    yield put(
      getListVoucherSuccess(
        { ...resultListVoucher, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list voucher", "Failed");
    yield put(getListVoucherFailed());
  }
}

export function* watchListVoucherRequest() {
  yield takeEvery(ActionType.GET_LIST_VOUCHER_REQUEST, getListVoucher);
}

function* createVoucher({ payload }) {
  try {
    const resultCreateVoucher = yield call(Api.fetchCreateVoucher, payload);
    if (resultCreateVoucher.success === true) {
      yield put(createVoucherSuccess(resultCreateVoucher.data));
      successNotification("Create voucher", "Success");
    } else {
      yield put(createVoucherFailed(resultCreateVoucher || {}));
      resultCreateVoucher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create voucher", "Failed");
    yield put(createVoucherFailed());
  }
}

export function* watchCreateVoucherRequest() {
  yield takeEvery(ActionType.CREATE_VOUCHER_REQUEST, createVoucher);
}

function* updateVoucher({ payload }) {
  try {
    const resultUpdateVoucher = yield call(Api.fetchUpdateVoucher, payload);
    if (resultUpdateVoucher.success === true) {
      yield put(updateVoucherSuccess(resultUpdateVoucher.data));
      successNotification("Update voucher", "Success");
    } else {
      yield put(updateVoucherFailed(resultUpdateVoucher || {}));
      resultUpdateVoucher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update voucher", "Failed");
    yield put(updateVoucherFailed());
  }
}

export function* watchUpdateVoucherRequest() {
  yield takeEvery(ActionType.UPDATE_VOUCHER_REQUEST, updateVoucher);
}

function* getSummaryVoucher() {
  try {
    const resultSummaryVoucher = yield call(Api.fetchSummaryVoucher, {});
    yield put(getSummaryVoucherSuccess(resultSummaryVoucher || {}));
  } catch (error) {
    errorNotification("Get summary voucher", "Failed");
    yield put(getSummaryVoucherFailed());
  }
}

export function* watchSummaryVoucherRequest() {
  yield takeEvery(ActionType.GET_SUMMARY_VOUCHER_REQUEST, getSummaryVoucher);
}

function* updateStatusVoucher({ payload }) {
  try {
    const resultUpdateStatusVoucher = yield call(
      Api.fetchUpdateStatusVoucher,
      payload
    );
    if (resultUpdateStatusVoucher.success === true) {
      yield put(updateStatusVoucherSuccess(resultUpdateStatusVoucher.data));
      successNotification("Update Status voucher", "Success");
    } else {
      yield put(updateStatusVoucherFailed(resultUpdateStatusVoucher || {}));
      resultUpdateStatusVoucher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status voucher", "Failed");
    yield put(updateStatusVoucherFailed());
  }
}

export function* watchUpdateStatusVoucherRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_VOUCHER_REQUEST,
    updateStatusVoucher
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchListVoucherRequest),
    fork(watchCreateVoucherRequest),
    fork(watchUpdateVoucherRequest),
    fork(watchSummaryVoucherRequest),
    fork(watchUpdateStatusVoucherRequest),
  ]);
}
