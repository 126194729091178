import * as ActionType from "../constants/Collection";

export const getListCollectionRequest = (payload) => ({
  type: ActionType.GET_LIST_COLLECTION_REQUEST,
  payload,
});

export const getListCollectionSuccess = (payload) => ({
  type: ActionType.GET_LIST_COLLECTION_SUCCESS,
  payload,
});

export const getListCollectionFailed = (payload) => ({
  type: ActionType.GET_LIST_COLLECTION_FAILED,
  payload,
});

export const publishCollectionRequest = (payload) => ({
  type: ActionType.PUBLISH_COLLECTION_REQUEST,
  payload,
});

export const publishCollectionSuccess = (payload) => ({
  type: ActionType.PUBLISH_COLLECTION_SUCCESS,
  payload,
});

export const publishCollectionFailed = (payload) => ({
  type: ActionType.PUBLISH_COLLECTION_FAILED,
  payload,
});

export const updateStatusCollectionRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_COLLECTION_REQUEST,
  payload,
});

export const updateStatusCollectionSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_COLLECTION_SUCCESS,
  payload,
});

export const updateStatusCollectionFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_COLLECTION_FAILED,
  payload,
});

export const createCollectionRequest = (payload) => ({
  type: ActionType.CREATE_COLLECTION_REQUEST,
  payload,
});

export const createCollectionSuccess = (payload) => ({
  type: ActionType.CREATE_COLLECTION_SUCCESS,
  payload,
});

export const createCollectionFailed = (payload) => ({
  type: ActionType.CREATE_COLLECTION_FAILED,
  payload,
});

export const getCollectionByIdRequest = (payload) => ({
  type: ActionType.GET_COLLECTION_BY_ID_REQUEST,
  payload,
});

export const getCollectionByIdSuccess = (payload) => ({
  type: ActionType.GET_COLLECTION_BY_ID_SUCCESS,
  payload,
});

export const getCollectionByIdFailed = (payload) => ({
  type: ActionType.GET_COLLECTION_BY_ID_FAILED,
  payload,
});

export const updateCollectionRequest = (payload) => ({
  type: ActionType.UPDATE_COLLECTION_REQUEST,
  payload,
});

export const updateCollectionSuccess = (payload) => ({
  type: ActionType.UPDATE_COLLECTION_SUCCESS,
  payload,
});

export const updateCollectionFailed = (payload) => ({
  type: ActionType.UPDATE_COLLECTION_FAILED,
  payload,
});
