export const GET_LIST_VOUCHER_REQUEST = "GET_LIST_VOUCHER_REQUEST";
export const GET_LIST_VOUCHER_SUCCESS = "GET_LIST_VOUCHER_SUCCESS";
export const GET_LIST_VOUCHER_FAILED = "GET_LIST_VOUCHER_FAILED";

export const CREATE_VOUCHER_REQUEST = "CREATE_VOUCHER_REQUEST";
export const CREATE_VOUCHER_SUCCESS = "CREATE_VOUCHER_SUCCESS";
export const CREATE_VOUCHER_FAILED = "CREATE_VOUCHER_FAILED";

export const UPDATE_VOUCHER_REQUEST = "UPDATE_VOUCHER_REQUEST";
export const UPDATE_VOUCHER_SUCCESS = "UPDATE_VOUCHER_SUCCESS";
export const UPDATE_VOUCHER_FAILED = "UPDATE_VOUCHER_FAILED";

export const GET_SUMMARY_VOUCHER_REQUEST = "GET_SUMMARY_VOUCHER_REQUEST";
export const GET_SUMMARY_VOUCHER_SUCCESS = "GET_SUMMARY_VOUCHER_SUCCESS";
export const GET_SUMMARY_VOUCHER_FAILED = "GET_SUMMARY_VOUCHER_FAILED";

export const UPDATE_STATUS_VOUCHER_REQUEST = "UPDATE_STATUS_VOUCHER_REQUEST";
export const UPDATE_STATUS_VOUCHER_SUCCESS = "UPDATE_STATUS_VOUCHER_SUCCESS";
export const UPDATE_STATUS_VOUCHER_FAILED = "UPDATE_STATUS_VOUCHER_FAILED";
