import * as ActionType from "../constants/Profile";

export const getMyProfileRequest = (payload) => ({
  type: ActionType.GET_MY_PROFILE_REQUEST,
  payload,
});

export const getMyProfileSuccess = (payload) => ({
  type: ActionType.GET_MY_PROFILE_SUCCESS,
  payload,
});

export const getMyProfileFailed = (payload) => ({
  type: ActionType.GET_MY_PROFILE_FAILED,
  payload,
});

export const clearMyProfileRequest = (payload) => ({
  type: ActionType.CLEAR_MY_PROFILE_REQUEST,
  payload,
});
