import * as ActionType from "../constants/Segmentation";

export const getListSegmentationRequest = (payload) => ({
  type: ActionType.GET_LIST_SEGMENTATION_REQUEST,
  payload,
});

export const getListSegmentationSuccess = (payload) => ({
  type: ActionType.GET_LIST_SEGMENTATION_SUCCESS,
  payload,
});

export const getListSegmentationFailed = (payload) => ({
  type: ActionType.GET_LIST_SEGMENTATION_FAILED,
  payload,
});
