export const GET_LIST_COLLECTION_REQUEST = "GET_LIST_COLLECTION_REQUEST";
export const GET_LIST_COLLECTION_SUCCESS = "GET_LIST_COLLECTION_SUCCESS";
export const GET_LIST_COLLECTION_FAILED = "GET_LIST_COLLECTION_FAILED";

export const PUBLISH_COLLECTION_REQUEST = "PUBLISH_COLLECTION_REQUEST";
export const PUBLISH_COLLECTION_SUCCESS = "PUBLISH_COLLECTION_SUCCESS";
export const PUBLISH_COLLECTION_FAILED = "PUBLISH_COLLECTION_FAILED";

export const UPDATE_STATUS_COLLECTION_REQUEST =
  "UPDATE_STATUS_COLLECTION_REQUEST";
export const UPDATE_STATUS_COLLECTION_SUCCESS =
  "UPDATE_STATUS_COLLECTION_SUCCESS";
export const UPDATE_STATUS_COLLECTION_FAILED =
  "UPDATE_STATUS_COLLECTION_FAILED";

export const CREATE_COLLECTION_REQUEST = "CREATE_COLLECTION_REQUEST";
export const CREATE_COLLECTION_SUCCESS = "CREATE_COLLECTION_SUCCESS";
export const CREATE_COLLECTION_FAILED = "CREATE_COLLECTION_FAILED";

export const GET_COLLECTION_BY_ID_REQUEST = "GET_COLLECTION_BY_ID_REQUEST";
export const GET_COLLECTION_BY_ID_SUCCESS = "GET_COLLECTION_BY_ID_SUCCESS";
export const GET_COLLECTION_BY_ID_FAILED = "GET_COLLECTION_BY_ID_FAILED";

export const UPDATE_COLLECTION_REQUEST = "UPDATE_COLLECTION_REQUEST";
export const UPDATE_COLLECTION_SUCCESS = "UPDATE_COLLECTION_SUCCESS";
export const UPDATE_COLLECTION_FAILED = "UPDATE_COLLECTION_FAILED";
