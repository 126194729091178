import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-custom">
    <Switch>
      <Route
        path={`${match.url}user-activation/reading-challenge`}
        component={asyncComponent(() =>
          import("./UserActivation/ReadingChallenge")
        )}
      />
      <Route
        path={`${match.url}dashboard/edit-info`}
        component={asyncComponent(() => import("./Dashboard/Publisher/Edit"))}
      />
      <Route
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />
      <Route
        path={`${match.url}staff-roles`}
        component={asyncComponent(() => import("./AdminRBAC"))}
      />
      <Route
        exact
        path={`${match.url}manage-content`}
        component={asyncComponent(() => import("./ManageContent"))}
      />
      <Route
        exact
        path={`${match.url}user-account/content-partner`}
        component={asyncComponent(() => import("./ContentPartner/List"))}
      />
      <Route
        exact
        path={`${match.url}user-account/school-free-trial`}
        component={asyncComponent(() =>
          import("./UserManagement/ManageAccount/SchoolFreeTrial/List")
        )}
      />
      <Route
        exact
        path={`${match.url}user-account/content-partner/add`}
        component={asyncComponent(() => import("./ContentPartner/Form"))}
      />
      <Route
        exact
        path={`${match.url}user-account/content-partner/edit/:id`}
        component={asyncComponent(() => import("./ContentPartner/Form"))}
      />
      <Route
        path={`${match.url}content-management/content`}
        component={asyncComponent(() => import("./ContentManagement/Content"))}
      />
      <Route
        path={`${match.url}user-activation/educational-resource`}
        component={asyncComponent(() =>
          import("./ContentManagement/EducationalResource")
        )}
      />
      <Route
        path={`${match.url}user-account/manage-account`}
        component={asyncComponent(() =>
          import("./UserManagement/ManageAccount")
        )}
      />
      <Route
        path={`${match.url}billing-management/account`}
        component={asyncComponent(() => import("./BillingManagement/Account"))}
      />
      <Route
        path={`${match.url}billing-management/pricing-plan`}
        component={asyncComponent(() =>
          import("./BillingManagement/Account/PricingPlan/List")
        )}
      />
      <Route
        path={`${match.url}billing-management/royalty-statement`}
        component={asyncComponent(() =>
          import("./BillingManagement/RoyaltyStatement")
        )}
      />
      <Route
        path={`${match.url}user-activation/app-inbox/edit/:id`}
        component={asyncComponent(() =>
          import("./UserEngagement/AppInbox/Form")
        )}
      />
      <Route
        path={`${match.url}user-engagement/data-analysis`}
        component={asyncComponent(() =>
          import("./UserEngagement/DataAnalysis")
        )}
      />
      <Route
        path={`${match.url}user-activation/app-inbox/add`}
        component={asyncComponent(() =>
          import("./UserEngagement/AppInbox/Form")
        )}
      />
      <Route
        path={`${match.url}user-activation/app-inbox`}
        component={asyncComponent(() =>
          import("./UserEngagement/AppInbox/List")
        )}
      />
      <Route
        path={`${match.url}user-account/account-segmentation`}
        component={asyncComponent(() =>
          import("./UserEngagement/AccountSegmentation")
        )}
      />
      <Route
        path={`${match.url}billing-management/voucher`}
        component={asyncComponent(() => import("./PromoManagement/Voucher"))}
      />
      <Route
        exact
        path={`${match.url}user-support-faq`}
        component={asyncComponent(() => import("./UserSupportFAQ/List"))}
      />
      <Route
        exact
        path={`${match.url}content-display/slide-banner`}
        component={asyncComponent(() =>
          import("./ContentManagement/Recommendations/SlideBanner/List")
        )}
      />
      <Route
        exact
        path={`${match.url}content-display/recommendation-row`}
        component={asyncComponent(() =>
          import("./ContentManagement/Recommendations/RecommendationRow/List")
        )}
      />
      <Route
        exact
        path={`${match.url}content-display/recommendation-row/add`}
        component={asyncComponent(() =>
          import("./ContentManagement/Recommendations/RecommendationRow/Form")
        )}
      />
      <Route
        exact
        path={`${match.url}content-display/recommendation-row/:id`}
        component={asyncComponent(() =>
          import("./ContentManagement/Recommendations/RecommendationRow/Form")
        )}
      />
      <Route
        exact
        path={`${match.url}campaign`}
        component={asyncComponent(() => import("./Campaign/List"))}
      />
    </Switch>
  </div>
);

export default App;
