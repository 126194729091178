import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Recommendation";
import * as Api from "../../api/recommendation";

import {
  getListRecommendationSuccess,
  getListRecommendationFailed,
  createRecommendationSuccess,
  createRecommendationFailed,
  getRecommendationByIdSuccess,
  getRecommendationByIdFailed,
  updateSortRecommendationSuccess,
  updateSortRecommendationFailed,
  updateRecommendationSuccess,
  updateRecommendationFailed,
  updateStatusRecommendationSuccess,
  updateStatusRecommendationFailed,
} from "../actions/Recommendation";
import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";

function* getListRecommendation({ payload }) {
  try {
    const resultListRecommendation = yield call(Api.fetchListRecommendation, {
      params: { ...payload.params },
    });
    yield put(
      getListRecommendationSuccess(
        {
          ...resultListRecommendation,
          limit: payload?.params?.limit,
          type: payload?.params?.type,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list recommendation", "Failed");
    yield put(getListRecommendationFailed());
  }
}

export function* watchListRecommendationRequest() {
  yield takeEvery(
    ActionType.GET_LIST_RECOMMENDATION_REQUEST,
    getListRecommendation
  );
}

function* createRecommendation({ payload }) {
  try {
    const resultCreateRecommendation = yield call(
      Api.fetchCreateRecommendation,
      payload
    );
    if (resultCreateRecommendation.success === true) {
      yield put(createRecommendationSuccess(resultCreateRecommendation.data));
      successNotification("Create recommendation", "Success");
    } else {
      yield put(createRecommendationFailed(resultCreateRecommendation || {}));
      resultCreateRecommendation?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create recommendation", "Failed");
    yield put(createRecommendationFailed());
  }
}

export function* watchCreateRecommendationRequest() {
  yield takeEvery(
    ActionType.CREATE_RECOMMENDATION_REQUEST,
    createRecommendation
  );
}

function* getRecommendationById({ payload }) {
  try {
    const resultRecommendation = yield call(
      Api.fetchRecommendationById,
      payload
    );
    yield put(getRecommendationByIdSuccess({ ...resultRecommendation } || {}));
  } catch (error) {
    errorNotification("Get recommendation by id", "Failed");
    yield put(getRecommendationByIdFailed());
  }
}

export function* watchGetRecommendationByIdRequest() {
  yield takeEvery(
    ActionType.GET_RECOMMENDATION_BY_ID_REQUEST,
    getRecommendationById
  );
}

function* updateSortRecommendation({ payload }) {
  try {
    const resultUpdateSortRecommendation = yield call(
      Api.fetchUpdateSortRecommendation,
      payload
    );
    if (resultUpdateSortRecommendation.success === true) {
      yield put(
        updateSortRecommendationSuccess(resultUpdateSortRecommendation.data)
      );
      successNotification("Update sort recommendation", "Success");
    } else {
      yield put(
        updateSortRecommendationFailed(resultUpdateSortRecommendation || {})
      );
      resultUpdateSortRecommendation?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update sort recommendation", "Failed");
    yield put(updateSortRecommendationFailed());
  }
}

export function* watchUpdateSortRecommendationRequest() {
  yield takeEvery(
    ActionType.UPDATE_SORT_RECOMMENDATION_REQUEST,
    updateSortRecommendation
  );
}

function* updateRecommendation({ payload }) {
  try {
    const resultUpdateRecommendation = yield call(
      Api.fetchUpdateRecommendation,
      payload
    );
    if (resultUpdateRecommendation.success === true) {
      yield put(updateRecommendationSuccess(resultUpdateRecommendation.data));
      successNotification("Update recommendation", "Success");
    } else {
      yield put(updateRecommendationFailed(resultUpdateRecommendation || {}));
      resultUpdateRecommendation?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update recommendation", "Failed");
    yield put(updateRecommendationFailed());
  }
}

export function* watchUpdateRecommendationRequest() {
  yield takeEvery(
    ActionType.UPDATE_RECOMMENDATION_REQUEST,
    updateRecommendation
  );
}

function* updateStatusRecommendation({ payload }) {
  try {
    const resultUpdateStatusRecommendation = yield call(
      Api.fetchUpdateStatusRecommendation,
      payload
    );
    if (resultUpdateStatusRecommendation.success === true) {
      yield put(
        updateStatusRecommendationSuccess(resultUpdateStatusRecommendation.data)
      );
      successNotification("Update Status recommendation", "Success");
    } else {
      yield put(
        updateStatusRecommendationFailed(resultUpdateStatusRecommendation || {})
      );
      resultUpdateStatusRecommendation?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status recommendation", "Failed");
    yield put(updateStatusRecommendationFailed());
  }
}

export function* watchUpdateStatusRecommendationRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_RECOMMENDATION_REQUEST,
    updateStatusRecommendation
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchListRecommendationRequest),
    fork(watchCreateRecommendationRequest),
    fork(watchGetRecommendationByIdRequest),
    fork(watchUpdateSortRecommendationRequest),
    fork(watchUpdateRecommendationRequest),
    fork(watchUpdateStatusRecommendationRequest),
  ]);
}
