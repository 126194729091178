import httpRequest from "../util/axios";

export const getListSchoolFreeTrial = async (option = {}) => {
  try {
    const res = await httpRequest.get(
      "/admin/schools/free-trials",
      option,
      null,
      {}
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return res.response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const getSchoolFreeTrialByRequestId = async (option = {}) => {
  try {
    const res = await httpRequest.get(
      `/admin/schools/free-trials/${option?.schoolRequestId}`,
      option,
      null,
      {}
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return res.response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const updateStatusSchoolFreeTrial = async (id = null, body = {}) => {
  try {
    const res = await httpRequest.put(
      `/admin/schools/free-trials/${id}/status`,
      body,
      null,
      {}
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return res.response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const updateSchoolFreeTrial = async (id = null, body = {}) => {
  try {
    const res = await httpRequest.put(
      `/admin/schools/free-trials/${id}`,
      body,
      null,
      {}
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return res.response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const deleteSchoolFreeTrial = async (id) => {
  try {
    const res = await httpRequest.delete(
      `/admin/schools/free-trials/${id}`,
      {},
      null,
      {}
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return res.response.data;
    }
  } catch (err) {
    throw err;
  }
};
