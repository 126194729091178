import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import notesSagas from "./Notes";
import bookSaga from "./Book";
import profileSaga from "./Profile";
import schoolSaga from "./School";
import collectionSaga from "./Collection";
import userSaga from "./User";
import publisherSaga from "./Publisher";
import categorySaga from "./Category";
import transactionSaga from "./Transaction";
import bannerSaga from "./Banner";
import voucherSaga from "./Voucher";
import faqSaga from "./Faq";
import recomenndationSaga from "./Recommendation";
import segmentationSaga from "./Segmentation";
import appInboxSaga from "./Inbox";
import activitySheet from "./ActivitySheet";
import dashboardSaga from "./Dashboard";
import readingSaga from "./Reading";
import rbacSaga from "./Rbac";
import campaignSaga from "./Campaign";
import schoolFreeTrialSaga from "./SchoolFreeTrial";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    notesSagas(),
    bookSaga(),
    profileSaga(),
    schoolSaga(),
    collectionSaga(),
    userSaga(),
    publisherSaga(),
    categorySaga(),
    transactionSaga(),
    bannerSaga(),
    voucherSaga(),
    faqSaga(),
    recomenndationSaga(),
    segmentationSaga(),
    appInboxSaga(),
    activitySheet(),
    dashboardSaga(),
    readingSaga(),
    rbacSaga(),
    campaignSaga(),
    schoolFreeTrialSaga(),
  ]);
}
