import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Reading";
import * as Api from "../../api/reading";

import {
  getListReadingParadeSuccess,
  getListReadingParadeFailed,
  createReadingParadeSuccess,
  createReadingParadeFailed,
  updateReadingParadeSuccess,
  updateReadingParadeFailed,
  getReadingParadeByIdSuccess,
  getReadingParadeByIdFailed,
  updateStatusReadingParadeSuccess,
  updateStatusReadingParadeFailed,
  rePublishedReadingParadeSuccess,
  rePublishedReadingParadeFailed,
  getListReadingChallengeSuccess,
  getListReadingChallengeFailed,
  createReadingChallengeSuccess,
  createReadingChallengeFailed,
  getReadingChallengeByIdSuccess,
  getReadingChallengeByIdFailed,
  updateReadingChallengeSuccess,
  updateReadingChallengeFailed,
  updateStatusReadingChallengeSuccess,
  updateStatusReadingChallengeFailed,
  rePublishedReadingChallengeSuccess,
  rePublishedReadingChallengeFailed,
  uploadPointParadeSuccess,
  uploadPointParadeFailed,
} from "../actions/Reading";

import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";
import { STATUS_READING } from "../../contstants";

function* getListReadingParade({ payload }) {
  try {
    const resultListReadingParade = yield call(Api.fetchListReadingParade, {
      params: { ...payload.params },
    });
    yield put(
      getListReadingParadeSuccess(
        {
          ...resultListReadingParade,
          limit: payload?.params?.limit,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list reading parade", "Failed");
    yield put(getListReadingParadeFailed());
  }
}

export function* watchListReadingParadeRequest() {
  yield takeEvery(
    ActionType.GET_LIST_READING_PARADE_REQUEST,
    getListReadingParade
  );
}

function* createReadingParade({ payload }) {
  try {
    const resultCreateReadingParade = yield call(
      Api.fetchCreateReadingParade,
      payload
    );
    if (resultCreateReadingParade.success === true) {
      yield put(createReadingParadeSuccess(resultCreateReadingParade.data));
      successNotification(
        `You have successfully Save Changes in the ${payload.title}`,
        "Success"
      );
    } else {
      yield put(createReadingParadeFailed(resultCreateReadingParade || {}));
      resultCreateReadingParade?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create reading parade", "Failed");
    yield put(createReadingParadeFailed());
  }
}

export function* watchCreateReadingParadeRequest() {
  yield takeEvery(
    ActionType.CREATE_READING_PARADE_REQUEST,
    createReadingParade
  );
}

function* updateReadingParade({ payload }) {
  try {
    const resultUpdateReadingParade = yield call(
      Api.fetchUpdateReadingParade,
      payload
    );
    yield put(
      updateReadingParadeSuccess(
        {
          data: {
            ...payload,
          },
        } || {}
      )
    );
    if (resultUpdateReadingParade.success === true) {
      successNotification(
        `You have successfully Save Changes in the ${payload?.payload?.title}`,
        "Success"
      );
    } else {
      resultUpdateReadingParade?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update reading parade", "Failed");
    yield put(updateReadingParadeFailed());
  }
}

export function* watchUpdateReadingParadeRequest() {
  yield takeEvery(
    ActionType.UPDATE_READING_PARADE_REQUEST,
    updateReadingParade
  );
}

function* getReadingParadeById({ payload }) {
  try {
    const resultReadingParade = yield call(Api.fetchReadingParadeById, {
      paradeReadingID: payload?.paradeReadingID,
    });
    yield put(getReadingParadeByIdSuccess({ ...resultReadingParade } || {}));
  } catch (error) {
    errorNotification("Get reading parade by id", "Failed");
    yield put(getReadingParadeByIdFailed());
  }
}

export function* watchGetReadingParadeByIdRequest() {
  yield takeEvery(
    ActionType.GET_READING_PARADE_BY_ID_REQUEST,
    getReadingParadeById
  );
}

function* updateStatusReadingParade({ payload }) {
  try {
    const resultUpdateStatusReadingParade = yield call(
      Api.fetchUpdateStatusReadingParade,
      payload
    );
    yield put(
      updateStatusReadingParadeSuccess(
        {
          data: {
            ...payload,
          },
        } || {}
      )
    );
    if (resultUpdateStatusReadingParade.success === true) {
      if (payload?.payload?.status === STATUS_READING.ARCHIVED) {
        successNotification(
          `You have successfully Archived the ${payload?.payload?.title}`,
          "Success"
        );
      }
    } else {
      resultUpdateStatusReadingParade?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status reading parade", "Failed");
    yield put(updateStatusReadingParadeFailed());
  }
}

export function* watchUpdateStatusReadingParadeRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_READING_PARADE_REQUEST,
    updateStatusReadingParade
  );
}

function* rePublishedReadingParade({ payload }) {
  try {
    const resultRePublishedReadingParade = yield call(
      Api.fetchRePublishedReadingParade,
      payload
    );
    yield put(
      rePublishedReadingParadeSuccess(
        {
          data: {
            ...payload,
          },
        } || {}
      )
    );
    if (resultRePublishedReadingParade.success === true) {
      successNotification(
        `You have successfully Published the ${payload?.payload?.title}`,
        "Success"
      );
    } else {
      resultRePublishedReadingParade?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status reading parade", "Failed");
    yield put(rePublishedReadingParadeFailed());
  }
}

export function* watchRePublishedReadingParadeRequest() {
  yield takeEvery(
    ActionType.RE_PUBLISHED_READING_PARADE_REQUEST,
    rePublishedReadingParade
  );
}

function* getListReadingChallenge({ payload }) {
  try {
    const resultListReadingChallenge = yield call(
      Api.fetchListReadingChallenge,
      {
        params: { ...payload.params },
      }
    );
    yield put(
      getListReadingChallengeSuccess(
        {
          ...resultListReadingChallenge,
          limit: payload?.params?.limit,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list reading challenge", "Failed");
    yield put(getListReadingChallengeFailed());
  }
}

export function* watchListReadingChallengeRequest() {
  yield takeEvery(
    ActionType.GET_LIST_READING_CHALLENGE_REQUEST,
    getListReadingChallenge
  );
}

function* createReadingChallenge({ payload }) {
  try {
    const resultCreateReadingChallenge = yield call(
      Api.fetchCreateReadingChallenge,
      payload
    );
    if (resultCreateReadingChallenge.success === true) {
      yield put(
        createReadingChallengeSuccess(resultCreateReadingChallenge.data)
      );
      successNotification(
        `You have successfully Save Changes in the ${payload.title}`,
        "Success"
      );
    } else {
      yield put(
        createReadingChallengeFailed(resultCreateReadingChallenge || {})
      );
      resultCreateReadingChallenge?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create reading challenge", "Failed");
    yield put(createReadingChallengeFailed());
  }
}

export function* watchCreateReadingChallengeRequest() {
  yield takeEvery(
    ActionType.CREATE_READING_CHALLENGE_REQUEST,
    createReadingChallenge
  );
}

function* getReadingChallengeById({ payload }) {
  try {
    const resultReadingChallenge = yield call(Api.fetchReadingChallengeById, {
      challengeReadingID: payload?.challengeReadingID,
    });
    yield put(
      getReadingChallengeByIdSuccess({ ...resultReadingChallenge } || {})
    );
  } catch (error) {
    errorNotification("Get reading challenge by id", "Failed");
    yield put(getReadingChallengeByIdFailed());
  }
}

export function* watchGetReadingChallengeByIdRequest() {
  yield takeEvery(
    ActionType.GET_READING_CHALLENGE_BY_ID_REQUEST,
    getReadingChallengeById
  );
}

function* updateReadingChallenge({ payload }) {
  try {
    const resultUpdateReadingChallenge = yield call(
      Api.fetchUpdateReadingChallenge,
      payload
    );
    yield put(
      updateReadingChallengeSuccess(
        {
          data: {
            ...payload,
          },
        } || {}
      )
    );
    if (resultUpdateReadingChallenge.success === true) {
      successNotification(
        `You have successfully Save Changes in the ${payload?.payload?.title}`,
        "Success"
      );
    } else {
      resultUpdateReadingChallenge?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update reading challenge", "Failed");
    yield put(updateReadingChallengeFailed());
  }
}

export function* watchUpdateReadingChallengeRequest() {
  yield takeEvery(
    ActionType.UPDATE_READING_CHALLENGE_REQUEST,
    updateReadingChallenge
  );
}

function* updateStatusReadingChallenge({ payload }) {
  try {
    const resultUpdateStatusReadingChallenge = yield call(
      Api.fetchUpdateStatusReadingChallenge,
      payload
    );
    yield put(
      updateStatusReadingChallengeSuccess(
        {
          data: {
            ...payload,
          },
        } || {}
      )
    );
    if (resultUpdateStatusReadingChallenge.success === true) {
      if (payload?.payload?.status === STATUS_READING.ARCHIVED) {
        successNotification(
          `You have successfully Archived the ${payload?.payload?.title}`,
          "Success"
        );
      }
    } else {
      resultUpdateStatusReadingChallenge?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status reading challenge", "Failed");
    yield put(updateStatusReadingChallengeFailed());
  }
}

export function* watchUpdateStatusReadingChallengeRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_READING_CHALLENGE_REQUEST,
    updateStatusReadingChallenge
  );
}

function* rePublishedReadingChallenge({ payload }) {
  try {
    const resultRePublishedReadingChallenge = yield call(
      Api.fetchRePublishedReadingChallenge,
      payload
    );
    yield put(
      rePublishedReadingChallengeSuccess(
        {
          data: {
            ...payload,
          },
        } || {}
      )
    );
    if (resultRePublishedReadingChallenge.success === true) {
      successNotification(
        `You have successfully Published the ${payload?.payload?.title}`,
        "Success"
      );
    } else {
      resultRePublishedReadingChallenge?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status reading challenge", "Failed");
    yield put(rePublishedReadingChallengeFailed());
  }
}

export function* watchRePublishedReadingChallengeRequest() {
  yield takeEvery(
    ActionType.RE_PUBLISHED_READING_CHALLENGE_REQUEST,
    rePublishedReadingChallenge
  );
}

function* uploadPointParade({ payload }) {
  try {
    const resultUploadPointParade = yield call(
      Api.fetchUploadPointParade,
      payload
    );
    yield put(
      uploadPointParadeSuccess(
        {
          data: {
            ...payload,
          },
        } || {}
      )
    );
    if (resultUploadPointParade.success === true) {
      successNotification(`You have successfully Save Point`, "Success");
    } else {
      resultUploadPointParade?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Upload point parade", "Failed");
    yield put(uploadPointParadeFailed());
  }
}

export function* watchUploadPointParadeRequest() {
  yield takeEvery(ActionType.UPLOAD_POINT_PARADE_REQUEST, uploadPointParade);
}

export default function* rootSaga() {
  yield all([
    fork(watchListReadingParadeRequest),
    fork(watchCreateReadingParadeRequest),
    fork(watchUpdateReadingParadeRequest),
    fork(watchGetReadingParadeByIdRequest),
    fork(watchUpdateStatusReadingParadeRequest),
    fork(watchRePublishedReadingParadeRequest),
    fork(watchListReadingChallengeRequest),
    fork(watchCreateReadingChallengeRequest),
    fork(watchGetReadingChallengeByIdRequest),
    fork(watchUpdateReadingChallengeRequest),
    fork(watchUpdateStatusReadingChallengeRequest),
    fork(watchRePublishedReadingChallengeRequest),
    fork(watchUploadPointParadeRequest),
  ]);
}
