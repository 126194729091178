export const GET_LIST_TRANSACTION_SUBS_TYPE_REQUEST =
  "GET_LIST_TRANSACTION_SUBS_TYPE_REQUEST";
export const GET_LIST_TRANSACTION_SUBS_TYPE_SUCCESS =
  "GET_LIST_TRANSACTION_SUBS_TYPE_SUCCESS";
export const GET_LIST_TRANSACTION_SUBS_TYPE_FAILED =
  "GET_LIST_TRANSACTION_SUBS_TYPE_FAILED";

export const CREATE_TRANSACTION_SUBS_TYPE_REQUEST =
  "CREATE_TRANSACTION_SUBS_TYPE_REQUEST";
export const CREATE_TRANSACTION_SUBS_TYPE_SUCCESS =
  "CREATE_TRANSACTION_SUBS_TYPE_SUCCESS";
export const CREATE_TRANSACTION_SUBS_TYPE_FAILED =
  "CREATE_TRANSACTION_SUBS_TYPE_FAILED";

export const UPDATE_TRANSACTION_SUBS_TYPE_REQUEST =
  "UPDATE_TRANSACTION_SUBS_TYPE_REQUEST";
export const UPDATE_TRANSACTION_SUBS_TYPE_SUCCESS =
  "UPDATE_TRANSACTION_SUBS_TYPE_SUCCESS";
export const UPDATE_TRANSACTION_SUBS_TYPE_FAILED =
  "UPDATE_TRANSACTION_SUBS_TYPE_FAILED";

export const UPDATE_STATUS_TRANSACTION_SUBS_TYPE_REQUEST =
  "UPDATE_STATUS_TRANSACTION_SUBS_TYPE_REQUEST";
export const UPDATE_STATUS_TRANSACTION_SUBS_TYPE_SUCCESS =
  "UPDATE_STATUS_TRANSACTION_SUBS_TYPE_SUCCESS";
export const UPDATE_STATUS_TRANSACTION_SUBS_TYPE_FAILED =
  "UPDATE_STATUS_TRANSACTION_SUBS_TYPE_FAILED";

export const GET_LIST_TRANSACTION_REQUEST = "GET_LIST_TRANSACTION_REQUEST";
export const GET_LIST_TRANSACTION_SUCCESS = "GET_LIST_TRANSACTION_SUCCESS";
export const GET_LIST_TRANSACTION_FAILED = "GET_LIST_TRANSACTION_FAILED";

export const CREATE_MANUAL_TRANSACTION_REQUEST =
  "CREATE_MANUAL_TRANSACTION_REQUEST";
export const CREATE_MANUAL_TRANSACTION_SUCCESS =
  "CREATE_MANUAL_TRANSACTION_SUCCESS";
export const CREATE_MANUAL_TRANSACTION_FAILED =
  "CREATE_MANUAL_TRANSACTION_FAILED";

export const UPDATE_STATUS_TRANSACTION_REQUEST =
  "UPDATE_STATUS_TRANSACTION_REQUEST";
export const UPDATE_STATUS_TRANSACTION_SUCCESS =
  "UPDATE_STATUS_TRANSACTION_SUCCESS";
export const UPDATE_STATUS_TRANSACTION_FAILED =
  "UPDATE_STATUS_TRANSACTION_FAILED";

export const GET_LIST_ROYALTY_STATEMENT_REQUEST =
  "GET_LIST_ROYALTY_STATEMENT_REQUEST";
export const GET_LIST_ROYALTY_STATEMENT_SUCCESS =
  "GET_LIST_ROYALTY_STATEMENT_SUCCESS";
export const GET_LIST_ROYALTY_STATEMENT_FAILED =
  "GET_LIST_ROYALTY_STATEMENT_FAILED";

export const UPDATE_STATUS_PAYOUT_REQUEST = "UPDATE_STATUS_PAYOUT_REQUEST";
export const UPDATE_STATUS_PAYOUT_SUCCESS = "UPDATE_STATUS_PAYOUT_SUCCESS";
export const UPDATE_STATUS_PAYOUT_FAILED = "UPDATE_STATUS_PAYOUT_FAILED";

export const GET_DETAIL_ROYALTY_STATEMENT_REQUEST =
  "GET_DETAIL_ROYALTY_STATEMENT_REQUEST";
export const GET_DETAIL_ROYALTY_STATEMENT_SUCCESS =
  "GET_DETAIL_ROYALTY_STATEMENT_SUCCESS";
export const GET_DETAIL_ROYALTY_STATEMENT_FAILED =
  "GET_DETAIL_ROYALTY_STATEMENT_FAILED";

export const GET_DETAIL_REPORTS_REQUEST = "GET_DETAIL_REPORTS_REQUEST";
export const GET_DETAIL_REPORTS_SUCCESS = "GET_DETAIL_REPORTS_SUCCESS";
export const GET_DETAIL_REPORTS_FAILED = "GET_DETAIL_REPORTS_FAILED";

export const CREATE_TRANSACTION_SPECIAL_PACKAGE_REQUEST =
  "CREATE_TRANSACTION_SPECIAL_PACKAGE_REQUEST";
export const CREATE_TRANSACTION_SPECIAL_PACKAGE_SUCCESS =
  "CREATE_TRANSACTION_SPECIAL_PACKAGE_SUCCESS";
export const CREATE_TRANSACTION_SPECIAL_PACKAGE_FAILED =
  "CREATE_TRANSACTION_SPECIAL_PACKAGE_FAILED";
