import httpRequest from "../util/axios";

export const fetchListCollection = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/collections",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchPublishCollection = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/collections/${body?.collectionId}/publish`,
      body,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateStatusCollection = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/collections/${body?.collectionId}/status`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateCollection = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      `/admin/collections`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCollectionById = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/collections/${body?.collectionId}`
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateCollection = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/collections/${body?.collectionId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
