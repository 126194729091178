import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/Collection";
import * as Api from "../../api/collection";
import {
  getListCollectionSuccess,
  getListCollectionFailed,
  publishCollectionSuccess,
  publishCollectionFailed,
  updateStatusCollectionSuccess,
  updateStatusCollectionFailed,
  createCollectionSuccess,
  createCollectionFailed,
  getCollectionByIdSuccess,
  getCollectionByIdFailed,
  updateCollectionSuccess,
  updateCollectionFailed,
} from "../actions/Collection";

import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";

function* getListCollectionWithOption({ payload }) {
  try {
    const resultListCollection = yield call(Api.fetchListCollection, {
      params: { ...payload.params },
    });
    yield put(
      getListCollectionSuccess(
        { ...resultListCollection, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list collection", "Failed");
    yield put(getListCollectionFailed());
  }
}

export function* watchListCollectionRequest() {
  yield takeEvery(
    ActionType.GET_LIST_COLLECTION_REQUEST,
    getListCollectionWithOption
  );
}

function* publishCollection({ payload }) {
  try {
    const resultPublishCollection = yield call(
      Api.fetchPublishCollection,
      payload
    );
    if (resultPublishCollection.success === true) {
      yield put(publishCollectionSuccess(resultPublishCollection.data));
      successNotification("Publish collection", "Success");
    } else {
      yield put(publishCollectionFailed(resultPublishCollection || {}));
      resultPublishCollection?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Publish collection", "Failed");
    yield put(publishCollectionFailed());
  }
}

export function* watchPublishCollectionRequest() {
  yield takeEvery(ActionType.PUBLISH_COLLECTION_REQUEST, publishCollection);
}

function* updateStatusCollection({ payload }) {
  try {
    const resultUpdateStatusCollection = yield call(
      Api.fetchUpdateStatusCollection,
      payload
    );
    if (resultUpdateStatusCollection.success === true) {
      yield put(
        updateStatusCollectionSuccess(resultUpdateStatusCollection.data)
      );
      successNotification("Update Status collection", "Success");
    } else {
      yield put(
        updateStatusCollectionFailed(resultUpdateStatusCollection || {})
      );
      resultUpdateStatusCollection?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status collection", "Failed");
    yield put(updateStatusCollectionFailed());
  }
}

export function* watchUpdateStatusCollectionRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_COLLECTION_REQUEST,
    updateStatusCollection
  );
}

function* createCollection({ payload }) {
  try {
    const resultCreateCollection = yield call(
      Api.fetchCreateCollection,
      payload
    );
    if (resultCreateCollection.success === true) {
      yield put(createCollectionSuccess(resultCreateCollection.data));
      successNotification("Create collection", "Success");
    } else {
      yield put(createCollectionFailed(resultCreateCollection || {}));
      resultCreateCollection?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create collection", "Failed");
    yield put(createCollectionFailed());
  }
}

export function* watchCreateCollectionRequest() {
  yield takeEvery(ActionType.CREATE_COLLECTION_REQUEST, createCollection);
}

function* getCollectionById({ payload }) {
  try {
    const resultCollection = yield call(Api.fetchCollectionById, payload);
    yield put(getCollectionByIdSuccess({ ...resultCollection } || {}));
  } catch (error) {
    errorNotification("Get collection by id", "Failed");
    yield put(getCollectionByIdFailed());
  }
}

export function* watchGetCollectionByIdRequest() {
  yield takeEvery(ActionType.GET_COLLECTION_BY_ID_REQUEST, getCollectionById);
}

function* updateCollection({ payload }) {
  try {
    const resultUpdateCollection = yield call(
      Api.fetchUpdateCollection,
      payload
    );
    if (resultUpdateCollection.success === true) {
      yield put(updateCollectionSuccess(resultUpdateCollection.data));
      successNotification("Update collection", "Success");
    } else {
      yield put(updateCollectionFailed(resultUpdateCollection || {}));
      resultUpdateCollection?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update collection", "Failed");
    yield put(updateCollectionFailed());
  }
}

export function* watchUpdateCollectionRequest() {
  yield takeEvery(ActionType.UPDATE_COLLECTION_REQUEST, updateCollection);
}

export default function* rootSaga() {
  yield all([
    fork(watchListCollectionRequest),
    fork(watchPublishCollectionRequest),
    fork(watchUpdateStatusCollectionRequest),
    fork(watchCreateCollectionRequest),
    fork(watchGetCollectionByIdRequest),
    fork(watchUpdateCollectionRequest),
  ]);
}
