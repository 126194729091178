import * as ActionType from "../constants/Inbox";

export const getListAppInboxRequest = (payload) => ({
  type: ActionType.GET_LIST_APP_INBOX_REQUEST,
  payload,
});

export const getListAppInboxSuccess = (payload) => ({
  type: ActionType.GET_LIST_APP_INBOX_SUCCESS,
  payload,
});

export const getListAppInboxFailed = (payload) => ({
  type: ActionType.GET_LIST_APP_INBOX_FAILED,
  payload,
});

export const createAppInboxRequest = (payload) => ({
  type: ActionType.CREATE_APP_INBOX_REQUEST,
  payload,
});

export const createAppInboxSuccess = (payload) => ({
  type: ActionType.CREATE_APP_INBOX_SUCCESS,
  payload,
});

export const createAppInboxFailed = (payload) => ({
  type: ActionType.CREATE_APP_INBOX_FAILED,
  payload,
});

export const sendAppInboxRequest = (payload) => ({
  type: ActionType.SEND_APP_INBOX_REQUEST,
  payload,
});

export const sendAppInboxSuccess = (payload) => ({
  type: ActionType.SEND_APP_INBOX_SUCCESS,
  payload,
});

export const sendAppInboxFailed = (payload) => ({
  type: ActionType.SEND_APP_INBOX_FAILED,
  payload,
});

export const getAppInboxByIdRequest = (payload) => ({
  type: ActionType.GET_APP_INBOX_BY_ID_REQUEST,
  payload,
});

export const getAppInboxByIdSuccess = (payload) => ({
  type: ActionType.GET_APP_INBOX_BY_ID_SUCCESS,
  payload,
});

export const getAppInboxByIdFailed = (payload) => ({
  type: ActionType.GET_APP_INBOX_BY_ID_FAILED,
  payload,
});

export const updateAppInboxRequest = (payload) => ({
  type: ActionType.UPDATE_APP_INBOX_REQUEST,
  payload,
});

export const updateAppInboxSuccess = (payload) => ({
  type: ActionType.UPDATE_APP_INBOX_SUCCESS,
  payload,
});

export const updateAppInboxFailed = (payload) => ({
  type: ActionType.UPDATE_APP_INBOX_FAILED,
  payload,
});
