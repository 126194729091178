export const transactionSubsTypeDTO = (transactionSubsType) => ({
  ...transactionSubsType,
});

export const listTransactionSubsTypeDTO = (listTransactionSubsType = []) => {
  if (!listTransactionSubsType.length) return [];
  return listTransactionSubsType.map(transactionSubsTypeDTO) ?? [];
};

export const transactionDTO = (transaction) => ({
  ...transaction,
});

export const listTransactionDTO = (listTransaction = []) => {
  if (!listTransaction.length) return [];
  return listTransaction.map(transactionDTO) ?? [];
};
