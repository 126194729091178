export const GET_LIST_BOOK_REQUEST = "GET_LIST_BOOK_REQUEST";
export const GET_LIST_BOOK_SUCCESS = "GET_LIST_BOOK_SUCCESS";
export const GET_LIST_BOOK_FAILED = "GET_LIST_BOOK_FAILED";

export const GET_LIST_CATEGORY_REQUEST = "GET_LIST_CATEGORY_REQUEST";
export const GET_LIST_CATEGORY_SUCCESS = "GET_LIST_CATEGORY_SUCCESS";
export const GET_LIST_CATEGORY_FAILED = "GET_LIST_CATEGORY_FAILED";

export const CREATE_BOOK_REQUEST = "CREATE_BOOK_REQUEST";
export const CREATE_BOOK_SUCCESS = "CREATE_BOOK_SUCCESS";
export const CREATE_BOOK_FAILED = "CREATE_BOOK_FAILED";

export const GET_BOOK_BY_ID_REQUEST = "GET_BOOK_BY_ID_REQUEST";
export const GET_BOOK_BY_ID_SUCCESS = "GET_BOOK_BY_ID_SUCCESS";
export const GET_BOOK_BY_ID_FAILED = "GET_BOOK_BY_ID_FAILED";

export const UPDATE_BOOK_REQUEST = "UPDATE_BOOK_REQUEST";
export const UPDATE_BOOK_SUCCESS = "UPDATE_BOOK_SUCCESS";
export const UPDATE_BOOK_FAILED = "UPDATE_BOOK_FAILED";

export const UPDATE_STATUS_BOOK_REQUEST = "UPDATE_STATUS_BOOK_REQUEST";
export const UPDATE_STATUS_BOOK_SUCCESS = "UPDATE_STATUS_BOOK_SUCCESS";
export const UPDATE_STATUS_BOOK_FAILED = "UPDATE_STATUS_BOOK_FAILED";
