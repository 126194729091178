import * as ActionType from "../constants/Faq";

const initialState = {
  listCategory: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  updateCategory: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  createTopic: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  createQuestion: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  updateQuestion: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  deleteQuestion: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  deleteSubSection: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

const faqReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_LIST_CATEGORY_REQUEST:
      return {
        ...state,
        listCategory: {
          ...state.listCategory,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        listCategory: {
          ...state.listCategory,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_CATEGORY_FAILED:
      return {
        ...state,
        listCategory: {
          ...state.listCategory,
          isLoading: false,
        },
      };

    case ActionType.CREATE_TOPIC_REQUEST:
      return {
        ...state,
        createTopic: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.CREATE_TOPIC_SUCCESS:
      return {
        ...state,
        createTopic: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.CREATE_TOPIC_FAILED:
      return {
        ...state,
        createTopic: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.CREATE_QUESTION_REQUEST:
      return {
        ...state,
        createQuestion: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        createQuestion: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.CREATE_QUESTION_FAILED:
      return {
        ...state,
        createQuestion: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.UPDATE_QUESTION_REQUEST:
    case ActionType.UPDATE_STATUS_QUESTION_REQUEST:
      return {
        ...state,
        updateQuestion: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_QUESTION_SUCCESS:
    case ActionType.UPDATE_STATUS_QUESTION_SUCCESS:
      return {
        ...state,
        updateQuestion: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_QUESTION_FAILED:
    case ActionType.UPDATE_STATUS_QUESTION_FAILED:
      return {
        ...state,
        updateQuestion: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.DELETE_QUESTION_REQUEST:
      return {
        ...state,
        deleteQuestion: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        deleteQuestion: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.DELETE_QUESTION_FAILED:
      return {
        ...state,
        deleteQuestion: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        updateCategory: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_CATEGORY_FAILED:
      return {
        ...state,
        updateCategory: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    case ActionType.DELETE_SUB_SECTION_REQUEST:
      return {
        ...state,
        deleteSubSection: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.DELETE_SUB_SECTION_SUCCESS:
      return {
        ...state,
        deleteSubSection: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.DELETE_SUB_SECTION_FAILED:
      return {
        ...state,
        deleteSubSection: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };

    default: {
      return state;
    }
  }
};

export default faqReducer;
