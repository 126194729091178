import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/School";
import * as Api from "../../api/school";
import * as ApiSchoolAdmin from "../../api/schoolAdmin";

import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";
import {
  getListSchoolFailed,
  getListSchoolSuccess,
  getListTeacherBySchoolIdFailed,
  getListTeacherBySchoolIdSuccess,
  getListStudentBySchoolIdSuccess,
  getListStudentBySchoolIdFailed,
  createSchoolSuccess,
  createSchoolFailed,
  updateSchoolSuccess,
  updateSchoolFailed,
  updateSchoolCredSuccess,
  updateSchoolCredFailed,
  updateStatusSchoolSuccess,
  updateStatusSchoolFailed,
  getListClassBySchoolIdSuccess,
  getListClassBySchoolIdFailed,
  createClassSuccess,
  createClassFailed,
  updateClassSuccess,
  updateClassFailed,
  deleteClassSuccess,
  deleteClassFailed,
  createTeacherSuccess,
  createTeacherFailed,
  updateStatusTeacherSuccess,
  updateStatusTeacherFailed,
  updateTeacherSuccess,
  updateTeacherFailed,
  deleteTeacherSuccess,
  deleteTeacherFailed,
  setClassTeacherSuccess,
  setClassTeacherFailed,
  setClassesStudentSuccess,
  setClassesStudentFailed,
  deleteStudentSuccess,
  deleteStudentFailed,
  updateStatusStudentSuccess,
  updateStatusStudentFailed,
  setBulkActiveTeacherSuccess,
  setBulkActiveTeacherFailed,
  setBulkActiveStudentSuccess,
  setBulkActiveStudentFailed,
  findSchoolSuccess,
  findSchoolFailed,
  getDetailSchoolSuccess,
  getDetailSchoolFailed,
  getSummarySchoolSuccess,
  getSummarySchoolFailed,
  getActivitySchoolSuccess,
  getActivitySchoolFailed,
  getListOfReadSchoolSuccess,
  getListOfReadSchoolFailed,
  getListOfReadStudentSuccess,
  getListOfReadStudentFailed,
  getReadHistorySchoolSuccess,
  getReadHistorySchoolFailed,
  getDetailReadHistorySchoolSuccess,
  getDetailReadHistorySchoolFailed,
  createStudentSuccess,
  createStudentFailed,
  updateStudentSuccess,
  updateStudentFailed,
  setClassStudentSuccess,
  setClassStudentFailed,
  connectAccountStudentSuccess,
  connectAccountStudentFailed,
  getListFamilySuccess,
  getListFamilyFailed,
  getChildFamilySuccess,
  getChildFamilyFailed,
  setAccountToFamilySuccess,
  setAccountToFamilyFailed,
  setAccountToFamilyAdminSuccess,
  setAccountToFamilyAdminFailed,
  verifyConnectFamilySuccess,
  verifyConnectFamilyFailed,
  getDetailConnectionByStudentIdSuccess,
  getDetailConnectionByStudentIdFailed,
  getDetailConnectionByStudentIdAdminSuccess,
  getDetailConnectionByStudentIdAdminFailed,
  updateTeacherAdminAccessSuccess,
  updateTeacherAdminAccessFailed,
} from "../actions/School";

function* getListSchoolWithOption({ payload }) {
  try {
    const resultListSchool = yield call(Api.fetchListSchool, {
      params: { ...payload.params },
    });
    yield put(
      getListSchoolSuccess(
        { ...resultListSchool, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list school", "Failed");
    yield put(getListSchoolFailed());
  }
}

export function* watchListSchoolRequest() {
  yield takeEvery(ActionType.GET_LIST_SCHOOL_REQUEST, getListSchoolWithOption);
}

function* getListTeacherBySchoolId({ payload }) {
  try {
    let resultListTeacher = {};
    if (payload?.isSchool) {
      resultListTeacher = yield call(ApiSchoolAdmin.fetchListTeacher, {
        params: { ...payload?.params },
      });
    } else {
      resultListTeacher = yield call(Api.fetchListTeacherBySchoolId, {
        params: { ...payload?.params },
        schoolId: payload?.schoolId,
      });
    }
    yield put(
      getListTeacherBySchoolIdSuccess(
        { ...resultListTeacher, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list teacher", "Failed");
    yield put(getListTeacherBySchoolIdFailed());
  }
}

export function* watchListTeacherSchoolByIdRequest() {
  yield takeEvery(
    ActionType.GET_LIST_TEACHER_BY_SCHOOL_ID_REQUEST,
    getListTeacherBySchoolId
  );
}

function* getListStudentBySchoolId({ payload }) {
  try {
    let resultListStudent = {};
    if (payload?.isSchool) {
      resultListStudent = yield call(ApiSchoolAdmin.fetchListStudent, {
        params: { ...payload?.params },
      });
    } else {
      resultListStudent = yield call(Api.fetchListStudentBySchoolId, {
        params: { ...payload?.params },
        id: payload?.id,
      });
    }
    yield put(
      getListStudentBySchoolIdSuccess(
        { ...resultListStudent, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list student", "Failed");
    yield put(getListStudentBySchoolIdFailed());
  }
}

export function* watchListStudentSchoolByIdRequest() {
  yield takeEvery(
    ActionType.GET_LIST_STUDENT_BY_SCHOOL_ID_REQUEST,
    getListStudentBySchoolId
  );
}

function* createSchool({ payload }) {
  try {
    const resultCreateSchool = yield call(Api.fetchCreateSchool, payload);
    if (resultCreateSchool.success === true) {
      yield put(createSchoolSuccess(resultCreateSchool.data));
      successNotification("Create school", "Success");
    } else {
      yield put(createSchoolFailed(resultCreateSchool || {}));
      resultCreateSchool?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create school", "Failed");
    yield put(createSchoolFailed());
  }
}

export function* watchCreateSchoolRequest() {
  yield takeEvery(ActionType.CREATE_SCHOOL_REQUEST, createSchool);
}

function* updateSchool({ payload }) {
  try {
    const resultUpdateSchool = yield call(Api.fetchUpdateSchool, payload);
    if (resultUpdateSchool.success === true) {
      yield put(updateSchoolSuccess(resultUpdateSchool.data));
      successNotification("Update school", "Success");
    } else {
      yield put(updateSchoolFailed(resultUpdateSchool || {}));
      resultUpdateSchool?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update school", "Failed");
    yield put(updateSchoolFailed());
  }
}

export function* watchUpdateSchoolRequest() {
  yield takeEvery(ActionType.UPDATE_SCHOOL_REQUEST, updateSchool);
}

function* updateSchoolCredReq({ payload }) {
  try {
    const resultUpdateSchoolCred = yield call(
      Api.fetchUpdateSchooCred,
      payload
    );
    if (resultUpdateSchoolCred.success) {
      yield put(updateSchoolCredSuccess(resultUpdateSchoolCred.data));
      successNotification("Update school", "Success");
    } else {
      yield put(updateSchoolCredFailed(resultUpdateSchoolCred || {}));
      resultUpdateSchoolCred?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update School", "Failed");
    yield put(updateSchoolCredFailed());
  }
}

export function* watchUpdateSchoolCredRequest() {
  yield takeEvery(ActionType.UPDATE_SCHOOL_CRED_REQUEST, updateSchoolCredReq);
}

function* updateStatusSchool({ payload }) {
  try {
    const resultUpdateStatusSchool = yield call(
      Api.fetchUpdateStatusSchool,
      payload
    );
    if (resultUpdateStatusSchool.success === true) {
      yield put(updateStatusSchoolSuccess(resultUpdateStatusSchool.data));
      successNotification("Update Status school", "Success");
    } else {
      yield put(updateStatusSchoolFailed(resultUpdateStatusSchool || {}));
      resultUpdateStatusSchool?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update Status school", "Failed");
    yield put(updateStatusSchoolFailed());
  }
}

export function* watchUpdateStatusSchoolRequest() {
  yield takeEvery(ActionType.UPDATE_STATUS_SCHOOL_REQUEST, updateStatusSchool);
}

function* getListClassBySchoolId({ payload }) {
  try {
    let resultListClass = {};
    if (payload?.isSchool) {
      resultListClass = yield call(ApiSchoolAdmin.fetchListClass, {
        params: { ...payload?.params },
      });
    } else {
      resultListClass = yield call(Api.fetchListClassBySchoolId, {
        params: { ...payload?.params },
        id: payload?.id,
      });
    }
    yield put(
      getListClassBySchoolIdSuccess(
        { ...resultListClass, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list class", "Failed");
    yield put(getListClassBySchoolIdFailed());
  }
}

export function* watchListClassSchoolByIdRequest() {
  yield takeEvery(
    ActionType.GET_LIST_CLASS_BY_SCHOOL_ID_REQUEST,
    getListClassBySchoolId
  );
}

function* createClass({ payload }) {
  try {
    const resultCreateClass = yield call(Api.fetchCreateClass, payload);
    if (resultCreateClass.success === true) {
      yield put(createClassSuccess(resultCreateClass.data));
      successNotification("Create class", "Success");
    } else {
      yield put(createClassFailed(resultCreateClass || {}));
      resultCreateClass?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create class", "Failed");
    yield put(createClassFailed());
  }
}

export function* watchCreateClassRequest() {
  yield takeEvery(ActionType.CREATE_CLASS_REQUEST, createClass);
}

function* updateClass({ payload }) {
  try {
    const resultUpdateClass = yield call(Api.fetchUpdateClass, payload);
    if (resultUpdateClass.success === true) {
      yield put(updateClassSuccess(resultUpdateClass.data));
      successNotification("Update class", "Success");
    } else {
      yield put(updateClassFailed(resultUpdateClass || {}));
      resultUpdateClass?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update school", "Failed");
    yield put(updateClassFailed());
  }
}

export function* watchUpdateClassRequest() {
  yield takeEvery(ActionType.UPDATE_CLASS_REQUEST, updateClass);
}

function* deleteClass({ payload }) {
  try {
    const resultDeleteClass = yield call(Api.fetchDeleteClass, payload);
    if (resultDeleteClass.success === true) {
      yield put(deleteClassSuccess(resultDeleteClass.data));
      successNotification("Delete class", "Success");
    } else {
      yield put(deleteClassFailed(resultDeleteClass || {}));
      resultDeleteClass?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Delete school", "Failed");
    yield put(deleteClassFailed());
  }
}

export function* watchDeleteClassRequest() {
  yield takeEvery(ActionType.DELETE_CLASS_REQUEST, deleteClass);
}

function* createTeacher({ payload }) {
  try {
    const resultCreateTeacher = yield call(Api.fetchCreateTeacher, payload);
    if (resultCreateTeacher.success === true) {
      yield put(createTeacherSuccess(resultCreateTeacher.data));
      successNotification("Create teacher", "Success");
    } else {
      yield put(createTeacherFailed(resultCreateTeacher || {}));
      resultCreateTeacher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create teacher", "Failed");
    yield put(createTeacherFailed());
  }
}

export function* watchCreateTeacherRequest() {
  yield takeEvery(ActionType.CREATE_TEACHER_REQUEST, createTeacher);
}

function* updateStatusTeacher({ payload }) {
  try {
    const resultUpdateStatusTeacher = yield call(
      Api.fetchUpdateStatusTeacher,
      payload
    );
    if (resultUpdateStatusTeacher.success === true) {
      yield put(updateStatusTeacherSuccess(resultUpdateStatusTeacher.data));
      successNotification("Update status teacher", "Success");
    } else {
      yield put(updateStatusTeacherFailed(resultUpdateStatusTeacher || {}));
      resultUpdateStatusTeacher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update status teacher", "Failed");
    yield put(updateStatusTeacherFailed());
  }
}

export function* watchUpdateStatusTeacherRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_TEACHER_REQUEST,
    updateStatusTeacher
  );
}

function* updateTeacher({ payload }) {
  try {
    const resultUpdateTeacher = yield call(Api.fetchUpdateTeacher, payload);
    if (resultUpdateTeacher.success === true) {
      yield put(updateTeacherSuccess(resultUpdateTeacher.data));
      successNotification("Update teacher", "Success");
    } else {
      yield put(updateTeacherFailed(resultUpdateTeacher || {}));
      resultUpdateTeacher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update teacher", "Failed");
    yield put(updateTeacherFailed());
  }
}

export function* watchUpdateTeacherRequest() {
  yield takeEvery(ActionType.UPDATE_TEACHER_REQUEST, updateTeacher);
}

function* deleteTeacher({ payload }) {
  try {
    const resultDeleteTeacher = yield call(Api.fetchDeleteTeacher, payload);
    if (resultDeleteTeacher.success === true) {
      yield put(deleteTeacherSuccess(resultDeleteTeacher.data));
      successNotification("Delete teacher", "Success");
    } else {
      yield put(deleteTeacherFailed(resultDeleteTeacher || {}));
      resultDeleteTeacher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Delete teacher", "Failed");
    yield put(deleteTeacherFailed());
  }
}

export function* watchDeleteTeacherRequest() {
  yield takeEvery(ActionType.DELETE_TEACHER_REQUEST, deleteTeacher);
}

function* setClassTeacher({ payload }) {
  try {
    const resultSetClassTeacher = yield call(Api.fetchSetClassTeacher, payload);
    if (resultSetClassTeacher.success === true) {
      yield put(setClassTeacherSuccess(resultSetClassTeacher.data));
      successNotification("Set class teacher", "Success");
    } else {
      yield put(setClassTeacherFailed(resultSetClassTeacher || {}));
      resultSetClassTeacher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Set class teacher", "Failed");
    yield put(setClassTeacherFailed());
  }
}

export function* watchSetClassTeacherRequest() {
  yield takeEvery(ActionType.SET_CLASS_TEACHER_REQUEST, setClassTeacher);
}

function* setClassesStudent({ payload }) {
  try {
    const resultSetClassesStudent = yield call(
      Api.fetchSetClassesStudent,
      payload
    );
    if (resultSetClassesStudent.success === true) {
      yield put(setClassesStudentSuccess(resultSetClassesStudent.data));
      successNotification("Set classes student", "Success");
    } else {
      yield put(setClassesStudentFailed(resultSetClassesStudent || {}));
      resultSetClassesStudent?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Set classes student", "Failed");
    yield put(setClassesStudentFailed());
  }
}

export function* watchSetClassesStudentRequest() {
  yield takeEvery(ActionType.SET_CLASSES_STUDENT_REQUEST, setClassesStudent);
}

function* deleteStudent({ payload }) {
  try {
    const resultDeleteStudent = yield call(Api.fetchDeleteStudent, payload);
    if (resultDeleteStudent.success === true) {
      yield put(deleteStudentSuccess(resultDeleteStudent.data));
      successNotification("Delete student", "Success");
    } else {
      yield put(deleteStudentFailed(resultDeleteStudent || {}));
      resultDeleteStudent?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Delete student", "Failed");
    yield put(deleteStudentFailed());
  }
}

export function* watchDeleteStudentRequest() {
  yield takeEvery(ActionType.DELETE_STUDENT_REQUEST, deleteStudent);
}

function* updateStatusStudent({ payload }) {
  try {
    const resultUpdateStatusStudent = yield call(
      Api.fetchUpdateStatusStudent,
      payload
    );
    if (resultUpdateStatusStudent.success === true) {
      yield put(updateStatusStudentSuccess(resultUpdateStatusStudent.data));
      successNotification("Update status student", "Success");
    } else {
      yield put(updateStatusStudentFailed(resultUpdateStatusStudent || {}));
      resultUpdateStatusStudent?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update status student", "Failed");
    yield put(updateStatusStudentFailed());
  }
}

export function* watchUpdateStatusStudentRequest() {
  yield takeEvery(
    ActionType.UPDATE_STATUS_STUDENT_REQUEST,
    updateStatusStudent
  );
}

function* setBulkActiveTeacher({ payload }) {
  try {
    const resultSetBulkActiveTeacher = yield call(
      Api.fetchSetBulkActiveTeacher,
      payload
    );
    if (resultSetBulkActiveTeacher.success === true) {
      yield put(setBulkActiveTeacherSuccess(resultSetBulkActiveTeacher.data));
      successNotification("Set bulk active teacher", "Success");
    } else {
      yield put(setBulkActiveTeacherFailed(resultSetBulkActiveTeacher || {}));
      resultSetBulkActiveTeacher?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Set bulk active teacher", "Failed");
    yield put(setBulkActiveTeacherFailed());
  }
}

export function* watchSetBulkActiveTeacherRequest() {
  yield takeEvery(
    ActionType.SET_BULK_ACTIVE_TEACHER_REQUEST,
    setBulkActiveTeacher
  );
}

function* setBulkActiveStudent({ payload }) {
  try {
    const resultSetBulkActiveStudent = yield call(
      Api.fetchSetBulkActiveStudent,
      payload
    );
    if (resultSetBulkActiveStudent.success === true) {
      yield put(setBulkActiveStudentSuccess(resultSetBulkActiveStudent.data));
      successNotification("Set bulk active student", "Success");
    } else {
      yield put(setBulkActiveStudentFailed(resultSetBulkActiveStudent || {}));
      resultSetBulkActiveStudent?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Set bulk active student", "Failed");
    yield put(setBulkActiveStudentFailed());
  }
}

export function* watchSetBulkActiveStudentRequest() {
  yield takeEvery(
    ActionType.SET_BULK_ACTIVE_STUDENT_REQUEST,
    setBulkActiveStudent
  );
}

function* findSchool({ payload }) {
  try {
    const resultFindSchool = yield call(Api.fetchFindSchool, {
      params: { ...payload.params },
    });
    yield put(findSchoolSuccess(resultFindSchool || {}));
  } catch (error) {
    errorNotification("Get list school", "Failed");
    yield put(findSchoolFailed());
  }
}

export function* watchFindSchoolRequest() {
  yield takeEvery(ActionType.FIND_SCHOOL_REQUEST, findSchool);
}

function* getDetailSchool({ payload }) {
  try {
    const resultDetailSchool = yield call(Api.fetchDetailSchool, payload);
    yield put(getDetailSchoolSuccess(resultDetailSchool || {}));
  } catch (error) {
    errorNotification("Get detail school", "Failed");
    yield put(getDetailSchoolFailed());
  }
}

export function* watchGetDetailSchoolRequest() {
  yield takeEvery(ActionType.GET_DETAIL_SCHOOL_REQUEST, getDetailSchool);
}

function* getSummarySchool({ payload }) {
  try {
    let resultSummarySchool;
    if (payload?.isSchool) {
      resultSummarySchool = yield call(Api.fetchSummarySchool, payload);
    } else {
      resultSummarySchool = yield call(Api.fetchSummaryBySchoolId, payload);
    }
    yield put(getSummarySchoolSuccess(resultSummarySchool || {}));
  } catch (error) {
    errorNotification("Get summary school", "Failed");
    yield put(getSummarySchoolFailed());
  }
}

export function* watchGetSummarySchoolRequest() {
  yield takeEvery(ActionType.GET_SUMMARY_SCHOOL_REQUEST, getSummarySchool);
}

function* getActivitySchoolWithOption({ payload }) {
  try {
    const resultActivitySchool = yield call(Api.fetchActivitySchool, {
      params: { ...payload.params },
    });
    yield put(
      getActivitySchoolSuccess(
        { ...resultActivitySchool, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get activity school", "Failed");
    yield put(getActivitySchoolFailed());
  }
}

export function* watchActivitySchoolRequest() {
  yield takeEvery(
    ActionType.GET_ACTIVITY_SCHOOL_REQUEST,
    getActivitySchoolWithOption
  );
}

function* getListOfReadSchoolWithOption({ payload }) {
  try {
    const resultListOfReadSchool = yield call(Api.fetchListOfReadSchool, {
      params: { ...payload.params },
    });
    yield put(
      getListOfReadSchoolSuccess(
        { ...resultListOfReadSchool, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list of read school", "Failed");
    yield put(getListOfReadSchoolFailed());
  }
}

export function* watchListOfReadSchoolRequest() {
  yield takeEvery(
    ActionType.GET_LIST_OF_READ_SCHOOL_REQUEST,
    getListOfReadSchoolWithOption
  );
}

function* getListOfReadStudent({ payload }) {
  try {
    const resultListOfReadStudent = yield call(
      Api.fetchListOfReadStudent,
      payload
    );
    yield put(getListOfReadStudentSuccess(resultListOfReadStudent || {}));
  } catch (error) {
    errorNotification("Get list of read stutdent", "Failed");
    yield put(getListOfReadStudentFailed());
  }
}

export function* watchListOfReadStudentRequest() {
  yield takeEvery(
    ActionType.GET_LIST_OF_READ_STUDENT_REQUEST,
    getListOfReadStudent
  );
}

function* getReadHistorySchool({ payload }) {
  try {
    const resultReadHistorySchool = yield call(Api.fetchReadHistorySchool, {
      params: { ...payload.params },
    });
    yield put(
      getReadHistorySchoolSuccess(
        { ...resultReadHistorySchool, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list of read history school", "Failed");
    yield put(getReadHistorySchoolFailed());
  }
}

export function* watchReadHistorySchoolRequest() {
  yield takeEvery(
    ActionType.GET_READ_HISTORY_SCHOOL_REQUEST,
    getReadHistorySchool
  );
}

function* getDetailReadHistorySchool({ payload }) {
  try {
    const resultDetailReadHistorySchool = yield call(
      Api.fetchDetailReadHistorySchool,
      payload
    );
    yield put(
      getDetailReadHistorySchoolSuccess(
        { ...resultDetailReadHistorySchool, limit: payload?.params?.limit } ||
          {}
      )
    );
  } catch (error) {
    errorNotification("Get detail of read history school", "Failed");
    yield put(getDetailReadHistorySchoolFailed());
  }
}

export function* watchDetailReadHistorySchoolRequest() {
  yield takeEvery(
    ActionType.GET_DETAIL_READ_HISTORY_SCHOOL_REQUEST,
    getDetailReadHistorySchool
  );
}

function* createStudent({ payload }) {
  try {
    const resultCreateStudent = yield call(Api.fetchCreateStudent, payload);
    if (resultCreateStudent.success === true) {
      yield put(createStudentSuccess(resultCreateStudent.data));
      successNotification("Create student", "Success");
    } else {
      yield put(createStudentFailed(resultCreateStudent || {}));
      resultCreateStudent?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create student", "Failed");
    yield put(createStudentFailed());
  }
}

export function* watchCreateStudentRequest() {
  yield takeEvery(ActionType.CREATE_STUDENT_REQUEST, createStudent);
}

function* updateStudent({ payload }) {
  try {
    const resultUpdateStudent = yield call(Api.fetchUpdateStudent, payload);
    if (resultUpdateStudent.success === true) {
      yield put(updateStudentSuccess(resultUpdateStudent.data));
      successNotification("Update student", "Success");
    } else {
      yield put(updateStudentFailed(resultUpdateStudent || {}));
      resultUpdateStudent?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update student", "Failed");
    yield put(updateStudentFailed());
  }
}

export function* watchUpdateStudentRequest() {
  yield takeEvery(ActionType.UPDATE_STUDENT_REQUEST, updateStudent);
}

function* setClassStudent({ payload }) {
  try {
    const resultSetClassStudent = yield call(Api.fetchSetClassStudent, payload);
    if (resultSetClassStudent.success === true) {
      yield put(setClassStudentSuccess(resultSetClassStudent.data));
      successNotification("Set class student", "Success");
    } else {
      yield put(setClassStudentFailed(resultSetClassStudent || {}));
      resultSetClassStudent?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Set class student", "Failed");
    yield put(setClassStudentFailed());
  }
}

export function* watchSetClassStudentRequest() {
  yield takeEvery(ActionType.SET_CLASS_STUDENT_REQUEST, setClassStudent);
}

function* connectAccountStudent({ payload }) {
  try {
    const resultConnectAccountStudent = yield call(
      Api.fetchConnectAccountStudent,
      payload
    );
    if (resultConnectAccountStudent.success === true) {
      yield put(connectAccountStudentSuccess(resultConnectAccountStudent.data));
      successNotification("Connect Account student", "Success");
    } else {
      yield put(connectAccountStudentFailed(resultConnectAccountStudent || {}));
      resultConnectAccountStudent?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Connect Account student", "Failed");
    yield put(connectAccountStudentFailed());
  }
}

export function* watchConnectAccountStudentRequest() {
  yield takeEvery(
    ActionType.CONNECT_ACCOUNT_STUDENT_REQUEST,
    connectAccountStudent
  );
}

function* getListFamily({ payload }) {
  try {
    const resultListFamily = yield call(Api.fetchListFamily, {
      params: { ...payload.params },
    });
    yield put(
      getListFamilySuccess(
        { ...resultListFamily, limit: payload?.params?.limit } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list family", "Failed");
    yield put(getListFamilyFailed());
  }
}

export function* watchListFamilyRequest() {
  yield takeEvery(ActionType.GET_LIST_FAMILY_REQUEST, getListFamily);
}

function* getChildFamily({ payload }) {
  try {
    const resultChildFamily = yield call(Api.fetchChildFamily, payload);
    yield put(getChildFamilySuccess(resultChildFamily || {}));
  } catch (error) {
    errorNotification("Get child family", "Failed");
    yield put(getChildFamilyFailed());
  }
}

export function* watchGetChildFamilyRequest() {
  yield takeEvery(ActionType.GET_CHILD_FAMILY_REQUEST, getChildFamily);
}

function* setAccountToFamily({ payload }) {
  try {
    const resultSetAccountToFamily = yield call(
      Api.fetchSetAccounToFamily,
      payload
    );
    if (resultSetAccountToFamily.success === true) {
      yield put(setAccountToFamilySuccess(resultSetAccountToFamily.data));
      successNotification("Set Account to Family", "Success");
    } else {
      yield put(setAccountToFamilyFailed(resultSetAccountToFamily || {}));
      resultSetAccountToFamily?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Set Account to Family", "Failed");
    yield put(setAccountToFamilyFailed());
  }
}

export function* watchSetAccountToFamilyRequest() {
  yield takeEvery(ActionType.SET_ACCOUNT_TO_FAMILY_REQUEST, setAccountToFamily);
}

function* setAccountToFamilyAdmin({ payload }) {
  try {
    const resultSetAccountToFamilyAdmin = yield call(
      Api.fetchSetAccounToFamilyAdmin,
      payload
    );
    if (resultSetAccountToFamilyAdmin.success === true) {
      yield put(
        setAccountToFamilyAdminSuccess(resultSetAccountToFamilyAdmin.data)
      );
      successNotification("Set Account to FamilyAdmin", "Success");
    } else {
      yield put(
        setAccountToFamilyAdminFailed(resultSetAccountToFamilyAdmin || {})
      );
      resultSetAccountToFamilyAdmin?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Set Account to FamilyAdmin", "Failed");
    yield put(setAccountToFamilyAdminFailed());
  }
}

export function* watchSetAccountToFamilyAdminRequest() {
  yield takeEvery(
    ActionType.SET_ACCOUNT_TO_FAMILY_ADMIN_REQUEST,
    setAccountToFamilyAdmin
  );
}

function* verifyConnectFamily({ payload }) {
  try {
    const resultVerifyConnectFamily = yield call(
      Api.fetchVerifyConnectFamily,
      payload
    );
    if (resultVerifyConnectFamily.success === true) {
      yield put(verifyConnectFamilySuccess(resultVerifyConnectFamily.data));
      successNotification("Verify Connect Family", "Success");
    } else {
      yield put(verifyConnectFamilyFailed(resultVerifyConnectFamily || {}));
      resultVerifyConnectFamily?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Verify Connect Family", "Failed");
    yield put(verifyConnectFamilyFailed());
  }
}

export function* watchVerifyConnectFamilyRequest() {
  yield takeEvery(
    ActionType.VERIFY_CONNECT_FAMILY_REQUEST,
    verifyConnectFamily
  );
}

function* getDetailConnectionByStudentId({ payload }) {
  try {
    const resultPublisher = yield call(
      Api.fetchGetDetailConnectionByStudentId,
      payload
    );
    yield put(
      getDetailConnectionByStudentIdSuccess({ ...resultPublisher } || {})
    );
  } catch (error) {
    errorNotification("Get detail connection by student id", "Failed");
    yield put(getDetailConnectionByStudentIdFailed());
  }
}

export function* watchGetDetailConnectionByStudentIdRequest() {
  yield takeEvery(
    ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_REQUEST,
    getDetailConnectionByStudentId
  );
}

function* getDetailConnectionByStudentIdAdmin({ payload }) {
  try {
    const resultPublisher = yield call(
      Api.fetchGetDetailConnectionByStudentIdAdmin,
      payload
    );
    yield put(
      getDetailConnectionByStudentIdAdminSuccess({ ...resultPublisher } || {})
    );
  } catch (error) {
    errorNotification("Get detail connection by student id", "Failed");
    yield put(getDetailConnectionByStudentIdAdminFailed());
  }
}

export function* watchGetDetailConnectionByStudentIdAdminRequest() {
  yield takeEvery(
    ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_REQUEST,
    getDetailConnectionByStudentIdAdmin
  );
}

export function* updateTeacherAdminAccessRequest({ payload }) {
  try {
    const resultUpdateTeacherAdminAccess = yield call(
      Api.updateTeacherAdminAccess,
      payload
    );
    if (resultUpdateTeacherAdminAccess.success === true) {
      yield put(
        updateTeacherAdminAccessSuccess(resultUpdateTeacherAdminAccess.data)
      );
      successNotification("Update teacher admin access", "Success");
    } else {
      yield put(
        updateTeacherAdminAccessFailed(resultUpdateTeacherAdminAccess || {})
      );
      resultUpdateTeacherAdminAccess?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update teacher admin access", "Failed");
    yield put(updateTeacherAdminAccessFailed());
  }
}

export function* watchUpdateTeacherAdminAccessRequest() {
  yield takeEvery(
    ActionType.UPDATE_TEACHER_ADMIN_ACCESS_REQUEST,
    updateTeacherAdminAccessRequest
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchListSchoolRequest),
    fork(watchListTeacherSchoolByIdRequest),
    fork(watchListStudentSchoolByIdRequest),
    fork(watchCreateSchoolRequest),
    fork(watchUpdateSchoolRequest),
    fork(watchUpdateStatusSchoolRequest),
    fork(watchListClassSchoolByIdRequest),
    fork(watchCreateClassRequest),
    fork(watchUpdateClassRequest),
    fork(watchDeleteClassRequest),
    fork(watchCreateTeacherRequest),
    fork(watchUpdateStatusTeacherRequest),
    fork(watchUpdateTeacherRequest),
    fork(watchDeleteTeacherRequest),
    fork(watchSetClassTeacherRequest),
    fork(watchSetClassesStudentRequest),
    fork(watchDeleteStudentRequest),
    fork(watchUpdateStatusStudentRequest),
    fork(watchSetBulkActiveTeacherRequest),
    fork(watchSetBulkActiveStudentRequest),
    fork(watchFindSchoolRequest),
    fork(watchGetDetailSchoolRequest),
    fork(watchGetSummarySchoolRequest),
    fork(watchActivitySchoolRequest),
    fork(watchListOfReadSchoolRequest),
    fork(watchListOfReadStudentRequest),
    fork(watchReadHistorySchoolRequest),
    fork(watchDetailReadHistorySchoolRequest),
    fork(watchCreateStudentRequest),
    fork(watchUpdateStudentRequest),
    fork(watchSetClassStudentRequest),
    fork(watchConnectAccountStudentRequest),
    fork(watchListFamilyRequest),
    fork(watchGetChildFamilyRequest),
    fork(watchSetAccountToFamilyRequest),
    fork(watchVerifyConnectFamilyRequest),
    fork(watchGetDetailConnectionByStudentIdRequest),
    fork(watchGetDetailConnectionByStudentIdAdminRequest),
    fork(watchSetAccountToFamilyAdminRequest),
    fork(watchUpdateSchoolCredRequest),
    fork(watchUpdateTeacherAdminAccessRequest),
  ]);
}
