export const GET_LIST_CATEGORY_TYPE_REQUEST = "GET_LIST_CATEGORY_TYPE_REQUEST";
export const GET_LIST_CATEGORY_TYPE_SUCCESS = "GET_LIST_CATEGORY_TYPE_SUCCESS";
export const GET_LIST_CATEGORY_TYPE_FAILED = "GET_LIST_CATEGORY_TYPE_FAILED";

export const GET_LIST_SUB_CATEGORY_REQUEST = "GET_LIST_SUB_CATEGORY_REQUEST";
export const GET_LIST_SUB_CATEGORY_SUCCESS = "GET_LIST_SUB_CATEGORY_SUCCESS";
export const GET_LIST_SUB_CATEGORY_FAILED = "GET_LIST_SUB_CATEGORY_FAILED";

export const CREATE_SUB_CATEGORY_REQUEST = "CREATE_SUB_CATEGORY_REQUEST";
export const CREATE_SUB_CATEGORY_SUCCESS = "CREATE_SUB_CATEGORY_SUCCESS";
export const CREATE_SUB_CATEGORY_FAILED = "CREATE_SUB_CATEGORY_FAILED";

export const UPDATE_STATUS_SUB_CATEGORY_REQUEST =
  "UPDATE_STATUS_SUB_CATEGORY_REQUEST";
export const UPDATE_STATUS_SUB_CATEGORY_SUCCESS =
  "UPDATE_STATUS_SUB_CATEGORY_SUCCESS";
export const UPDATE_STATUS_SUB_CATEGORY_FAILED =
  "UPDATE_STATUS_SUB_CATEGORY_FAILED";

export const UPDATE_SUB_CATEGORY_REQUEST = "UPDATE_SUB_CATEGORY_REQUEST";
export const UPDATE_SUB_CATEGORY_SUCCESS = "UPDATE_SUB_CATEGORY_SUCCESS";
export const UPDATE_SUB_CATEGORY_FAILED = "UPDATE_SUB_CATEGORY_FAILED";

export const GET_SUB_CATEGORY_BY_ID_REQUEST = "GET_SUB_CATEGORY_BY_ID_REQUEST";
export const GET_SUB_CATEGORY_BY_ID_SUCCESS = "GET_SUB_CATEGORY_BY_ID_SUCCESS";
export const GET_SUB_CATEGORY_BY_ID_FAILED = "GET_SUB_CATEGORY_BY_ID_FAILED";

export const SET_BOOK_CATEGORY_REQUEST = "SET_BOOK_CATEGORY_REQUEST";
export const SET_BOOK_CATEGORY_SUCCESS = "SET_BOOK_CATEGORY_SUCCESS";
export const SET_BOOK_CATEGORY_FAILED = "SET_BOOK_CATEGORY_FAILED";

export const UNSET_BOOK_CATEGORY_REQUEST = "UNSET_BOOK_CATEGORY_REQUEST";
export const UNSET_BOOK_CATEGORY_SUCCESS = "UNSET_BOOK_CATEGORY_SUCCESS";
export const UNSET_BOOK_CATEGORY_FAILED = "UNSET_BOOK_CATEGORY_FAILED";
