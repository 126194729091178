export const GET_LIST_SCHOOL_REQUEST = "GET_LIST_SCHOOL_REQUEST";
export const GET_LIST_SCHOOL_SUCCESS = "GET_LIST_SCHOOL_SUCCESS";
export const GET_LIST_SCHOOL_FAILED = "GET_LIST_SCHOOL_FAILED";

export const GET_LIST_TEACHER_BY_SCHOOL_ID_REQUEST =
  "GET_LIST_TEACHER_BY_SCHOOL_ID_REQUEST";
export const GET_LIST_TEACHER_BY_SCHOOL_ID_SUCCESS =
  "GET_LIST_TEACHER_BY_SCHOOL_ID_SUCCESS";
export const GET_LIST_TEACHER_BY_SCHOOL_ID_FAILED =
  "GET_LIST_TEACHER_BY_SCHOOL_ID_FAILED";

export const GET_LIST_STUDENT_BY_SCHOOL_ID_REQUEST =
  "GET_LIST_STUDENT_BY_SCHOOL_ID_REQUEST";
export const GET_LIST_STUDENT_BY_SCHOOL_ID_SUCCESS =
  "GET_LIST_STUDENT_BY_SCHOOL_ID_SUCCESS";
export const GET_LIST_STUDENT_BY_SCHOOL_ID_FAILED =
  "GET_LIST_STUDENT_BY_SCHOOL_ID_FAILED";

export const CREATE_SCHOOL_REQUEST = "CREATE_SCHOOL_REQUEST";
export const CREATE_SCHOOL_SUCCESS = "CREATE_SCHOOL_SUCCESS";
export const CREATE_SCHOOL_FAILED = "CREATE_SCHOOL_FAILED";

export const UPDATE_SCHOOL_REQUEST = "UPDATE_SCHOOL_REQUEST";
export const UPDATE_SCHOOL_SUCCESS = "UPDATE_SCHOOL_SUCCESS";
export const UPDATE_SCHOOL_FAILED = "UPDATE_SCHOOL_FAILED";

export const UPDATE_SCHOOL_CRED_REQUEST = "UPDATE_SCHOOL_CRED_REQUEST";
export const UPDATE_SCHOOL_CRED_SUCCESS = "UPDATE_SCHOOL_CRED_SUCCESS";
export const UPDATE_SCHOOL_CRED_FAILED = "UPDATE_SCHOOL_CRED_FAILED";

export const RESET_STATUS_SCHOOL_REQUEST = "RESET_STATUS_SCHOOL_REQUEST";

export const UPDATE_STATUS_SCHOOL_REQUEST = "UPDATE_STATUS_SCHOOL_REQUEST";
export const UPDATE_STATUS_SCHOOL_SUCCESS = "UPDATE_STATUS_SCHOOL_SUCCESS";
export const UPDATE_STATUS_SCHOOL_FAILED = "UPDATE_STATUS_SCHOOL_FAILED";

export const GET_LIST_CLASS_BY_SCHOOL_ID_REQUEST =
  "GET_LIST_CLASS_BY_SCHOOL_ID_REQUEST";
export const GET_LIST_CLASS_BY_SCHOOL_ID_SUCCESS =
  "GET_LIST_CLASS_BY_SCHOOL_ID_SUCCESS";
export const GET_LIST_CLASS_BY_SCHOOL_ID_FAILED =
  "GET_LIST_CLASS_BY_SCHOOL_ID_FAILED";

export const CREATE_CLASS_REQUEST = "CREATE_CLASS_REQUEST";
export const CREATE_CLASS_SUCCESS = "CREATE_CLASS_SUCCESS";
export const CREATE_CLASS_FAILED = "CREATE_CLASS_FAILED";

export const UPDATE_CLASS_REQUEST = "UPDATE_CLASS_REQUEST";
export const UPDATE_CLASS_SUCCESS = "UPDATE_CLASS_SUCCESS";
export const UPDATE_CLASS_FAILED = "UPDATE_CLASS_FAILED";

export const DELETE_CLASS_REQUEST = "DELETE_CLASS_REQUEST";
export const DELETE_CLASS_SUCCESS = "DELETE_CLASS_SUCCESS";
export const DELETE_CLASS_FAILED = "DELETE_CLASS_FAILED";

export const CREATE_TEACHER_REQUEST = "CREATE_TEACHER_REQUEST";
export const CREATE_TEACHER_SUCCESS = "CREATE_TEACHER_SUCCESS";
export const CREATE_TEACHER_FAILED = "CREATE_TEACHER_FAILED";

export const UPDATE_STATUS_TEACHER_REQUEST = "UPDATE_STATUS_TEACHER_REQUEST";
export const UPDATE_STATUS_TEACHER_SUCCESS = "UPDATE_STATUS_TEACHER_SUCCESS";
export const UPDATE_STATUS_TEACHER_FAILED = "UPDATE_STATUS_TEACHER_FAILED";

export const UPDATE_TEACHER_REQUEST = "UPDATE_TEACHER_REQUEST";
export const UPDATE_TEACHER_SUCCESS = "UPDATE_TEACHER_SUCCESS";
export const UPDATE_TEACHER_FAILED = "UPDATE_TEACHER_FAILED";

export const DELETE_TEACHER_REQUEST = "DELETE_TEACHER_REQUEST";
export const DELETE_TEACHER_SUCCESS = "DELETE_TEACHER_SUCCESS";
export const DELETE_TEACHER_FAILED = "DELETE_TEACHER_FAILED";

export const SET_CLASS_TEACHER_REQUEST = "SET_CLASS_TEACHER_REQUEST";
export const SET_CLASS_TEACHER_SUCCESS = "SET_CLASS_TEACHER_SUCCESS";
export const SET_CLASS_TEACHER_FAILED = "SET_CLASS_TEACHER_FAILED";

export const SET_CLASSES_STUDENT_REQUEST = "SET_CLASSES_STUDENT_REQUEST";
export const SET_CLASSES_STUDENT_SUCCESS = "SET_CLASSES_STUDENT_SUCCESS";
export const SET_CLASSES_STUDENT_FAILED = "SET_CLASSES_STUDENT_FAILED";

export const DELETE_STUDENT_REQUEST = "DELETE_STUDENT_REQUEST";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_FAILED = "DELETE_STUDENT_FAILED";

export const UPDATE_STATUS_STUDENT_REQUEST = "UPDATE_STATUS_STUDENT_REQUEST";
export const UPDATE_STATUS_STUDENT_SUCCESS = "UPDATE_STATUS_STUDENT_SUCCESS";
export const UPDATE_STATUS_STUDENT_FAILED = "UPDATE_STATUS_STUDENT_FAILED";

export const SET_BULK_ACTIVE_TEACHER_REQUEST =
  "SET_BULK_ACTIVE_TEACHER_REQUEST";
export const SET_BULK_ACTIVE_TEACHER_SUCCESS =
  "SET_BULK_ACTIVE_TEACHER_SUCCESS";
export const SET_BULK_ACTIVE_TEACHER_FAILED = "SET_BULK_ACTIVE_TEACHER_FAILED";

export const SET_BULK_ACTIVE_STUDENT_REQUEST =
  "SET_BULK_ACTIVE_STUDENT_REQUEST";
export const SET_BULK_ACTIVE_STUDENT_SUCCESS =
  "SET_BULK_ACTIVE_STUDENT_SUCCESS";
export const SET_BULK_ACTIVE_STUDENT_FAILED = "SET_BULK_ACTIVE_STUDENT_FAILED";

export const FIND_SCHOOL_REQUEST = "FIND_SCHOOL_REQUEST";
export const FIND_SCHOOL_SUCCESS = "FIND_SCHOOL_SUCCESS";
export const FIND_SCHOOL_FAILED = "FIND_SCHOOL_FAILED";

export const GET_DETAIL_SCHOOL_REQUEST = "GET_DETAIL_SCHOOL_REQUEST";
export const GET_DETAIL_SCHOOL_SUCCESS = "GET_DETAIL_SCHOOL_SUCCESS";
export const GET_DETAIL_SCHOOL_FAILED = "GET_DETAIL_SCHOOL_FAILED";

export const GET_SUMMARY_SCHOOL_REQUEST = "GET_SUMMARY_SCHOOL_REQUEST";
export const GET_SUMMARY_SCHOOL_SUCCESS = "GET_SUMMARY_SCHOOL_SUCCESS";
export const GET_SUMMARY_SCHOOL_FAILED = "GET_SUMMARY_SCHOOL_FAILED";

export const GET_ACTIVITY_SCHOOL_REQUEST = "GET_ACTIVITY_SCHOOL_REQUEST";
export const GET_ACTIVITY_SCHOOL_SUCCESS = "GET_ACTIVITY_SCHOOL_SUCCESS";
export const GET_ACTIVITY_SCHOOL_FAILED = "GET_ACTIVITY_SCHOOL_FAILED";

export const GET_LIST_OF_READ_SCHOOL_REQUEST =
  "GET_LIST_OF_READ_SCHOOL_REQUEST";
export const GET_LIST_OF_READ_SCHOOL_SUCCESS =
  "GET_LIST_OF_READ_SCHOOL_SUCCESS";
export const GET_LIST_OF_READ_SCHOOL_FAILED = "GET_LIST_OF_READ_SCHOOL_FAILED";

export const GET_LIST_OF_READ_STUDENT_REQUEST =
  "GET_LIST_OF_READ_STUDENT_REQUEST";
export const GET_LIST_OF_READ_STUDENT_SUCCESS =
  "GET_LIST_OF_READ_STUDENT_SUCCESS";
export const GET_LIST_OF_READ_STUDENT_FAILED =
  "GET_LIST_OF_READ_STUDENT_FAILED";

export const GET_READ_HISTORY_SCHOOL_REQUEST =
  "GET_READ_HISTORY_SCHOOL_REQUEST";
export const GET_READ_HISTORY_SCHOOL_SUCCESS =
  "GET_READ_HISTORY_SCHOOL_SUCCESS";
export const GET_READ_HISTORY_SCHOOL_FAILED = "GET_READ_HISTORY_SCHOOL_FAILED";

export const GET_DETAIL_READ_HISTORY_SCHOOL_REQUEST =
  "GET_DETAIL_READ_HISTORY_SCHOOL_REQUEST";
export const GET_DETAIL_READ_HISTORY_SCHOOL_SUCCESS =
  "GET_DETAIL_READ_HISTORY_SCHOOL_SUCCESS";
export const GET_DETAIL_READ_HISTORY_SCHOOL_FAILED =
  "GET_DETAIL_READ_HISTORY_SCHOOL_FAILED";

export const CREATE_STUDENT_REQUEST = "CREATE_STUDENT_REQUEST";
export const CREATE_STUDENT_SUCCESS = "CREATE_STUDENT_SUCCESS";
export const CREATE_STUDENT_FAILED = "CREATE_STUDENT_FAILED";

export const UPDATE_STUDENT_REQUEST = "UPDATE_STUDENT_REQUEST";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_FAILED = "UPDATE_STUDENT_FAILED";

export const SET_CLASS_STUDENT_REQUEST = "SET_CLASS_STUDENT_REQUEST";
export const SET_CLASS_STUDENT_SUCCESS = "SET_CLASS_STUDENT_SUCCESS";
export const SET_CLASS_STUDENT_FAILED = "SET_CLASS_STUDENT_FAILED";

export const CONNECT_ACCOUNT_STUDENT_REQUEST =
  "CONNECT_ACCOUNT_STUDENT_REQUEST";
export const CONNECT_ACCOUNT_STUDENT_SUCCESS =
  "CONNECT_ACCOUNT_STUDENT_SUCCESS";
export const CONNECT_ACCOUNT_STUDENT_FAILED = "CONNECT_ACCOUNT_STUDENT_FAILED";

export const GET_LIST_FAMILY_REQUEST = "GET_LIST_FAMILY_REQUEST";
export const GET_LIST_FAMILY_SUCCESS = "GET_LIST_FAMILY_SUCCESS";
export const GET_LIST_FAMILY_FAILED = "GET_LIST_FAMILY_FAILED";

export const GET_CHILD_FAMILY_REQUEST = "GET_CHILD_FAMILY_REQUEST";
export const GET_CHILD_FAMILY_SUCCESS = "GET_CHILD_FAMILY_SUCCESS";
export const GET_CHILD_FAMILY_FAILED = "GET_CHILD_FAMILY_FAILED";

export const SET_ACCOUNT_TO_FAMILY_REQUEST = "SET_ACCOUNT_TO_FAMILY_REQUEST";
export const SET_ACCOUNT_TO_FAMILY_SUCCESS = "SET_ACCOUNT_TO_FAMILY_SUCCESS";
export const SET_ACCOUNT_TO_FAMILY_FAILED = "SET_ACCOUNT_TO_FAMILY_FAILED";

export const SET_ACCOUNT_TO_FAMILY_ADMIN_REQUEST =
  "SET_ACCOUNT_TO_FAMILY_ADMIN_REQUEST";
export const SET_ACCOUNT_TO_FAMILY_ADMIN_SUCCESS =
  "SET_ACCOUNT_TO_FAMILY_ADMIN_SUCCESS";
export const SET_ACCOUNT_TO_FAMILY_ADMIN_FAILED =
  "SET_ACCOUNT_TO_FAMILY_ADMIN_FAILED";

export const VERIFY_CONNECT_FAMILY_REQUEST = "VERIFY_CONNECT_FAMILY_REQUEST";
export const VERIFY_CONNECT_FAMILY_SUCCESS = "VERIFY_CONNECT_FAMILY_SUCCESS";
export const VERIFY_CONNECT_FAMILY_FAILED = "VERIFY_CONNECT_FAMILY_FAILED";

export const GET_DETAIL_CONNECTION_BY_STUDENT_ID_REQUEST =
  "GET_DETAIL_CONNECTION_BY_STUDENT_ID_REQUEST";
export const GET_DETAIL_CONNECTION_BY_STUDENT_ID_SUCCESS =
  "GET_DETAIL_CONNECTION_BY_STUDENT_ID_SUCCESS";
export const GET_DETAIL_CONNECTION_BY_STUDENT_ID_FAILED =
  "GET_DETAIL_CONNECTION_BY_STUDENT_ID_FAILED";

export const GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_REQUEST =
  "GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_REQUEST";
export const GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_SUCCESS =
  "GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_SUCCESS";
export const GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_FAILED =
  "GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_FAILED";

export const UPDATE_TEACHER_ADMIN_ACCESS_REQUEST =
  "UPDATE_TEACHER_ADMIN_ACCESS_REQUEST";
export const UPDATE_TEACHER_ADMIN_ACCESS_SUCCESS =
  "UPDATE_TEACHER_ADMIN_ACCESS_SUCCESS";
export const UPDATE_TEACHER_ADMIN_ACCESS_FAILED =
  "UPDATE_TEACHER_ADMIN_ACCESS_FAILED";
