import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as ActionType from "../constants/ActivitySheet";
import * as Api from "../../api/activitySheet";
import {
  getListActivitySheetSuccess,
  getListActivitySheetFailed,
  createActivitySheetSectionSuccess,
  createActivitySheetSectionFailed,
  updateActivitySheetSectionSuccess,
  updateActivitySheetSectionFailed,
  updateSortActivitySheetSuccess,
  updateSortActivitySheetFailed,
  getListActivitySheetSectionSuccess,
  getListActivitySheetSectionFailed,
  createActivitySheetSuccess,
  createActivitySheetFailed,
  getActivitySheetByIdSuccess,
  getActivitySheetByIdFailed,
  updateActivitySheetSuccess,
  updateActivitySheetFailed,
  deleteActivitySheetSuccess,
  deleteActivitySheetFailed,
} from "../actions/ActivitySheet";
import {
  errorNotification,
  successNotification,
} from "../../components/NotificationPopUp";

function* getListActivitySheet({ payload }) {
  try {
    const resultListActivitySheetsSection = yield call(
      Api.fetchListActivitySheetsSection,
      {
        params: {
          limit: 0,
          page: 1,
        },
      }
    );
    const resultListActivitySheet = yield call(Api.fetchListActivitySheets, {
      params: {
        limit: 0,
        page: 1,
        sectionIDs: resultListActivitySheetsSection.data
          .map((section) => section.activitySheetSectionId)
          .join(","),
        ...payload?.params,
      },
    });
    yield put(
      getListActivitySheetSuccess(
        {
          listActivitySheetsSection: resultListActivitySheetsSection,
          listActivitySheet: resultListActivitySheet,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list activity sheet", "Failed");
    yield put(getListActivitySheetFailed());
  }
}

export function* watchListActivitySheetRequest() {
  yield takeEvery(
    ActionType.GET_LIST_ACTIVITY_SHEET_REQUEST,
    getListActivitySheet
  );
}

function* createActivitySheetSection({ payload }) {
  try {
    const resultCreateActivitySheetSection = yield call(
      Api.fetchCreateActivitySheetSection,
      payload
    );
    if (resultCreateActivitySheetSection.success === true) {
      yield put(
        createActivitySheetSectionSuccess(resultCreateActivitySheetSection.data)
      );
      successNotification("Create activity sheet section", "Success");
    } else {
      yield put(
        createActivitySheetSectionFailed(resultCreateActivitySheetSection || {})
      );
      resultCreateActivitySheetSection?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create activity sheet section", "Failed");
    yield put(createActivitySheetSectionFailed());
  }
}

export function* watchCreateActivitySheetSectionRequest() {
  yield takeEvery(
    ActionType.CREATE_ACTIVITY_SHEET_SECTION_REQUEST,
    createActivitySheetSection
  );
}

function* updateActivitySheetSection({ payload }) {
  try {
    const resultUpdateActivitySheetSection = yield call(
      Api.fetchUpdateActivitySheetSection,
      payload
    );
    if (resultUpdateActivitySheetSection.success === true) {
      yield put(
        updateActivitySheetSectionSuccess(resultUpdateActivitySheetSection.data)
      );
      successNotification("Update actvity sheet section", "Success");
    } else {
      yield put(
        updateActivitySheetSectionFailed(resultUpdateActivitySheetSection || {})
      );
      resultUpdateActivitySheetSection?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update activity sheet section", "Failed");
    yield put(updateActivitySheetSectionFailed());
  }
}

export function* watchUpdateActivitSheetSectionRequest() {
  yield takeEvery(
    ActionType.UPDATE_ACTIVITY_SHEET_SECTION_REQUEST,
    updateActivitySheetSection
  );
}

function* updateSortActivitySheet({ payload }) {
  try {
    const resultUpdateSortActivitySheet = yield call(
      Api.fetchUpdateSortActivitySheet,
      payload
    );
    if (resultUpdateSortActivitySheet.success === true) {
      yield put(
        updateSortActivitySheetSuccess(resultUpdateSortActivitySheet.data)
      );
      successNotification("Update sort recommendation", "Success");
    } else {
      yield put(
        updateSortActivitySheetFailed(resultUpdateSortActivitySheet || {})
      );
      resultUpdateSortActivitySheet?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update sort recommendation", "Failed");
    yield put(updateSortActivitySheetFailed());
  }
}

export function* watchUpdateSortActivitySheetRequest() {
  yield takeEvery(
    ActionType.UPDATE_SORT_ACTIVITY_SHEET_REQUEST,
    updateSortActivitySheet
  );
}

function* getListActivitySheetSection() {
  try {
    const resultListActivitySheetSection = yield call(
      Api.fetchListActivitySheetsSection,
      {
        params: {
          limit: 0,
          page: 1,
        },
      }
    );
    yield put(
      getListActivitySheetSectionSuccess(
        {
          ...resultListActivitySheetSection,
        } || {}
      )
    );
  } catch (error) {
    errorNotification("Get list activity sheet section", "Failed");
    yield put(getListActivitySheetSectionFailed());
  }
}

export function* watchListActivitySheetSectionRequest() {
  yield takeEvery(
    ActionType.GET_LIST_ACTIVITY_SHEET_SECTION_REQUEST,
    getListActivitySheetSection
  );
}

function* createActivitySheet({ payload }) {
  try {
    const resultCreateActivitySheet = yield call(
      Api.fetchCreateActivitySheet,
      payload
    );
    if (resultCreateActivitySheet.success === true) {
      yield put(createActivitySheetSuccess(resultCreateActivitySheet.data));
      successNotification("Create activity sheet", "Success");
    } else {
      yield put(createActivitySheetFailed(resultCreateActivitySheet || {}));
      resultCreateActivitySheet?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Create activity sheet", "Failed");
    yield put(createActivitySheetFailed());
  }
}

export function* watchCreateActivitySheetRequest() {
  yield takeEvery(
    ActionType.CREATE_ACTIVITY_SHEET_REQUEST,
    createActivitySheet
  );
}

function* getActivitySheetById({ payload }) {
  try {
    const resultActivitySheet = yield call(Api.fetchActivitySheetById, {
      activitySheetId: payload?.activitySheetId,
    });
    yield put(getActivitySheetByIdSuccess({ ...resultActivitySheet } || {}));
  } catch (error) {
    errorNotification("Get activity sheet by id", "Failed");
    yield put(getActivitySheetByIdFailed());
  }
}

export function* watchGetActivitySheetByIdRequest() {
  yield takeEvery(
    ActionType.GET_ACTIVITY_SHEET_BY_ID_REQUEST,
    getActivitySheetById
  );
}

function* updateActivitySheet({ payload }) {
  try {
    const resultUpdateActivitySheet = yield call(
      Api.fetchUpdateActivitySheet,
      payload
    );
    yield put(
      updateActivitySheetSuccess(
        {
          data: {
            ...payload,
          },
        } || {}
      )
    );
    if (resultUpdateActivitySheet.success === true) {
      successNotification("Update activity sheet", "Success");
    } else {
      resultUpdateActivitySheet?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Update activity sheet", "Failed");
    yield put(updateActivitySheetFailed());
  }
}

export function* watchUpdateActivitySheetRequest() {
  yield takeEvery(
    ActionType.UPDATE_ACTIVITY_SHEET_REQUEST,
    updateActivitySheet
  );
}

function* deleteActivitySheet({ payload }) {
  try {
    const resultDeleteActivitySheet = yield call(
      Api.fetchDeleteActivitySheet,
      payload
    );
    if (resultDeleteActivitySheet.success === true) {
      yield put(deleteActivitySheetSuccess(resultDeleteActivitySheet.data));
      successNotification("Delete activity sheet", "Success");
    } else {
      yield put(deleteActivitySheetFailed(resultDeleteActivitySheet || {}));
      resultDeleteActivitySheet?.errors?.map((error) =>
        errorNotification(error?.message)
      );
    }
  } catch (error) {
    errorNotification("Delete activity sheet", "Failed");
    yield put(deleteActivitySheetFailed());
  }
}

export function* watchDeleteActivitySheetRequest() {
  yield takeEvery(
    ActionType.DELETE_ACTIVITY_SHEET_REQUEST,
    deleteActivitySheet
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchListActivitySheetRequest),
    fork(watchCreateActivitySheetSectionRequest),
    fork(watchUpdateActivitSheetSectionRequest),
    fork(watchUpdateSortActivitySheetRequest),
    fork(watchListActivitySheetSectionRequest),
    fork(watchCreateActivitySheetRequest),
    fork(watchGetActivitySheetByIdRequest),
    fork(watchUpdateActivitySheetRequest),
    fork(watchDeleteActivitySheetRequest),
  ]);
}
