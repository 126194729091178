import httpRequest from "../util/axios";

export const fetchListBanner = async (option = {}) => {
  try {
    const result = await httpRequest.get("/admin/banners", option, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateBanner = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/banners",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateBanner = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/banners/${body?.bannerId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDeleteBanner = async (body = {}) => {
  try {
    const result = await httpRequest.delete(
      `/admin/banners/${body?.bannerId}`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchGetBannerOnSchool = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/public/config/literaton-2",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
