import httpRequest from "../util/axios";

export const fetchDashboardTotalUsersChart = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-users-chart",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalUsersAverage = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-users-average",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalRevenue = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-revenue",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalUsersSigninCohort = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-users-signin-cohort",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalUsersSubscriberCohort = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-users-subscriber-cohort",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalUsersTransactionsAverage = async (
  option = {}
) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-users-transactions-average",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalTransactionsPackageRatio = async (
  option = {}
) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-transactions-package-ratio",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalDurationReadAverage = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-duration-read-average",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalAccountSummary = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-account-summary",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalCollectionAndCompletionBook = async (
  option = {}
) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-collection-and-completion-book",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalSummaryReadChart = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-summary-read-chart",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalTopReader = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-top-reader",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardChartUserLogin = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/chart-user-login",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalUserLogin = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-user-login",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalBookDurationById = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-book-duration-by-id",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalVoucherUsage = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-voucher-usage",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalVoucherChart = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-voucher-chart",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardActivitySheets = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/activity-sheets",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardTotalContentPopular = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/total-content-popular",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardSegmentationsUsers = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/segmentations/users",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardSegmentationsTopUsers = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/segmentations/top-users",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardSegmentationsChartUsers = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/dashboards/segmentations/chart-users",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
