export const GET_DASHBOARD_TOTAL_USERS_CHART_REQUEST =
  "GET_DASHBOARD_TOTAL_USERS_CHART_REQUEST";
export const GET_DASHBOARD_TOTAL_USERS_CHART_SUCCESS =
  "GET_DASHBOARD_TOTAL_USERS_CHART_SUCCESS";
export const GET_DASHBOARD_TOTAL_USERS_CHART_FAILED =
  "GET_DASHBOARD_TOTAL_USERS_CHART_FAILED";

export const GET_DASHBOARD_TOTAL_USERS_AVERAGE_REQUEST =
  "GET_DASHBOARD_TOTAL_USERS_AVERAGE_REQUEST";
export const GET_DASHBOARD_TOTAL_USERS_AVERAGE_SUCCESS =
  "GET_DASHBOARD_TOTAL_USERS_AVERAGE_SUCCESS";
export const GET_DASHBOARD_TOTAL_USERS_AVERAGE_FAILED =
  "GET_DASHBOARD_TOTAL_USERS_AVERAGE_FAILED";

export const GET_DASHBOARD_TOTAL_REVENUE_REQUEST =
  "GET_DASHBOARD_TOTAL_REVENUE_REQUEST";
export const GET_DASHBOARD_TOTAL_REVENUE_SUCCESS =
  "GET_DASHBOARD_TOTAL_REVENUE_SUCCESS";
export const GET_DASHBOARD_TOTAL_REVENUE_FAILED =
  "GET_DASHBOARD_TOTAL_REVENUE_FAILED";

export const GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_REQUEST =
  "GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_REQUEST";
export const GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_SUCCESS =
  "GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_SUCCESS";
export const GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_FAILED =
  "GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_FAILED";

export const GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_REQUEST =
  "GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_REQUEST";
export const GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_SUCCESS =
  "GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_SUCCESS";
export const GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_FAILED =
  "GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_FAILED";

export const GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_REQUEST =
  "GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_REQUEST";
export const GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_SUCCESS =
  "GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_SUCCESS";
export const GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_FAILED =
  "GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_FAILED";

export const GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_REQUEST =
  "GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_REQUEST";
export const GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_SUCCESS =
  "GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_SUCCESS";
export const GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_FAILED =
  "GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_FAILED";

export const GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_REQUEST =
  "GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_REQUEST";
export const GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_SUCCESS =
  "GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_SUCCESS";
export const GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_FAILED =
  "GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_FAILED";

export const GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_REQUEST =
  "GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_REQUEST";
export const GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_SUCCESS =
  "GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_SUCCESS";
export const GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_FAILED =
  "GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_FAILED";

export const GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_REQUEST =
  "GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_REQUEST";
export const GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_SUCCESS =
  "GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_SUCCESS";
export const GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_FAILED =
  "GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_FAILED";

export const GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_REQUEST =
  "GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_REQUEST";
export const GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_SUCCESS =
  "GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_SUCCESS";
export const GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_FAILED =
  "GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_FAILED";

export const GET_DASHBOARD_TOTAL_TOP_READER_REQUEST =
  "GET_DASHBOARD_TOTAL_TOP_READER_REQUEST";
export const GET_DASHBOARD_TOTAL_TOP_READER_SUCCESS =
  "GET_DASHBOARD_TOTAL_TOP_READER_SUCCESS";
export const GET_DASHBOARD_TOTAL_TOP_READER_FAILED =
  "GET_DASHBOARD_TOTAL_TOP_READER_FAILED";

export const GET_DASHBOARD_CHART_USER_LOGIN_REQUEST =
  "GET_DASHBOARD_CHART_USER_LOGIN_REQUEST";
export const GET_DASHBOARD_CHART_USER_LOGIN_SUCCESS =
  "GET_DASHBOARD_CHART_USER_LOGIN_SUCCESS";
export const GET_DASHBOARD_CHART_USER_LOGIN_FAILED =
  "GET_DASHBOARD_CHART_USER_LOGIN_FAILED";

export const GET_DASHBOARD_TOTAL_USER_LOGIN_REQUEST =
  "GET_DASHBOARD_TOTAL_USER_LOGIN_REQUEST";
export const GET_DASHBOARD_TOTAL_USER_LOGIN_SUCCESS =
  "GET_DASHBOARD_TOTAL_USER_LOGIN_SUCCESS";
export const GET_DASHBOARD_TOTAL_USER_LOGIN_FAILED =
  "GET_DASHBOARD_TOTAL_USER_LOGIN_FAILED";

export const GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_REQUEST =
  "GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_REQUEST";
export const GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_SUCCESS =
  "GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_SUCCESS";
export const GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_FAILED =
  "GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_FAILED";

export const GET_DASHBOARD_TOTAL_VOUCHER_USAGE_REQUEST =
  "GET_DASHBOARD_TOTAL_VOUCHER_USAGE_REQUEST";
export const GET_DASHBOARD_TOTAL_VOUCHER_USAGE_SUCCESS =
  "GET_DASHBOARD_TOTAL_VOUCHER_USAGE_SUCCESS";
export const GET_DASHBOARD_TOTAL_VOUCHER_USAGE_FAILED =
  "GET_DASHBOARD_TOTAL_VOUCHER_USAGE_FAILED";

export const GET_DASHBOARD_TOTAL_VOUCHER_CHART_REQUEST =
  "GET_DASHBOARD_TOTAL_VOUCHER_CHART_REQUEST";
export const GET_DASHBOARD_TOTAL_VOUCHER_CHART_SUCCESS =
  "GET_DASHBOARD_TOTAL_VOUCHER_CHART_SUCCESS";
export const GET_DASHBOARD_TOTAL_VOUCHER_CHART_FAILED =
  "GET_DASHBOARD_TOTAL_VOUCHER_CHART_FAILED";

export const GET_DASHBOARD_ACTIVITY_SHEETS_REQUEST =
  "GET_DASHBOARD_ACTIVITY_SHEETS_REQUEST";
export const GET_DASHBOARD_ACTIVITY_SHEETS_SUCCESS =
  "GET_DASHBOARD_ACTIVITY_SHEETS_SUCCESS";
export const GET_DASHBOARD_ACTIVITY_SHEETS_FAILED =
  "GET_DASHBOARD_ACTIVITY_SHEETS_FAILED";

export const GET_DASHBOARD_TOTAL_CONTENT_POPULAR_REQUEST =
  "GET_DASHBOARD_TOTAL_CONTENT_POPULAR_REQUEST";
export const GET_DASHBOARD_TOTAL_CONTENT_POPULAR_SUCCESS =
  "GET_DASHBOARD_TOTAL_CONTENT_POPULAR_SUCCESS";
export const GET_DASHBOARD_TOTAL_CONTENT_POPULAR_FAILED =
  "GET_DASHBOARD_TOTAL_CONTENT_POPULAR_FAILED";

export const GET_DASHBOARD_SEGMENTATIONS_USERS_REQUEST =
  "GET_DASHBOARD_SEGMENTATIONS_USERS_REQUEST";
export const GET_DASHBOARD_SEGMENTATIONS_USERS_SUCCESS =
  "GET_DASHBOARD_SEGMENTATIONS_USERS_SUCCESS";
export const GET_DASHBOARD_SEGMENTATIONS_USERS_FAILED =
  "GET_DASHBOARD_SEGMENTATIONS_USERS_FAILED";

export const GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_REQUEST =
  "GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_REQUEST";
export const GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_SUCCESS =
  "GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_SUCCESS";
export const GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_FAILED =
  "GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_FAILED";

export const GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_REQUEST =
  "GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_REQUEST";
export const GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_SUCCESS =
  "GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_SUCCESS";
export const GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_FAILED =
  "GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_FAILED";
