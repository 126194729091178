import * as ActionType from "../constants/Rbac";

export const getListRolesRequest = (payload) => ({
  type: ActionType.GET_LIST_ROLES_REQUEST,
  payload,
});

export const getListRolesSuccess = (payload) => ({
  type: ActionType.GET_LIST_ROLES_SUCCESS,
  payload,
});

export const getListRolesFailed = (payload) => ({
  type: ActionType.GET_LIST_ROLES_FAILED,
  payload,
});

export const getListMenusRequest = (payload) => ({
  type: ActionType.GET_LIST_MENUS_REQUEST,
  payload,
});

export const getListMenusSuccess = (payload) => ({
  type: ActionType.GET_LIST_MENUS_SUCCESS,
  payload,
});

export const getListMenusFailed = (payload) => ({
  type: ActionType.GET_LIST_MENUS_FAILED,
  payload,
});

export const createAdminRoleRequest = (payload) => ({
  type: ActionType.CREATE_ADMIN_ROLE_REQUEST,
  payload,
});

export const createAdminRoleSuccess = (payload) => ({
  type: ActionType.CREATE_ADMIN_ROLE_SUCCESS,
  payload,
});

export const createAdminRoleFailed = (payload) => ({
  type: ActionType.CREATE_ADMIN_ROLE_FAILED,
  payload,
});

export const resetStatusRbacRequest = (payload) => ({
  type: ActionType.RESET_STATUS_RBAC_REQUEST,
  payload,
});

export const getAdminRoleByIdRequest = (payload) => ({
  type: ActionType.GET_ADMIN_ROLE_BY_ID_REQUEST,
  payload,
});

export const getAdminRoleByIdSuccess = (payload) => ({
  type: ActionType.GET_ADMIN_ROLE_BY_ID_SUCCESS,
  payload,
});

export const getAdminRoleByIdFailed = (payload) => ({
  type: ActionType.GET_ADMIN_ROLE_BY_ID_FAILED,
  payload,
});

export const updateAdminRoleRequest = (payload) => ({
  type: ActionType.UPDATE_ADMIN_ROLE_REQUEST,
  payload,
});

export const updateAdminRoleSuccess = (payload) => ({
  type: ActionType.UPDATE_ADMIN_ROLE_SUCCESS,
  payload,
});

export const updateAdminRoleFailed = (payload) => ({
  type: ActionType.UPDATE_ADMIN_ROLE_FAILED,
  payload,
});

export const deleteAdminRoleRequest = (payload) => ({
  type: ActionType.DELETE_ADMIN_ROLE_REQUEST,
  payload,
});

export const deleteAdminRoleSuccess = (payload) => ({
  type: ActionType.DELETE_ADMIN_ROLE_SUCCESS,
  payload,
});

export const deleteAdminRoleFailed = (payload) => ({
  type: ActionType.DELETE_ADMIN_ROLE_FAILED,
  payload,
});

export const getListAdminUsersRequest = (payload) => ({
  type: ActionType.GET_LIST_ADMIN_USERS_REQUEST,
  payload,
});

export const getListAdminUsersSuccess = (payload) => ({
  type: ActionType.GET_LIST_ADMIN_USERS_SUCCESS,
  payload,
});

export const getListAdminUsersFailed = (payload) => ({
  type: ActionType.GET_LIST_ADMIN_USERS_FAILED,
  payload,
});

export const createAdminUserRequest = (payload) => ({
  type: ActionType.CREATE_ADMIN_USER_REQUEST,
  payload,
});

export const createAdminUserSuccess = (payload) => ({
  type: ActionType.CREATE_ADMIN_USER_SUCCESS,
  payload,
});

export const createAdminUserFailed = (payload) => ({
  type: ActionType.CREATE_ADMIN_USER_FAILED,
  payload,
});

export const deleteAdminUserRequest = (payload) => ({
  type: ActionType.DELETE_ADMIN_USER_REQUEST,
  payload,
});

export const deleteAdminUserSuccess = (payload) => ({
  type: ActionType.DELETE_ADMIN_USER_SUCCESS,
  payload,
});

export const deleteAdminUserFailed = (payload) => ({
  type: ActionType.DELETE_ADMIN_USER_FAILED,
  payload,
});

export const getAdminUserByIdRequest = (payload) => ({
  type: ActionType.GET_ADMIN_USER_BY_ID_REQUEST,
  payload,
});

export const getAdminUserByIdSuccess = (payload) => ({
  type: ActionType.GET_ADMIN_USER_BY_ID_SUCCESS,
  payload,
});

export const getAdminUserByIdFailed = (payload) => ({
  type: ActionType.GET_ADMIN_USER_BY_ID_FAILED,
  payload,
});

export const updateRoleUserRequest = (payload) => ({
  type: ActionType.UPDATE_ROLE_USER_REQUEST,
  payload,
});

export const updateRoleUserSuccess = (payload) => ({
  type: ActionType.UPDATE_ROLE_USER_SUCCESS,
  payload,
});

export const updateRoleUserFailed = (payload) => ({
  type: ActionType.UPDATE_ROLE_USER_FAILED,
  payload,
});

export const resetPasswordRequest = (payload) => ({
  type: ActionType.RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordSuccess = (payload) => ({
  type: ActionType.RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFailed = (payload) => ({
  type: ActionType.RESET_PASSWORD_FAILED,
  payload,
});
