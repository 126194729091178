export const contentData = {
  dashboard: {
    title: "Dashboard",
  },
  'staff-roles': {
    title: "Staff Roles",
  },
  "content-partner": {
    title: "Content Partner",
  },
  "content-management": {
    title: "Content Management",
  },
  "user-account": {
    title: "User Account",
  },
  "billing-management": {
    title: "Billing Management",
  },
  "user-engagement": {
    title: "User Engagement",
  },
  "promo-management": {
    title: "Promo Management",
  },
  "user-support-faq": {
    title: "FAQ",
  },
  "user-activation": {
    title: "User Activation",
  },
  "content-display": {
    title: "Content Display"
  }
};
