import * as ActionType from "../constants/Dashboard";

const initialState = {
  dashboardTotalUsersChart: {
    data: [],
    isLoading: false,
  },
  dashboardTotalUsersAverage: {
    data: {},
    isLoading: false,
  },
  dashboardTotalRevenue: {
    data: [],
    isLoading: false,
  },
  dashboardTotalUsersSigninCohort: {
    data: {},
    isLoading: false,
  },
  dashboardTotalUsersSubscriberCohort: {
    data: {},
    isLoading: false,
  },
  dashboardTotalUsersTransactionsAverage: {
    data: {},
    isLoading: false,
  },
  dashboardTotalTransactionsPackageRatio: {
    data: [],
    isLoading: false,
  },
  dashboardTotalDurationReadAverage: {
    data: {},
    isLoading: false,
  },
  dashboardTotalAccountSummary: {
    data: {},
    isLoading: false,
  },
  dashboardTotalCollectionAndCompletionBook: {
    data: {},
    isLoading: false,
  },
  dashboardTotalSummaryReadChart: {
    data: [],
    isLoading: false,
  },
  dashboardTotalTopReader: {
    data: [],
    isLoading: false,
  },
  dashboardChartUserLogin: {
    data: [],
    isLoading: false,
  },
  dashboardTotalUserLogin: {
    data: {},
    isLoading: false,
  },
  dashboardTotalBookDurationById: {
    data: {},
    isLoading: false,
  },
  dashboardTotalVoucherUsage: {
    data: {},
    isLoading: false,
  },
  dashboardTotalVoucherChart: {
    data: [],
    isLoading: false,
  },
  dashboardActivitySheets: {
    data: {
      charts: [],
      total: 0,
    },
    isLoading: false,
  },
  dashboardTotalContentPopular: {
    data: [],
    isLoading: false,
  },
  dashboardSegmentationsUsers: {
    data: [],
    isLoading: false,
  },
  dashboardSegmentationsTopUsers: {
    data: [],
    isLoading: false,
  },
  dashboardSegmentationsChartUsers: {
    data: [],
    isLoading: false,
  },
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.GET_DASHBOARD_TOTAL_USERS_CHART_REQUEST:
      return {
        ...state,
        dashboardTotalUsersChart: {
          ...state.dashboardTotalUsersChart,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USERS_CHART_SUCCESS:
      return {
        ...state,
        dashboardTotalUsersChart: {
          ...state.dashboardTotalUsersChart,
          data: payload.data || [],
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USERS_CHART_FAILED:
      return {
        ...state,
        dashboardTotalUsersChart: {
          ...state.dashboardTotalUsersChart,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_USERS_AVERAGE_REQUEST:
      return {
        ...state,
        dashboardTotalUsersAverage: {
          ...state.dashboardTotalUsersAverage,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USERS_AVERAGE_SUCCESS:
      return {
        ...state,
        dashboardTotalUsersAverage: {
          ...state.dashboardTotalUsersAverage,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USERS_AVERAGE_FAILED:
      return {
        ...state,
        dashboardTotalUsersAverage: {
          ...state.dashboardTotalUsersAverage,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_REVENUE_REQUEST:
      return {
        ...state,
        dashboardTotalRevenue: {
          ...state.dashboardTotalRevenue,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_REVENUE_SUCCESS:
      return {
        ...state,
        dashboardTotalRevenue: {
          ...state.dashboardTotalRevenue,
          data: payload.data || [],
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_REVENUE_FAILED:
      return {
        ...state,
        dashboardTotalRevenue: {
          ...state.dashboardTotalRevenue,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_REQUEST:
      return {
        ...state,
        dashboardTotalUsersSigninCohort: {
          ...state.dashboardTotalUsersSigninCohort,
          data: {},
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_SUCCESS:
      return {
        ...state,
        dashboardTotalUsersSigninCohort: {
          ...state.dashboardTotalUsersSigninCohort,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USERS_SIGNIN_COHORT_FAILED:
      return {
        ...state,
        dashboardTotalUsersSigninCohort: {
          ...state.dashboardTotalUsersSigninCohort,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_REQUEST:
      return {
        ...state,
        dashboardTotalUsersSubscriberCohort: {
          ...state.dashboardTotalUsersSubscriberCohort,
          data: {},
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_SUCCESS:
      return {
        ...state,
        dashboardTotalUsersSubscriberCohort: {
          ...state.dashboardTotalUsersSubscriberCohort,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USERS_SUBSCRIBER_COHORT_FAILED:
      return {
        ...state,
        dashboardTotalUsersSubscriberCohort: {
          ...state.dashboardTotalUsersSubscriberCohort,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_REQUEST:
      return {
        ...state,
        dashboardTotalUsersTransactionsAverage: {
          ...state.dashboardTotalUsersTransactionsAverage,
          data: {},
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_SUCCESS:
      return {
        ...state,
        dashboardTotalUsersTransactionsAverage: {
          ...state.dashboardTotalUsersTransactionsAverage,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USERS_TRANSACTIONS_AVERAGE_FAILED:
      return {
        ...state,
        dashboardTotalUsersTransactionsAverage: {
          ...state.dashboardTotalUsersTransactionsAverage,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_REQUEST:
      return {
        ...state,
        dashboardTotalTransactionsPackageRatio: {
          ...state.dashboardTotalTransactionsPackageRatio,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_SUCCESS:
      return {
        ...state,
        dashboardTotalTransactionsPackageRatio: {
          ...state.dashboardTotalTransactionsPackageRatio,
          data: payload.data
            ? payload.data.map((item) => {
                return {
                  ...item,
                  name: item.packageName,
                  value: item.int,
                };
              })
            : [],
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_TRANSACTIONS_PACKAGE_RATIO_FAILED:
      return {
        ...state,
        dashboardTotalTransactionsPackageRatio: {
          ...state.dashboardTotalTransactionsPackageRatio,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_REQUEST:
      return {
        ...state,
        dashboardTotalDurationReadAverage: {
          ...state.dashboardTotalDurationReadAverage,
          data: {},
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_SUCCESS:
      return {
        ...state,
        dashboardTotalDurationReadAverage: {
          ...state.dashboardTotalDurationReadAverage,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_DURATION_READ_AVERAGE_FAILED:
      return {
        ...state,
        dashboardTotalDurationReadAverage: {
          ...state.dashboardTotalDurationReadAverage,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_REQUEST:
      return {
        ...state,
        dashboardTotalAccountSummary: {
          ...state.dashboardTotalAccountSummary,
          data: {},
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_SUCCESS:
      return {
        ...state,
        dashboardTotalAccountSummary: {
          ...state.dashboardTotalAccountSummary,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_ACCOUNT_SUMMARY_FAILED:
      return {
        ...state,
        dashboardTotalAccountSummary: {
          ...state.dashboardTotalAccountSummary,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_REQUEST:
      return {
        ...state,
        dashboardTotalCollectionAndCompletionBook: {
          ...state.dashboardTotalCollectionAndCompletionBook,
          data: {},
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_SUCCESS:
      return {
        ...state,
        dashboardTotalCollectionAndCompletionBook: {
          ...state.dashboardTotalCollectionAndCompletionBook,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_COLLECTION_AND_COMPLETION_BOOK_FAILED:
      return {
        ...state,
        dashboardTotalCollectionAndCompletionBook: {
          ...state.dashboardTotalCollectionAndCompletionBook,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_REQUEST:
      return {
        ...state,
        dashboardTotalSummaryReadChart: {
          ...state.dashboardTotalSummaryReadChart,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_SUCCESS:
      return {
        ...state,
        dashboardTotalSummaryReadChart: {
          ...state.dashboardTotalSummaryReadChart,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_SUMMARY_READ_CHART_FAILED:
      return {
        ...state,
        dashboardTotalSummaryReadChart: {
          ...state.dashboardTotalSummaryReadChart,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_TOP_READER_REQUEST:
      return {
        ...state,
        dashboardTotalTopReader: {
          ...state.dashboardTotalTopReader,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_TOP_READER_SUCCESS:
      return {
        ...state,
        dashboardTotalTopReader: {
          ...state.dashboardTotalTopReader,
          data: payload.data || [],
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_TOP_READER_FAILED:
      return {
        ...state,
        dashboardTotalTopReader: {
          ...state.dashboardTotalTopReader,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_CHART_USER_LOGIN_REQUEST:
      return {
        ...state,
        dashboardChartUserLogin: {
          ...state.dashboardChartUserLogin,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_CHART_USER_LOGIN_SUCCESS:
      return {
        ...state,
        dashboardChartUserLogin: {
          ...state.dashboardChartUserLogin,
          data: payload.data || [],
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_CHART_USER_LOGIN_FAILED:
      return {
        ...state,
        dashboardChartUserLogin: {
          ...state.dashboardChartUserLogin,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_USER_LOGIN_REQUEST:
      return {
        ...state,
        dashboardTotalUserLogin: {
          ...state.dashboardTotalUserLogin,
          data: {},
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USER_LOGIN_SUCCESS:
      return {
        ...state,
        dashboardTotalUserLogin: {
          ...state.dashboardTotalUserLogin,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_USER_LOGIN_FAILED:
      return {
        ...state,
        dashboardTotalUserLogin: {
          ...state.dashboardTotalUserLogin,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_REQUEST:
      return {
        ...state,
        dashboardTotalBookDurationById: {
          ...state.dashboardTotalBookDurationById,
          data: {},
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_SUCCESS:
      return {
        ...state,
        dashboardTotalBookDurationById: {
          ...state.dashboardTotalBookDurationById,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_BOOK_DURATION_BY_ID_FAILED:
      return {
        ...state,
        dashboardTotalBookDurationById: {
          ...state.dashboardTotalBookDurationById,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_VOUCHER_USAGE_REQUEST:
      return {
        ...state,
        dashboardTotalVoucherUsage: {
          ...state.dashboardTotalVoucherUsage,
          data: {},
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_VOUCHER_USAGE_SUCCESS:
      return {
        ...state,
        dashboardTotalVoucherUsage: {
          ...state.dashboardTotalVoucherUsage,
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_VOUCHER_USAGE_FAILED:
      return {
        ...state,
        dashboardTotalVoucherUsage: {
          ...state.dashboardTotalVoucherUsage,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_VOUCHER_CHART_REQUEST:
      return {
        ...state,
        dashboardTotalVoucherChart: {
          ...state.dashboardTotalVoucherChart,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_VOUCHER_CHART_SUCCESS:
      return {
        ...state,
        dashboardTotalVoucherChart: {
          ...state.dashboardTotalVoucherChart,
          data: payload.data || [],
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_VOUCHER_CHART_FAILED:
      return {
        ...state,
        dashboardTotalVoucherChart: {
          ...state.dashboardTotalVoucherChart,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_ACTIVITY_SHEETS_REQUEST:
      return {
        ...state,
        dashboardActivitySheets: {
          ...state.dashboardActivitySheets,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_ACTIVITY_SHEETS_SUCCESS:
      return {
        ...state,
        dashboardActivitySheets: {
          ...state.dashboardActivitySheets,
          data: {
            ...payload.data,
            charts: payload.data.charts.map((item) => ({
              ...item,
              type: "Download",
            })),
          },
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_ACTIVITY_SHEETS_FAILED:
      return {
        ...state,
        dashboardActivitySheets: {
          ...state.dashboardActivitySheets,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_TOTAL_CONTENT_POPULAR_REQUEST:
      return {
        ...state,
        dashboardTotalContentPopular: {
          ...state.dashboardTotalContentPopular,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_CONTENT_POPULAR_SUCCESS:
      return {
        ...state,
        dashboardTotalContentPopular: {
          ...state.dashboardTotalContentPopular,
          data: payload.data || [],
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_TOTAL_CONTENT_POPULAR_FAILED:
      return {
        ...state,
        dashboardTotalContentPopular: {
          ...state.dashboardTotalContentPopular,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_SEGMENTATIONS_USERS_REQUEST:
      return {
        ...state,
        dashboardSegmentationsUsers: {
          ...state.dashboardSegmentationsUsers,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_SEGMENTATIONS_USERS_SUCCESS:
      return {
        ...state,
        dashboardSegmentationsUsers: {
          ...state.dashboardSegmentationsUsers,
          data: payload.data || [],
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_SEGMENTATIONS_USERS_FAILED:
      return {
        ...state,
        dashboardSegmentationsUsers: {
          ...state.dashboardSegmentationsUsers,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_REQUEST:
      return {
        ...state,
        dashboardSegmentationsTopUsers: {
          ...state.dashboardSegmentationsTopUsers,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_SUCCESS:
      return {
        ...state,
        dashboardSegmentationsTopUsers: {
          ...state.dashboardSegmentationsTopUsers,
          data: payload.data || [],
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_SEGMENTATIONS_TOP_USERS_FAILED:
      return {
        ...state,
        dashboardSegmentationsTopUsers: {
          ...state.dashboardSegmentationsTopUsers,
          isLoading: false,
        },
      };

    case ActionType.GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_REQUEST:
      return {
        ...state,
        dashboardSegmentationsChartUsers: {
          ...state.dashboardSegmentationsChartUsers,
          isLoading: true,
        },
      };
    case ActionType.GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_SUCCESS:
      return {
        ...state,
        dashboardSegmentationsChartUsers: {
          ...state.dashboardSegmentationsChartUsers,
          data: payload.data || [],
          isLoading: false,
        },
      };
    case ActionType.GET_DASHBOARD_SEGMENTATIONS_CHART_USERS_FAILED:
      return {
        ...state,
        dashboardSegmentationsChartUsers: {
          ...state.dashboardSegmentationsChartUsers,
          isLoading: false,
        },
      };

    default: {
      return state;
    }
  }
};

export default dashboardReducer;
