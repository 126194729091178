import * as ActionType from "../constants/SchoolFreeTrial";

const initialState = {
  listSchoolFreeTrial: {
    data: [],
    isLoading: false,
    pagination: {
      limit: 10,
      page: 1,
      totalPage: 1,
    },
    totalData: 0,
  },
  detailSchoolFreeTrial: {
    data: {},
    isLoading: false,
  },
  updateStatus: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  updateSchoolFreeTrial: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  deleteSchoolFreeTrial: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

const schoolFreeTrialReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.GET_LIST_SCHOOL_FREE_TRIAL_REQUEST:
      return {
        ...state,
        listSchoolFreeTrial: {
          ...state.listSchoolFreeTrial,
          isLoading: true,
        },
      };
    case ActionType.GET_LIST_SCHOOL_FREE_TRIAL_SUCCESS:
      return {
        ...state,
        listSchoolFreeTrial: {
          ...state.listSchoolFreeTrial,
          data: payload.data,
          isLoading: false,
          pagination: {
            limit: payload?.limit,
            page: payload?.paging?.currentPage || 1,
            totalPage: payload?.paging?.totalPages || 1,
          },
          totalData: payload?.paging?.totalData || 0,
        },
      };
    case ActionType.GET_LIST_SCHOOL_FREE_TRIAL_FAILED:
      return {
        ...state,
        listSchoolFreeTrial: {
          ...state.listSchoolFreeTrial,
          isLoading: false,
        },
      };
    case ActionType.GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_REQUEST:
      return {
        ...state,
        detailSchoolFreeTrial: {
          ...state.detailSchoolFreeTrial,
          isLoading: true,
        },
      };
    case ActionType.GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_SUCCESS:
      return {
        ...state,
        detailSchoolFreeTrial: {
          data: payload.data,
          isLoading: false,
        },
      };
    case ActionType.GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_FAILED:
      return {
        ...state,
        detailSchoolFreeTrial: {
          ...state.detailSchoolFreeTrial,
          isLoading: false,
        },
      };

    // update status
    case ActionType.UPDATE_STATUS_SCHOOL_FREE_TRIAL_REQUEST:
      return {
        ...state,
        updateStatus: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_STATUS_SCHOOL_FREE_TRIAL_SUCCESS:
      return {
        ...state,
        updateStatus: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_STATUS_SCHOOL_FREE_TRIAL_FAILED:
      return {
        ...state,
        updateStatus: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };
    // update
    case ActionType.UPDATE_SCHOOL_FREE_TRIAL_REQUEST:
      return {
        ...state,
        updateSchoolFreeTrial: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.UPDATE_SCHOOL_FREE_TRIAL_SUCCESS:
      return {
        ...state,
        updateSchoolFreeTrial: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.UPDATE_SCHOOL_FREE_TRIAL_FAILED:
      return {
        ...state,
        updateSchoolFreeTrial: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };
    // delete
    case ActionType.DELETE_SCHOOL_FREE_TRIAL_REQUEST:
      return {
        ...state,
        deleteSchoolFreeTrial: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      };
    case ActionType.DELETE_SCHOOL_FREE_TRIAL_SUCCESS:
      return {
        ...state,
        deleteSchoolFreeTrial: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      };
    case ActionType.DELETE_SCHOOL_FREE_TRIAL_FAILED:
      return {
        ...state,
        deleteSchoolFreeTrial: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      };
    default: {
      return state;
    }
  }
};

export default schoolFreeTrialReducer;
