import * as ActionType from "../constants/School";

export const getListSchoolRequest = (payload) => ({
  type: ActionType.GET_LIST_SCHOOL_REQUEST,
  payload,
});

export const getListSchoolSuccess = (payload) => ({
  type: ActionType.GET_LIST_SCHOOL_SUCCESS,
  payload,
});

export const getListSchoolFailed = (payload) => ({
  type: ActionType.GET_LIST_SCHOOL_FAILED,
  payload,
});

export const getListTeacherBySchoolIdRequest = (payload) => ({
  type: ActionType.GET_LIST_TEACHER_BY_SCHOOL_ID_REQUEST,
  payload,
});

export const getListTeacherBySchoolIdSuccess = (payload) => ({
  type: ActionType.GET_LIST_TEACHER_BY_SCHOOL_ID_SUCCESS,
  payload,
});

export const getListTeacherBySchoolIdFailed = (payload) => ({
  type: ActionType.GET_LIST_TEACHER_BY_SCHOOL_ID_FAILED,
  payload,
});

export const getListStudentBySchoolIdRequest = (payload) => ({
  type: ActionType.GET_LIST_STUDENT_BY_SCHOOL_ID_REQUEST,
  payload,
});

export const getListStudentBySchoolIdSuccess = (payload) => ({
  type: ActionType.GET_LIST_STUDENT_BY_SCHOOL_ID_SUCCESS,
  payload,
});

export const getListStudentBySchoolIdFailed = (payload) => ({
  type: ActionType.GET_LIST_STUDENT_BY_SCHOOL_ID_FAILED,
  payload,
});

export const createSchoolRequest = (payload) => ({
  type: ActionType.CREATE_SCHOOL_REQUEST,
  payload,
});

export const createSchoolSuccess = (payload) => ({
  type: ActionType.CREATE_SCHOOL_SUCCESS,
  payload,
});

export const createSchoolFailed = (payload) => ({
  type: ActionType.CREATE_SCHOOL_FAILED,
  payload,
});

export const updateSchoolRequest = (payload) => ({
  type: ActionType.UPDATE_SCHOOL_REQUEST,
  payload,
});

export const updateSchoolCredRequest = (payload) => ({
  type: ActionType.UPDATE_SCHOOL_CRED_REQUEST,
  payload,
});

export const updateSchoolCredSuccess = (payload) => ({
  type: ActionType.UPDATE_SCHOOL_CRED_SUCCESS,
  payload,
});

export const updateSchoolCredFailed = (payload) => ({
  type: ActionType.UPDATE_SCHOOL_CRED_FAILED,
  payload,
});

export const updateSchoolSuccess = (payload) => ({
  type: ActionType.UPDATE_SCHOOL_SUCCESS,
  payload,
});

export const updateSchoolFailed = (payload) => ({
  type: ActionType.UPDATE_SCHOOL_FAILED,
  payload,
});

export const resetStatusSchoolRequest = (payload) => ({
  type: ActionType.RESET_STATUS_SCHOOL_REQUEST,
  payload,
});

export const updateStatusSchoolRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_SCHOOL_REQUEST,
  payload,
});

export const updateStatusSchoolSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_SCHOOL_SUCCESS,
  payload,
});

export const updateStatusSchoolFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_SCHOOL_FAILED,
  payload,
});

export const getListClassBySchoolIdRequest = (payload) => ({
  type: ActionType.GET_LIST_CLASS_BY_SCHOOL_ID_REQUEST,
  payload,
});

export const getListClassBySchoolIdSuccess = (payload) => ({
  type: ActionType.GET_LIST_CLASS_BY_SCHOOL_ID_SUCCESS,
  payload,
});

export const getListClassBySchoolIdFailed = (payload) => ({
  type: ActionType.GET_LIST_CLASS_BY_SCHOOL_ID_FAILED,
  payload,
});

export const createClassRequest = (payload) => ({
  type: ActionType.CREATE_CLASS_REQUEST,
  payload,
});

export const createClassSuccess = (payload) => ({
  type: ActionType.CREATE_CLASS_SUCCESS,
  payload,
});

export const createClassFailed = (payload) => ({
  type: ActionType.CREATE_CLASS_FAILED,
  payload,
});

export const updateClassRequest = (payload) => ({
  type: ActionType.UPDATE_CLASS_REQUEST,
  payload,
});

export const updateClassSuccess = (payload) => ({
  type: ActionType.UPDATE_CLASS_SUCCESS,
  payload,
});

export const updateClassFailed = (payload) => ({
  type: ActionType.UPDATE_CLASS_FAILED,
  payload,
});

export const deleteClassRequest = (payload) => ({
  type: ActionType.DELETE_CLASS_REQUEST,
  payload,
});

export const deleteClassSuccess = (payload) => ({
  type: ActionType.DELETE_CLASS_SUCCESS,
  payload,
});

export const deleteClassFailed = (payload) => ({
  type: ActionType.DELETE_CLASS_FAILED,
  payload,
});

export const createTeacherRequest = (payload) => ({
  type: ActionType.CREATE_TEACHER_REQUEST,
  payload,
});

export const createTeacherSuccess = (payload) => ({
  type: ActionType.CREATE_TEACHER_SUCCESS,
  payload,
});

export const createTeacherFailed = (payload) => ({
  type: ActionType.CREATE_TEACHER_FAILED,
  payload,
});

export const updateStatusTeacherRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_TEACHER_REQUEST,
  payload,
});

export const updateStatusTeacherSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_TEACHER_SUCCESS,
  payload,
});

export const updateStatusTeacherFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_TEACHER_FAILED,
  payload,
});

export const updateTeacherRequest = (payload) => ({
  type: ActionType.UPDATE_TEACHER_REQUEST,
  payload,
});

export const updateTeacherSuccess = (payload) => ({
  type: ActionType.UPDATE_TEACHER_SUCCESS,
  payload,
});

export const updateTeacherFailed = (payload) => ({
  type: ActionType.UPDATE_TEACHER_FAILED,
  payload,
});

export const deleteTeacherRequest = (payload) => ({
  type: ActionType.DELETE_TEACHER_REQUEST,
  payload,
});

export const deleteTeacherSuccess = (payload) => ({
  type: ActionType.DELETE_TEACHER_SUCCESS,
  payload,
});

export const deleteTeacherFailed = (payload) => ({
  type: ActionType.DELETE_TEACHER_FAILED,
  payload,
});

export const setClassTeacherRequest = (payload) => ({
  type: ActionType.SET_CLASS_TEACHER_REQUEST,
  payload,
});

export const setClassTeacherSuccess = (payload) => ({
  type: ActionType.SET_CLASS_TEACHER_SUCCESS,
  payload,
});

export const setClassTeacherFailed = (payload) => ({
  type: ActionType.SET_CLASS_TEACHER_FAILED,
  payload,
});

export const setClassesStudentRequest = (payload) => ({
  type: ActionType.SET_CLASSES_STUDENT_REQUEST,
  payload,
});

export const setClassesStudentSuccess = (payload) => ({
  type: ActionType.SET_CLASSES_STUDENT_SUCCESS,
  payload,
});

export const setClassesStudentFailed = (payload) => ({
  type: ActionType.SET_CLASSES_STUDENT_FAILED,
  payload,
});

export const deleteStudentRequest = (payload) => ({
  type: ActionType.DELETE_STUDENT_REQUEST,
  payload,
});

export const deleteStudentSuccess = (payload) => ({
  type: ActionType.DELETE_STUDENT_SUCCESS,
  payload,
});

export const deleteStudentFailed = (payload) => ({
  type: ActionType.DELETE_STUDENT_FAILED,
  payload,
});

export const updateStatusStudentRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_STUDENT_REQUEST,
  payload,
});

export const updateStatusStudentSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_STUDENT_SUCCESS,
  payload,
});

export const updateStatusStudentFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_STUDENT_FAILED,
  payload,
});

export const setBulkActiveTeacherRequest = (payload) => ({
  type: ActionType.SET_BULK_ACTIVE_TEACHER_REQUEST,
  payload,
});

export const setBulkActiveTeacherSuccess = (payload) => ({
  type: ActionType.SET_BULK_ACTIVE_TEACHER_SUCCESS,
  payload,
});

export const setBulkActiveTeacherFailed = (payload) => ({
  type: ActionType.SET_BULK_ACTIVE_TEACHER_FAILED,
  payload,
});

export const setBulkActiveStudentRequest = (payload) => ({
  type: ActionType.SET_BULK_ACTIVE_STUDENT_REQUEST,
  payload,
});

export const setBulkActiveStudentSuccess = (payload) => ({
  type: ActionType.SET_BULK_ACTIVE_STUDENT_SUCCESS,
  payload,
});

export const setBulkActiveStudentFailed = (payload) => ({
  type: ActionType.SET_BULK_ACTIVE_STUDENT_FAILED,
  payload,
});

export const findSchoolRequest = (payload) => ({
  type: ActionType.FIND_SCHOOL_REQUEST,
  payload,
});

export const findSchoolSuccess = (payload) => ({
  type: ActionType.FIND_SCHOOL_SUCCESS,
  payload,
});

export const findSchoolFailed = (payload) => ({
  type: ActionType.FIND_SCHOOL_FAILED,
  payload,
});

export const getDetailSchoolRequest = (payload) => ({
  type: ActionType.GET_DETAIL_SCHOOL_REQUEST,
  payload,
});

export const getDetailSchoolSuccess = (payload) => ({
  type: ActionType.GET_DETAIL_SCHOOL_SUCCESS,
  payload,
});

export const getDetailSchoolFailed = (payload) => ({
  type: ActionType.GET_DETAIL_SCHOOL_FAILED,
  payload,
});

export const getSummarySchoolRequest = (payload) => ({
  type: ActionType.GET_SUMMARY_SCHOOL_REQUEST,
  payload,
});

export const getSummarySchoolSuccess = (payload) => ({
  type: ActionType.GET_SUMMARY_SCHOOL_SUCCESS,
  payload,
});

export const getSummarySchoolFailed = (payload) => ({
  type: ActionType.GET_SUMMARY_SCHOOL_FAILED,
  payload,
});

export const getActivitySchoolRequest = (payload) => ({
  type: ActionType.GET_ACTIVITY_SCHOOL_REQUEST,
  payload,
});

export const getActivitySchoolSuccess = (payload) => ({
  type: ActionType.GET_ACTIVITY_SCHOOL_SUCCESS,
  payload,
});

export const getActivitySchoolFailed = (payload) => ({
  type: ActionType.GET_ACTIVITY_SCHOOL_FAILED,
  payload,
});

export const getListOfReadSchoolRequest = (payload) => ({
  type: ActionType.GET_LIST_OF_READ_SCHOOL_REQUEST,
  payload,
});

export const getListOfReadSchoolSuccess = (payload) => ({
  type: ActionType.GET_LIST_OF_READ_SCHOOL_SUCCESS,
  payload,
});

export const getListOfReadSchoolFailed = (payload) => ({
  type: ActionType.GET_LIST_OF_READ_SCHOOL_FAILED,
  payload,
});

export const getListOfReadStudentRequest = (payload) => ({
  type: ActionType.GET_LIST_OF_READ_STUDENT_REQUEST,
  payload,
});

export const getListOfReadStudentSuccess = (payload) => ({
  type: ActionType.GET_LIST_OF_READ_STUDENT_SUCCESS,
  payload,
});

export const getListOfReadStudentFailed = (payload) => ({
  type: ActionType.GET_LIST_OF_READ_STUDENT_FAILED,
  payload,
});

export const getReadHistorySchoolRequest = (payload) => ({
  type: ActionType.GET_READ_HISTORY_SCHOOL_REQUEST,
  payload,
});

export const getReadHistorySchoolSuccess = (payload) => ({
  type: ActionType.GET_READ_HISTORY_SCHOOL_SUCCESS,
  payload,
});

export const getReadHistorySchoolFailed = (payload) => ({
  type: ActionType.GET_READ_HISTORY_SCHOOL_FAILED,
  payload,
});

export const getDetailReadHistorySchoolRequest = (payload) => ({
  type: ActionType.GET_DETAIL_READ_HISTORY_SCHOOL_REQUEST,
  payload,
});

export const getDetailReadHistorySchoolSuccess = (payload) => ({
  type: ActionType.GET_DETAIL_READ_HISTORY_SCHOOL_SUCCESS,
  payload,
});

export const getDetailReadHistorySchoolFailed = (payload) => ({
  type: ActionType.GET_DETAIL_READ_HISTORY_SCHOOL_FAILED,
  payload,
});

export const createStudentRequest = (payload) => ({
  type: ActionType.CREATE_STUDENT_REQUEST,
  payload,
});

export const createStudentSuccess = (payload) => ({
  type: ActionType.CREATE_STUDENT_SUCCESS,
  payload,
});

export const createStudentFailed = (payload) => ({
  type: ActionType.CREATE_STUDENT_FAILED,
  payload,
});

export const updateStudentRequest = (payload) => ({
  type: ActionType.UPDATE_STUDENT_REQUEST,
  payload,
});

export const updateStudentSuccess = (payload) => ({
  type: ActionType.UPDATE_STUDENT_SUCCESS,
  payload,
});

export const updateStudentFailed = (payload) => ({
  type: ActionType.UPDATE_STUDENT_FAILED,
  payload,
});

export const setClassStudentRequest = (payload) => ({
  type: ActionType.SET_CLASS_STUDENT_REQUEST,
  payload,
});

export const setClassStudentSuccess = (payload) => ({
  type: ActionType.SET_CLASS_STUDENT_SUCCESS,
  payload,
});

export const setClassStudentFailed = (payload) => ({
  type: ActionType.SET_CLASS_STUDENT_FAILED,
  payload,
});

export const connectAccountStudentRequest = (payload) => ({
  type: ActionType.CONNECT_ACCOUNT_STUDENT_REQUEST,
  payload,
});

export const connectAccountStudentSuccess = (payload) => ({
  type: ActionType.CONNECT_ACCOUNT_STUDENT_SUCCESS,
  payload,
});

export const connectAccountStudentFailed = (payload) => ({
  type: ActionType.CONNECT_ACCOUNT_STUDENT_FAILED,
  payload,
});

export const getListFamilyRequest = (payload) => ({
  type: ActionType.GET_LIST_FAMILY_REQUEST,
  payload,
});

export const getListFamilySuccess = (payload) => ({
  type: ActionType.GET_LIST_FAMILY_SUCCESS,
  payload,
});

export const getListFamilyFailed = (payload) => ({
  type: ActionType.GET_LIST_FAMILY_FAILED,
  payload,
});

export const getChildFamilyRequest = (payload) => ({
  type: ActionType.GET_CHILD_FAMILY_REQUEST,
  payload,
});

export const getChildFamilySuccess = (payload) => ({
  type: ActionType.GET_CHILD_FAMILY_SUCCESS,
  payload,
});

export const getChildFamilyFailed = (payload) => ({
  type: ActionType.GET_CHILD_FAMILY_FAILED,
  payload,
});

export const setAccountToFamilyRequest = (payload) => ({
  type: ActionType.SET_ACCOUNT_TO_FAMILY_REQUEST,
  payload,
});

export const setAccountToFamilySuccess = (payload) => ({
  type: ActionType.SET_ACCOUNT_TO_FAMILY_SUCCESS,
  payload,
});

export const setAccountToFamilyAdminFailed = (payload) => ({
  type: ActionType.SET_ACCOUNT_TO_FAMILY_ADMIN_FAILED,
  payload,
});

export const setAccountToFamilyAdminRequest = (payload) => ({
  type: ActionType.SET_ACCOUNT_TO_FAMILY_ADMIN_REQUEST,
  payload,
});

export const setAccountToFamilyAdminSuccess = (payload) => ({
  type: ActionType.SET_ACCOUNT_TO_FAMILY_ADMIN_SUCCESS,
  payload,
});

export const setAccountToFamilyFailed = (payload) => ({
  type: ActionType.SET_ACCOUNT_TO_FAMILY_FAILED,
  payload,
});

export const verifyConnectFamilyRequest = (payload) => ({
  type: ActionType.VERIFY_CONNECT_FAMILY_REQUEST,
  payload,
});

export const verifyConnectFamilySuccess = (payload) => ({
  type: ActionType.VERIFY_CONNECT_FAMILY_SUCCESS,
  payload,
});

export const verifyConnectFamilyFailed = (payload) => ({
  type: ActionType.VERIFY_CONNECT_FAMILY_FAILED,
  payload,
});

export const getDetailConnectionByStudentIdRequest = (payload) => ({
  type: ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_REQUEST,
  payload,
});

export const getDetailConnectionByStudentIdSuccess = (payload) => ({
  type: ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_SUCCESS,
  payload,
});

export const getDetailConnectionByStudentIdFailed = (payload) => ({
  type: ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_FAILED,
  payload,
});

export const getDetailConnectionByStudentIdAdminRequest = (payload) => ({
  type: ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_REQUEST,
  payload,
});

export const getDetailConnectionByStudentIdAdminSuccess = (payload) => ({
  type: ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_SUCCESS,
  payload,
});

export const getDetailConnectionByStudentIdAdminFailed = (payload) => ({
  type: ActionType.GET_DETAIL_CONNECTION_BY_STUDENT_ID_ADMIN_FAILED,
  payload,
});

export const updateTeacherAdminAccessRequest = (payload) => ({
  type: ActionType.UPDATE_TEACHER_ADMIN_ACCESS_REQUEST,
  payload,
});

export const updateTeacherAdminAccessSuccess = (payload) => ({
  type: ActionType.UPDATE_TEACHER_ADMIN_ACCESS_SUCCESS,
  payload,
});

export const updateTeacherAdminAccessFailed = (payload) => ({
  type: ActionType.UPDATE_TEACHER_ADMIN_ACCESS_FAILED,
  payload,
});
