export const GET_LIST_PUBLISHER_REQUEST = "GET_LIST_PUBLISHER_REQUEST";
export const GET_LIST_PUBLISHER_SUCCESS = "GET_LIST_PUBLISHER_SUCCESS";
export const GET_LIST_PUBLISHER_FAILED = "GET_LIST_PUBLISHER_FAILED";

export const UPDATE_STATUS_PUBLISHER_REQUEST =
  "UPDATE_STATUS_PUBLISHER_REQUEST";
export const UPDATE_STATUS_PUBLISHER_SUCCESS =
  "UPDATE_STATUS_PUBLISHER_SUCCESS";
export const UPDATE_STATUS_PUBLISHER_FAILED = "UPDATE_STATUS_PUBLISHER_FAILED";

export const CREATE_PUBLISHER_REQUEST = "CREATE_PUBLISHER_REQUEST";
export const CREATE_PUBLISHER_SUCCESS = "CREATE_PUBLISHER_SUCCESS";
export const CREATE_PUBLISHER_FAILED = "CREATE_PUBLISHER_FAILED";

export const GET_PUBLISHER_BY_ID_REQUEST = "GET_PUBLISHER_BY_ID_REQUEST";
export const GET_PUBLISHER_BY_ID_SUCCESS = "GET_PUBLISHER_BY_ID_SUCCESS";
export const GET_PUBLISHER_BY_ID_FAILED = "GET_PUBLISHER_BY_ID_FAILED";

export const UPDATE_PUBLISHER_REQUEST = "UPDATE_PUBLISHER_REQUEST";
export const UPDATE_PUBLISHER_SUCCESS = "UPDATE_PUBLISHER_SUCCESS";
export const UPDATE_PUBLISHER_FAILED = "UPDATE_PUBLISHER_FAILED";

export const GET_PUBLISHER_INFO_REQUEST = "GET_PUBLISHER_INFO_REQUEST";
export const GET_PUBLISHER_INFO_SUCCESS = "GET_PUBLISHER_INFO_SUCCESS";
export const GET_PUBLISHER_INFO_FAILED = "GET_PUBLISHER_INFO_FAILED";

export const GET_MY_TOP_MOST_POPULAR_BOOKS_REQUEST =
  "GET_MY_TOP_MOST_POPULAR_BOOKS_REQUEST";
export const GET_MY_TOP_MOST_POPULAR_BOOKS_SUCCESS =
  "GET_MY_TOP_MOST_POPULAR_BOOKS_SUCCESS";
export const GET_MY_TOP_MOST_POPULAR_BOOKS_FAILED =
  "GET_MY_TOP_MOST_POPULAR_BOOKS_FAILED";

export const GET_TOP_MOST_POPULAR_BOOKS_REQUEST =
  "GET_TOP_MOST_POPULAR_BOOKS_REQUEST";
export const GET_TOP_MOST_POPULAR_BOOKS_SUCCESS =
  "GET_TOP_MOST_POPULAR_BOOKS_SUCCESS";
export const GET_TOP_MOST_POPULAR_BOOKS_FAILED =
  "GET_TOP_MOST_POPULAR_BOOKS_FAILED";

export const UPDATE_PUBLISHER_INFO_REQUEST = "UPDATE_PUBLISHER_INFO_REQUEST";
export const UPDATE_PUBLISHER_INFO_SUCCESS = "UPDATE_PUBLISHER_INFO_SUCCESS";
export const UPDATE_PUBLISHER_INFO_FAILED = "UPDATE_PUBLISHER_INFO_FAILED";
