export const GET_LIST_APP_INBOX_REQUEST = "GET_LIST_APP_INBOX_REQUEST";
export const GET_LIST_APP_INBOX_SUCCESS = "GET_LIST_APP_INBOX_SUCCESS";
export const GET_LIST_APP_INBOX_FAILED = "GET_LIST_APP_INBOX_FAILED";

export const CREATE_APP_INBOX_REQUEST = "CREATE_APP_INBOX_REQUEST";
export const CREATE_APP_INBOX_SUCCESS = "CREATE_APP_INBOX_SUCCESS";
export const CREATE_APP_INBOX_FAILED = "CREATE_APP_INBOX_FAILED";

export const SEND_APP_INBOX_REQUEST = "SEND_APP_INBOX_REQUEST";
export const SEND_APP_INBOX_SUCCESS = "SEND_APP_INBOX_SUCCESS";
export const SEND_APP_INBOX_FAILED = "SEND_APP_INBOX_FAILED";

export const GET_APP_INBOX_BY_ID_REQUEST = "GET_APP_INBOX_BY_ID_REQUEST";
export const GET_APP_INBOX_BY_ID_SUCCESS = "GET_APP_INBOX_BY_ID_SUCCESS";
export const GET_APP_INBOX_BY_ID_FAILED = "GET_APP_INBOX_BY_ID_FAILED";

export const UPDATE_APP_INBOX_REQUEST = "UPDATE_APP_INBOX_REQUEST";
export const UPDATE_APP_INBOX_SUCCESS = "UPDATE_APP_INBOX_SUCCESS";
export const UPDATE_APP_INBOX_FAILED = "UPDATE_APP_INBOX_FAILED";
