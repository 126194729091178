import httpRequest from "../util/axios";

export const fetchListActivitySheetsSection = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/activity-sheets/section",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListActivitySheets = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/activity-sheets",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateActivitySheetSection = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/activity-sheets/section",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateActivitySheetSection = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/activity-sheets/section/${body?.sectionId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateSortActivitySheet = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/activity-sheets/${body?.activitySheetId}/sort`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateActivitySheet = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/activity-sheets",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchActivitySheetById = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/activity-sheets/${body?.activitySheetId}`
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateActivitySheet = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/activity-sheets/${body?.activitySheetId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDeleteActivitySheet = async (body = {}) => {
  try {
    const result = await httpRequest.delete(
      `/admin/activity-sheets/${body?.activitySheetId}`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
