import httpRequest from "../util/axios";

export const fetchListRoles = async (option = {}) => {
  try {
    const result = await httpRequest.get("/admin/rbac/roles", option, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListMenus = async (option = {}) => {
  try {
    const result = await httpRequest.get("/admin/rbac/menus", option, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateAdminRole = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/rbac/roles",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchAdminRoleById = async (body = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/rbac/roles/${body?.adminRoleId}`
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateAdminRole = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/rbac/roles/${body?.roleId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDeleteAdminRole = async (body = {}) => {
  try {
    const result = await httpRequest.delete(
      `/admin/rbac/roles/${body?.adminRoleId}`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListAdminUsers = async (option = {}) => {
  try {
    const result = await httpRequest.get("/admin/rbac/users", option, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateAdminUser = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/rbac/users",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDeleteAdminUser = async (body = {}) => {
  try {
    const result = await httpRequest.delete(
      `/admin/rbac/users/${body?.adminUserId}`,
      {},
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchAdminUserById = async (body = {}) => {
  try {
    // const result = await httpRequest.get(
    //   `/admin/activity-sheets/${body?.adminRoleId}`
    // );
    const result = {
      data: {
        data: {
          email: "email1@gmail.com",
          roleId: 1,
        },
      },
      status: 200,
    };

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateRoleUser = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/rbac/users/${body?.userId}/roles/${body?.roleId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchResetPassword = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/rbac/users/${body?.userId}/reset-password`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
