import { notification } from "antd";

const openNotificationWithIcon = (type, message, description, duration = 3) => {
  notification[type]({
    message,
    description,
    duration,
    style: {
      width: 380,
    },
  });
};

const successNotification = (message, description) =>
  openNotificationWithIcon("success", message, description);
const infoNotification = (message, description) =>
  openNotificationWithIcon("info", message, description);
const warningNotification = (message, description) =>
  openNotificationWithIcon("warning", message, description);
const errorNotification = (message, description) =>
  openNotificationWithIcon("error", message, description);

export default openNotificationWithIcon;
export {
  successNotification,
  infoNotification,
  warningNotification,
  errorNotification,
};
