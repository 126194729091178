import httpRequest from "../util/axios";

export const fetchListSegmentation = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      "/admin/users/engagements",
      option,
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
