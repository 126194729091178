import * as ActionType from "../constants/SchoolFreeTrial";

export const getListSchoolFreeTrialRequest = (payload) => ({
  type: ActionType.GET_LIST_SCHOOL_FREE_TRIAL_REQUEST,
  payload,
});
export const getListSchoolFreeTrialSuccess = (payload) => ({
  type: ActionType.GET_LIST_SCHOOL_FREE_TRIAL_SUCCESS,
  payload,
});
export const getListSchoolFreeTrialFailed = (payload) => ({
  type: ActionType.GET_LIST_SCHOOL_FREE_TRIAL_FAILED,
  payload,
});

export const getSchoolFreeTrialByRequestIdRequest = (payload) => ({
  type: ActionType.GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_REQUEST,
  payload,
});
export const getSchoolFreeTrialByRequestIdSuccess = (payload) => ({
  type: ActionType.GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_SUCCESS,
  payload,
});
export const getSchoolFreeTrialByRequestIdFailed = (payload) => ({
  type: ActionType.GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_FAILED,
  payload,
});

// update
export const updateSchoolFreeTrialRequest = (payload) => ({
  type: ActionType.UPDATE_SCHOOL_FREE_TRIAL_REQUEST,
  payload,
});
export const updateSchoolFreeTrialSuccess = (payload) => ({
  type: ActionType.UPDATE_SCHOOL_FREE_TRIAL_SUCCESS,
  payload,
});
export const updateSchoolFreeTrialFailed = (payload) => ({
  type: ActionType.UPDATE_SCHOOL_FREE_TRIAL_FAILED,
  payload,
});

// update status
export const updateStatusSchoolFreeTrialRequest = (payload) => ({
  type: ActionType.UPDATE_STATUS_SCHOOL_FREE_TRIAL_REQUEST,
  payload,
});
export const updateStatusSchoolFreeTrialSuccess = (payload) => ({
  type: ActionType.UPDATE_STATUS_SCHOOL_FREE_TRIAL_SUCCESS,
  payload,
});
export const updateStatusSchoolFreeTrialFailed = (payload) => ({
  type: ActionType.UPDATE_STATUS_SCHOOL_FREE_TRIAL_FAILED,
  payload,
});

// delete
export const deleteSchoolFreeTrialRequest = (payload) => ({
  type: ActionType.DELETE_SCHOOL_FREE_TRIAL_REQUEST,
  payload,
});
export const deleteSchoolFreeTrialSuccess = (payload) => ({
  type: ActionType.DELETE_SCHOOL_FREE_TRIAL_SUCCESS,
  payload,
});
export const deleteSchoolFreeTrialFailed = (payload) => ({
  type: ActionType.DELETE_SCHOOL_FREE_TRIAL_FAILED,
  payload,
});
