export const GET_LIST_SCHOOL_FREE_TRIAL_REQUEST =
  "schoolFreeTrial/GET_LIST_SCHOOL_FREE_TRIAL_REQUEST";
export const GET_LIST_SCHOOL_FREE_TRIAL_SUCCESS =
  "schoolFreeTrial/GET_LIST_SCHOOL_FREE_TRIAL_SUCCESS";
export const GET_LIST_SCHOOL_FREE_TRIAL_FAILED =
  "schoolFreeTrial/GET_LIST_SCHOOL_FREE_TRIAL_FAILED";

export const GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_REQUEST =
  "schoolFreeTrial/GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_REQUEST";
export const GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_SUCCESS =
  "schoolFreeTrial/GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_SUCCESS";
export const GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_FAILED =
  "schoolFreeTrial/GET_SCHOOL_FREE_TRIAL_BY_REQUEST_ID_FAILED";

export const UPDATE_SCHOOL_FREE_TRIAL_REQUEST =
  "schoolFreeTrial/UPDATE_SCHOOL_FREE_TRIAL_REQUEST";
export const UPDATE_SCHOOL_FREE_TRIAL_SUCCESS =
  "schoolFreeTrial/UPDATE_SCHOOL_FREE_TRIAL_SUCCESS";
export const UPDATE_SCHOOL_FREE_TRIAL_FAILED =
  "schoolFreeTrial/UPDATE_SCHOOL_FREE_TRIAL_FAILED";

export const UPDATE_STATUS_SCHOOL_FREE_TRIAL_REQUEST =
  "schoolFreeTrial/UPDATE_STATUS_SCHOOL_FREE_TRIAL_REQUEST";
export const UPDATE_STATUS_SCHOOL_FREE_TRIAL_SUCCESS =
  "schoolFreeTrial/UPDATE_STATUS_SCHOOL_FREE_TRIAL_SUCCESS";
export const UPDATE_STATUS_SCHOOL_FREE_TRIAL_FAILED =
  "schoolFreeTrial/UPDATE_STATUS_SCHOOL_FREE_TRIAL_FAILED";

export const DELETE_SCHOOL_FREE_TRIAL_REQUEST =
  "schoolFreeTrial/DELETE_SCHOOL_FREE_TRIAL_REQUEST";
export const DELETE_SCHOOL_FREE_TRIAL_SUCCESS =
  "schoolFreeTrial/DELETE_SCHOOL_FREE_TRIAL_SUCCESS";
export const DELETE_SCHOOL_FREE_TRIAL_FAILED =
  "schoolFreeTrial/DELETE_SCHOOL_FREE_TRIAL_FAILED";
