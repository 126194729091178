import httpRequest from "../util/axios";

export const fetchListAppInbox = async (option = {}) => {
  try {
    const result = await httpRequest.get("/admin/app-inbox", option, null, {});

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCreateAppInbox = async (body = {}) => {
  try {
    const result = await httpRequest.post(
      "/admin/app-inbox",
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSendAppInbox = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/app-inbox/${body?.appInboxId}/send`,
      {
        ...body.payload,
      },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchAppInboxById = async (option = {}) => {
  try {
    const result = await httpRequest.get(
      `/admin/app-inbox/${option?.appInboxId}`
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdateAppInbox = async (body = {}) => {
  try {
    const result = await httpRequest.put(
      `/admin/app-inbox/${body?.appInboxId}`,
      { ...body.payload },
      null,
      {}
    );

    if (result.status === 200) {
      return result.data;
    } else {
      return result.response.data;
    }
  } catch (error) {
    throw error;
  }
};
